var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'UIElement',
    _vm.$style.ToggleSwitch,
    _vm.disabled && _vm.$style.disabled
  ]},[_c('span',{class:[
      _vm.$style.slider,
      _vm.isChecked && _vm.$style.checked
    ],style:(_vm.isChecked ? ("background-color: " + _vm.color): ''),on:{"click":_vm.toggle}})])}
var staticRenderFns = []

export { render, staticRenderFns }