<script>
export default {
  props: {
    /**
     * When disabled, the component renders the default slot content directly
     * without wrapping it in the form element.
     */
    enabled: {
      type: Boolean,
      default: true
    }
  },
  render(h) {
    if (!this.enabled) {
      return this.$scopedSlots.default({})
    }
    return h('form', {
      attrs: {
        autocomplete: 'off',
        onsubmit: 'return false;'
      }
    }, this.$scopedSlots.default({}))
  }
}
</script>
