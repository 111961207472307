<script>
import {mergeData} from 'vue-functional-data-merge'
import {Icon} from '../Icon'
import {InlineError} from '../InlineError'

export default {
  name: 'Labelled',
  components: {
    InlineError
  },
  props: {
    /**
     * The title of the field.
     */
    label: {
      type: String
    },
    /**
     * Displays the value as an error message below the slot content.
     */
    error: {
      type: String
    },
    /**
     * Displays the value as help text below the slot content.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    /**
     * @deprecated Makes the label title bold.
     */
    required: {
      type: Boolean
    }
  },
  functional: true,
  render(h, {data, props, slots, $style}) {
    const helpLinkMarkup = props.helpLink && h('a', {
      domProps: {
        href: props.helpLink,
        target: '_blank'
      }
    }, [
      h(Icon, {
        class: $style.helpIcon,
        props: {
          icon: 'fas fa-info-circle'
        }
      })
    ])

    let labelMarkup = props.label && h('div', {
      class: $style.label
    }, [
      props.label,
      helpLinkMarkup
    ])

    const labelScopedSlot = data.scopedSlots && data.scopedSlots.label
    if (labelScopedSlot) {
      labelMarkup = labelScopedSlot({
        label: props.label,
        helpLink: props.helpLink,
        className: $style.label
      })
    }

    const errorMarkup = props.error && h(InlineError, {
      props: {
        message: props.error
      }
    })

    const helpText = props.helpText && h('div', {
      class: $style.helpText
    }, [props.helpText])

    const helpTextHtml = props.helpTextHtml && h('div', {
      class: $style.helpText,
      domProps: {
        innerHTML: props.helpTextHtml
      }
    })

    return h('div', mergeData(data, {
      class: ['UIElement', props.required && $style.required]
    }), [
      labelMarkup,
      slots().default,
      errorMarkup,
      helpText,
      helpTextHtml
    ])
  }
}
</script>

<style lang="scss" module>
.label {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
}
.error {
  margin-top: 4px;
  color: #bf0711;
  font-size: 14px;
  line-height: 20px;
}
.helpIcon {
  margin-left: 5px;
  color: #86929d;
  &:hover {
    color: #333;
    cursor: pointer;
  }
}
.helpText {
  margin-top: 4px;
  color: #637381;
  font-size: 14px;
  line-height: 20px;
}
.required {
  font-weight: bold;
}
</style>
