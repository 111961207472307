import Vue from 'vue'
import {isObject} from './index'

/**
 * Extends the native `Map` class to allow it to be used reactively by Vue 2.
 */
export class ReactiveMap extends Map {
  constructor(...args) {
    super(...args)
    // Create temporary reactive object to get access to its Observer instance
    const temp = Vue.observable({})
    const ob = temp.__ob__
    // Point the observer to this Map instance
    ob.value = this
    // Add the observer to this instance so Vue reacts to its changes
    define(this, '__ob__', ob)
    // Wrap each of the Map methods which can cause mutations
    const originalSet = this.set
    define(this, 'set', function mutator(key, value) {
      const oldSize = this.size
      const oldValue = this.get(key)
      originalSet.call(this, key, value)
      if (
        // New property was added
        this.size !== oldSize ||
        // Existing primitive value or reference changed
        value !== oldValue ||
        // Always trigger changes for objects in case deeply nested structures
        // have changed. It's naive but simply and it'll work.
        isObject(value)
      ) {
        ob.dep.notify()
      }
    })
    ;['delete', 'clear'].forEach(method => {
      const original = this[method]
      define(this, method, function mutator(...args) {
        const oldSize = this.size
        original.apply(this, args)
        if (this.size !== oldSize) {
          ob.dep.notify()
        }
      })
    })
  }
}


/**
 * Extends the native `Set` class to allow it to be used reactively by Vue 2.
 */
export class ReactiveSet extends Set {
  constructor(...args) {
    super(...args)
    // Create temporary reactive object to get access to its Observer instance
    const temp = Vue.observable({})
    const ob = temp.__ob__
    // Point the observer to this Set instance
    ob.value = this
    // Add the observer to this instance so Vue reacts to its changes
    define(this, '__ob__', ob)
    // Wrap each of the Set methods which can cause mutations
    ;['add', 'delete', 'clear'].forEach(method => {
      const original = this[method]
      define(this, method, function mutator(...args) {
        const oldSize = this.size
        original.apply(this, args)
        if (this.size !== oldSize) {
          // Only notify when the set changes
          ob.dep.notify()
        }
      })
    })
  }
}

function define(obj, key, value) {
  Object.defineProperty(obj, key, {
    value: value,
    enumerable: false,
    writable: true,
    configurable: true
  })
}
