<script>
import {elementChildren, mergeData} from '../../utils'

export default {
  name: 'FormLayout',
  functional: true,
  render(h, {children, data, $style}) {
    const childrenMarkup = elementChildren(children).map(node => {
      return h('div', {
        class: $style.FormLayoutItem
      }, [node])
    })
    return h('div', mergeData(data, {
      class: $style.FormLayout
    }), childrenMarkup)
  }
}
</script>

<style lang="scss" module>
.FormLayout + .FormLayout {
  margin-top: 16px;
}
.FormLayoutItem + .FormLayoutItem {
  margin-top: 16px;
}
</style>
