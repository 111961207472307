<template>
  <div
    :class="[
      'UIElement',
      $style.Tree
    ]"
    :style="{
      '--gutter': gutter,
      '--lineColor': lineColor
    }"
  >
    <div :class="$style.Tree_wrapper">
      <TreeNode
        :node="root"
        :children-prop="childrenProp"
        :layout="layout"
      >
        <template v-slot:node="{node, parent, index}">
          <slot
            name="node"
            :node="node"
            :parent="parent"
            :index="index"
          />
        </template>
      </TreeNode>
    </div>
  </div>
</template>

<script>
import TreeNode from './TreeNode.vue'

export default {
  name: 'Tree',
  components: {
    TreeNode
  },
  props: {
    /**
     * The root node of the tree. This can be a recursive structure in the
     * form:
     *
     * ```
     * interface TreeNode {
     *   id: string | number // Unique ID
     *   children?: TreeNode[]
     * }
     * ```
     */
    root: {
      type: Object,
      required: true
    },
    /**
     * The name of the property to read as each node's children.
     */
    childrenProp: {
      type: String,
      default: 'children'
    },
    /**
     * The direction of the tree, `"horizontal"` or `"vertical"`.
     */
    layout: {
      type: String,
      default: 'vertical'
    },
    gutter: {
      type: String,
      default: '10px'
    },
    lineColor: {
      type: String,
      default: '#aaa'
    }
  }
}
</script>

<style lang="scss" module>
.Tree {
  display: flex;
}

.Tree_wrapper {
  // Used to center vertical layout otherwise depending on the width
  // there can be a 1px diff with the connecting primary lines.
  margin: 0 auto;
}
</style>
