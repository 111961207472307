var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.BulkEditor},[_c('layout',[_c('layout-section',{attrs:{"size":"primary"}},[_c('value-editor',{ref:"editor",on:{"update":function (val) { return _vm.setValue(val); }}}),_c('popup',{ref:"errorPopup"},[_vm._v(_vm._s(_vm.currentRowError))]),_c('card',{class:_vm.$style.TableCard,attrs:{"title":_vm.title,"actions":_vm.editorActions}},[_c('div',{class:_vm.$style.Table,style:(("--grid-template: " + _vm.gridTemplate))},[_c('div',{class:[
            _vm.$style.Row,
            _vm.$style.HeaderRow
          ]},[_c('div',{class:[
                _vm.$style.Cell,
                _vm.$style.idColumn,
                _vm.allowSort && _vm.$style.Cell_sortable,
                _vm.allowSort && _vm.sort.column === 'id' && _vm.$style.Cell_isSorted
              ],on:{"click":function () { return _vm.updateSort({ key: 'id', sortable: !!_vm.allowSort }); }}},[(_vm.allowSort)?_c('Icon',{class:_vm.$style.SortIcon,attrs:{"icon":("fa fa-caret-" + (_vm.sort.direction === 'asc' ? 'up' : 'down'))}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('ID'))+" ")],1),_vm._l((_vm._columns),function(col){return _c('div',{key:col.key,class:[
                _vm.$style.Cell,
                _vm.allowSort && col.sortable && _vm.$style.Cell_sortable,
                _vm.allowSort && _vm.sort.column === col.key && _vm.$style.Cell_isSorted ].concat( col.classes
              ),on:{"click":function () { return _vm.updateSort(col); }}},[(_vm.allowSort && col.sortable)?_c('Icon',{class:_vm.$style.SortIcon,attrs:{"icon":("fa fa-caret-" + (_vm.sort.direction === 'asc' ? 'up' : 'down'))}}):_vm._e(),_vm._v(" "+_vm._s(col.label)+" ")],1)})],2),_vm._l((_vm.records),function(row){return _c('div',{key:row.id,class:[
              _vm.$style.Row ].concat( _vm.getRowClasses(row)
            ),attrs:{"data-row-id":row.id}},[_c('div',{class:[
                _vm.$style.Cell,
                _vm.$style.idColumn
              ],attrs:{"data-id-col":""}},[_c('ActionList',{attrs:{"items":_vm.getRowActions(row)}},[_c('div',{class:_vm.$style.IdColumn_text},[_vm._v(" "+_vm._s(row.state === 'create' ? 'CREATED' : row.id)+" "),_c('Icon',{attrs:{"icon":"fa fa-caret-down"}})],1)]),(row.error)?_c('icon',{class:_vm.$style.RowErrorIcon,attrs:{"icon":"fa fa-exclamation-circle"},on:{"mouseenter":function($event){return _vm.showRowErrors(row)},"mouseleave":_vm.hideRowErrors}}):_vm._e()],1),_vm._l((_vm._columns),function(col){return _c('cell',{key:col.key,class:[].concat( col.classes ),attrs:{"column":col,"source":row.source[col.key],"value":row.updates[col.key],"selection":_vm.getCellSelection(col, row),"highlight":col.key === _vm.highlightedColumnKey && _vm.highlightedRowIds.includes(row.id),"row-state":row.state},on:{"edit":function($event){return _vm.editCell(col, row)},"dblclick":function($event){return _vm.editCell(col, row)},"revert":function($event){return _vm.revertCell(col, row)},"mousedown":function($event){return _vm.toggleCell($event, col, row)}}})})],2)})],2)])],1),_c('layout-section',{attrs:{"size":"secondary"}},[_c('div',{staticStyle:{"position":"sticky","top":"0"}},[_c('card',{attrs:{"title":_vm.$t('ui.lang.summary')}},[_c('card-section',[(_vm.changeSummary.length)?_c('List',_vm._l((_vm.changeSummary),function(item,index){return _c('ListItem',{key:index},[_c('TextStyle',{attrs:{"type":"link"},on:{"click":function($event){return _vm.highlightSummary(item)}}},[_vm._v(_vm._s(item.content))])],1)}),1):_c('TextStyle',{attrs:{"type":"subdued"}},[_vm._v(_vm._s(_vm.$t('ui.bulkEditor.noPendingChanges')))])],1),_c('card-section',{attrs:{"title":_vm.$t('ui.lang.problems') + ' (' + _vm.errors.length + ')'}},[_c('List',{attrs:{"type":"error","in-card-section":"","items":_vm.errors},on:{"select-item":_vm.onClickError}})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }