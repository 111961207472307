
export function getContextInjections() {
  return {
    defaultLocale: {
      from: 'locale',
      default: ''
    },
    defaultCurrency: {
      from: 'currency',
      default: ''
    }
  }
}