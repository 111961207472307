<template>
  <Labelled
    :label="label"
    :error="error"
    :help-text="helpText"
    :help-text-html="helpTextHtml"
    :help-link="helpLink"
  >
    <div
      class="UIElement"
      :class="[
        $style.Select,
        error && $style.hasError,
        isEmpty && $style.isEmpty,
        isOpen && $style.isOpen,
        multiple && $style.isMultiple,
        disabled && $style.isDisabled,
        readonly && $style.isReadonly,
        showDescription && $style.showDescription,
        $style['popperPlacement_' + popperPlacement]
      ]"
    >
      <div
        ref="control"
        :class="$style.control"
        :tabindex="disabled ? -1 : 0"
        @click="open"
        @blur="onBlur"
        @keydown="onKeydown"
      >
        <div :class="$style.DisplayText">
          <slot
            name="display"
            :displayText="displayText"
            :selectedOptions="selectedOptions"
          >
            <span v-html="displayText"></span>
          </slot>
        </div>
        <Spinner
          v-if="loading"
          size="small"
        />
        <Icon
          v-if="canClear"
          :class="$style.ClearButton"
          icon="fas fa-times-circle"
          @click.stop="clearSelected"
        />
        <Icon
          v-if="!loading"
          :class="$style.caret"
          icon="fas fa-caret-down"
        />
      </div>

      <div
        v-if="multiple && tags && selectedOptions.length > 0"
        :class="$style.Tags"
      >
        <slot
          name="tags"
          :selectedOptions="selectedOptions"
          :removeFn="toggleOption"
        >
          <Tag
            :class="$style.Tag"
            v-for="option in selectedOptions"
            :key="option.value"
            @remove="toggleOption(option)"
          >{{option.label}}</Tag>
        </slot>
      </div>
      <MaybeMountingPortal
        v-if="isOpen"
        mount-to="body"
        append
        :teleport="teleport"
      >
        <div
          ref="dropdown"
          :class="[
            'UIElement',
            $style.Dropdown,
            dropdownMinWidth && $style.Dropdown__minWidthSet
          ]"
          style="z-index: 10000;"
          :style="dropdownStyle"
          @click.stop
        >
          <div
            v-if="showSearch"
            :class="$style.Dropdown_search"
          >
            <Icon
              :class="$style.Dropdown_searchIcon"
              icon="far fa-search"
            />
            <input
              :class="$style.Dropdown_searchInput"
              v-model="searchText"
              ref="searchInput"
              :placeholder="$t('ui.select.search')"
              tabindex="-1"
              @focus="searchFocus"
              @blur="searchBlur"
              @keydown="onKeydown"
            />
            <Icon
              :class="$style.Dropdown_searchCloseIcon"
              icon="far fa-times"
              @click="searchText = ''"
              v-show="searchText"
            />
          </div>
          <div
            v-if="multiple && toggleAll && numCurrentOptions"
            :class="$style.ToggleAll"
            @mousedown.prevent="toggleAllOptions"
            @mouseover="activeIndex = -1"
          >
            <CheckboxControl
              :class="$style.Option_check"
              :checked="toggleAllState.checked"
              :indeterminate="toggleAllState.indeterminate"
              tabindex="-1"
            />
            {{$t('ui.lang.toggleAll')}} ({{numCurrentOptions}})
          </div>
          <ScrollPane
            :class="$style.Dropdown_content"
            ref="list"
          >
            <div
              v-for="(group, groupIndex) in filteredGroups"
              :key="groupIndex"
              :class="$style.OptionGroup"
            >
              <div
                v-if="group.title"
                :class="$style.OptionGroup_title"
              >{{group.title}}</div>
              <div
                v-for="option in group.options"
                :key="option.value"
                :class="[
                  $style.Option,
                  selectedMap[option.value] && $style.Option__selected,
                  option.disabled && $style.Option__disabled,
                  activeIndex === option.index && $style.Option__active
                ]"
                @mousedown.prevent="toggleOption(option)"
                @mouseover="activeIndex = option.index"
              >
                <slot
                  name="option"
                  :option="option"
                  :group="group"
                  :selected="selectedMap[option.value]"
                >
                  <CheckboxControl
                    v-if="multiple"
                    :class="$style.Option_check"
                    :checked="selectedMap[option.value]"
                    :disabled="option.disabled"
                    tabindex="-1"
                  />
                  <div :class="$style.Option_content">
                    <div :class="$style.Option_label">{{option.label}}</div>
                    <div
                      v-if="option.description"
                      :class="$style.Option_desc"
                    >{{option.description}}</div>
                  </div>
                </slot>
              </div>
            </div>
          </ScrollPane>
          <div
            v-if="!filteredGroups.length && searchText"
            :class="$style.NoResults"
          >{{noResults || $t('ui.select.noResults')}}</div>
          <div
            v-else-if="!filteredGroups.length && !searchText"
            :class="$style.NoResults"
          >{{noOptions || $t('ui.select.noOptions')}}</div>
        </div>
      </MaybeMountingPortal>
    </div>
    <!-- <pre>value: {{value}}</pre> -->
    <!-- <pre>isEmpty: {{isEmpty}}</pre> -->
    <!-- <pre>selected: {{selected}}</pre> -->
    <!-- <pre>selectedMap: {{selectedMap}}</pre> -->
    <!-- <pre>selectedOptions: {{selectedOptions}}</pre> -->
    <!-- <pre>options: {{options}}</pre> -->
    <!-- <pre>activeIndex: {{activeIndex}}</pre> -->
  </Labelled>
</template>

<script>
import {CheckboxControl} from '../Checkbox'
import {Icon} from '../Icon'
import {Labelled} from '../Labelled'
import {MaybeMountingPortal} from '../MaybeMountingPortal'
import {ScrollPane} from '../ScrollPane'
import {Spinner} from '../Spinner'
import {Tag} from '../Tag'
import createPopper from '../../utils/createPopper'
import {ReactiveSet, scrollIntoViewIfNeeded} from '../../utils'
import {nanoid} from 'nanoid'
import {nextTickUntil} from '../../utils/nextTickUntil'

export default {
  name: 'Select',
  components: {
    CheckboxControl,
    Icon,
    Labelled,
    MaybeMountingPortal,
    ScrollPane,
    Spinner,
    Tag
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * Label for the control.
     */
    label: {
      type: String
    },
    /**
     * Error text to show below the control.
     */
    error: {
      type: String
    },
    /**
     * Help text to show below the control.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    /**
     * A array of values when using the `multiple` mode, otherwise a single
     * value of any type.
     */
    value: {
      type: null
    },
    /**
     * The value to consider as "empty". When `value` is equal to this the
     * placeholder option will be shown. If an array is provided then any of its
     * values will be considered as empty.
     */
    emptyValue: {
      type: null,
      default: () => [null, undefined]
    },
    /**
     * An array with Option or OptionGroup object.
     *
     * ```ts
     * interface Option {
     *   label: string
     *   value: any
     *   // Archived options are hidden from the user unless the current control
     *   // value is equal to the option value, OR if the user has selected the
     *   // archived value previously since the component was created.
     *   archived?: boolean
     *   disabled?: boolean
     *   description?: string
     * }
     * ```
     */
    options: {
      type: Array,
      default: () => []
    },
    /**
     * Use as an alternative to `options`.
     *
     * ```ts
     * interface OptionGroup {
     *   label: string
     *   options: Option[]
     * }
     * ```
     */
    optionGroups: {
      type: Array
    },
    /**
     * Placeholder text to display when the input has no value.
     */
    placeholder: {
      type: String
    },
    /**
     * Whether to allow multiple values.
     */
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     * Text to display when no options are provided.
     */
    noOptions: {
      type: String
    },
    /**
     * Text to display when the user's search query matches no options.
     */
    noResults: {
      type: String
    },
    /**
     * Text to show the number of additional options selected when there are
     * more than 3 options are chosen. The `{count}` variable will be
     * substituted with the remaining number of options.
     */
    additionalOptions: {
      type: String
    },
    /**
     * Whether to show tags represented selected options at the bottom
     * of the control.
     */
    tags: {
      type: Boolean,
      default: false
    },
    /**
     * Shows the selected option description. Only applies to single-value
     * controls.
     */
    showDescription: {
      type: Boolean,
      default: false
    },
    /**
     * Function to customize how options are filtered for Selects using the
     * `searchable` prop. By default options are filtered based on a
     * case-insensitive match on option labels.
     *
     * The function has the following signature:
     *
     * `(searchText: string, options: Option[]) => Option[]`
     */
    filterFn: {
      type: Function,
      default(searchText, options) {
        const text = searchText.toLowerCase()

        return options.filter((d) => {
          return (
            d.label.toLowerCase().indexOf(text) >= 0 ||
            (d.description && d.description.toLowerCase().indexOf(text) >= 0)
          )
        })
      }
    },
    /**
     * Whether to show the filter input in the dropdown.
     *
     * - `auto`: Automatically show the search filter when there are more than
     *  10 items.
     * - `true`: Always show.
     * - `false`: Never show.
     *
     * Adding the `searchable` prop without a value is the same as setting it
     * to `true`.
     */
    searchable: {
      type: [Boolean, String],
      default: 'auto'
    },
    /**
     * Whether to show the loading spinner.
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Disables the control and prevents all interaction.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Prevents the user from editing the control value.
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * Allows clearing the selected value(s).
     *
     * - `multiple`: Only allow clearing multi-select inputs (default).
     * - `true`: Always allow clearing.
     * - `false`: Always disable clearing.
     */
    clearable: {
      type: [Boolean, String],
      default: 'multiple'
    },
    /**
     * When used with `multiple` it adds a "Toggle All" option at the top of the
     * option list. This special option toggles all/none of the currently
     * filtered options.
     */
    toggleAll: {
      type: Boolean,
      default: false
    },
    dropdownMinWidth: {
      type: String,
      default: ''
    },
    /**
     * Whether to use a portal to render the dropdown list.
     */
    teleport: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: false,
      teleportId: `Select_Teleport_${nanoid()}`,
      searchText: '',
      selected: null,
      popperPlacement: 'bottom',
      activeIndex: 0,
      // A reactive Set instance which holds all of the values ever selected
      // since component creation. This is used to determine if an archived
      // option has ever been selected, in which case we should continue to show
      // it as a visible option in the list.
      prevSelected: new ReactiveSet(),

      controlWidth: null,
      resizeObserver: null
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      if (this.$refs.control) {
        this.controlWidth = this.$refs.control.offsetWidth
      }
    }).observe(this.$refs.control)
  },
  watch: {
    value: {
      handler(value) {
        if (this.multiple) {
          this.selected = value || []
          for (const val of this.selected) {
            this.prevSelected.add(val)
          }
        } else {
          this.selected = value
          this.prevSelected.add(value)
        }
      },
      immediate: true
    },
    numCurrentOptions: {
      handler() {
        this.activeIndex = 0
      }
    }
  },
  computed: {
    dropdownStyle() {
      let style = ''
      if (this.teleport) {
        // const controlWidth = this.$refs.control.offsetWidth
        if (this.controlWidth) {
          style += `width: ${this.controlWidth}px;`
        }
      }
      if (this.dropdownMinWidth) {
        style += `min-width: ${this.dropdownMinWidth};`
      }
      return style
    },
    hasInvalidValue() {
      if (process.env.NODE_ENV === 'development') {
        const {isEmpty, multiple, selected, selectedOptions} = this
        const isInvalid = multiple
          ? selected.length !== selectedOptions.length
          : !isEmpty && !selectedOptions.length
        if (isInvalid) {
          if (multiple) {
            // eslint-disable-next-line no-console
            console.error(
              '<Select> model contains one or more invalid values.'
            )
          } else {
            // eslint-disable-next-line no-console
            console.error('<Select> model has invalid value.')
          }
        }
        return isInvalid
      }
      return false
    },
    displayText() {
      const {selectedOptions} = this
      if (this.isEmpty) {
        return this.placeholder
      }
      const count = selectedOptions.length
      if (!count) {
        // `value` does not match a valid option.
        return ''
      }
      if (this.multiple) {
        let html = selectedOptions
          .slice(0, 3)
          .map((opt) => opt.label)
          .join(', ')
        const remaining = count - 3
        if (remaining > 0) {
          let extraText = this.additionalOptions
          if (extraText) {
            extraText = extraText.replace('{count}', remaining)
          } else {
            extraText = this.$t('ui.select.additionalOptions', {
              count: remaining
            })
          }
          html += ` <span class="${this.$style.more}">${extraText}</span>`
        }
        return html
      } else {
        const option = selectedOptions[0]
        let html = option.label
        if (this.showDescription && option.description) {
          html = `<div class="${this.$style.DisplayText_label}">${option.label}</div>`
          html += `<div class="${this.$style.DisplayText_desc}">${option.description}</div>`
        }
        return html
      }
    },
    selectedMap() {
      if (this.isEmpty) {
        return {}
      }
      if (this.multiple) {
        return this.selected.reduce((map, val) => {
          map[val] = true
          return map
        }, {})
      }
      return {
        [this.selected]: true
      }
    },
    isEmpty() {
      const {emptyValue, multiple, selected} = this
      if (multiple) {
        return !selected.length
      }
      return Array.isArray(emptyValue)
        ? emptyValue.indexOf(selected) >= 0
        : emptyValue === selected
    },
    resolvedOptions() {
      const {options, optionGroups} = this
      if (optionGroups) {
        return optionGroups.reduce(function(list, group) {
          return list.concat(group.options)
        }, [])
      }

      return options
    },
    resolvedOptionGroups() {
      if (this.optionGroups) {
        return this.optionGroups
      }
      if (this.options.length) {
        return [
          {
            title: '',
            options: this.options
          }
        ]
      }
      return []
    },
    selectedOptions() {
      const {resolvedOptions, selectedMap} = this
      return resolvedOptions.filter((opt) => selectedMap[opt.value])
    },
    filteredGroups() {
      const {filterFn, resolvedOptionGroups, searchText} = this
      const trimmedSearchText = searchText && searchText.trim()
      // All the options in the final output need to have an `index` property
      // to indicate their order in the dropdown to allow keyboard navigation.
      let optionIndex = 0
      return resolvedOptionGroups.reduce((groups, group) => {
        let options = group.options
        if (filterFn && trimmedSearchText) {
          // Filter options for search text
          options = filterFn(trimmedSearchText, options, group)
        }
        // Hide archived options unless they have been previously selected
        options = options.filter(opt => {
          return !opt.archived || this.prevSelected.has(opt.value)
        })
        // Cache the indicies of visible options for keyboard navigation
        options = options.map(opt => {
          // Create new objects to avoid mutating the original options
          return {
            ...opt,
            index: optionIndex++
          }
        })
        if (options.length) {
          // Only return option groups that have at least one option
          groups.push({
            title: group.title,
            options
          })
        }
        return groups
      }, [])
    },
    /**
     * All options from `filteredGroups` merged together excluding the special
     * "Select All" option.
     */
    filteredOptions() {
      return this.filteredGroups.reduce((options, group) => {
        return options.concat(group.options)
      }, [])
    },
    numCurrentOptions() {
      return this.filteredOptions.length
    },
    showSearch() {
      return (
        this.searchable === true ||
        (this.searchable === 'auto' && this.resolvedOptions.length > 10)
      )
    },
    canClear() {
      return (
        (this.clearable === true ||
          (this.clearable === 'multiple' && this.multiple)) &&
        !this.loading &&
        !this.isEmpty &&
        !this.readonly &&
        !this.disabled
      )
    },
    toggleAllState() {
      const numSelected = this.selected ? this.selected.length : 0
      return {
        checked: numSelected !== 0,
        indeterminate:
          numSelected > 0 && numSelected !== this.numCurrentOptions
      }
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
    if (this._popper) {
      this._popper.destroy()
      this._popper = null
    }
    this.setDocClickHandler(false)
  },
  methods: {
    onBlur() {
      this.$nextTick(() => {
        if (this.isOpen && !this._didJustFocusSearch) {
          this.close()
        }
      })
    },
    focus() {
      this.$refs.control.focus()
    },
    searchFocus() {
      this._didJustFocusSearch = true
      this._isSearchFocused = true
      this.$nextTick(() => {
        this._didJustFocusSearch = false
      })
    },
    searchBlur() {
      this._isSearchFocused = false
      if (this.isOpen) {
        this.close()
      }
    },
    // Bound to both `control` and `searchInput` refs.
    onKeydown(event) {
      const code = event.code
      if (!this.isOpen) {
        if (code === 'Enter' || code === 'ArrowDown' || code === 'ArrowUp') {
          event.preventDefault()
          this.open()
        } else if (isSearchableKey(event.key)) {
          // User is starting to type a value. We need to open the dropdown and
          // start searching for the best option.
          event.preventDefault()
          this.open()
          if (this.showSearch) {
            // The search input is visible so iniitalize it with the initial
            // key. Subsequent keystrokes will continue to populate the input as
            // it will be focused after the dropdown opens.
            this.searchText = event.key
          } else {
            // No search input. Set the closest matching option to the active
            // index.
            this._findText = event.key.toLowerCase()
            const option = this.findOptionWithLabelStart(this._findText.trim())
            this.activeIndex = option ? option.index : 0
            this.scrollActiveOptionIntoView()
          }
        }
      } else {
        // Dropdown is open.
        if (code === 'Enter') {
          // Toggle active option.
          event.preventDefault()
          const option = this.findOption(
            (opt) => opt.index === this.activeIndex
          )
          if (option) {
            this.toggleOption(option)
          }
        } else if (code === 'ArrowDown') {
          // Navigate down/forwards in the list.
          event.preventDefault()
          this.activeIndex += 1
          if (this.activeIndex > this.numCurrentOptions - 1) {
            this.activeIndex = this.numCurrentOptions - 1
          }
          this.scrollActiveOptionIntoView()
        } else if (code === 'ArrowUp') {
          // Navigate up/backwards in the list.
          event.preventDefault()
          this.activeIndex -= 1
          if (this.activeIndex < 0) this.activeIndex = 0
          this.scrollActiveOptionIntoView()
        } else if (code === 'Escape') {
          // Close the dropdown.
          this.close()
          // Ensure the main control is focused in case the search input had
          // focus. This is so that the focus outline appears.
          this.$refs.control.focus()
        } else if (isSearchableKey(event.key)) {
          // The user has continued to type searchable keystrokes. Add this to a
          // search buffer to narrow down the active option chosen.
          this._findText += event.key.toLowerCase()
          // Clear the search buffer after 500ms so the user can initiate a
          // fresh search after pausing.
          clearTimeout(this._findTextTimeout)
          this._findTextTimeout = setTimeout(() => {
            this._findText = ''
          }, 500)
          const option = this.findOptionWithLabelStart(this._findText.trim())
          if (option) {
            this.activeIndex = option.index
            this.scrollActiveOptionIntoView()
          }
          // Prevent the page from jumping if there is no search input as the
          // user might press the SPACE key.
          if (!this.showSearch) {
            event.preventDefault()
          }
        }
      }
    },
    findOption(predicate) {
      for (const group of this.filteredGroups) {
        for (const option of group.options) {
          if (predicate(option)) {
            return option
          }
        }
      }
    },
    findOptionWithLabelStart(label) {
      return this.findOption(
        (opt) =>
          opt.label
            .toLowerCase()
            .trim()
            .indexOf(label) === 0
      )
    },
    scrollActiveOptionIntoView() {
      this.$nextTick(() => {
        const activeOptionEl = this.$refs.list.$el.querySelector(
          'div[class*="Option__active"]'
        )
        if (activeOptionEl) {
          scrollIntoViewIfNeeded(activeOptionEl)
        }
      })
    },
    open() {
      if (this.disabled || this.readonly) {
        return
      }
      // Reset find buffer
      clearTimeout(this._findTextTimeout)
      this._findText = ''
      this.searchText = ''

      this.isOpen = true
      this.setDocClickHandler(true)

      // For single value mode, reset the active index to the selected option,
      // defaulting to the first visible option if none is currently selected.
      this.activeIndex = 0
      const selected = this.selected
      if (!this.multiple && selected) {
        const selectedOption = this.findOption((opt) => opt.value === selected)
        if (selectedOption) {
          this.activeIndex = selectedOption.index
        }
      }

      // Wait for the dropdown element ref to exist before initializing the
      // popper to position it.
      nextTickUntil(() => {
        return !!this.$refs.dropdown
      }, () => this.initPopper())
    },
    close() {
      this.setDocClickHandler(false)
      this.isOpen = false
    },
    initPopper() {
      const {
        control: controlEl,
        dropdown: dropdownEl
      } = this.$refs
      if (this._popper) {
        this._popper.destroy()
      }
      let lastPopperPlacement = this.dropdownMinWidth ? 'bottom-start' : 'bottom'
      this._popper = createPopper(controlEl, dropdownEl, {
        placement: this.dropdownMinWidth ? 'bottom-start' : 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0]
            }
          },
          {
            name: 'detectFlip',
            phase: 'afterWrite',
            enabled: true,
            fn: ({state}) => {
              if (state.placement !== lastPopperPlacement) {
                lastPopperPlacement = state.placement
                this.popperPlacement = state.placement
              }
            }
          }
        ],
        onFirstUpdate: () => {
          if (this.showSearch) {
            this.$refs.searchInput.focus()
          }
          // Scroll list to active item after showing the popper.
          this.scrollActiveOptionIntoView()
        }
      })
    },
    updatePopper() {
      if (this._popper) {
        this._popper.update()
      }
    },
    clearSelected() {
      if (this.multiple) {
        this.selected = []
      } else {
        this.selected = Array.isArray(this.emptyValue)
          ? this.emptyValue[0]
          : null
      }
      this.emitChange(this.selected)
    },
    setDocClickHandler(doAdd) {
      if (doAdd && !this._onDocClick) {
        setTimeout(() => {
          this._onDocClick = () => {
            this.close()
          }
          document.addEventListener('click', this._onDocClick)
        })
      } else if (!doAdd && this._onDocClick) {
        document.removeEventListener('click', this._onDocClick)
        this._onDocClick = null
      }
    },
    getOptionState(option) {
      if (this.multiple) {
        return this.selected.indexOf(option.value) >= 0
      }
      return this.selected === option.value
    },
    toggleOption(option) {
      if (option.disabled) {
        return
      }
      const isSelected = this.getOptionState(option)
      if (this.multiple) {
        if (isSelected) {
          // Deselect an option
          const newSelected = this.selected.filter(
            (val) => val !== option.value
          )
          this.selected = newSelected
          this.emitChange(this.selected)
        } else {
          // Select an option
          const newSelected = this.selected.slice(0)
          newSelected.push(option.value)
          this.selected = newSelected
          this.emitChange(this.selected)
          // Add to list of previously selected values so that archived options
          // are respected.
          this.prevSelected.add(option.value)
        }
      } else {
        this.close()
        this.selected = option.value
        // Ensure the that control regains focus after choosing an option in a
        // searchable list.
        this.$refs.control.focus()
        this.emitChange(this.selected)
      }
    },
    toggleAllOptions() {
      const allValues = this.filteredOptions.map((option) => option.value)
      if (this.selected.length !== allValues.length) {
        this.selected = allValues
      } else {
        this.selected = []
      }
      this.emitChange(this.selected)
    },
    emitChange(selected) {
      /**
       * An array of values if `multiple` otherwise a single value.
       * @type {any, any[]}
       */

      this.$emit('change', selected)
      this.updatePopper()
    }
  }
}

function isSearchableKey(char) {
  return (
    char === ' ' || (char >= '0' && char <= '9') || (char >= 'a' && char <= 'z')
  )
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

$borderColor: #c4cdd5;

.Select {
  position: relative;
}

.isReadonly {
  &,
  * {
    cursor: $inputReadonlyCursor !important;
  }
  .control {
    background: #fff;
    box-shadow: none;
  }
}
.isDisabled {
  &,
  * {
    cursor: $inputDisabledCursor !important;
  }
}

.control {
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 5px 12px;
  background: linear-gradient(to bottom, #fff, #f9fafb);
  border: 1px solid $borderColor;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(22, 29, 37, 0.05);
  font-size: 14px;

  &:focus {
    border-color: $focusColor;
    box-shadow: 0 0 0 1px $focusColor;
    outline: none;
  }

  .hasError & {
    border-color: $errorColor;
    background: linear-gradient(to bottom, lighten(#fbeae5, 2%), #fbeae5);
    &:focus {
      box-shadow: 0 0 0 1px $errorColor;
    }
  }

  .isDisabled & {
    background: #f9fafb;
    box-shadow: none;
    border-color: $borderColor;
    color: #919eab;
  }

  .isOpen & {
    border: 1px solid $focusColor;
    box-shadow: none;
  }
  .isOpen.popperPlacement_top & {
    border-top: 1px solid $borderColor !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 1px 5px 0 rgba(22, 29, 37, 0.2);
  }
  .isOpen.popperPlacement_bottom & {
    border-bottom: 1px solid $borderColor !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -1px 5px 0 rgba(22, 29, 37, 0.2);
  }
  .isOpen.hasError & {
    border: 1px solid $errorColor;
  }
}

.DisplayText {
  flex: 1;
  cursor: default;

  .isEmpty & {
    color: #888;
  }
}
.more {
  color: #888;
}

.caret {
  color: #627280;

  .isDisabled & {
    color: #919eab;
  }
}

.showDescription {
  .control {
    padding: 8px 12px;
  }
  .DisplayText {
    line-height: 1.5;
    min-width: 0;
  }
}

.DisplayText_label {
  font-size: 13px;
  font-weight: 500;
}
.DisplayText_desc {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  :global(.UILayoutSectionAnnotated) & {
    // HACK: The maximum width of LayoutSectionAnnotated content.
    max-width: 480px;
  }
}

.ClearButton {
  margin-right: 6px;
  color: #627280;

  &:hover {
    color: #000;
    cursor: pointer;
  }
}

.Dropdown {
  position: absolute;
  top: 36px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border: 1px solid $focusColor;
  border-radius: 3px;

  .hasError & {
    border-color: $errorColor;
  }

  &[data-popper-placement='top'] {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -3px 5px 0 rgba(22, 29, 37, 0.2);
  }
  &[data-popper-placement='bottom'] {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 3px 5px 0 rgba(22, 29, 37, 0.2);
  }

  &.Dropdown__minWidthSet {
    // because top-start / bottom-start is used don't need to override?
    &[data-popper-placement='top-start'] {
      box-shadow: 0 -3px 5px 0 rgba(22, 29, 37, 0.2);
    }
    &[data-popper-placement='bottom-start'] {
      box-shadow: 0 3px 5px 0 rgba(22, 29, 37, 0.2);
    }
  }
}

.Dropdown_search {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lighten(#c4cdd5, 10%);
}
.Dropdown_searchIcon {
  margin-left: 10px;
  color: #c4cdd5;
  font-size: 14px;
}
.Dropdown_searchCloseIcon {
  padding: 4px 10px;
  color: #c4cdd5;
  font-size: 18px;

  &:hover {
    color: #000;
    cursor: pointer;
  }
}
.Dropdown_searchInput {
  width: 100%;
  padding: 8px 5px;
  border: none;
  font-family: inherit;
  font-size: 14px;
  outline: none;
}
.Dropdown_content {
  flex: 1 1 auto;
  max-height: 200px;
}

.NoResults {
  padding: 10px;
  color: #212b36;
  font-size: 14px;
}

.OptionGroup {
  & + & {
    margin-top: 2px;
  }
}

.OptionGroup_title {
  padding: 8px 8px 2px 10px;
  color: #888;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
}

.Option,
.ToggleAll {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
}
.Option_check {
  // Prevent long labels from squashing checkbox.
  flex: 0 0 auto;
  margin-right: 10px !important;
  // Prevent interaction as checkbox is toggled programmatically.
  pointer-events: none;
}
.Option_desc {
  margin-top: 3px;
  color: #666;
  font-size: 12px;
  line-height: 1.2;
}
.Option__disabled {
  opacity: 0.5;
  pointer-events: none;
}
.Option__active {
  background: #f5f5f5;
}

.ToggleAll {
  border-bottom: 1px solid #ddd;

  &:hover {
    background: #f5f5f5;
  }
}

.Tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
