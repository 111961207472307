<script>
import {mergeData} from 'vue-functional-data-merge'
import {Icon} from '../Icon'
// import {Tooltip} from '../Tooltip'

export default {
  props: {
    /**
     * Disables the remove button.
     */
    required: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: null
    },
    tooltipPosition: {
      type: String,
      default: 'top'
    }
  },
  functional: true,
  render(h, {children, data, props, $style}) {
    const remove = data.on && data.on.remove

    const buttonMarkup = h('button', {
      class: $style.Tag_button,
      domProps: {
        disabled: props.required
      },
      on: remove ? {
        click: remove
      } : {}
    }, [
      h(Icon, {
        props: {
          icon: 'fal fa-times'
        }
      })
    ])

    const labelMarkup = h('span', {
      class: $style.Tag_text
    }, children)

    const contentMarkup = []
    contentMarkup.push(labelMarkup, buttonMarkup)

    const outerAttributes = {
      class: [
        'UIElement',
        $style.Tag,
        props.required && $style.Tag__isRequired
      ],
      attrs: {}
    }
    if (props.tooltip) {
      outerAttributes.attrs['data-ui-tooltip'] = props.tooltip
      outerAttributes.attrs['data-ui-tooltip-position'] = props.tooltipPosition
    }
    return h('span', mergeData(data, outerAttributes), contentMarkup)
  }
}
</script>

<style lang="scss" module>
$radius: 3px;
$size: 28px;

.Tag {
  display: inline-flex;
  // display: inline-block;
  grid-template-columns: auto auto;
  align-items: center;
  min-height: $size;
  padding-left: 8px;
  background: #dfe3e7;
  border-radius: $radius;
  color: #2b3640;
  cursor: default;
}

// .Tag + .Tag {
//   margin-left: 4px;
// }

.Tag__isRequired {
  .Tag_button {
    cursor: not-allowed;
    color: #aaa;
  }
}

.Tag_text {
  border-radius: $radius 0 0 $radius;
  flex: 0 0 auto;
}

.Tag_button {
  flex: 0 0 $size;
  width: $size;
  height: $size;
  margin-left: 4px;
  background: transparent;
  border: none;
  border-radius: 0 $radius $radius 0;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  &:not(:disabled) {
    &:hover, &:focus {
      background: #c4cdd5;
      color: #000;
      cursor: pointer;
    }
  }
}
</style>
