var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Labelled',{attrs:{"label":_vm.label,"error":_vm.error,"help-text":_vm.helpText,"help-text-html":_vm.helpTextHtml,"help-link":_vm.helpLink}},[_c('div',{staticClass:"UIElement",class:[
      _vm.$style.AutoComplete,
      _vm.error && _vm.$style.hasError,
      _vm.isEmpty && _vm.$style.isEmpty,
      _vm.isOpen && _vm.filteredOptions.length && _vm.$style.isOpen,
      _vm.disabled && _vm.$style.isDisabled,
      _vm.readonly && _vm.$style.isReadonly,
      _vm.$style['popperPlacement_' + _vm.popperPlacement]
    ]},[_c('div',{class:_vm.$style.control,on:{"click":_vm.open,"keydown":_vm.onKeydown}},[_c('input',{ref:"control",class:[
          _vm.$style.AutoComplete_input,
          _vm.error && _vm.$style.AutoComplete_input__hasError
        ],attrs:{"tabindex":_vm.disabled ? -1 : 0,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"text"},domProps:{"value":_vm.inputValue},on:{"input":_vm.onInput}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"dropdown",class:_vm.$style.Dropdown},[_c('ScrollPane',{ref:"list",class:_vm.$style.Dropdown_content},_vm._l((_vm.filteredOptions),function(option){return _c('div',{key:option.index,class:[
            _vm.$style.Option,
            _vm.activeIndex === option.index && _vm.$style.Option__active
          ],on:{"mousedown":function($event){$event.preventDefault();return _vm.select(option.value)},"mouseover":function($event){_vm.activeIndex = option.index}}},[_c('div',{class:_vm.$style.Option_content},[_c('div',{class:_vm.$style.Option_label},[_vm._v(_vm._s(option.value))])])])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }