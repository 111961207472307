var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"title":_vm.opts.title,"primaryAction":{
    label: _vm.opts.primaryActionLabel || _vm.$t('ui.modal.submit'),
    type: 'primary',
    disabled: !this.canSubmit,
    onAction: _vm.submit
  },"secondaryActions":[{
    label: _vm.opts.secondaryActionLabel || _vm.$t('ui.modal.cancel'),
    onAction: _vm.cancel
  }],"visible":_vm.visible},on:{"close":_vm.cancel,"afterEnter":_vm.handleEnter,"afterLeave":_vm.handleLeave}},[_c('render-node',{staticStyle:{"margin-bottom":"8px"},attrs:{"node":_vm.opts.content}}),_c('TextField',{ref:"input",attrs:{"helpText":_vm.opts.inputHelpText,"error":_vm.error,"placeholder":_vm.opts.inputPlaceholder,"multiline":_vm.multiline},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit($event, true)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }return _vm.cancel.apply(null, arguments)}]},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }