<template>
  <div
    :class="[
      'UIElement',
      $style.ToggleSwitch,
      disabled && $style.disabled
    ]"
  >
    <span
      :class="[
        $style.slider,
        isChecked && $style.checked
      ]"
      :style="isChecked ? `background-color: ${color}`: ''"
      @click="toggle"
    />
  </div>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * The bound value / v-model.
     */
    value: {
      type: null
    },
    /**
     * The model value to use when checked.
     */
    trueValue: {
      type: null,
      default: true
    },
    /**
     * The model value to use when not checked.
     */
    falseValue: {
      type: null,
      default: false
    },
    /**
     * When `true` the checkbox will be checked. This is an alternative
     * to assigning `value` to be `trueValue`.
     */
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type:String,
      default: '#2196F3'
    }
  },
  methods: {
    toggle() {
      this.$emit('change', this.isChecked ? this.falseValue : this.trueValue)
    }
  },
  computed: {
    isChecked() {
      return (this.value === this.trueValue || this.checked)
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

// https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_switch
.ToggleSwitch {
  position: relative;
  display: flex;
  width: 60px;
  height: 34px;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}


.slider {
  border-radius: 34px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  border-radius: 50%;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

// .slider.checked {
//   background-color: #2196F3;
// }

.slider.checked:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

</style>
