var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.TreeNode,
    _vm.layout === 'horizontal' && _vm.$style.horizontal,
    _vm.layout === 'vertical' && _vm.$style.vertical,
    _vm.info.hasSiblings && _vm.$style.hasSiblings,
    _vm.info.isFirst && _vm.$style.isFirst,
    _vm.info.isLast && _vm.$style.isLast,
    _vm.info.isEdge && _vm.$style.isEdge,
    _vm.info.hasParent && _vm.$style.hasParent,
    _vm.info.hasChildren && _vm.$style.hasChildren
  ]},[_c('div',{class:_vm.$style.TreeNode_contentWrapper},[_c('div',{class:_vm.$style.TreeNode_content,style:(_vm.contentStyle)},[_vm._t("node",function(){return [_vm._v(_vm._s(_vm.node.id))]},{"node":_vm.node,"parent":_vm.parent,"index":_vm.index})],2)]),(_vm.node[_vm.childrenProp] && _vm.node[_vm.childrenProp].length)?_c('div',{class:_vm.$style.TreeNode_children},_vm._l((_vm.node[_vm.childrenProp]),function(child,index){return _c('TreeNode',{key:child.id,attrs:{"parent":_vm.node,"node":child,"children-prop":_vm.childrenProp,"index":index,"layout":_vm.layout},scopedSlots:_vm._u([{key:"node",fn:function(ref){
  var node = ref.node;
  var parent = ref.parent;
  var index = ref.index;
return [_vm._t("node",function(){return [_vm._v(_vm._s(node.id))]},{"node":node,"parent":parent,"index":index})]}}],null,true)})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }