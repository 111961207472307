<template>
  <Labelled
    :label="label"
    :error="error"
    :help-text="helpText"
    :help-text-html="helpTextHtml"
    :help-link="helpLink"
  >
    <div
      :class="[
        'UIElement',
        $style.FileInput
      ]"
    >
      <input
        type="file"
        ref="input"
        :accept="accept"
        :multiple="multiple"
        @change="onChangeFiles"
      />
      <ItemList
        v-if="items.length"
        :items="items"
        style="margin-bottom: 8px"
        :readonly="readonly"
        @remove="index => removeItemAt(index)"
      />
      <div
        :class="$style.Validation"
        v-if="isValidating"
      >
        <Spinner size="small" />
        <span style="padding-left: 10px">{{$t('ui.fileInput.validatingFiles')}}</span>
      </div>
      <DropZone
        v-else-if="(multiple || !items.length) && !readonly"
        :multiple="multiple"
        @click="openDialog"
        @drop="files => appendFiles(files)"
      />
    </div>
  </Labelled>
</template>

<script>
import {Labelled} from '../Labelled'
import {Spinner} from '../Spinner'
import {format} from '../../utils/format'
import DropZone from './DropZone'
import ItemList from './ItemList'
import {
  createAcceptFileMatcher,
  getMimeTypeInfo
} from './utils'

export default {
  name: 'FileInput',
  components: {
    DropZone,
    ItemList,
    Labelled,
    Spinner
  },
  model: {
    prop: 'files',
    event: 'change'
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    /**
     * Label to display above the input.
     */
    label: {
      type: String
    },
    /**
     * Error text to display beneath the input.
     */
    error: {
      type: String
    },
    /**
     * Additional help text to display.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    /**
     * A string that defines the file types the file input should accept.
     *
     * This string is a comma-separated list of [unique file type specifiers](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers).
     */
    accept: {
      type: String,
      default: ''
    },
    /**
     * Maximum individual file size allowed in bytes.
     */
    maxBytes: {
      type: Number,
      default: Infinity
    },
    /**
     * Allow multiple files to be uploaded.
     */
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     * Custom file validator which should return a custom error.
     *
     * - All truthy results will be considered an error. If the result is
     *  a string, it will be used as the error message.
     * - Supports promises.
     */
    validator: {
      type: Function
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isValidating: false
    }
  },
  computed: {
    acceptFn() {
      return createAcceptFileMatcher(this.accept)
    },
    items() {
      return this.files.map(file => {
        return {
          name: file.name,
          size: file.size,
          error: file.error,
          lastModified: file.lastModified,
          icon: getMimeTypeInfo(file.type).icon
        }
      })
    }
  },
  methods: {
    openDialog() {
      this.$refs.input.click()
    },
    emitChange(files) {
      /**
       * An array of native `File` instances which is emitted when the the user
       * adds or removes files.
       *
       * **Note**: An additional `error` property is added to File instances which
       * is equal to any error message shown in the component.
       * @event change
       * @property {File[]} files Current files in the list.
       */
      this.$emit('change', files)
    },
    removeItemAt(index) {
      const files = this.files.slice(0)
      files.splice(index, 1)
      this.emitChange(files)
    },
    async appendFiles(fileList) {
      const validator = this.validator || (() => undefined)
      const showProcessingTimeout = setTimeout(() => this.isValidating = true, 100)
      const files = await Promise.all(Array.from(fileList).map(file => {
        return Promise.resolve(validator(file)).then(result => {
          if (result) {
            file.error = typeof result === 'string'
              ? result
              : this.$t('ui.fileInput.invalidFile')
          }
          if (!file.error && !this.acceptFn(file)) {
            file.error = this.$t('ui.fileInput.invalidFileType')
          }
          if (!file.error && file.size > this.maxBytes) {
            const size = format(this.maxBytes, 'filesize')
            file.error = this.$t('ui.fileInput.mustBeLessThan', {size})
          }
          return Object.freeze(file)
        })
      }))
      clearTimeout(showProcessingTimeout)
      this.isValidating = false
      if (files.length) {
        if (this.multiple) {
          this.emitChange([
            ...this.files,
            ...files
          ])
        } else {
          this.emitChange([
            files[0]
          ])
        }
      }
      // Clear the input so changes can be detected later
      this.$refs.input.value = ''
    },
    onChangeFiles() {
      const files = this.$refs.input.files
      this.appendFiles(files)
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

.FileInput {
  input[type=file] {
    position: absolute;
    visibility: hidden;
  }
}

.Validation {
  display: flex;
  align-items: center;
  padding: 12px 12px;
  background: #f4f6f8;
  border-radius: 3px;
}
</style>
