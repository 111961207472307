<script>
import {mergeData} from 'vue-functional-data-merge'
import {Popper} from '../Popper'

export default {
  name: 'Tooltip',
  components: {
    Popper
  },
  props: {
    /**
     * The placement of the tooltip.
     *
     * @see Popper
     */
    placement: {
      type: String
    },
    content: {
      type: String
    }
  },
  functional: true,
  render(h, {data, props, slots, $style}) {
    const contentMarkup = h('div', {
      class: [
        'UIElement',
        $style.Tooltip
      ]
    }, props.content || slots().content)

    return h(Popper, mergeData(data, {
      props: {
        placement: props.placement,
        zIndex: 10000
      },
      style: {
        display: 'inline'
      },
      scopedSlots: {
        trigger({show, hide}) {
          return h('span', {
            class: $style.Tooltip_trigger,
            on: {
              mouseenter() {
                show()
              },
              mouseleave() {
                hide()
              }
            }
          }, slots().default)
        }
      }
    }), [contentMarkup])
  }
}
</script>

<style lang="scss" module>
.Tooltip {
  max-width: 300px;
  padding: 8px;
  background: #212b36;
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  color: #fff;
}

.Tooltip_trigger {
  // border: 1px solid blue;
}
</style>
