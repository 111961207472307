import {createWildcardRegExp} from '../../utils'

export function getMimeTypeInfo(mimeType) {
  if (/image/.test(mimeType)) {
    return {
      mimeType,
      type: 'image',
      icon: 'fa-file-image'
    }
  }
  if (/video/.test(mimeType)) {
    return {
      mimeType,
      type: 'video',
      icon: 'fa-file-video'
    }
  }
  if (/audio/.test(mimeType)) {
    return {
      mimeType,
      type: 'audio',
      icon: 'fa-file-audio'
    }
  }
  if (/text\/csv/.test(mimeType)) {
    return {
      mimeType,
      type: 'csv',
      icon: 'fa-file-csv'
    }
  }
  if (/spreadsheet/.test(mimeType)) {
    return {
      mimeType,
      type: 'spreadsheet',
      icon: 'fa-file-spreadsheet'
    }
  }
  if (/application\/(zip|x-gzip)/.test(mimeType)) {
    return {
      mimeType,
      type: 'archive',
      icon: 'fa-file-archive'
    }
  }
  if (/text\/plain/.test(mimeType)) {
    return {
      mimeType,
      type: 'text',
      icon: 'fa-file-alt'
    }
  }
  if (/application\/.*(msword|wordprocessingml)/.test(mimeType)) {
    return {
      mimeType,
      type: 'text',
      icon: 'fa-file-word'
    }
  }
  if (/presentation/.test(mimeType)) {
    return {
      mimeType,
      type: 'text',
      icon: 'fa-file-powerpoint'
    }
  }
  if (/application\/pdf/.test(mimeType)) {
    return {
      mimeType,
      type: 'text',
      icon: 'fa-file-pdf'
    }
  }
  return {
    mimeType,
    type: 'other',
    icon: 'fa-file'
  }
}

export function createAcceptFileMatcher(accept) {
  const matchers = accept
    .trim()
    .split(/\s*,+\s*/)
    .filter(d => d.trim())
    .map(specifier => {
      // File extension match e.g. `.ext`
      const extensionMatch = /^\.(.+)/.exec(specifier)
      if (extensionMatch) {
        const extRE = /\.([^.]+)$/
        return function(file) {
          const match = extRE.exec(file.name)
          return match && match[1] === extensionMatch[1]
        }
      }
      // MIME type match e.g. `image/*`
      const typeRE = createWildcardRegExp(specifier)
      return function(file) {
        return typeRE.test(file.type)
      }
    })
  return function(file) {
    return !matchers.length || matchers.some(fn => fn(file))
  }
}
