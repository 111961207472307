var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MaybeRouterLink',{class:_vm.$style.ItemContainer,attrs:{"to":_vm.item.url,"match-routes":!_vm.location,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('div',{class:[
        'UIElement',
        _vm.$style.ItemContainer,
        _vm.isSubItem && _vm.$style.isSubItem,
        _vm.hasChildren && _vm.$style.hasChildren,
        (isActive || _vm.simpleMatch.isActive) && _vm.$style.isActive,
        (isExactActive || _vm.simpleMatch.isExactActive) && _vm.$style.isExactActive,
        _vm.min && _vm.$style.min
      ]},[(_vm.min && (!_vm.item.items || !_vm.item.items.length))?_c('Tooltip',{attrs:{"content":_vm.item.label,"placement":"right"}},[_c('a',{class:[
            _vm.$style.Item,
            _vm.theme === 'dark' && _vm.$style.Dark
          ],attrs:{"href":href},on:{"click":function (event) { return _vm.$emit('click', _vm.item, event); }}},[(_vm.hasPrimaryIcons && _vm.item.icon)?_c('Icon',{class:[
              _vm.$style.Item_icon,
              _vm.$style.Item_icon_min
            ],attrs:{"icon":'fa-fw ' + _vm.item.icon}}):_vm._e()],1)]):(!_vm.min)?_c('a',{class:[
          _vm.$style.Item,
          _vm.theme === 'dark' && _vm.$style.Dark
        ],attrs:{"href":href},on:{"click":function (event) { return _vm.$emit('click', _vm.item, event); }}},[(_vm.hasPrimaryIcons && _vm.item.icon)?_c('Icon',{class:[
            _vm.$style.Item_icon,
            _vm.$style.Item_icon_max
          ],attrs:{"icon":'fa-fw ' + _vm.item.icon}}):_vm._e(),_c('span',{class:_vm.$style.Item_label},[_vm._v(_vm._s(_vm.item.label))]),(_vm.item.badge)?_c('span',{class:[
            _vm.$style.Item_badge,
            'dark' && _vm.$style.Item_badge_dark ]},[_vm._v(_vm._s(_vm.item.badge))]):_vm._e()],1):(_vm.min && _vm.item.items && _vm.item.items.length)?_c('Popper',{attrs:{"zIndex":10000,"placement":"right"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
          var show = ref.show;
          var hide = ref.hide;
return [_c('a',{class:[
              _vm.$style.Item,
              _vm.theme === 'dark' && _vm.$style.Dark
            ],attrs:{"href":href},on:{"click":function (event) { return _vm.$emit('click', _vm.item, event); },"mouseenter":function($event){return _vm.mouseEnterIcon(show)},"mouseleave":function($event){return _vm.mouseLeaveIcon(hide)}}},[(_vm.hasPrimaryIcons && _vm.item.icon)?_c('Icon',{class:[
                _vm.$style.Item_icon,
                _vm.$style.Item_icon_min ],attrs:{"icon":'fa-fw ' + _vm.item.icon}}):_vm._e()],1)]}},{key:"default",fn:function(ref){
              var hide = ref.hide;
return [_c('div',{class:[
              _vm.$style.Content,
              _vm.$style.SubItemContent,
              _vm.theme === 'dark' && _vm.$style.SubItemContent_Dark,
              _vm.min && _vm.$style.SubItemContent_min
            ],on:{"mouseenter":_vm.mouseEnterSub,"mouseleave":function($event){return _vm.mouseLeaveSub(hide)}}},_vm._l((_vm.item.items),function(child){return _c('Item',{key:child.id,attrs:{"item":child,"theme":_vm.theme,"location":_vm.location},on:{"click":function (child, event) { return _vm.$emit('click', child, event); }}})}),1)]}}],null,true)}):_vm._e(),(!_vm.isSubItem && (isActive || _vm.simpleMatch.isActive) && !_vm.min)?_c('div',{class:_vm.$style.Content},_vm._l((_vm.item.items),function(child){return _c('Item',{key:child.id,attrs:{"item":child,"theme":_vm.theme,"location":_vm.location,"is-sub-item":""},on:{"click":function (child, event) { return _vm.$emit('click', child, event); }}})}),1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }