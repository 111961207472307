<template>
  <div :class="$style.SimplePopper">
    <transition
      :css="animate"
      :enter-active-class="$style.backdropEnterActive"
      :leave-active-class="$style.backdropLeaveActive"
      :enter-class="$style.backdropEnter"
      :leave-to-class="$style.backdropLeaveTo"
    >
      <div
        v-if="backdrop && isOpen"
        :class="$style.Backdrop"
      ></div>
    </transition>
    <div
      @click="open"
    >
      <slot name="trigger" :isOpen="isOpen"></slot>
    </div>
    <transition
      :css="animate"
      :enter-active-class="$style.contentEnterActive"
      :leave-active-class="$style.contentLeaveActive"
      :enter-class="$style.contentEnter"
      :leave-to-class="$style.contentLeaveTo"
    >
      <div
        v-if="isOpen"
        :class="[
          $style.Content,
          align === 'left' ? $style.Content__left : $style.Content__right,
          contentClass
        ]"
        ref="content"
      >
        <slot :close="close"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: 'left'
    },
    backdrop: {
      type: Boolean
    },
    animate: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    contentClass: {
      type: String
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    open() {
      if (this.disabled) {
        return
      }
      this.removeClickHandler()
      this.isOpen = true
      setTimeout(() => {
        this.onDocClick = (event) => {
          const contenetEl = this.$refs.content
          if (contenetEl && contenetEl.contains(event.target)) {
            return
          }
          this.close()
        }
        window.addEventListener('click', this.onDocClick, {capture: true})
      }, 100)
    },
    close() {
      this.removeClickHandler()
      this.isOpen = false
    },
    removeClickHandler() {
      if (this.onDocClick) {
        window.removeEventListener('click', this.onDocClick, {capture: true})
        this.onDocClick = null
      }
    }
  }
}
</script>

<style lang="scss" module>
.SimplePopper {
  position: relative;
}
.Content {
  position: absolute;
  z-index: 500;
}
.Content__left {
  left: 0;
}
.Content__right {
  right: 0;
}

.Backdrop {
  position: fixed;
  inset: 0;
  z-index: 499;
  background: #000;
  opacity: 0.32;
  will-change: opacity;
}

.backdropEnterActive,
.backdropLeaveActive {
  transition: opacity 0.5s ease;
}
.backdropEnter,
.backdropLeaveTo {
  opacity: 0;
}

.contentEnterActive {
  transition: all .3s ease;
}
.contentEnter, .contentLeaveTo {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
