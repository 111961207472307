<template>
  <div :class="$style.Layout">
    <slot></slot>
  </div>
</template>

<script>
/**
 * @requires ./LayoutSection.vue
 * @requires ./LayoutSectionAnnotated.vue
 */
export default {
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

.Layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-self: flex-start;
  margin-top: -$layoutGutter;
  margin-left: -$layoutGutter;
}
</style>
