var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MountingPortal',{attrs:{"mountTo":"#sheetManager","append":""}},[_c('div',{ref:"sheet",class:[
      'UIElement',
      _vm.$style.sheet,
      _vm.index === 0 && _vm.$style.foreground,
      _vm.index > 0 && _vm.$style.background,
      _vm.size === 'large' && _vm.$style.sizeLarge
    ],style:({
      zIndex: _vm.index
    })},[_vm._t("external"),_c('transition',{attrs:{"name":"Sheet-backdrop","enter-active-class":_vm.$style.backdropEnterActive,"leave-active-class":_vm.$style.backdropLeaveActive,"enter-class":_vm.$style.backdropEnter,"leave-to-class":_vm.$style.backdropLeaveTo}},[(_vm.visible)?_c('div',{class:_vm.$style.backdrop,attrs:{"style2":{
          outline: '3px solid transparent',
          outlineOffset: '-3px',
          outlineColor: _vm.color
        }},on:{"click":_vm.close}}):_vm._e()]),_c('transition',{attrs:{"name":"Sheet-container"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter,"after-enter":_vm.afterEnter,"before-leave":_vm.beforeLeave,"after-leave":_vm.afterLeave}},[(_vm.visible)?_c('div',{class:[
          _vm.$style.container,
          _vm.containerClass
        ],style:({
          width: _vm.finalWidth + 'px',
          transform: ("translate(" + _vm.x + "px, 0)")
        })},[(_vm.loading)?_c('div',{class:_vm.$style.loader},[_c('Spinner',{attrs:{"color":"blue","size":"large"}})],1):_vm._e(),_c('div',{class:_vm.$style.header},[_c('div',{class:_vm.$style.close,on:{"click":_vm.close}},[_c('Icon',{attrs:{"icon":"fal fa-times"}})],1),_c('div',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.title))]),_c('div',{class:_vm.$style.actions},[_vm._t("actions"),(_vm.actions.length)?_c('ButtonGroup',{style:([
                _vm.$slots.actions && 'margin-left: 8px'
              ])},_vm._l((_vm.actions),function(action,index){return _c('Button',{key:index,attrs:{"type":action.type,"disabled":action.disabled,"url":action.url,"external":action.external,"icon":action.icon},on:{"click":action.onAction}},[_vm._v(_vm._s(action.label))])}),1):_vm._e()],2)]),_c('ScrollPane',{class:_vm.$style.contentWrapper,attrs:{"disabled":_vm.disableScroll,"padding":_vm.padded ? '30px' : '0'}},[_c('div',{class:[
              _vm.$style.content,
              _vm.padded && _vm.$style.contentPadded,
              _vm.contentClass
            ]},[_vm._t("default")],2)])],1):_vm._e()])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }