export default {
  show(message) {
    const event = new Event('ui-loader')
    event.subType = 'show'
    event.message = message
    window.dispatchEvent(event)
  },
  hide() {
    const event = new Event('ui-loader')
    event.subType = 'hide'
    window.dispatchEvent(event)
  },
  addEventListener(handler) {
    window.addEventListener('ui-loader', handler)
  },
  removeEventListener(handler) {
    window.removeEventListener('ui-loader', handler)
  }
}
