<script>
import {mergeData} from 'vue-functional-data-merge'
import {Card, CardSection} from '../Card'
import SkeletonBodyText from './SkeletonBodyText'

export default {
  name: 'SkeletonCard',
  props: {
    title: {
      type: String
    },
    subdued: {
      type: Boolean
    },
    /**
     * Number of sections to show.
     */
    sections: {
      type: Number,
      default: 1
    }
  },
  functional: true,
  render(h, {data, props}) {
    const sectionMarkup = []
    for (let i = 0; i < props.sections; i++) {
      sectionMarkup.push(
        h(CardSection, [
          h(SkeletonBodyText, {
            props: {
              lines: 3
            }
          })
        ])
      )
    }
    return h(Card, mergeData(data, {
      props: {
        title: props.title,
        subdued: props.subdued
      }
    }), sectionMarkup)
  }
}
</script>
