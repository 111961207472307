<template>
  <Modal
    :title="opts.title"
    :primaryAction="{
      label: opts.primaryActionLabel || $t('ui.modal.confirm'),
      type: opts.primaryActionType || 'primary',
      onAction: confirm
    }"
    :secondaryActions="[{
      label: opts.secondaryActionLabel || $t('ui.modal.cancel'),
      onAction: cancel
    }]"
    :visible="visible"
    @close="cancel"
    @afterLeave="handleLeave"
  >
    <render-node :node="opts.content" />
  </Modal>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  methods: {
    confirm() {
      if (this.opts.onConfirm) {
        this.opts.onConfirm()
      }
      this.handleAction(true)
    },
    cancel() {
      if (this.opts.onCancel) {
        this.opts.onCancel()
      }
      this.handleAction(false)
    }
  }
}
</script>
