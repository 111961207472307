<script>
import {mergeData} from 'vue-functional-data-merge'
import styles from './Choice.module.scss'
import {InlineError} from '../InlineError'
import {Icon} from '../Icon'

export default {
  name: 'Choice',
  props: {
    label: {
      type: String
    },
    helpText: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    error: {
      type: String
    },
    type: {
      type: String,
      default: 'checkbox'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: null,
      default: undefined
    },
    /**
     * Tooltip to show when hovering the button.
     */
     tooltip: {
      type: String
    },
    /**
     * Tooltip position, "top", "bottom", "right" or "left".
     */
    tooltipPosition: {
      type: String,
      default: 'bottom'
    }
  },
  functional: true,
  render(h, {children, data, props, scopedSlots}) {
    const helpLinkMarkup = props.helpLink && h('a', {
      domProps: {
        href: props.helpLink,
        target: '_blank'
      }
    }, [
      h(Icon, {
        class: styles.HelpIcon,
        props: {
          icon: 'fas fa-info-circle'
        }
      })
    ])

    const labelMarkup = h('label', {
      class: [
        'UIElement',
        styles.Label_wrapper,
        props.disabled && styles.disabled,
        props.readonly && styles.readonly
      ],
      attrs: {
        'data-ui-tooltip': props.tooltip,
        'data-ui-tooltip-position': props.tooltipPosition
      }
    }, [
      h('span', {
        class: styles.Control
      }, children),
      h('span', {
        class: styles.Label
      }, [
        props.label,
        helpLinkMarkup
      ])
    ])

    const helpTextMarkup = props.helpText && h('div', {
      class: styles.HelpText
    }, props.helpText)

    const errorMarkup = props.error && h(InlineError, {
      class: styles.Error,
      props: {
        message: props.error
      }
    })

    // const descriptionMarkup = (helpTextMarkup || errorMarkup) &&
    //   h('div', {
    //     class: style.Descs
    //   }, [
    //     errorMarkup,
    //     helpTextMarkup
    //   ])

    // console.log('content', slots().content)
    // console.log('content2', scopedSlots.content())

    return h('div', mergeData(data, {
      class: [
        'UIElement',
        styles.Choice
      ]
    }), [
      labelMarkup,
      errorMarkup,
      helpTextMarkup,
      scopedSlots.content()
    ])
  }
}
</script>
