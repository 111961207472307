<template>
  <div
    :class="[
      $style.DropZone,
      isDragging && $style.isDragging,
      error && $style.hasError
    ]"
    @click="$emit('click')"
    @drop.prevent="onDrop"
    @dragenter.prevent="onDragEnter"
    @dragleave.prevent="isDragging = false"
    @dragover.prevent
  >
    <div :class="$style.Content">
      <div v-if="error">{{error}}</div>
      <div v-else>
        <Icon
          icon="far fa-file-upload"
          style="margin-right: 6px"
        />
        {{$t('ui.fileInput.dropHere')}}
      </div>
    </div>
  </div>
</template>

<script>
import {Icon} from '../Icon'

export default {
  components: {
    Icon
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDragging: false,
      error: null
    }
  },
  methods: {
    onDrop(event) {
      const files = event.dataTransfer.files
      const count = files.length
      this.isDragging = false
      if (count) {
        if (this.multiple || count === 1) {
          this.$emit('drop', files)
        } else {
          this.error = 'You can only drop a single file, please try again.'
        }
      }
    },
    onDragEnter() {
      this.isDragging = true
      this.error = false
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

$dropBorderColor: #929eac;
$borderHoverColor: $focusColor;
$bgHoverColor: lighten($focusColor, 45%);

.DropZone {
  padding: 12px;
  border: 2px dashed $dropBorderColor;
  border-radius: 3px;
  color: $textSubduedColor;
  font-size: 18px;
  text-align: center;
  cursor: pointer;

  &:hover, &.isDragging {
    background: $bgHoverColor;
    border-color: $borderHoverColor;
    color: $focusColor;
  }
}

.isDragging .Content {
  pointer-events: none;
}

.hasError {
  background: lighten($errorColor, 55%);
  border-color: $errorColor;
  color: $errorColor;

  &:hover {
    background: lighten($errorColor, 52%);
    border-color: $errorColor;
    color: $errorColor;
  }
}
</style>
