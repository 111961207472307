var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Labelled',{attrs:{"label":_vm.label,"error":_vm.error,"help-text":_vm.helpText,"help-text-html":_vm.helpTextHtml,"help-link":_vm.helpLink}},[_c('PreventAutoComplete',{attrs:{"enabled":_vm.autocomplete === 'off'}},[_c('div',{class:[
        'UIElement',
        _vm.$style.TextField,
        _vm.error && _vm.$style.TextField__hasError,
        _vm.align && _vm.$style['TextField__align_' + _vm.align],
        _vm.multiline && _vm.$style.TextField__multiline,
        _vm.disabled && _vm.$style.isDisabled,
        (_vm.prefix || _vm.$slots.prefix) && _vm.$style.TextField__hasPrefix,
        (_vm.suffix || _vm.$slots.suffix) && _vm.$style.TextField__hasSuffix,
        _vm.$slots.left && _vm.$style.TextField__hasLeft,
        _vm.$slots.right && _vm.$style.TextField__hasRight
      ]},[(_vm.$slots.left)?_c('div',{class:_vm.$style.TextField_left},[_vm._t("left")],2):_vm._e(),_c('div',{class:[_vm.$style.TextField_control]},[(_vm.prefix || _vm.$slots.prefix)?_c('div',{class:_vm.$style.TextField_prefix},[_vm._t("prefix",function(){return [_vm._v(_vm._s(_vm.prefix))]})],2):_vm._e(),_c(_vm.multiline ? 'textarea' : 'input',{ref:"input",tag:"component",class:_vm.$style.TextField_input,style:({
            height: _vm.height ? _vm.height + 'px' : null,
            fontFamily: _vm.monospace ? 'monospace' : 'inherit'
          }),attrs:{"type":_vm.multiline ? null : _vm.type,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"min":_vm.min,"max":_vm.max,"step":_vm.step,"maxlength":_vm.maxLength,"autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"keydown":_vm.onKeydown,"click":function($event){return _vm.$emit('click')},"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')}}}),(_vm.suffix || _vm.$slots.suffix)?_c('div',{class:_vm.$style.TextField_suffix},[_vm._t("suffix",function(){return [_vm._v(_vm._s(_vm.suffix))]})],2):_vm._e(),(_vm.multiline)?_c('div',{ref:"ghost",class:[
            _vm.$style.TextField_input,
            _vm.$style.TextField_ghost
          ],attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.value + '\n'))]):_vm._e()],1),(_vm.$slots.right)?_c('div',{class:_vm.$style.TextField_right},[_vm._t("right")],2):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }