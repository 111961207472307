<template>
  <div :class="[
    'UIElement',
    $style.Calendar
  ]">
    <div :class="$style.Nav">
      <Button
        :class="$style.NavButton"
        style="margin-right: 0px"
        type="plain"
        icon="fa fa-chevron-double-left"
        @click="navigate(-1, false)"
      />
      <Button
        v-if="view === 'date' || view === 'datetime'"
        :class="$style.NavButton"
        type="plain"
        icon="fa fa-chevron-left"
        @click="navigate(-1, true)"
      />
      <div
        :class="[
          $style.Nav_title,
          view !== 'year' && $style.Nav_title__canNavigate
        ]"
        @click="navigateType"
      >{{viewData.title}}</div>
      <Button
        v-if="view === 'date' || view === 'datetime'"
        :class="$style.NavButton"
        type="plain"
        icon="fa fa-chevron-right"
        @click="navigate(1, true)"
      />
      <Button
        :class="$style.NavButton"
        style="margin-left: 0px"
        type="plain"
        icon="fa fa-chevron-double-right"
        @click="navigate(1, false)"
      />
    </div>
    <div
      :class="$style.Cells"
      :style="{
        '--columns': viewData.columns,
        '--cell-margin': viewData.cellMargin
      }"
    >
      <template v-if="viewData.headers">
        <div
          v-for="(text, index) in viewData.headers"
          :key="index"
          :class="$style.CellHeader"
        >
          {{text}}
        </div>
      </template>
      <div
        v-for="cell in viewData.cells"
        :key="cell.value"
        :class="[
          $style.Cell,
          cell.isSelected && $style.Cell__selected,
          cell.isCurrent && $style.Cell__current,
          cell.isOtherPeriod && $style.Cell__otherPeriod,
          cell.isDisabled && $style.Cell__disabled
        ]"
        @click="select(cell)"
      >
        {{cell.label}}
      </div>
    </div>
    <div
      v-if="type === 'datetime'"
      :class="$style.TimePicker"
    >
      <select
        :class="$style.TimeSelect"
        :value="selectedHour"
        @change="selectTime($event, 'hour')"
      >
        <option
          v-for="hour in hours"
          :key="hour.value"
          :value="hour.value"
          :disabled="hour.disabled"
        >{{hour.label}}</option>
      </select>
      <select
        :class="$style.TimeSelect"
        :value="selectedMinute"
        @change="selectTime($event, 'minute')"
      >
        <option
          v-for="minute in minutes"
          :key="minute.value"
          :value="minute.value"
          :disabled="minute.disabled"
        >{{minute.label}}</option>
      </select>
      <button
        :class="$style.ConfirmButton"
        @click="close"
      >{{$t('ui.modal.close')}}</button>
    </div>
  </div>
</template>

<script>
import {dayjs} from '../../utils/date'
import {Button} from '../Button'
import {pad} from '../../utils'

/**
 * Calendars let users choose dates from a visual calendar.
 */
export default {
  components: {
    Button
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * The type of picker:
     *
     *  - datetime
     *  - date
     *  - month
     *  - year
     */
    type: {
      type: String,
      default: 'date'
    },
    /**
     * The model date value formatted according to `modelFormat`. Parts of the
     * date may be truncated depending on the `type` being used.
     */
    value: {
      type: String
    },
    /**
     * Format of the underling value.
     *
     * @see https://day.js.org/docs/en/display/format
     */
    modelFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    /**
     * Provide a filter function to disable specific dates. The function is
     * passed a Dayjs instance.
     */
    disableDate: {
      type: Function,
      default: null
    },
    /**
     * Restricts the minimum time that can be selected.
     */
    minTime: {
      type: String,
      default: '00:00'
    },
    /**
     * Restricts the maximum time that can be selected.
     */
    maxTime: {
      type: String,
      default: '23:59'
    }
  },
  data() {
    return {
      viewDate: null,
      selectedDate: null,
      view: this.type
    }
  },
  computed: {
    viewData() {
      const {view} = this
      if (view === 'date' || view === 'datetime') {
        return this.dateData
      } else if (view === 'month') {
        return this.monthData
      } else {
        return this.yearData
      }
    },
    dateData() {
      const {viewDate, selectedDate, disableDate} = this
      const cells = []
      let date = viewDate.clone().startOf('month').startOf('week')
      const today = dayjs()
      for (let i = 0; i < 42; i++) {
        const isSamePeriod = date.isSame(viewDate, 'month')
        cells.push({
          value: date.format('YYYY-MM-DD'),
          label: date.format('D'),
          isSelected: isSamePeriod && date.isSame(selectedDate, 'day'),
          isCurrent: isSamePeriod && date.isSame(today, 'day'),
          isOtherPeriod: !isSamePeriod,
          isDisabled: disableDate && disableDate(date)
        })
        date = date.add(1, 'day')
      }
      const headers = []
      for (let i = 0; i < 7; i++) {
        headers.push(dayjs().weekday(i).format('ddd'))
      }
      return {
        cells,
        cellMargin: 0,
        columns: 7,
        headers,
        title: viewDate.format('MMMM YYYY')
      }
    },
    monthData() {
      const {viewDate, selectedDate, disableDate} = this
      const cells = []
      for (let i = 0; i < 12; i++) {
        const date = viewDate.clone().month(i).startOf('month')
        cells.push({
          value: date.format('YYYY-MM-DD'),
          label: date.format('MMM'),
          isSelected: date.isSame(selectedDate, 'month'),
          isDisabled: disableDate && disableDate(date)
        })
      }
      return {
        cells,
        cellMargin: 10,
        columns: 4,
        title: viewDate.format('YYYY')
      }
    },
    yearData() {
      const {viewDate, selectedDate, disableDate} = this
      const startYear = Math.floor(viewDate.year() / 10) * 10
      const cells = []
      for (let i = 0; i < 10; i++) {
        const date = dayjs(`${startYear + i}-01-01`)
        cells.push({
          value: date.format('YYYY-MM-DD'),
          label: date.format('YYYY'),
          isSelected: date.isSame(selectedDate, 'year'),
          isDisabled: disableDate && disableDate(date)
        })
      }
      const startDate = startYear + '-01-01'
      const endDate = (startYear + 9) + '-01-01'
      const title = dayjs(startDate).format('YYYY') + ' — ' + dayjs(endDate).format('YYYY')
      return {
        cells,
        cellMargin: 10,
        columns: 4,
        title
      }
    },
    selectedHour() {
      return this.selectedDate.hour()
    },
    selectedMinute() {
      return this.selectedDate.minute()
    },
    hours() {
      const {minTime, maxTime} = this
      const minParts = minTime.split(':')
      const maxParts = maxTime.split(':')
      const hours = []
      for (let i = 0; i < 24; i++) {
        const label = pad(i)
        hours.push({
          label,
          value: i,
          disabled: label < minParts[0] || label > maxParts[0]
        })
      }
      return hours
    },
    minutes() {
      const {minTime, maxTime, selectedHour} = this
      const minutes = []
      for (let i = 0; i < 60; i++) {
        const label = pad(i)
        const compare = pad(selectedHour) + ':' + label
        minutes.push({
          label,
          value: i,
          disabled: compare < minTime || compare > maxTime
        })
      }
      return minutes
    }
  },
  created() {
    this.$watch('value', value => {
      // TODO: not sure if this line is right...
      let date = dayjs(value).isValid() && dayjs(value, this.modelFormat, true)
      if (!date || !date.isValid()) {
        date = dayjs().startOf('day')
      }
      const clamped = Object.freeze(this.clampDate(date))
      this.viewDate = clamped
      this.selectedDate = clamped
    }, {immediate: true})
  },
  methods: {
    navigate(amount, isMinor) {
      const {view, viewDate} = this
      let months = amount * 12
      if ((view === 'date' || view === 'datetime') && isMinor) {
        months = amount
      } else if (view === 'year') {
        months = amount * 120
      }
      this.viewDate = Object.freeze(viewDate.clone().add(months, 'month'))
    },
    navigateType() {
      const {view} = this
      if (view === 'date' || view === 'datetime') {
        this.view = 'month'
      } else if (view === 'month') {
        this.view = 'year'
      }
    },
    select(cell) {
      const {view, type} = this
      if (cell.isDisabled) {
        return
      }
      if (view === type) {
        // Update selected value
        this.updateValue(dayjs(cell.value).set({
          hour: this.selectedHour,
          minute: this.selectedMinute
        }))
      } else {
        // Change view
        this.viewDate = Object.freeze(dayjs(cell.value))
        if (view === 'year') {
          this.view = 'month'
        } else if (view === 'month') {
          this.view = type
        }
      }
    },
    selectTime(event, unit) {
      const number = parseInt(event.target.value, 10)
      const date = this.selectedDate.clone().set({
        hour: this.selectedHour,
        minute: this.selectedMinute,
        [unit]: number
      })
      this.updateValue(date)
    },
    /**
     * Mutates the input date to ensure that it is within the min and max time.
     *
     * @param {Dayjs} date
     * @returns {Dayjs}
     */
    clampDate(date) {
      const {minTime, maxTime} = this
      const minParts = minTime.split(':')
      const maxParts = maxTime.split(':')
      const compare = date.format('HH:mm')
      if (compare < minTime) {
        date = date.clone().set({
          hour: minParts[0],
          minute: minParts[1]
        })
      } else if (compare > maxTime) {
        date = date.clone().set({
          hour: maxParts[0],
          minute: maxParts[1]
        })
      }
      return date
    },
    updateValue(date) {
      const value = this.clampDate(date.clone())
      if (this.type !== 'datetime') {
        value.startOf('day')
      }
      /**
       * Emitted when a value is selected.
       *
       * @event change
       * @property {string} value
       */
      this.$emit('change', value.format(this.modelFormat))
    },
    close() {
      /**
       * Emitted when the user clicks the "close" button near the time picker.
       */
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

$selectedColor: #42526e;
$subTextColor: #6b778c;
$hoverBgColor: #ebecf0;

.Calendar {
  width: 280px;
}

.Nav {
  display: flex;
  align-items: center;
}
.Nav_title {
  flex: 1;
  margin: 0 10px;
  padding: 5px 0;
  border-radius: 3px;
  color: #172a4c;
  font-weight: 600;
  text-align: center;
  cursor: default;
}
.Nav_title__canNavigate {
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background: $hoverBgColor;
  }
}
.NavButton {
  color: #627381;
}

.Cells {
  display: flex;
  flex-wrap: wrap;
}

.Cell, .CellHeader {
  position: relative;
  width: calc(100% / var(--columns));
  // margin-left: -1px;
  // margin-top: -1px;
  padding: 9px;
  border-radius: 3px;
  color: #091d41;
  font-weight: 500;
  text-align: center;
}

.Cell {
  margin: calc(var(--cell-margin) * 1px) 0px;

  &:hover {
    background: $hoverBgColor;
    cursor: pointer;
  }

  &.Cell__disabled {
    background: #f9fafb;
    color: #dfe1e6;
    cursor: not-allowed;
  }
}
.Cell__selected {
  background: $selectedColor;
  color: #fff;

  &:hover {
    background: darken($selectedColor, 5%);
  }
}
.Cell__otherPeriod {
  color: $subTextColor;
  font-weight: 400;
}
.Cell__current {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    border: 2px solid $focusColor;
    border-radius: 3px;
  }

  &.Cell__disabled {
    color: $subTextColor;
    &::after {
      border-color: lighten($subTextColor, 25%);
    }
  }
}

.CellHeader {
  color: #6b778c;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.TimePicker {
  display: flex;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
  font-size: 16px;
}

.TimeSelect {
  min-width: 50px;
  margin-right: 8px;
  padding: 3px 10px;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  background: $hoverBgColor;
}

.ConfirmButton {
  margin-left: auto;
  background: #eee;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 16px;

  &:hover {
    background: #ddd;
    cursor: pointer;
  }
}
</style>
