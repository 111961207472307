var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimplePopper',{attrs:{"align":_vm.align,"disabled":_vm.disabled || !_vm.finalSections.length},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var isOpen = ref.isOpen;
return [_vm._t("default",null,{"isOpen":isOpen})]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',_vm._b({class:[
        _vm.$style.Menu,
        _vm.hasIcons && _vm.$style.hasIcons,
        _vm.hasSelected && _vm.$style.hasSelected
      ]},'div',_vm.menuProps,false),[_vm._t("header"),_vm._l((_vm.finalSections),function(section,index){return _c('div',{key:index,class:_vm.$style.Section},[(section.title)?_c('div',{class:_vm.$style.Section_title},[_vm._v(_vm._s(section.title))]):_vm._e(),_vm._l((section.items),function(item,index){return _c('MaybeRouterLink',{key:index,class:_vm.$style.Item,attrs:{"to":item.url,"plain":""},nativeOn:{"click":function($event){return _vm.onClickItem(item, close)}}},[(_vm.hasIcons)?_c('div',{class:_vm.$style.IconWrapper},[_vm._t("item-icon",function(){return [(item.icon)?_c('Icon',{attrs:{"icon":'fa-fw ' + item.icon}}):_vm._e()]},{"item":item})],2):_vm._e(),_c('div',{class:_vm.$style.Item_text},[_c('div',{class:_vm.$style.fItem_label},[_vm._t("item-label",function(){return [_vm._v(_vm._s(item.label))]},{"item":item})],2),(item.description)?_c('div',{class:_vm.$style.Item_desc},[_vm._v(_vm._s(item.description))]):_vm._e()])])})],2)})],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }