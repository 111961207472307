<script>
import {mergeData} from 'vue-functional-data-merge'

export default {
  functional: true,
  render(h, {data, children, $style}) {
    return h('p', mergeData(data, {
      class: [
        'UIElement',
        $style.Caption
      ]
    }), children)
  }
}
</script>

<style lang="scss" module>
.Caption {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;

  @media (min-width: 400px) {
    font-size: 12px;
    line-height: 16px;
  }
}
</style>
