<template>
  <MountingPortal mountTo="#uiToastManager" append>
    <transition-group
      tag="div"
      :class="[
        'UIElement',
        $style.ToastManager
      ]"
      :enter-active-class="$style.enterActive"
      :leave-active-class="$style.leaveActive"
      :enter-class="$style.enter"
      :leave-to-class="$style.leaveTo"
      :move-class="$style.moveTo"
    >
      <Toast
        v-for="item in items"
        :key="item._id"
        :item="item"
        @remove="item => removeItem(item)"
      />
    </transition-group>
  </MountingPortal>
</template>

<script>
// import {Portal} from '@linusborg/vue-simple-portal'
import {MountingPortal} from 'portal-vue'
import Toast from './Toast'

export default {
  components: {
    MountingPortal,
    Toast
  },
  data() {
    return {
      nextId: 0,
      items: []
    }
  },
  created() {
    if (!document.getElementById('uiToastManager')) {
      const container = document.createElement('div')
      container.id = 'uiToastManager'
      document.body.appendChild(container)
    }
  },
  methods: {
    addItem(data) {
      const item = {
        icon: null,
        loading: false,
        progress: null,
        duration: 4500,
        ...data,
        _id: this.nextId++,
        remove: () => this.removeItem(item)
      }
      // Remove the default `duration` if the user has not explicitly defined a
      // duration and is using the `progress` or `loading` features.
      if (
        !data.duration && (
          typeof item.progress === 'number' ||
          item.loading ||
          (Array.isArray(item.actions) && item.actions.length)
        )
      ) {
        item.duration = 0
      }
      if (item.duration) {
        setTimeout(() => this.removeItem(item), item.duration)
      }
      this.items.push(item)
      return item
    },
    removeItem(item) {
      const index = this.items.indexOf(item)
      if (index >= 0) {
        this.items.splice(index, 1)
      }
    },
    clearAll() {
      this.items = []
    }
  }
}
</script>

<style lang="scss" module>
.ToastManager {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  width: 432px;

  @media (max-width: 500px) {
    max-width: calc(100vw - 32px);
  }

}

.enterActive, .leaveActive {
  transition-duration: 0.3s;
  transition-property: transform, opacity;
  transition-timing-function: ease;
}
.enter, .leaveTo {
  opacity: 0;
  transform: translateY(100px);
}
.moveTo {
  transition: transform 0.3s;
}
</style>
