var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'UIElement',
    _vm.$style.Thumbnail
  ]},[_c('div',{class:[
      _vm.$style.Thumbnail__container,
      _vm.$style[("Thumbnail__" + _vm.size)],
      _vm.noBorder && _vm.$style.Thumbnail__no_border
    ]},[(_vm.imgIsValid)?_c('img',{class:_vm.$style.Thumbnail__image,attrs:{"src":_vm.url,"alt":_vm.alt},on:{"error":function($event){_vm.imgIsValid = false}}}):_vm._e(),(!_vm.url || !_vm.imgIsValid)?_c('div',{class:[
        _vm.$style.Thumbnail__placeholder,
        _vm.$style[("Thumbnail__placeholder__" + _vm.size)]
      ]},[_c('i',{staticClass:"fal fa-image"})]):_vm._e()]),_c('div',{class:_vm.$style.Thumbnail__content},[(_vm.title)?_c('div',{class:_vm.$style.Thumbnail__heading},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.subtitle)?_c('div',{class:_vm.$style.Thumbnail__subtitle},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]):_vm._e(),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }