<template>
  <div
    :class="[
      'UIElement',
      $style.Thumbnail
    ]"
  >
    <div
      :class="[
        $style.Thumbnail__container,
        $style[`Thumbnail__${size}`],
        noBorder && $style.Thumbnail__no_border
      ]"
    >
    <!-- If dynamically updated check if its still valid -->
      <img
        v-if="imgIsValid"
        @error="imgIsValid = false"
        :class="$style.Thumbnail__image"
        :src="url"
        :alt="alt"
      />

      <div
        v-if="!url || !imgIsValid"
        :class="[
          $style.Thumbnail__placeholder,
          $style[`Thumbnail__placeholder__${size}`]
        ]"
      >
        <i class="fal fa-image"></i>
      </div>
    </div>

    <div :class="$style.Thumbnail__content">
      <div
        v-if="title"
        :class="$style.Thumbnail__heading"
      >{{title}}</div>
      <div
        v-if="subtitle"
        :class="$style.Thumbnail__subtitle"
      >
        {{subtitle}}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Title of the thumbnail, having the highest visual priority.
     */
    title: {
      type: String
    },
    /**
     * Subtitle of the thumbnail, having a secondary visual priority.
     */
    subtitle: {
      type: String
    },
    /**
     * Thumbnail image URL.
     */
    url: {
      type: String
    },
    /**
     * The `alt` attribute to provide to the underlying `img` element.
     */
    alt: {
      type: String
    },
    /**
     * The size of the thumbnail:
     *
     *   - extraSmall
     *   - small
     *   - medium
     *   - large
     *   - extraLarge
     */
    size: {
      type: String,
      default: 'small'
    },
    /**
     * Removes the border around the thumbnail image.
     */
    noBorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imgIsValid: true
    }
  },
  watch: {
    url(imageUrl) {
      if (!this.imgIsValid && imageUrl) {
        this.imgIsValid = true
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

.Thumbnail {
  display: flex;

  &__container {
    border: 1px solid #e0e2e3;
    border-radius: 3px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
  }

  &__image {
    max-height: 80%;
    max-width: 80%;
  }

  &__placeholder {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > i {
      font-size: 1.2rem;
      color: #c8c8c8;
    }

    &__medium {
      & > i {
        font-size: 1.4rem;
      }
    }

    &__large {
      & > i {
        font-size: 2rem;
      }
    }

    &__extraLarge {
      & > i {
        font-size: 2.6rem;
      }
    }
  }

  &__no_border {
    background-color: transparent;
    border: none;

    & > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__content {
    flex: 1 1 auto;
    margin-top: 0.2rem;
    font-family: $defaultFontFamily;
  }

  &__heading {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }

  &__subtitle {
    color: $textSubduedColor;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  &__extraSmall {
    max-width: 50px;
    max-height: 50px;
  }

  &__small {
    max-width: 80px;
    max-height: 80px;
  }

  &__medium {
    max-width: 120px;
    max-height: 120px;
  }

  &__large {
    max-width: 200px;
    max-height: 200px;
  }

  &__extraLarge {
    max-width: 250px;
    max-height: 250px;
  }
}
</style>
