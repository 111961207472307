<script>
import {mergeData} from 'vue-functional-data-merge'
import {Formatter} from '../Formatter'
import {emptyValuePlaceholder} from '../../utils/lang'

export default {
  name: 'Stat',
  props: {
    /**
     * The text to display in the label.
     */
    label: {
      type: String
    },
    /**
     * Value format.
     * @see [Formatter.type](/#/Components/Formatter)
     */
    type: {
      type: null
    },
    /**
     * The value to pass to formatter as a property. Alternatively the value can
     * be passed as the default slot content.
     * @see [Formatter.value](/#/Components/Formatter)
     */
    value: {
      type: null
    },
    /**
     * Formatter locale (e.g. to be used with Intl.NumberFormat)
     * @see [Formatter.locale](/#/Components/Formatter)
     */
    locale: {
      type: String
    },
    /**
     * Formatter currency (e.g. to be used with Intl.NumberFormat)
     * @see [Formatter.currency](/#/Components/Formatter)
     */
    currency: {
      type: String
    },
    /**
     * Formatter timezone
     * @deprecated
     * @see [Formatter.timezone](/#/Components/Formatter)
     */
    timezone: {
      type: String
    },
    /**
     * Formatter input timezone
     * @see [Formatter.locale](/#/Components/Formatter)
     * @deprecated
     */
    inputTimezone: {
      type: String
    },
    /**
     * By default the value is rendered on a single line and truncated if too
     * long. Enabling this property allows the value to span multiple lines.
     */
    multiline: {
      type: Boolean,
      default: false
    }
  },
  functional: true,
  render(h, {children, data, props, $style}) {
    const valueMarkup = props.value || props.value === 0
      ? h(
          'div',
          {
            class: [
              $style.Stat_value,
              props.multiline && $style.Stat_multiline
            ]
          },
          [
            h(Formatter, {
              props: props
            })
          ]
        )
      : h(
          'div',
          {
            class: [
              $style.Stat_value,
              props.multiline && $style.Stat_multiline
            ]
          },
          children || emptyValuePlaceholder
        )
    return h('div', mergeData(data, {
      class: $style.Stat
    }), [
      h('div', {
        class: $style.Stat_label
      }, [props.label]),
      valueMarkup
    ])
  }
}
</script>

<style lang="scss" module>
.Stat_label {
  padding-bottom: 2px;
  color: #212b36;
  font-weight: 500;
}
.Stat_value {
  color: #637381;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.Stat_multiline {
  overflow: auto;
  overflow-wrap: break-word;
  white-space: normal;
}
</style>
