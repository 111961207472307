<template>
  <div :class="$style.AccountNav">
    <SimpleMenu
      align="right"
      :sections="sections"
      :menu-props="{
        style: {
          minWidth: '240px',
          paddingTop: 0
        }
      }"
    >
      <template #header>
        <div :class="$style.MenuHeader">
          <Icon
            :class="$style.User_icon"
            icon="fa fa-user-circle"
          />
          <div :class="$style.User">
            <div :class="$style.User_name">{{userName}}</div>
            <div :class="$style.User_email">{{userEmail}}</div>
          </div>
        </div>
      </template>
      <div
        :class="$style.Button"
        :disabled="!sections.length"
      >
        <div
          v-if="avatarUrl"
          :class="$style.Avatar"
          :style="{
            backgroundImage: avatarUrl ? 'url(' + avatarUrl + ')' : undefined
          }"
        ></div>
        <div :class="$style.Title">
          <div :class="$style.Title_account">{{accountName}}</div>
        </div>
      </div>
    </SimpleMenu>
  </div>
</template>

<script>
import {Icon} from '@/components/Icon'
import SimpleMenu from './SimpleMenu'

export default {
  components: {
    Icon,
    SimpleMenu
  },
  props: {
    accountName: {
      type: String,
      default: 'Account'
    },
    userName: {
      type: String,
      default: 'User Name'
    },
    userEmail: {
      type: String,
      default: 'user@email.com'
    },
    avatarUrl: {
      type: String
    },
    sections: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" module>
@import '@/styles/variables';
@import '../common.module.scss';

.AccountNav {
  min-width: 42px;
}

.Button {
  @include navButtonBase;
  padding: 2px 12px;
}

.Avatar {
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
}

.Title {
  flex: 1;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Title_account {
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Menu {
  @include menuBase;
  padding: 8px 0;
}
.MenuItem {
  @include menuItemBase;
}
.MenuHeader {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 16px;
  background: #f5f5f5;
  border-radius: 3px 3px 0 0;
}

.User_name {
  font-weight: bold;
}
.User_icon {
  margin-right: 12px;
  font-size: 22px;
}
</style>
