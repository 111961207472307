<script>
import {mergeDataClassStyle} from '../../utils'
import {Choice} from '../Choice'
import CheckboxControl from './CheckboxControl'

export default {
  name: 'Checkbox',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String
    },
    error: {
      type: String
    },
    helpText: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    name: {
      type: String
    },
    /**
     * The bound value / v-model.
     */
    value: {
      type: null
    },
    /**
     * The model value to use when checked.
     */
    trueValue: {
      type: null,
      default: true
    },
    /**
     * The model value to use when not checked.
     */
    falseValue: {
      type: null,
      default: false
    },
    /**
     * When `true` the checkbox will be checked. This is an alternative
     * to assigning `value` to be `trueValue`.
     */
    checked: {
      type: Boolean,
      default: false
    },
    /**
     * Renders the checkbox in the indeterminate state.
     */
    indeterminate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * Tooltip to show when hovering the button.
     */
     tooltip: {
      type: String
    },
    /**
     * Tooltip position, "top", "bottom", "right" or "left".
     */
    tooltipPosition: {
      type: String,
      default: 'bottom'
    }
  },
  functional: true,
  render(h, {children, data, props, $style}) {
    const isChecked = props.value === props.trueValue || props.checked
    const controlMarkup = h('div', {
      class: $style.Checkbox
    }, [
      h(CheckboxControl, {
        props: {
          name: props.name,
          checked: isChecked,
          indeterminate: props.indeterminate,
          disabled: props.disabled,
          readonly: props.readonly,
          error: !!props.error
        },
        on: {
          change(event) {
            if (data.on && data.on.change) {
              const value = event.target.checked
                ? props.trueValue
                : props.falseValue
              data.on.change(value)
            }
          }
        }
      })
    ])
    return h(Choice, mergeDataClassStyle(data, {
      props: {
        label: props.label,
        error: props.error,
        helpText: props.helpText,
        helpLink: props.helpLink,
        disabled: props.disabled,
        readonly: props.readonly,
        tooltip: props.tooltip,
        tooltipPosition: props.tooltipPosition
      },
      scopedSlots: {
        content() {
          if (isChecked && children) {
            return h('div', {
              class: $style.Content
            }, children)
          }
        }
      }
    }), [
      controlMarkup
    ])
  }
}
</script>

<style lang="scss" module>
.Checkbox {
  position: relative;
  width: 100%;
}

.Content {
  padding-left: 24px;
}
</style>
