<script>
import {
  capitalize,
  mergeDataFixListeners
} from '../../utils'
import {Icon} from '../Icon'
import {UnstyledLink} from '../UnstyledLink'

/**
 * Buttons are used primarily for actions, such as “Add”, “Close”, “Cancel”, or
 * “Save”. Plain buttons, which look similar to links, are used for less
 * important or less commonly used actions, such as “view more details”.
 */
export default {
  name: 'Button',
  props: {
    /**
     * The type of button:
     *
     *   - default
     *   - primary - Extra visual weight and identifies the button as the
     *     primary action.
     *   - destructive - Indicates a dangerous or potentially negative action.
     *   - outline - Gives the button a subtle alternative to the default button
     *     styling, appropriate for certain backdrops.
     *   - plain - Renders a button that looks like a link.
     */
    type: {
      type: String,
      default: 'default'
    },
    /**
     * Font Awesome icon.
     * @see [Icon](/#/Components/Icon) for more details.
     */
    icon: {
      type: String
    },
    /**
     * Disables the button, preventing all interaction.
     */
    disabled: {
      type: Boolean
    },
    /**
     * Tooltip to show when hovering the button.
     */
    tooltip: {
      type: String
    },
    /**
     * Tooltip position, "top", "bottom", "right" or "left".
     */
    tooltipPosition: {
      type: String,
      default: 'bottom'
    },
    /**
     * Shortcut to append a dropdown icon to the button.
     */
    dropdown: {
      type: Boolean,
      default: false
    },
    /**
     * Allows the button to grow to the width of its container.
     */
    fullWidth: {
      type: Boolean,
      default: false
    },
    /**
     * Changes the size of the button, giving it more or less paadding.
     *
     * Sizes:
     *
     *   - small
     *   - medium
     *   - large
     */
    size: {
      type: String,
      default: 'medium'
    },
    /**
     * If provided the button will be rendered as a link and this value will be
     * used as the href attribute.
     */
    url: {
      type: String
    },
    /**
     * When used with `url` it forces the link to open in a new tab.
     */
    external: {
      type: Boolean
    },
    /**
     * This makes the button such that the color is applied to the text and outline instead of the body of the button.
     */
    invert: {
      type: Boolean,
      default: false
    }
  },
  functional: true,
  render(h, {props, data, children, $style}) {
    const contentChildren = []
    if (props.icon) {
      contentChildren.push(h(Icon, {
        style: [
          children && 'margin-right: 6px'
        ],
        props: {
          icon: props.icon
        }
      }))
    }
    if (children) {
      contentChildren.push(...children)
    }
    if (props.dropdown) {
      contentChildren.push(h(Icon, {
        style: [
          children && 'margin-left: 6px'
        ],
        props: {
          icon: 'fa fa-caret-down'
        }
      }))
    }
    const content = h('div', {
      class: $style.content
    }, contentChildren)
    const element = props.url && !props.disabled
      ? UnstyledLink
      : 'button'
    return h(element, mergeDataFixListeners(data, {
      class: [
        'UIElement',
        $style.Button,
        $style[`type${capitalize(props.type)}${props.invert ? '_invert' : ''}`],
        $style['size' + capitalize(props.size)],
        props.fullWidth && $style.fullWidth
      ],
      domProps: {
        disabled: props.disabled,
        href: props.url,
        target: props.external ? '_blank' : undefined
      },
      attrs: {
        'data-ui-tooltip': props.tooltip,
        'data-ui-tooltip-position': props.tooltipPosition
      },
      on: {
        click(event) {
          event.currentTarget.blur()
        }
      }
    }), [content])
  }
}
</script>

<style lang="scss" module>
$textColor: #212b36;
$shadow: 0 1px 0 0 rgba(22, 29, 37, .05);

@mixin buttonBase {
  position: relative;
  display: inline-flex;
  align-items: center;
  min-height: 36px;
  padding: 5px 12px;
  border: 1px solid transparent;
  border-radius: 3px;
  color: $textColor;
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  text-decoration: none;

  &:focus {
    z-index: 1;
    outline: none;
  }
}

@mixin buttonFilled($buttonColor, $focusColor) {
  $borderColor: darken($buttonColor, 10%);
  $activeColor: darken($buttonColor, 15%);

  padding: 5px 12px;
  background: linear-gradient(
    to bottom,
    lighten($buttonColor, 2%),
    darken($buttonColor, 2%)
  );
  border-color: $borderColor;
  box-shadow: $shadow;
  color: #fff;

  &:hover:not(:disabled) {
    background: linear-gradient(
      to bottom,
      $buttonColor,
      darken($buttonColor, 5%)
    );
    text-decoration: none;
    cursor: pointer;
  }

  &:focus:not(:active) {
    border-color: $focusColor;
    box-shadow: 0 0 0 1px $focusColor;
  }

  &:active:not(:disabled) {
    background: linear-gradient(
      to bottom,
      $borderColor,
      $borderColor
    );
    border-color: $activeColor;
    box-shadow:
      $shadow,
      0 0 1px 0 $activeColor;
  }
}

@mixin buttonFilledDisabled($buttonColor) {
  background: linear-gradient(
    to bottom,
    lighten($buttonColor, 25%),
    lighten($buttonColor, 25%)
  );
  border-color: lighten($buttonColor, 20%);
  box-shadow: none;
  color: #fff;
}

@mixin buttonOutline($color) {
  padding: 5px 12px;
  background: transparent;
  border: 1px solid rgba($color, 0.4);
  box-shadow: none;

  &:hover:not(:disabled) {
    background: rgba($color, 0.05);
    border-color: rgba($color, 0.4);
    cursor: pointer;
  }

  &:focus:not(:active) {
    border-color: rgba($color, 0.8);
    box-shadow: 0 0 0 1px rgba($color, 0.8);
  }

  &:active {
    background: rgba($color, 0.1);
    box-shadow: none;
  }
}

@mixin buttonOutlineDisabled($color) {
  background: transparent;
  border-color: rgba($color, 0.25);
  box-shadow: none;
  color: #919eab;
}

@mixin buttonInvert($color) {
  padding: 5px 12px;
  background: transparent;
  border: 1px solid rgba($color, 0.8);
  box-shadow: none;
  color: $color;

  &:hover:not(:disabled) {
    background: rgba($color, 0.05);
    cursor: pointer;
  }

  &:focus:not(:active) {
    box-shadow: 0 0 0 1px rgba($color, 0.8);
  }

  &:active {
    background: rgba($color, 0.1);
    box-shadow: none;
  }
}

@mixin buttonInvertDisabled($color) {
  background: transparent;
  border-color: rgba($color, 0.4);
  box-shadow: none;
  color: rgba($color, 0.4);
}

.Button {
  @include buttonBase;
}

[data-buttongroup-segmented] .Button {
  border-radius: 0;
}
[data-buttongroup-segmented] > :first-child .Button {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
[data-buttongroup-segmented] > :last-child .Button {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typeDefault, .typeDefault_invert {
  @include buttonFilled(#fff, #1a73e8);
  border-color: #c4cdd5;
  color: $textColor;

  &:disabled {
    background: linear-gradient(
      to bottom,
      #f4f6f7,
      #f4f6f7
    );
    box-shadow: none;
    color: #919eab;
  }
}

.typePrimary_invert {
  @include buttonInvert(#1a73e8);

  &:disabled {
    @include buttonInvertDisabled(#1a73e8);
  }
}
.typeDestructive_invert {
  @include buttonInvert(#de3618);

  &:disabled {
    @include buttonInvertDisabled(#de3618);
  }
}

.typePrimary {
  @include buttonFilled(#1a73e8, #1151a5);

  &:disabled {
    @include buttonFilledDisabled(#1a73e8);
  }
}

.typeSecondary {
  @include buttonFilled(#46417D, #1151a5);

  &:disabled {
    @include buttonFilledDisabled(#46417D);
  }
}
.typeSecondary_invert {
  @include buttonInvert(#46417D);

  &:disabled {
    @include buttonInvertDisabled(#46417D);
  }
}

:global(.UIThemePink) .typePrimary {
  @include buttonFilled(#ee4570, #1151a5);

  &:disabled {
    @include buttonFilledDisabled(#ee4570);
  }
}
:global(.UIThemePink) .typePrimary_invert {
  @include buttonInvert(#ee4570);

  &:disabled {
    @include buttonInvertDisabled(#ee4570);
  }
}

.typeDestructive {
  @include buttonFilled(#de3618, #920008);

  &:disabled {
    @include buttonFilledDisabled(#de3618);
  }
}

.typeOutline, .typeOutline_invert {
  @include buttonOutline(#637381);

  &:disabled {
    @include buttonOutlineDisabled(#637381);
  }
}

.typePlain, .typePlain_invert {
  margin: -5px -12px;
  padding-left: 12px;
  padding-right: 12px;
  background: transparent;
  border: none;
  box-shadow: none;
  color: #006fbb;

  &:hover:not(:disabled) {
    color: #084e8a;
    background: transparent;
    cursor: pointer;
  }
  &:active {
    background: transparent;
    box-shadow: none;
  }
  &:focus {
    padding-left: 12px;
    padding-right: 12px;

    .content {
      margin: -2px -5px;
      padding: 2px 5px;
      background: rgba(33, 43, 54, .1);
      border-radius: 3px;
    }
  }
  &:disabled {
    color: #919eab;
  }
}

.sizeSmall {
  min-height: 30px;
  padding: 4px 12px;
}
.sizeLarge {
  min-height: 44px;
  min-width: 44px;
  padding: 11px 24px;

  .content {
    font-size: 16px;
  }
}

.fullWidth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
