<template>
  <div>
    <SimplePopper
      align="right"
      content-class="Notification_content"
    >
      <template v-slot:trigger>
        <div :class="$style.Button">
          <Icon
            :icon="(items.length ? 'fas' : 'far') + ' fa-bell'"
          />
          <div
            v-if="items.length"
            :class="$style.Count"
          >{{items.length}}</div>
        </div>
      </template>
      <div :class="$style.Menu">
        <div
          v-if="!items.length"
          :class="$style.Empty"
        >{{i18n.noNotifications}}</div>
        <div
          v-for="item in items"
          :key="item.id"
          :class="$style.Item"
        >
          <div :class="$style.Item_header">
            <div :class="$style.Item_title">
              {{item.title}}
              <span
                v-if="item.badge"
                :class="[
                  $style.Item_badge,
                  $style['Item_badge__' + (item.badgeStatus || 'default')]
                ]"
              >{{item.badge}}</span>
            </div>
            <Formatter
              :class="$style.Item_date"
              type="dateAgo"
              :value="item.date"
            />
          </div>
          <div :class="$style.Item_desc">{{item.description}}</div>
          <div
            v-if="item.actions"
            :class="$style.Item_actions"
          >
            <button
              v-for="(action, index) in item.actions"
              :key="index"
              :class="$style.Action"
              :disabled="action.disabled"
              @click="onClickAction(item, action)"
            >{{action.label}}</button>
          </div>
        </div>
      </div>
    </SimplePopper>
  </div>
</template>

<script>
import {Icon} from '@/components/Icon'
import {Formatter} from '@/components/Formatter'
import SimplePopper from './SimplePopper'

export default {
  components: {
    Icon,
    Formatter,
    SimplePopper
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    i18n: {
      type: Object
    }
  },
  methods: {
    onClickAction(item, action) {
      if (action.onAction) {
        action.onAction(item)
      }
    }
  }
}
</script>

<style lang="scss" module>
@import "@/styles/variables";
@import "../common.module.scss";

:global(.Notification_content) {
  @media (max-width: 700px) {
    position: fixed !important;
    top: calc(var(--ui-globalNav-height) - 4px);
    right: 16px;
  }
}

.Button {
  @include navButtonBase;
}

.Count {
  position: relative;
  left: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  background: var(--ui-globalNav-color);
  box-shadow: -1px 0 var(--ui-globalNav-bgColor);
  border-radius: 50%;
  color: var(--ui-globalNav-bgColor);
  font-size: 10px;
}

.Menu {
  @include menuBase;
  width: 350px;
}

.Empty {
  padding: 10px 0;
  color: #6b778c;
  text-align: center;
}

.Item + .Item {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
}
.Item_header {
  display: flex;
  align-items: center;
}
.Item_title {
  display: flex;
  align-items: center;
  flex: 1;
  font-weight: bold;
  font-size: 14px;
}
.Item_badge {
  margin-left: 6px;
  padding: 2px 5px 1px;
  border-radius: 3px;
  background-color: #dfe3e8;
  color: #454f5b;
  font-weight: 500;
  font-size: 11px;
}
.Item_badge__info {
  background-color: #b4e1fa;
  color: #3e4e57;
}
.Item_badge__success {
  background-color: #bbe5b3;
  color: #414f3e;
}
.Item_badge__attention {
  background-color: #ffea8a;
  color: #595130;
}
.Item_badge__warning {
  background-color: #ffc58b;
  color: #594430;
}

.Item_date {
  color: #6b778c;
  font-size: 12px;
}
.Item_desc {
  margin-top: 4px;
  color: rgba(0,0,0,0.66);
  font-size: 13px;
  line-height: 18px;
}
.Item_actions {
  margin-top: 8px;
}

.Action {
  @include actionButtonBase;
}
</style>
