<template>
  <div>
    <PopperTrigger
      ref="trigger"
      @click.native="handleTriggerClick"
    >
      <slot
        name="trigger"
        v-bind="slotProps"
      />
    </PopperTrigger>
    <MountingPortal :mountTo="portalSelector" append>
      <div
        ref="body"
        :aria-hidden="String(!isVisible)"
        :class="bodyClasses"
        :style="mergedBodyStyles"
      >
        <slot v-bind="slotProps">
          <div>Empty</div>
        </slot>
      </div>
    </MountingPortal>
  </div>
</template>

<script>
// import {Portal} from '@linusborg/vue-simple-portal'
import {MountingPortal} from 'portal-vue'
import createPopper from '../../utils/createPopper'

const PopperTrigger = {
  mounted() {
    this.$forceUpdate()
  },
  updated() {
    this.$parent.popperReference = this.$el
    this.$parent.updatePopper()
  },
  render() {
    // console.log('render PopperTrigger', this.$scopedSlots.default())
    return this.$scopedSlots.default()
  }
}

const bodySizeModes = ['auto']
const validPlacements = [
  'top', 'top-start', 'top-end',
  'bottom', 'bottom-start', 'bottom-end',
  'left', 'left-start', 'left-end',
  'right', 'right-start', 'right-end'
]

function ensureSelectorExists(selector) {
  if (selector.charAt(0) !== '#') {
    throw new Error('Popper selector must be an ID selector')
  }
  if (!document.querySelector(selector)) {
    const container = document.createElement('div')
    container.id = selector.substring(1)
    document.body.appendChild(container)
  }
}

export default {
  components: {
    PopperTrigger,
    MountingPortal
},
  props: {
    adjustsVisibility: {
      type: Boolean,
      default: true
    },
    bodyClasses: {
      type: null
    },
    bodyStyles: {
      type: Object,
      default: () => {}
    },
    portalSelector: {
      type: String,
      default: '#PopperPortal'
    },
    offset: {
      type: Number,
      default: 0
    },
    bodySizeMode: {
      type: String,
      default: 'auto',
      validator: val => bodySizeModes.indexOf(val) >= 0
    },
    zIndex: {
      type: [Number, String],
      default: 1
    },
    visible: {
      type: Boolean,
      default: false
    },
    modifiers: {
      type: Object,
      default: () => {}
    },
    placement: {
      type: String,
      default: 'bottom',
      validator: val => validPlacements.indexOf(val) >= 0
    },
    autoHide: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: this.visible,
      canDisplay: false
    }
  },
  computed: {
    slotProps() {
      return {
        visible: this.isVisible,
        show: this.show,
        hide: this.hide,
        toggle: this.toggle
      }
    },
    stateThatRequiresUpdate() {
      return {
        autoHide: this.autoHide,
        offset: this.offset,
        placement: this.placement
      }
    },
    hasCustomTriggerLogic() {
      return this.$slots.trigger == null && this.$scopedSlots.trigger != null
    },
    mergedBodyStyles() {
      return {
        ...this.bodyStyles,
        zIndex: this.zIndex,
        position: 'absolute',
        visibility: this.isVisible || !this.adjustsVisibility
          ? 'visible'
          : 'hidden',
        display: this.canDisplay
          ? undefined
          : 'none'
      }
    },
    finalModifiers() {
      return [
        {
          name: 'offset',
          options: {
            offset: [0, this.offset]
          }
        },
        {
          name: 'flip',
          options: {
            altBoundary: true
          }
        }
        // {
        //   name: 'topLogger',
        //   enabled: true,
        //   phase: 'main',
        //   fn({ state }) {
        //     const {reference, popper} = state.elements
        //     popper.style.minWidth = reference.clientWidth + 'px'
        //     console.log('topLogger', state)
        //   }
        // }
      ]
    }
  },
  watch: {
    stateThatRequiresUpdate: {
      deep: true,
      handler() {
        this.updatePopper()
      }
    },
    visible(visible) {
      this.setVisible(visible)
    }
  },
  created() {
    ensureSelectorExists(this.portalSelector)
  },
  beforeDestroy() {
    this.destroyPopper()
    this.removeDocClickListener()
  },
  mounted() {
    this.$forceUpdate()
  },
  methods: {
    updatePopper() {
      this.destroyPopper()
      const triggerEl = this.popperReference
      const contentEl = this.$refs.body
      if (!triggerEl || !contentEl) {
        return
      }
      this.popper = createPopper(triggerEl, contentEl, {
        modifiers: this.finalModifiers,
        placement: this.placement,
        onFirstUpdate: () => {
          this.$emit('first-update')
        }
      })
    },
    destroyPopper() {
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
      }
    },
    handleTriggerClick() {
      if(this.hasCustomTriggerLogic) {
        return
      }
      this.toggle()
    },
    setVisible(isVisible) {
      if (this.disabled) {
        return
      }
      // First change the "display" style and wait for DOM update.
      // This will allow the Popper library to correctly see layout.
      this.canDisplay = isVisible
      setTimeout(() => {
        // Now change "visibility" and position the popper.
        this.isVisible = isVisible
        this.$emit('update:visible', isVisible)
        this.updatePopper()
        if (this.isVisible && this.autoHide) {
          this.addDocClickListener()
        } else {
          this.removeDocClickListener()
        }
      }, 0)
    },
    show() {
      this.setVisible(true)
    },
    hide() {
      this.setVisible(false)
    },
    toggle() {
      this.setVisible(!this.isVisible)
    },
    addDocClickListener() {
      setTimeout(() => {
        if (!this.onDocClick) {
          this.onDocClick = (event) => {
            if (
              !this.autoHide ||
              (this.$refs.body && this.$refs.body.contains(event.target)) ||
              (this.popperReference && this.popperReference.contains(event.target))
            ) {
              return
            }
            this.hide()
          }
          document.addEventListener('click', this.onDocClick)
        }
      })
    },
    removeDocClickListener() {
      if (this.onDocClick) {
        document.removeEventListener('click', this.onDocClick)
        this.onDocClick = null
      }
    }
  }
}
</script>
