<script>
import {mergeData} from 'vue-functional-data-merge'
import {renderHeader} from './utils'

/**
 * Cards are used to group similar concepts and tasks together to make screens
 * easier for users to scan, read, and get things done.
 *
 * @requires ./CardSection.vue
 */
export default {
  name: 'Card',
  props: {
    /**
     * The title heading of the card.
     */
    title: {
      type: String
    },
    /**
     * An array of action objects:
     *
     * ```ts
     * interface Action {
     *   label: string
     *   icon: string
     *   disabled: boolean
     *   url: string
     *   external: boolean
     *   onAction(): void
     * }
     * ```
     */
    actions: {
      type: Array
    },
    collapseActions: {
      type: Boolean
    },
    /**
     * Renders the card with a subtle background color.
     */
    subdued: {
      type: Boolean
    }
  },
  functional: true,
  render(h, {props, data, scopedSlots, children, $style}) {
    const header = renderHeader(h, props, $style, scopedSlots)
    return h(
      'div',
      mergeData(data, {
        class: [
          'UIElement',
          $style.Card,
          props.subdued && $style.subdued
        ]
      }),
      [header, children]
    )
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

.Card {
  // overflow: hidden;
  background: #fff;
  // border: 1px solid #e7e9ec;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05),
    0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.header {
  display: flex;
  align-items: center;
  padding: $s4 $s4 0;
}

.actions {
  margin-left: auto;
}

.action + .action {
  margin-left: 4px;
}

.title {
  flex: 1 1 auto;
  color: #212b36;
  font-size: 17px;
  font-weight: 500;
}
.title__custom {
  flex: 1 1 auto;
  color: #212b36;
  font-size: 17px;
}

.subdued {
  background: #f9fafb;
}

.Card + .Card {
  margin-top: 20px;
}
</style>
