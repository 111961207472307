<script>
export default {
  name: 'Sticky',
  data() {
    return {
      top: 0
    }
  },
  mounted() {
    this.top = this.$el.offsetTop
  },
  render(h) {
    return h('div', {
      style: {
        position: 'sticky',
        top: (this.top || 0) + 'px'
      }
    }, this.$slots.default)
  }
}
</script>
