<template>
  <Labelled
    :label="label"
    :error="error"
    :help-text="helpText"
    :help-text-html="helpTextHtml"
    :help-link="helpLink"
  >
    <div
      :class="[
        'UIElement',
        $style.NavField,
        (disabled || loading) && $style.disabled,
        readonly && $style.readonly,
        !title && $style.noTitle
      ]"
      v-on="$listeners"
      :tabindex="canFocus ? 0 : -1"
      @keydown.space="$emit('click')"
    >
      <slot name="content">
        <div :class="$style.content">
          <div
            v-if="placeholder && !title"
            :class="$style.placeholder"
          >{{placeholder}}</div>
          <div :class="$style.title">{{title}}</div>
          <div :class="$style.description">{{description}}</div>
        </div>
      </slot>
      <Spinner
        v-if="loading"
        size="small"
      />
      <Icon
        v-if="!loading && !readonly"
        :class="$style.icon"
        icon="far fa-chevron-right"
      />
    </div>
  </Labelled>
</template>

<script>
import {Icon} from '../Icon'
import {Labelled} from '../Labelled'
import {Spinner} from '../Spinner'

export default {
  name: 'NavField',
  components: {
    Icon,
    Labelled,
    Spinner
  },
  props: {
    label: {
      type: String
    },
    error: {
      type: String
    },
    /**
     * Additional help text to display.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    placeholder: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canFocus() {
      return !this.disabled
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

.NavField {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #c4cdd5;
  border-radius: 3px;
  background: linear-gradient(to bottom, #fff, #f9fafb);
  box-shadow:
    0 0 0 1px transparent,
    0 1px 0 0 rgba(22, 29, 37, 0.05);
  font-size: 14px;
  line-height: 1.5;
  cursor: default;

  &:focus {
    outline: none;

    &:not(.disabled) {
      border-color: $focusColor;
      box-shadow: 0 0 0 1px $focusColor;
    }
  }

  &.readonly {
    // border: none;
    background: #fff;
  }

  &.disabled, &.readonly {
    box-shadow: none;
    cursor: $inputDisabledCursor;
  }
}
.content {
  flex: 1;
  min-width: 0;
  padding-right: 10px;

  .disabled & {
    opacity: 0.4;
  }

  .noTitle & {
    color: rgba(0, 0, 0, 0.54);
  }
}
.title {
  font-size: 13px;
  font-weight: 500;
}
.description {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.icon {
  flex: 0 0 8px;

  .disabled & {
    opacity: 0.2;
  }
}
</style>
