<template>
  <Modal
    :title="title"
    :visible="visible"
    :primary-action="{
      label: $t('Update'),
      disabled: disableUpdate,
      tooltip: disableUpdate ? $t('validation.required', {_field_: col.label }) : null,
      onAction: update
    }"
    :secondary-actions="[
      {
        label: $t('Cancel'),
        onAction: close
      }
    ]"
    overflow-visible
    @afterLeave="afterLeave"
    @close="close"
  >
    <template v-if="col">
      <FormLayout v-if="col.type === 'text'">
        <TextField
          :label="col.label"
          :multiline="col.multiline"
          v-model="value"
        />
      </FormLayout>
      <FormLayout v-else-if="col.type === 'number'">
        <TextField
          :label="col.label"
          type="number"
          :min="col.min"
          :max="col.max"
          :step="col.step"
          v-model="value"
        />
      </FormLayout>
      <FormLayout v-else-if="col.type === 'list'">
        <Labelled
          v-if="col.multiple"
          :label="$t('ui.bulkEditor.updateStrategy')"
        >
          <RadioButton
            :label="$t('ui.bulkEditor.replaceValues.label')"
            :help-text="$t('ui.bulkEditor.replaceValues.desc')"
            true-value="replace"
            v-model="updateStrategy"
          />
          <RadioButton
            :label="$t('ui.bulkEditor.addValues.label')"
            :help-text="$t('ui.bulkEditor.addValues.desc')"
            true-value="add"
            v-model="updateStrategy"
          />
          <RadioButton
            :label="$t('ui.bulkEditor.removeValues.label')"
            :help-text="$t('ui.bulkEditor.removeValues.desc')"
            true-value="remove"
            v-model="updateStrategy"
          />
        </Labelled>
        <Select
          :label="col.label"
          :options="col.options || []"
          :multiple="col.multiple"
          :placeholder="$t('Select')"
          :tags="col.multiple"
          :clearable="col.clearable"
          v-model="value"
        />
      </FormLayout>
      <FormLayout v-else-if="col.type === 'boolean'">
        <Select
          :label="col.label"
          :options="[
            {value: true, label: $t('TRUE')},
            {value: false, label: $t('FALSE')}
          ]"
          :placeholder="$t('Select')"
          v-model="value"
        />
      </FormLayout>
      <FormLayout v-else-if="isDatePickerType(col.type)">
        <DatePicker
          :type="col.type"
          :label="col.label"
          clearable
          v-model="value"
        />
      </FormLayout>
      <FormLayout v-else-if="col.type === 'timezone'">
        <TimezonePicker
          :label="col.label"
          v-model="value"
        />
      </FormLayout>
    </template>
  </Modal>
</template>

<script>
import {DatePicker, isDatePickerType} from '../DatePicker'
import {Modal} from '../Modal'
import {Select} from '../Select'
import {TextField} from '../TextField'
import {TimezonePicker} from '../TimezonePicker'
// import {cloneDeep, isEqualUnordered} from '../../utils'
import {cloneDeep} from '../../utils'

export default {
  components: {
    DatePicker,
    Modal,
    Select,
    TextField,
    TimezonePicker
  },
  data() {
    return {
      visible: false,
      value: null,
      currentValue: null,
      col: null,
      row: null,
      updateStrategy: 'replace'
    }
  },
  computed: {
    title() {
      const {col} = this
      return col ? `${this.$t('Edit')} "${col.label}"` : null
    },
    disableUpdate() {
      const {col,value} = this
      if (!col) return false
      const required = col.required
        || (!col.multiple && col.type === 'list')
        || col.type === 'boolean'
      if (!required) return false
      const isMissing = !value && value !== 0 && value !== false
      const isEmptyArray = this.updateStrategy === 'replace' && col.multiple && !(value && value.length)
      return isMissing || isEmptyArray
    }
    // sourceValue() {
    //   return this.row ? this.row.source[this.col.key] : null
    // }
    // hasChanged() {
    //   // return !isEqualUnordered(this.value, this.sourceValue)
    //   return !isEqualUnordered(this.value, this.currentValue)
    // }
  },
  methods: {
    isDatePickerType,
    beginEdit(data) {
      const {col, row, noValue} = data
      this.currentValue = col.key in row.updates
        ? row.updates[col.key]
        : row.source[col.key]
      this.col = col
      this.row = row
      this.value = !noValue ? cloneDeep(this.currentValue) : null
      this.updateStrategy = 'replace'
      this.visible = true
    },
    update() {
      this.visible = false
      this.$emit('update', {
        value: this.value,
        strategy: this.updateStrategy
      })
    },
    afterLeave() {
      this.col = null
      this.row = null
      this.value = null
    },
    close() {
      this.visible = false
    }
  }
}
</script>

<style lang="scss" module>
</style>
