export default {
  start() {
    const event = new Event('ui-progress')
    event.subType = 'start'
    window.dispatchEvent(event)
  },
  done() {
    const event = new Event('ui-progress')
    event.subType = 'done'
    window.dispatchEvent(event)
  },
  addEventListener(handler) {
    window.addEventListener('ui-progress', handler)
  },
  removeEventListener(handler) {
    window.removeEventListener('ui-progress', handler)
  }
}
