<script>
import {mergeData} from 'vue-functional-data-merge'
import styles from './List.module.scss'
import {Icon} from '../Icon'

export default {
  props: {
    item: {
      type: Object
    },
    listType: {
      type: String
    }
  },
  functional: true,
  render(h, {children, data, props}) {
    const item = props.item
    if (item) {
      const iconMarkup = (
        item.label &&
        props.listType === 'error'
      ) && h(Icon, {
        class: styles.ListItem_icon,
        props: {
          icon: 'far fa-exclamation-circle'
        }
      })
      const labelMarkup = item.label && h('div', {
        class: styles.ListItem_label
      }, [
        iconMarkup,
        item.label
      ])
      const contentMarkup = item.content && h('div', {
        class: styles.ListItem_content
      }, item.content)
      const idLabelMarkup = item.idLabel && h('div', {
        class: styles.ListItem_idLabel
      }, item.idLabel)
      return h('li', mergeData(data, {
        class: [
          styles.ListItem,
          item.onAction && styles.ListItem__hasAction
        ]
      }), [
        labelMarkup,
        contentMarkup,
        idLabelMarkup
      ])
    }
    return h('li', mergeData(data, {
      class: styles.ListItem
    }), children)
  }
}
</script>
