var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'UIElement',
    _vm.$style.Tabs,
    _vm.theme === 'purple' && _vm.$style.Purple
  ]},[_c('div',{ref:"tabContainer",class:_vm.$style.Tab_container},[_vm._l((_vm.tabs),function(tab){return _c('button',{key:tab.id,ref:"tabs",refInFor:true,class:[
        _vm.$style.Tab,
        _vm.selected === tab.id && _vm.$style.Tab__selected
      ],style:({
        visibility: _vm.truncatedTabIdMap[tab.id] ? 'hidden' : 'visible',
        position: _vm.truncatedTabIdMap[tab.id] ? 'absolute' : 'relative'
      }),attrs:{"type":"button"},on:{"click":function($event){return _vm.onChange(tab)},"mouseup":function($event){return $event.currentTarget.blur()}}},[_c('div',{class:_vm.$style.Tab_title},[_vm._t("tab",function(){return [_vm._v(" "+_vm._s(tab.label)+" "),(tab.badge)?_c('Badge',{class:_vm.$style.Tab_badge,attrs:{"status":tab.badgeStatus,"progress":tab.badgeProgress}},[_vm._v(_vm._s(tab.badge))]):_vm._e()]},{"tab":tab})],2),_c('div',{class:_vm.$style.Tab_bar})])}),(_vm.truncatedTabActions.length > 0)?_c('ActionList',{class:[
        _vm.$style.TruncatedTabsButton,
        _vm.hasSelectedTruncatedTab && _vm.$style.selected
      ],attrs:{"items":_vm.truncatedTabActions,"placement":"bottom-end"}},[_c('Button',{attrs:{"type":"plain","icon":_vm.truncatedTabLabel ? '' : 'fas fa-ellipsis-h',"dropdown":!!_vm.truncatedTabLabel}},[_vm._v(_vm._s(_vm.truncatedTabLabel))])],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }