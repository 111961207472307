<template>
  <MountingPortal mountTo="#sheetManager" append>
    <div
      :class="[
        'UIElement',
        $style.sheet,
        index === 0 && $style.foreground,
        index > 0 && $style.background,
        size === 'large' && $style.sizeLarge
      ]"
      :style="{
        zIndex: index
      }"
      ref="sheet"
    >
      <slot name="external"></slot>
      <transition
        name="Sheet-backdrop"
        :enter-active-class="$style.backdropEnterActive"
        :leave-active-class="$style.backdropLeaveActive"
        :enter-class="$style.backdropEnter"
        :leave-to-class="$style.backdropLeaveTo"
      >
        <div
          :class="$style.backdrop"
          @click="close"
          v-if="visible"
          :style2="{
            outline: '3px solid transparent',
            outlineOffset: '-3px',
            outlineColor: color
          }"
        ></div>
      </transition>
      <transition
        name="Sheet-container"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @after-leave="afterLeave"
      >
        <div
          :class="[
            $style.container,
            containerClass
          ]"
          v-if="visible"
          :style="{
            width: finalWidth + 'px',
            transform: `translate(${x}px, 0)`
          }"
        >
          <div v-if="loading" :class="$style.loader">
            <Spinner color="blue" size="large" />
          </div>
          <div :class="$style.header">
            <div :class="$style.close" @click="close">
              <Icon icon="fal fa-times" />
            </div>
            <div :class="$style.title">{{title}}</div>
            <div :class="$style.actions">
              <slot name="actions"></slot>
              <ButtonGroup
                v-if="actions.length"
                :style="[
                  $slots.actions && 'margin-left: 8px'
                ]"
              >
                <Button
                  v-for="(action, index) in actions"
                  :key="index"
                  :type="action.type"
                  :disabled="action.disabled"
                  :url="action.url"
                  :external="action.external"
                  :icon="action.icon"
                  @click="action.onAction"
                >{{action.label}}</Button>
              </ButtonGroup>
            </div>
          </div>
          <ScrollPane
            :class="$style.contentWrapper"
            :disabled="disableScroll"
            :padding="padded ? '30px' : '0'"
          >
            <div
              :class="[
                $style.content,
                padded && $style.contentPadded,
                contentClass
              ]"
            >
              <slot></slot>
            </div>
          </ScrollPane>
        </div>
      </transition>
    </div>
  </MountingPortal>
</template>

<script>
// import {Portal} from '@linusborg/vue-simple-portal'
import {MountingPortal} from 'portal-vue'
import {Button,ButtonGroup} from '../Button'
import {Icon} from '../Icon'
import {ScrollPane} from '../ScrollPane'
import {Spinner} from '../Spinner'
import {debounce} from '../../utils'

const manager = (function() {
  const stack = (window.uiSheetStack = window.uiSheetStack || [])
  let overflowClass = null
  return {
    stack,
    add(vm) {
      const index = stack.indexOf(vm)
      if (index > 0) {
        stack.splice(index, 1)
      }
      for (const other of stack) {
        other.isActive = false
      }

      let xOffset = 0
      let i = stack.length - 1
      while (i >= 0) {
        xOffset += stack[i].peekWidth
        stack[i].xOffset = xOffset
        i--
      }

      stack.push(vm)
      this.handleOverflow()
    },
    beforeRemove(vm) {
      const length = stack.length
      if (length > 1) {
        const index = stack.indexOf(vm)
        if (index === length - 1) {
          stack[length - 2].isActive = true
        }
      }

      let xOffset = 0
      let i = stack.length - 2
      while (i >= 0) {
        stack[i].xOffset = xOffset
        xOffset += stack[i].peekWidth
        i--
      }
    },
    remove(vm) {
      const index = stack.indexOf(vm)
      if (index >= 0) {
        stack.splice(index, 1)
      }
      if (stack.length) {
        stack[stack.length - 1].isActive = true
      }
      this.handleOverflow()
    },
    getNextZIndex() {
      let index = 1000
      for (const vm of stack) {
        index = Math.max(index, vm.index + 1)
      }
      return index
    },
    handleOverflow() {
      document.body.classList.toggle(overflowClass, stack.length > 0)
    },
    setOverflowClass(name) {
      overflowClass = name
    }
  }
})()

export default {
  name: 'Sheet',
  model: {
    prop: 'visible',
    event: 'update'
  },
  components: {
    Button,
    ButtonGroup,
    Icon,
    MountingPortal,
    ScrollPane,
    Spinner
  },
  props: {
    title: {
      type: String
    },
    visible: {
      type: Boolean
    },
    size: {
      type: String
    },
    width: {
      type: Number,
      default: 700
    },
    peekWidth: {
      type: Number,
      default: 100
    },
    padded: {
      type: Boolean
    },
    minIndex: {
      type: Number,
      default: 1000
    },
    color: {
      type: String
    },
    /**
     * Classes to add to the sheet element.
     */
    containerClass: {
      type: null
    },
    /**
     * Classes to add to the sheet content element.
     */
    contentClass: {
      type: null
    },
    /**
     * Shows a spinner over the whole sheet and prevents closing.
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * The main sheet action, displayed at the upper right corner
     * of the sheet
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // Defaults to "primary". See `Button` for other types.
     *   type: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled?: boolean
     *   url?: string
     *   external?: boolean
     *   onAction?(): void
     * }
     * ```
     */
    primaryAction: {
      type: Object
    },
    /**
     * Optional secondary actions. These actions and the action groups are
     * displayed left of the primary action button
     *
     * ```ts
     * interface Action {
     *   label: string
     *   type: string
     *   icon: string
     *   disabled?: boolean
     *   url?: string
     *   external?: boolean
     *   onAction?(): void
     * }
     * ```
     */
    secondaryActions: {
      type: Array
    }
  },
  computed: {
    x() {
      let x = Math.max(
        this.xOffset ? this.screenWidth - this.width - this.xOffset : 0,
        0
      )
      if (this.isActive) {
        x = Math.max(0, this.screenWidth - this.width)
      }
      return x
    },
    finalWidth() {
      let width = this.width
      if (this.isActive) {
        width = Math.min(this.width, this.screenWidth)
      }
      return width
    },
    actions() {
      const actions = []
      if (this.secondaryActions) {
        actions.push(...this.secondaryActions)
      }
      if (this.primaryAction) {
        actions.push({
          type: 'primary',
          ...this.primaryAction
        })
      }
      return actions
    }
  },
  data() {
    return {
      index: 0,
      isActive: false,
      disableScroll: true,
      screenWidth: window.innerWidth,
      xOffset: 0
    }
  },
  created() {
    if (!document.getElementById('sheetManager')) {
      const container = document.createElement('div')
      container.id = 'sheetManager'
      document.body.appendChild(container)
    }

    manager.setOverflowClass(this.$style.preventScroll)
    this.onResize = debounce(
      () => {
        this.screenWidth = window.innerWidth
      },
      250,
      {leading: true}
    )
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    manager.remove(this)
  },
  methods: {
    beforeEnter(el) {
      this.index = Math.max(this.minIndex, manager.getNextZIndex())
      // For some reason Safari on Mac does not like a scrollable container
      // inside a fixed position element. The container cannot be scrolled with
      // the mouse without first manually dragging the scrollbar. Might have
      // something to do with GPU rendering bug. A workaround is to change from
      // overflow `hidden` to `scroll` after the animation.
      this.disableScroll = true
      manager.add(this)
      el.style.transform = `translate(${this.screenWidth}px, 0)`
      /**
       * Before Opening
       * @event before-open
       */
      this.$emit('before-open')
    },
    enter(el, done) {
      const x = Math.max(0, this.screenWidth - this.finalWidth)
      el.style.transform = `translate(${x}px, 0)`
      this.isActive = true
      setTimeout(done, 300)
    },
    afterEnter() {
      // Enable scrolling for workaround in `beforeEnter` hook.
      this.disableScroll = false
      /**
       * After Opening
       * @event after-open
       */
      this.$emit('after-open')
    },
    beforeLeave(el) {
      el.style.transform = `translate(${this.screenWidth}px, 0px)`
      manager.beforeRemove(this)
      /**
       * Before Closing
       * @event before-close
       */
      this.$emit('before-close')
    },
    afterLeave() {
      manager.remove(this)
      /**
       * After Close
       * @event after-close
       */
      this.$emit('after-close')
    },
    close() {
      if (!this.loading) {
        /**
         * On Close 1st
         * @event update
         */
        this.$emit('update', false)
        /**
         * On Close 2nd
         * @event closee
         */
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" module>
$slideDuration: 0.7s;

// Added to <body> when sheet is open.
.preventScroll {
  // Safari on Mac does not respect `overflow-y: hidden`.
  overflow: hidden;
}

.sheet {
  position: fixed;
  z-index: 1000;
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.32;
  will-change: opacity;
}
.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 400px;
  background: #f1f3f4;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.2), 0 0 24px 2px rgba(0, 0, 0, 0.14),
    0 0 30px 5px rgba(0, 0, 0, 0.12);
  transform: translate(0, 0);
  transition: transform $slideDuration ease;
  will-change: transform;
}
.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background: rgba(255, 255, 255, 0.8);
}
.header {
  display: flex;
  align-items: center;
  min-height: 65px;
  padding-left: 10px;
  padding-right: 16px;
  border-bottom: 1px solid #dadce0;
  background: #fff;
}
.close {
  padding: 10px 15px;
  color: #767676;
  font-size: 20px;
  &:hover {
    color: #000;
    cursor: pointer;
  }
}
.title {
  flex: 1 1 auto;
  font-size: 20px;
}
.actions {
  display: flex;
  flex: 0 0 auto;
}

.contentWrapper {
  flex: 1;
  overflow-y: hidden;
  // padding: 25px;
}

.sizeLarge {
  > .container {
    // width: 75%;
  }
}

.background {
  > .container {
    // width: 100%;
  }
}

.contentPadded {
  max-width: 900px;
  margin: 0px auto;
}

.backdropEnterActive,
.backdropLeaveActive {
  transition: opacity $slideDuration ease;
}
.backdropEnter,
.backdropLeaveTo {
  opacity: 0;
}
</style>
