<script>
import {MountingPortal} from "portal-vue"

export default {
  props: {
    /**
     * Renders the content in a `<MountingPortal>` if `true` otherwise renders
     * the content in a `<div>`.
     */
    teleport: {
      type: Boolean,
      default: true
    },
    mountTo: {
      type: String,
      default: 'body'
    },
    append: {
      type: [Boolean, String],
      default: true
    }
  },
  functional: true,
  render(h, {props, children}) {
    if (props.teleport) {
      return h(MountingPortal, {
        props: {
          mountTo: props.mountTo,
          append: props.append
        }
      }, children)
    }
    return h('div', {
      attr: {
        'data-mounting-portal-disabled': true
      }
    }, children)
  }
}
</script>