import {Button} from '../Button'
import {ActionList} from '../ActionList'

const noop = () => {}

export function renderHeader(h, props, styles, scopedSlots = {}) {
  // const actionsMarkup = (props.actions || [])
  //   .filter((d) => d.visible !== false)
  //   .map((d) =>
  //     h(
  //       Button,
  //       {
  //         class: styles.action,
  //         props: {
  //           type: 'plain',
  //           icon: d.icon,
  //           disabled: d.disabled,
  //           url: d.url,
  //           external: d.external
  //         },
  //         on: {
  //           click: d.onAction || noop
  //         }
  //       },
  //       d.label
  //     )
  //   )

  const visibleActions = (props.actions || []).filter(d => d.visible !== false)
  let actionsMarkup = null
  if (visibleActions.length) {
    if (props.collapseActions) {
      actionsMarkup = h(
        'div', {
          class: styles.actions
        },
        [
          h(ActionList, {
            props: {
              items: visibleActions,
              placement: 'bottom-end',
              popperOffset: -10
            }
          }, [
            // h(Button, {
            //   props: {
            //     type: 'plain',
            //     icon: 'fa fa-ellipsis-h',
            //     dropdown: true
            //   }
            // }, 'Actions')
            h(Button, {
              props: {
                type: 'plain',
                icon: 'fa fa-ellipsis-h'
              }
            })
          ])
        ]
      )
    } else {
      actionsMarkup = h(
        'div', {
          class: styles.actions
        },
        visibleActions.map(d => h(
          Button,
          {
            class: styles.action,
            props: {
              type: 'plain',
              icon: d.icon,
              disabled: d.disabled,
              tooltip: d.tooltip,
              tooltipPosition: d.tooltipPosition,
              url: d.url,
              external: d.external
            },
            on: {
              click: d.onAction || noop
            }
          },
          d.label
        ))
      )
    }
  }

  // if (props.title || actionsMarkup.length) {
  if (props.title || scopedSlots.title || actionsMarkup) {
    const content = []

    if (scopedSlots.title) {
      content.push(
        h(
          'div',
          {
            class: styles.title__custom
          },
          [scopedSlots.title()]
        )
      )
    } else if (props.title) {
      content.push(
        h(
          'div',
          {
            class: styles.title
          },
          props.title
        )
      )
    }

    if (actionsMarkup) {
      content.push(actionsMarkup)
    }

    // if (props.actions) {
    //   if (actionsMarkup) {
    //     // content.push(actionsMarkup)
    //     content.push(
    //       h(
    //         'div',
    //         {
    //           class: styles.actions
    //         },
    //         [actionsMarkup]
    //       )
    //     )
    //   }
    // }

    return h(
      'div',
      {
        class: styles.header,
        attrs: {
          'data-card-header': true
        }
      },
      content
    )
  }
}
