<template>
  <div
    :class="[
      'UIElement',
      'UILayoutSectionAnnotated',
      $style.LayoutSectionAnnotated,
      fullWidth && $style.fullWidth
    ]"
  >
    <div :class="$style.Info">
      <div :class="$style.Info_content">
        <Heading element="h2">{{title}}</Heading>
        <div
          :class="$style.description"
          v-if="description || $slots.description"
        >
          <slot name="description">
            {{description}}
          </slot>
        </div>
      </div>
      <div :class="$style.Info_actions">
        <slot name="actions"></slot>
      </div>
    </div>
    <div :class="$style.content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {Heading} from '../Heading'

export default {
  components: {
    Heading
  },
  props: {
    /**
     * The title to show next to the section content.
     */
    title: {
      type: String
    },
    /**
     * The description text to display below the title.
     */
    description: {
      type: String
    },
    /**
     * Forces the layout to use the full width for the title and description,
     * rather than placing these to the left on larger screens.
     */
    fullWidth: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

.LayoutSectionAnnotated {
  flex: 1 1 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: $layoutGutter;
  margin-left: $layoutGutter;
  // overflow: hidden;

  &.fullWidth {
    display: block;
  }
}

.LayoutSectionAnnotated + .LayoutSectionAnnotated {
  margin-top: 2rem;
  padding-top: 20px;
  border-top: 1px solid #dfe3e8;
}

.Info {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  flex: 1 1 240px;
}
.Info_content {
  flex: 1 1 240px;
  padding-right: 64px;
}
.Info_actions {

}

.description {
  margin-top: 16px;
  color: #637381;
  font-size: 14px;
  line-height: 20px;

  p {
    margin-top: 12px;
  }
  p:first-child {
    margin-top: 0;
  }
}

.content {
  flex: 2 1 $layoutAnnotationContentMinWidth;
  max-width: 100%;
  // Add minor padding so that the `overflow: hidden` styles used in this
  // component does not cut off drop shadows of Card components.
  // padding: 2px;
  // overflow: hidden;
}
</style>
