const STATES = ['unchanged', 'changed', 'saving']

const store = {
  state: 'unchanged',
  save() {
    const event = new CustomEvent('ui-save-bar', {
      detail: {
        type: 'save',
        state: this.state
      }
    })
    window.dispatchEvent(event)
  },
  discard() {
    const event = new CustomEvent('ui-save-bar', {
      detail: {
        type: 'discard',
        state: this.state
      }
    })
    window.dispatchEvent(event)
  },
  /**
   * @param {'unchanged' | 'changed' | 'saving'} value
   */
  setState(value) {
    this.state = value
    const event = new CustomEvent('ui-save-bar', {
      detail: {
        type: 'stateChange',
        state: this.state
      }
    })
    window.dispatchEvent(event)
  },
  addEventListener(handler) {
    window.addEventListener('ui-save-bar', handler)
  },
  removeEventListener(handler) {
    window.removeEventListener('ui-save-bar', handler)
  }
}

export function validateState(state) {
  if (STATES.indexOf(state) === -1) {
    throw new Error(`Unknown save bar state "${state}"`)
  }
  return true
}

export default store
