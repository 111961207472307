<template>
  <div
    :class="[
      $style.TreeNode,
      layout === 'horizontal' && $style.horizontal,
      layout === 'vertical' && $style.vertical,
      info.hasSiblings && $style.hasSiblings,
      info.isFirst && $style.isFirst,
      info.isLast && $style.isLast,
      info.isEdge && $style.isEdge,
      info.hasParent && $style.hasParent,
      info.hasChildren && $style.hasChildren
    ]"
  >
    <div :class="$style.TreeNode_contentWrapper">
      <div
        :class="$style.TreeNode_content"
        :style="contentStyle"
      >
        <slot
          name="node"
          :node="node"
          :parent="parent"
          :index="index"
        >{{node.id}}</slot>
      </div>
    </div>
    <div
      v-if="node[childrenProp] && node[childrenProp].length"
      :class="$style.TreeNode_children"
    >
      <TreeNode
        v-for="(child, index) in node[childrenProp]"
        :key="child.id"
        :parent="node"
        :node="child"
        :children-prop="childrenProp"
        :index="index"
        :layout="layout"
      >
        <template v-slot:node="{node, parent, index}">
          <slot
            name="node"
            :node="node"
            :parent="parent"
            :index="index"
          >{{node.id}}</slot>
        </template>
      </TreeNode>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreeNode',
  props: {
    node: {
      type: Object
    },
    parent: {
      type: Object,
      default: null
    },
    childrenProp: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      default: null
    },
    layout: {
      type: String,
      default: 'vertical'
    }
  },
  computed: {
    info() {
      const {node, parent, childrenProp} = this
      const info = {
        hasSiblings: false,
        isFirst: false,
        isLast: false,
        isEdge: false,
        hasParent: !!parent,
        hasChildren: node[childrenProp] ? node[childrenProp].length > 0 : false
      }
      if (parent) {
        const siblings = parent[childrenProp]
        if (siblings) {
          const length = siblings.length
          info.hasSiblings = length > 1
          info.isFirst = siblings[0] === node
          info.isLast = siblings[length - 1] === node
          info.isEdge = info.isFirst || info.isLast
        }
      }
      return info
    },
    contentStyle() {
      const style = this.node.style
      if (style) {
        const {width, height} = style
        if (this.layout === 'vertical') {
          return {
            width,
            height
          }
        }
        return {
          width: height,
          height: width
        }
      }
      return null
    }
  }
}
</script>

<style lang="scss" module>
@use "sass:math";

$lineWidth: 2px;
$halfLineWidth: math.div($lineWidth, 2);
$lineColor: #aaa;

$primaryLineColor: $lineColor;
$secondaryLineColor: $lineColor;
$edgeColor: $lineColor;
$outline: none;

$debug: false;
@if $debug {
  $primaryLineColor: red;
  $secondaryLineColor: orange;
  $edgeColor: purple;
  $outline: 1px solid #ddd;
}

.TreeNode {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  outline: $outline;
}

.TreeNode_contentWrapper {
  position: relative;
  margin: var(--gutter);
}

.TreeNode_content {
  position: relative;
  z-index: 1;
}

.TreeNode_children {
  display: flex;
  flex-direction: row;
}

// A line going along the primary axis.
// E.g. if the tree is in the horizontal layout this line will be
// going horizontally.
@mixin primaryLine {
  > .TreeNode_contentWrapper:after {
    @content;
  }
}

@mixin secondaryLine {
  &:before {
    @content;
  }
}

.horizontal {
  flex-direction: row;

  > .TreeNode_children {
    flex-direction: column;
  }

  @include primaryLine {
    content: '';
    position: absolute;
    top: calc(50% - #{$halfLineWidth});
    left: 100%;
    display: block;
    width: var(--gutter);
    height: $lineWidth;
    // background: $primaryLineColor;
    background: var(--lineColor);
  }

  &.hasParent {
    @include primaryLine {
      left: calc(var(--gutter) * -1);
      width: var(--gutter);
    }
  }

  &.hasParent.hasChildren {
    @include primaryLine {
      width: calc(100% + var(--gutter) * 2);
    }
  }

  &.hasSiblings {
    @include secondaryLine {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: $lineWidth;
      height: calc(100% + var(--gutter) * 2);
      transform: translate(0, -50%);
      // background: $secondaryLineColor;
      background: var(--lineColor);
    }
  }

  &.isEdge {
    @include secondaryLine {
      height: calc(50% + var(--gutter));
      // background: $edgeColor;
      background: var(--lineColor);
      transform: none;
    }
  }

  &.isLast {
    @include secondaryLine {
      top: calc(var(--gutter) * -1);
    }
  }
}

.vertical {
  flex-direction: column;

  > .TreeNode_children {
    flex-direction: row;
  }

  @include primaryLine {
    content: '';
    position: absolute;
    top: 100%;
    left: calc(50% - #{$halfLineWidth});
    display: block;
    width: $lineWidth;
    height: var(--gutter);
    // background: $primaryLineColor;
    background: var(--lineColor);
  }

  &.hasParent {
    @include primaryLine {
      height: var(--gutter);
      top: calc(var(--gutter) * -1);
    }
  }

  &.hasParent.hasChildren {
    @include primaryLine {
      height: calc(100% + var(--gutter) * 2);
    }
  }

  &.hasSiblings {
    @include secondaryLine {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      display: block;
      width: calc(100% + var(--gutter) * 2);
      height: $lineWidth;
      transform: translate(-50%, 0);
      // background: $secondaryLineColor;
      background: var(--lineColor);
    }
  }

  &.isEdge {
    @include secondaryLine {
      width: calc(50% + var(--gutter));
      // background: $edgeColor;
      background: var(--lineColor);
      transform: none;
    }
  }

  &.isLast {
    @include secondaryLine {
      left: calc(var(--gutter) * -1);
    }
  }
}
</style>
