<script>
// https://github.com/vuejs/vue/blob/b00868c/src/core/vdom/helpers/update-listeners.js#L14
function normalizeEvent(name) {
  const passive = name.charAt(0) === '&'
  name = passive ? name.slice(1) : name
  const once = name.charAt(0) === '~' // Prefixed last, checked first
  name = once ? name.slice(1) : name
  const capture = name.charAt(0) === '!'
  name = capture ? name.slice(1) : name
  return {
    name,
    once,
    capture,
    passive
  }
}

/**
 * @typedef Listener
 * @property {string} name The event name
 * @property {Function} handler The event handler
 * @property {ListenerOptions} options The options used to add the event
 * listener
 * @property {Function} remove A function to remove the event listener
 */

/**
 * @typedef ListenerOptions
 * @property {boolean} [capture]
 * @property {boolean} [passive]
 */

/**
 * @param {string} name
 * @param {EventTarget} target
 * @param {Function} handler
 */
function createEventListener(name, target, handler) {
  const opts = normalizeEvent(name)
  /** @type {Listener} */
  const listener = {
    name: opts.name,
    handler: handler,
    options: {
      capture: opts.capture,
      passive: opts.passive
    },
    remove: null
  }
  if (opts.once) {
    listener.handler = function() {
      handler.apply(null, arguments)
      listener.remove()
    }
  }
  listener.remove = function() {
    target.removeEventListener(listener.name, listener.handler, listener.options)
  }
  target.addEventListener(listener.name, listener.handler, listener.options)
  return listener
}

export default {
  props: {
    /**
     * The target for all event listeners. Can be one of the following:
     *
     *  - `window`
     *  - `document`
     */
    target: {
      type: String,
      default: 'window',
      validator(value) {
        return ['window', 'document'].includes(value)
      }
    }
  },
  render() {
    return undefined
  },
  created() {
    let target
    if (this.target === 'window') {
      target = window
    } else if (this.target === 'document') {
      target = document
    }
    this.listeners = []
    for (const [name, handler] of Object.entries(this.$listeners)) {
      const listener = createEventListener(name, target, handler)
      this.listeners.push(listener)
    }
  },
  beforeDestroy() {
    for (const listener of this.listeners) {
      listener.remove()
    }
    this.listeners = null
  }
}
</script>
