<template>
  <div :class="$style.ItemList">
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="[
        $style.Item,
        readonly && $style.readonly
      ]"
    >
      <Icon
        :class="$style.Item_icon"
        :icon="'fad ' + item.icon"
      />
      <div :class="$style.Item_info">
        <div :class="$style.Item_name">{{item.name}}</div>
        <div :class="$style.Item_size">
          <Formatter
            type="filesize"
            :value="item.size"
          />
          •
          <Formatter
            type="dateAgo"
            :value="item.lastModified"
          />
        </div>
        <div
          v-if="item.error"
          :class="$style.Item_error"
        >{{item.error}}</div>
      </div>
      <Icon
        v-if="!readonly"
        :class="$style.Item_close"
        icon="fas fa-times-circle"
        @click="$emit('remove', index)"
      />
    </div>
  </div><!-- .ItemList -->
</template>

<script>
import {Formatter} from '../Formatter'
import {Icon} from '../Icon'

export default {
  components: {
    Formatter,
    Icon
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

$itemBgColor: #f4f6f8;

.ItemList {
  border-radius: 3px;
  overflow: hidden;
}

.Item {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background: $itemBgColor;
  border-radius: 3px;

  & + & {
    margin-top: 5px;
  }

  &:hover:not(.readonly) {
    background: darken($itemBgColor, 3%);
  }
}
.Item_icon {
  color: #6384dd;
  align-self: baseline;
  margin-right: 14px;
  font-size: 32px;
}
.Item_info {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Item_name {
  color: #444;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.Item_size {
  margin-top: 2px;
  color: $textSubduedColor;
  font-size: 90%;
}
.Item_error {
  margin-top: 4px;
  color: $textNegativeColor;
  font-weight: bold;
  font-size: 90%;
}
.Item_close {
  margin-right: 2px;
  font-size: 18px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}
</style>
