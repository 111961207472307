var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'UIElement',
    _vm.$style.ResourceList
  ]},[_c('div',{class:_vm.$style.ResourceList_header,style:(_vm.gridStyle)},_vm._l((_vm.columns),function(col){return _c('div',{key:col.key,class:[
        _vm.$style.ResourceList_headerCell,
        _vm.getColumnClass(col)
      ]},[_vm._v(_vm._s(col.label))])}),0),(_vm.$scopedSlots.item)?[_vm._l((_vm.items),function(item){return _vm._t("item",function(){return [_vm._v(" Item "+_vm._s(item.id)+" ")]},{"item":item})})]:_vm._l((_vm.items),function(row){return _c('ResourceItem',{key:row.id,class:_vm.$style.ResourceList_row,style:(_vm.gridStyle)},_vm._l((_vm.columns),function(col){return _c('div',{key:col.key,class:[
          _vm.$style.ResourceList_cell,
          _vm.getColumnClass(col)
        ]},[_vm._v(_vm._s(_vm.formatCell(col, row)))])}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }