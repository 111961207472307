<template>
  <Labelled
    :label="label"
    :error="error"
    :help-text="helpText"
    :help-text-html="helpTextHtml"
    :help-link="helpLink"
  >
    <div
      :class="[
        $style.RangeSlider,
        disabled && $style.disabled,
        readonly && $style.readonly
      ]"
      :style="cssVars"
    >
      <input
        type="range"
        :class="[
          $style.Input,
          readonly && $style.Input_readonly
        ]"
        :min="min"
        :step="step"
        :max="max"
        :value="localValue"
        :disabled="disabled || readonly"
        @input="onInput"
        @change="onChange"
      />
      <output :class="$style.Output">{{localValue}}</output>
    </div>
  </Labelled>
</template>

<script>
import {Labelled} from '../Labelled'
import {clamp} from '../../utils'

export default {
  name: 'RangeSlider',
  components: {
    Labelled
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    /**
     * Label for the control.
     */
    label: {
      type: String
    },
    /**
     * Error text to show below the control.
     */
    error: {
      type: String
    },
    /**
     * Help text to show below the control.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    /**
     * Disables the control and prevents all interaction.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Prevents the control value from being edited.
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * The value of the control.
     */
    value: {
      type: Number
    },
    /**
     * The minimum value allowed.
     */
    min: {
      type: Number
    },
    /**
     * The maximum value allowed.
     */
    max: {
      type: Number
    },
    /**
     * Passes this value to the step attribute of the input element.
     *
     * If it's a float number the tooltip will show decimal values.
     */
    step: {
      type: Number
    }
  },
  data() {
    return {
      localValue: 0
    }
  },
  computed: {
    cssVars() {
      const {localValue, min, max} = this
      const clamped = clamp(localValue, min, max)
      const factor = (clamped - min) / (max - min)
      const progress = ((clamped - min) * 100) / (max - min)
      return {
        '--factor': `${factor}`,
        '--progress': `${progress}%`
      }
    }
  },
  watch: {
    value: {
      handler(value) {
        this.localValue = value
      },
      immediate: true
    }
  },
  methods: {
    changeValue(emitParam, value) {
      let rangeSliderValue
      if (this.step && !Number.isInteger(this.step)) {
        rangeSliderValue = parseFloat(value)
      } else {
        rangeSliderValue = parseInt(value, 10)
      }
      if (emitParam === 'input') {
        this.localValue = rangeSliderValue
      }
      this.$emit(emitParam, rangeSliderValue)
    },
    onInput(event) {
      this.changeValue('input', event.target.value)
    },
    onChange(event) {
      this.changeValue('change', event.target.value)
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';
$trackHeight: 4px;
$thumbSize: 24px;
$progressEmptyColor: #c5cdd5;
$progressFullColor: $focusColor;

@mixin track {
  height: $trackHeight;
  box-sizing: border-box;
  border: none;
  border-radius: 2px;
  background: $progressEmptyColor;
  background: linear-gradient(
    to right,
    $progressFullColor 0%,
    $progressFullColor var(--progress, 0%),
    $progressEmptyColor var(--progress, 0%),
    $progressEmptyColor 100%
  );
}

@mixin thumb {
  box-sizing: border-box;
  border: none;
  width: $thumbSize;
  height: $thumbSize;
  border-radius: 50%;
  box-shadow: 0 0 2px #000;
  background: linear-gradient(to bottom, #fff, #f9fafb);
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
}

@mixin thumbFocus {
  box-shadow: 0 0 0 2px $focusColor;
}

@mixin trackContent {
  &::-webkit-slider-runnable-track {
    @content;
  }
  &::-moz-range-track {
    @content;
  }
  &::-ms-track {
    @content;
  }
}

@mixin thumbContent {
  &::-webkit-slider-thumb {
    @content;
  }
  &::-moz-range-thumb {
    @content;
  }
  &::-ms-thumb {
    @content;
  }
}

.RangeSlider {
  position: relative;

  &:not(.disabled),
  &:not(.disabled).readonly {
    &:hover .Output,
    &:focus-within .Output {
      opacity: 1;
    }
  }
}

/* https://css-tricks.com/sliding-nightmare-understanding-range-input/ */
.Input {
  flex: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  min-height: $thumbSize;
  background: transparent;
  outline: none;
  cursor: pointer;
  font: inherit;

  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-webkit-slider-runnable-track {
    @include track;
  }
  &::-moz-range-track {
    @include track;
  }
  &::-ms-track {
    @include track;
  }

  &::-webkit-slider-thumb {
    margin-top: 0.5 * ($trackHeight - $thumbSize);
    @include thumb;
  }
  &::-moz-range-thumb {
    @include thumb;
  }
  &::-ms-thumb {
    margin-top: 0;
    @include thumb;
  }

  &::-ms-tooltip {
    display: none;
  }

  &:focus {
    &::-webkit-slider-thumb {
      @include thumbFocus;
    }
    &::-webkit-slider-thumb {
      @include thumbFocus;
    }
    &::-moz-range-thumb {
      @include thumbFocus;
    }
    &::-ms-thumb {
      @include thumbFocus;
    }
  }

  &:disabled.Input_readonly {
    cursor: $inputReadonlyCursor;
    @include trackContent {
      cursor: $inputReadonlyCursor;
    }
    @include thumbContent {
      cursor: $inputReadonlyCursor;
    }
  }
  &:disabled:not(.Input_readonly) {
    cursor: $inputDisabledCursor;
    @include trackContent {
      background: $progressEmptyColor;
      cursor: $inputDisabledCursor;
    }
    @include thumbContent {
      box-shadow: 0 0 0 2px $progressEmptyColor;
      cursor: $inputDisabledCursor;
    }
  }
}

.Output {
  display: block;
  position: absolute;
  left: 0.5 * $thumbSize;
  top: 0;
  padding: 0.25em 0.5em;
  border-radius: 3px;
  left: var(--progress, 0%);
  transform: translate(calc((-100% * var(--factor, 0))), -35px);
  background: #000;
  color: #eee;
  opacity: 0;
  transition: opacity 0.3s ease;
}
</style>
