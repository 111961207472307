<template>
  <ButtonGroup segmented fill>
    <ActionList
      v-for="(list, index) in shortcuts"
      :key="index"
      :items="list.items"
    >
      <Button dropdown>{{list.label}}</Button>
    </ActionList>
    <ButtonGroupItem v-if="mode === 'multi'" style="margin-left: auto">
      <Button
        v-if="filters.length"
        icon="fa fa-tasks"
        @click="$emit('open')"
      >{{$t('ui.filters.moreFilters')}}</Button>
    </ButtonGroupItem>
  </ButtonGroup>
</template>

<script>
import {ActionList} from '../ActionList'
import {Button, ButtonGroup, ButtonGroupItem} from '../Button'

export default {
  components: {
    ActionList,
    Button,
    ButtonGroup,
    ButtonGroupItem
  },
  props: {
    filters: {
      type: Array,
      default: () => []
    },
    shortcuts: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'multi'
    }
  }
}
</script>
