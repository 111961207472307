<template>
  <div :class="[
    $style.Section,
    min && $style.Section_min,
    theme === 'dark' && $style.Section_Dark
  ]">
    <div
      v-if="section.title"
      :class="$style.Section_header"
    >
      <div v-if="!min" :class="$style.Section_title">{{section.title}}</div>
      <div
        v-if="section.action"
        :class="$style.Section_action"
      >
        <Icon
          :icon="'fa-fw ' + section.action.icon"
        />
      </div>
    </div>
    <Item
      v-for="item in section.items"
      :theme="theme"
      :key="item.id"
      :item="item"
      :location="location"
      :has-primary-icons="hasPrimaryIcons"
      @click="(item, event) => $emit('click-item', item, event)"
      :min="min"
    />
  </div>
</template>

<script>
import {Icon} from '@/components/Icon'
import Item from './Item.vue'

export default {
  name: 'Navigation',
  components: {
    Icon,
    Item
  },
  props: {
    theme: {
      type:String,
      default:'light'
    },
    section: {
      type: Object
    },
    location: {
      type: String
    },
    hasPrimaryIcons: {
      type: Boolean
    },
    min: {
      type: Boolean,
      default: false
    }
  }
}
</script>


<style lang="scss" module>
.Section {
  // Add top margin when inside an AppFrame
  [data-app-frame] &:first-child {
    margin-top: 10px;
  }
  padding: 10px 0px;

  &.Section_Dark {
    .Section_title {
      color: var(--nav-textColor);
    }
  }

  .Section_header {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    min-height: 32px;
    margin-right: 8px;
    padding-left: 20px;
    background: var(--nav-bgColor);
    margin-bottom: 5px;

    .Section_title {
      flex: 1;
      color: var(--nav-textColor);
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .Section_action {
    padding: 8px 12px;
    border-radius: 3px;
    color: var(--nav-textColor);

    &:hover {
      background: #f1f2f3;
      color: #1a1c1d;
      cursor: pointer;
    }
  }

  &.Section_min {
    .Section_header {
      display: none;
    }
  }
}

</style>
