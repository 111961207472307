<template>
  <div
    :class="['UIElement', $style.FooterHelp]"
  >
    <Icon
      :class="$style.icon"
      icon="fa fa-info-circle"
    />
    <div :class="$style.content">
      <div
        v-if="title"
        :class="$style.title"
      >{{title}}: </div>
      <slot />
    </div>
  </div>
</template>

<script>
import {Icon} from '../Icon'
export default {
  components: {
    Icon
  },
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style lang="scss" module>
.FooterHelp {
  display: flex;
  margin: 16px;
}

.icon {
  margin-top: 2px;
  margin-right: 8px;
  color: #454f5b;
}

.content {
  line-height: 1.4;
  opacity: 0.7;
}

.title {
  display: inline;
  font-weight: bold;
}
</style>
