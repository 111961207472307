<script>
import {mergeData} from 'vue-functional-data-merge'
import {nonEmptyChildren} from '../../utils'
import {Icon} from '../Icon'

export default {
  props: {
    message: {
      type: String
    }
  },
  functional: true,
  render(h, {children, data, props, $style}) {
    if (props.message || nonEmptyChildren(children).length) {
      const content = [
        h(Icon, {
          props: {
            icon: 'fas fa-exclamation-circle'
          },
          style: 'margin-right: 6px'
        })
      ]
      if (props.message) {
        content.push(props.message)
      } else {
        content.push(...children)
      }
      return h('div', mergeData(data, {
        class: [
          'UIElement',
          $style.InlineError
        ]
      }), content)
    }
    return h()
  }
}
</script>

<style lang="scss" module>
.InlineError {
  margin-top: 4px;
  color: #bf0711;
  font-size: 14px;
  line-height: 20px;
}
</style>
