<script>
import {debounce} from '../../utils'
import {
  format,
  isNumericType,
  isSummableNumericType
} from '../../utils/format'
import {ActionList} from '../ActionList'
import {Button} from '../Button'
import {CheckboxControl} from '../Checkbox'
import {Icon} from '../Icon'
import {SkeletonDisplayText} from '../Skeleton'
import {Spinner} from '../Spinner'
import BulkActions from './BulkActions'
import DataTableFooter from './DataTableFooter'

export default {
  props: {
    /**
     * An array of Columns.
     *
     * ```ts
     * interface Column {
     *   key: string
     *   label: string
     *   // @See Formatter
     *   format?: FormatType
     *   // Allows overriding the format context for the current cell.
     *   // @See Formatter
     *   formatContext?: (row: object, forTotal: boolean) => Partial&lt;FormatContext&gt;
     *   // Shortcut for setting minWidth and maxWidth to same value.
     *   width?: number
     *   // Minimum column width in pixels.
     *   minWidth?: number
     *   // Maximum column width in pixels.
     *   maxWidth?: number
     *   // Fixes the column in place, only applies to first column.
     *   fixed?: boolean
     *   sortable?: boolean
     *   // Wraps any overflowing content in the column instead of truncating it.
     *   // Combine with `minWidth` option to prevent column from being made
     *   // infinitely narrow.
     *   wrap?: boolean
     * }
     * ```
     */
    columns: {
      type: Array,
      required: true
    },
    /**
     * An array of row data objects to display.
     *
     *   - Each row should have a unique `id` property.
     *   - Data will be displayed in the order provided.
     *   - If client-side pagination is used, the data table will truncate rows as neccessary.
     *   - If server-side pagination is used, you are responsible for pagination.
     */
    rows: {
      type: Array,
      required: true
    },
    /**
     * An array of actions to display at the end of each row.
     *
     * A function can be provided that will be called once for each row to
     * customize the action on a per-row basis.
     *
     * When necessary the action colum will collapse into an action list
     * and stay fixed to the right edge to improve accessibility.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url: string
     *   external: boolean
     *   onAction(): void
     * }
     * ```
     */
    rowActions: {
      type: [Array, Function]
    },
    /**
     * An array of actions to display when the user selects on or more rows.
     * The `onAction` callback of actions will be invoked with an array of the
     * selected row data objects.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url: string
     *   external: boolean
     *   onAction(): void
     * }
     * ```
     */
    bulkActions: {
      type: Array
    },
    /**
     * An array containing all the row IDs that are selected.
     * The `update:selectedIds` event is emitted whenever the user changes the
     * selection.
     */
    selectedIds: {
      type: Array,
      default: null
    },
    /**
     * Splits the data in the table into multiple pages if the number of rows
     * exceeds the page limit.
     *
     * ```ts
     * interface Pagination {
     *   offset: number
     *   // Callback method to invoke with the new offset value when the user
     *   // changes a page.
     *   updateOffset(number): void
     *   // The number of rows to show per page.
     *   limit: number
     *   // Callback method to invoke with the new limit value when the user
     *   // changes the page size.
     *   updateLimit(number): void
     *   // An array of page sizes (limits) the user can choose from.
     *   // See `Pagination` component for defaults and options.
     *   pageSizes: number[]
     *   // Defaults to rows.total.
     *   // If the total provided is greater than the current number of rows
     *   // then the table will render in "server-side" mode where it shows all
     *   // rows and leaves handing of the pagination up to you.
     *   total?: number
     *   // Localized footer summary, defaults to "Showing {range} of {total}"
     *   summary?: string
     * }
     * ```
     */
    pagination: {
      type: Object
    },
    /**
     * If `true` then all numeric columns will be automatically summed.
     *
     * If an array is provided then it will use these numbers for the totals.
     * Use empty strings as placeholders for columns with no total.
     *
     * For example the follow array would manually show totals of 50 and 100 for
     * the second and forth columns and format them based on the column format.
     *
     * ```js
     * ['', 50, '', 100]
     * ```
     *
     * **Note:** The first column can never have a total.
     */
    totals: {
      type: [Boolean, Array],
      default: false
    },
    /**
     * Controls loading states for parts of the table. Accepts an object or boolean.
     * Passing `true` is the same as using `{rows: true}`.
     *
     * ```ts
     * interface LoadingStates {
     *   // Shows skeleton text where headers totals should be.
     *   totals: boolean
     *   // Shows a spinner centered on the table and dims all content.
     *   rows: boolean
     *   // Shows skeleton text in the footer.
     *   footer: boolean
     * }
     * ```
     */
    loading: {
      type: [Boolean, Object],
      default: false
    },
    /**
     * Text to show when there are no rows to display.
     */
    emptyText: {
      type: String
    },
    /**
     * The current column to sort by and its direction.
     *
     * ```ts
     * interface Sort {
     *   column: string
     *   direction: string // asc | desc
     * }
     * ```
     */
    sort: {
      type: Object
    },
    /**
     * Renders rows in compact form.
     */
    compact: {
      type: Boolean
    },
    /**
     * The minimum width in pixels which the DataTable must be for row actions
     * to be displayed inline. When the DataTable is smaller than this width the
     * actions are collapsed into a dropdown menu.
     */
    actionCollapseThreshold: {
      type: Number,
      default: 900
    },
    /**
     * The maximum number of actions to display inline before collapsing into a
     * dropdown menu.
     * @default 3
     */
    maxPersistActions: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      hasScrollWidth: false,
      hasScrollLeft: false,
      hasScrollRight: false,
      width: null
    }
  },
  computed: {
    cols() {
      const {$style} = this
      let hasFixedCol = false
      const cols = this.columns.map((col, index) => {
        const colFormat = col.format
        if (typeof colFormat === 'string') {
          col.formatString = colFormat
        }
        col = {
          ...col,
          index,
          classes: [
            $style.Cell,
            isNumericType(colFormat) && $style.Cell_numeric,
            col.wrap && $style.Cell_wrap
          ]
        }
        if (typeof colFormat !== 'function') {
          col.format = function(value, row, forTotal) {
            const context = col.formatContext
              ? col.formatContext(row, forTotal)
              : null
            return format(value, colFormat, context)
          }
        }
        if (col.fixed && index === 0) {
          hasFixedCol = true
          col.classes.push($style.Cell_fixed, $style.Cell_fixedLast)
        }
        return col
      })
      if (this.bulkActions || Array.isArray(this.selectedIds)) {
        const bulkCol = {
          type: 'bulkActions',
          classes: [$style.Cell, $style.Cell_bulkActions]
        }
        if (!hasFixedCol) {
          bulkCol.classes.push($style.Cell_fixedLast)
        }
        cols.unshift(bulkCol)
      }
      if (this.rowActions) {
        cols.push({
          type: 'actions',
          classes: [$style.Cell, $style.Cell_actions]
        })
      }
      return cols
    },
    totalValues() {
      const {columns, rows, loadingStates} = this
      let totals = this.totals
      if (loadingStates.totals) {
        totals = columns.map(() => 'loading')
      } else if (totals === true) {
        totals = []
        for (const col of columns) {
          if (isSummableNumericType(col.format)) {
            let sum = 0
            for (const row of rows) {
              sum += row[col.key]
            }
            totals.push(sum)
          } else {
            totals.push('')
          }
        }
      }
      return totals
    },
    hasServerPagination() {
      return (
        this.pagination &&
        this.pagination.total > 0 &&
        this.pagination.total !== this.rows.length
      )
    },
    filteredRows() {
      const {pagination} = this
      if (pagination && !this.hasServerPagination) {
        return this.rows.slice(
          pagination.offset,
          pagination.offset + pagination.limit
        )
      }
      return this.rows
    },
    wrappedBulkActions() {
      if (this.bulkActions) {
        return this.bulkActions.map((action) => {
          return {
            ...action,
            onAction: () => {
              if (action.onAction) {
                const selection = this.selectedIds
                const rows = this.rows.filter((d) => selection.includes(d.id))
                action.onAction(rows)
              }
            }
          }
        })
      }
      return null
    },
    maxPages() {
      if (this.width < this.actionCollapseThreshold) {
        return 0
      }
      return 5
    },
    loadingStates() {
      const states = this.loading
      return {
        totals: states && states.totals,
        rows: states === true || (states && states.rows),
        footer: states && states.footer
      }
    }
  },
  watch: {
    // Recalculate scroll hints whenever rows are rendered.
    filteredRows: {
      handler() {
        this.$nextTick(this.updateScroll)
      }
    }
  },
  created() {
    this.updateScroll = debounce(this.updateScrollImmediate, 100, {
      leading: true,
      trailing: true
    })
    window.addEventListener('resize', this.updateScroll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScroll)
  },
  mounted() {
    this.updateScroll()
  },
  render(h) {
    const {pagination, totalValues, sort, $style} = this
    const selectedIds = this.selectedIds || []
    const hasSelection = selectedIds.length > 0
    const headerColsMarkup = this.cols.map((col) => {
      let content = null
      if (col.type === 'bulkActions') {
        content = h(BulkActions, {
          props: {
            actions: this.wrappedBulkActions,
            totalItems: this.filteredRows.length,
            selectedItems: selectedIds.length
          },
          on: {
            change: (selectAll) => {
              const selection = selectAll
                ? this.filteredRows.map((d) => d.id)
                : []
              this.updateSelection(selection)
            }
          }
        })
      } else {
        const scopedSlot =
          col.key &&
          (this.$scopedSlots['head:' + col.key] ||
            this.$scopedSlots['head:' + col.key.toLowerCase()])
        if (scopedSlot) {
          content = scopedSlot({
            col,
            value: col.label,
            className: $style.HeaderContent
          })
        } else {
          content = h(
            'span',
            {
              class: $style.HeaderContent
            },
            col.label
          )
        }
      }
      const isSorted = sort && sort.column === col.key
      const sortMarkup =
        col.sortable &&
        h(Icon, {
          props: {
            icon:
              'fa fa-caret-' +
              (sort && sort.direction === 'asc' ? 'up' : 'down')
          },
          class: $style.SortIcon
        })
      return h(
        'th',
        {
          class: [
            ...col.classes,
            $style.HeaderCell,
            col.sortable && $style.Cell_sortable,
            isSorted && $style.Cell_isSorted
          ],
          on: {
            click: () => {
              if (!hasSelection && col.sortable) {
                const newSort = {
                  column: col.key,
                  direction: sort ? sort.direction : 'desc'
                }
                if (sort && sort.column === col.key) {
                  newSort.direction =
                    sort.direction === 'desc' ? 'asc' : 'desc'
                }
                /**
                 * Emitted with the new sort object when the user changes the
                 * sort column or direction.
                 *
                 * @type {Sort}
                 */
                this.$emit('update:sort', newSort)
              }
            }
          }
        },
        [sortMarkup, content]
      )
    })
    const totalsColsMarkup =
      totalValues &&
      this.cols.map((col, index) => {
        let content = totalValues[col.index]
        if (index === 0) {
          content = 'Totals'
        } else if (content === 'loading') {
          content = h(SkeletonDisplayText, {
            props: {
              size: 'tiny'
            },
            style: {
              display: 'inline-block',
              minWidth: '100px'
            }
          })
        } else if (content && col.format) {
          content = col.format(content, this.filteredRows[0], true)
        }
        return h(
          'th',
          {
            class: [...col.classes, $style.Cell_total]
          },
          [content]
        )
      })
    const headerMarkup = h('thead', [
      h('tr', headerColsMarkup),
      totalsColsMarkup && h('tr', totalsColsMarkup)
    ])

    const rowsMarkup = this.filteredRows.map((row) => this.renderRow(h, row))
    const emptyText =
      this.emptyText !== null
        ? this.emptyText || this.$t('ui.dataTable.noData')
        : null
    const emptyMarkup =
      !rowsMarkup.length &&
      emptyText &&
      h('tr', [
        h(
          'td',
          {
            class: [$style.EmptyText],
            attrs: {
              colspan: this.cols.length
            }
          },
          emptyText
        )
      ])

    // const bodyMarkup = h('tbody', [rowsMarkup || emptyMarkup])
    const bodyMarkup = h(
      'tbody',
      rowsMarkup.length ? rowsMarkup : [emptyMarkup]
    )

    const tableMarkup = h(
      'table',
      {
        class: this.$style.Table
      },
      [headerMarkup, bodyMarkup]
    )

    const footerMarkup =
      pagination &&
      h(DataTableFooter, {
        props: {
          offset: pagination.offset,
          limit: pagination.limit,
          total: pagination.total || this.rows.length,
          summary: pagination.summary,
          maxPages: this.maxPages,
          pageSizes: pagination.pageSizes,
          loading: this.loadingStates.footer
        },
        on: {
          'update-offset': (val) => this.updateOffset(val),
          'update:limit': (val) => this.updateLimit(val)
        }
      })

    const scrollContainerInnerMarkup = h(
      'div',
      {
        ref: 'scroll',
        class: $style.ScrollContainerInner,
        on: {
          scroll: this.updateScroll
        }
      },
      [tableMarkup]
    )

    const hasLeftFixed = (
      this.bulkActions ||
      Array.isArray(this.selectedIds) ||
      this.cols.some(c => c.fixed)
    )
    const hasRightFixed = !!this.rowActions
    const scrollContainerOuterMarkup = h(
      'div',
      {
        class: [
          $style.ScrollContainerOuter,
          !hasLeftFixed && $style.NoFixedLeft,
          !hasRightFixed && $style.NoFixedRight
        ]
      },
      [scrollContainerInnerMarkup]
    )

    const loaderMarkup =
      this.loadingStates.rows &&
      h(
        'div',
        {
          class: $style.Loader
        },
        [
          h(Spinner, {
            props: {
              color: 'blue'
            }
          })
        ]
      )

    return h(
      'div',
      {
        class: [
          'UIElement',
          $style.DataTable,
          this.hasScrollWidth && $style.hasScrollWidth,
          this.hasScrollLeft && $style.hasScrollLeft,
          this.hasScrollRight && $style.hasScrollRight,
          this.bulkActions && $style.hasBulkActions,
          this.selectedIds && this.selectedIds.length && $style.hasSelection,
          this.$listeners['click-row'] && $style.hasClickableRows,
          this.compact && $style.isCompact
        ]
      },
      [loaderMarkup, scrollContainerOuterMarkup, footerMarkup]
    )
  },
  methods: {
    renderRow(h, row) {
      const {$style, selectedIds} = this
      const isSelected = selectedIds ? selectedIds.includes(row.id) : false
      const hasSelection = selectedIds && selectedIds.length > 0
      const cells = this.cols.map((col) => {
        let content = row[col.key]
        if (col.format) {
          content = col.format(content, row)
        }
        const scopedSlot =
          col.key &&
          (this.$scopedSlots['col:' + col.key] ||
            this.$scopedSlots['col:' + col.key.toLowerCase()])
        if (scopedSlot) {
          content = scopedSlot({
            row,
            value: content
          })
        }
        if (col.type === 'bulkActions') {
          content = h(CheckboxControl, {
            class: $style.RowCheckbox,
            props: {
              checked: isSelected
            },
            on: {
              // Prevent the click on the checkbox from toggling it a second
              // time as it will occur after the change event.
              click: (event) => event.stopPropagation(),
              // Toggle the row selection on change (could be from a click or
              // using the keyboard).
              change: () => this.toggleRowSelection(row.id)
            }
          })
        } else if (col.type === 'actions') {
          const actions =
            typeof this.rowActions === 'function'
              ? this.rowActions(row)
              : this.rowActions
          if (
            actions.length > this.maxPersistActions ||
            this.width < this.actionCollapseThreshold
          ) {
            content = h(
              ActionList,
              {
                props: {
                  items: actions.map((action) => {
                    return {
                      ...action,
                      onAction() {
                        if (action.onAction) {
                          action.onAction(row)
                        }
                      }
                    }
                  }),
                  placement: 'bottom-end'
                }
              },
              [
                h(Button, {
                  class: $style.ActionButton,
                  props: {
                    size: 'small',
                    type: 'plain',
                    icon: 'fa fa-ellipsis-h',
                    disabled: hasSelection
                  }
                })
              ]
            )
          } else {
            content = h(
              'div',
              actions.map((action) => {
                return h(
                  Button,
                  {
                    class: $style.ActionButton,
                    props: {
                      type: 'plain',
                      disabled: hasSelection ? true : action.disabled,
                      url: action.url,
                      external: action.external,
                      tooltip: action.tooltip,
                      tooltipPosition: action.tooltipPosition || 'left'
                    },
                    on: {
                      click() {
                        if (action.onAction) {
                          action.onAction(row)
                        }
                      }
                    }
                  },
                  action.label
                )
              })
            )
          }
        }
        const minWidth = col.minWidth || col.width
        const maxWidth = col.maxWidth || col.width
        return h(
          'td',
          {
            class: [$style.RowCell, ...col.classes],
            style: {
              minWidth: minWidth && minWidth + 'px',
              maxWidth: maxWidth && maxWidth + 'px'
            },
            attrs: {
              title: maxWidth && typeof content === 'string' ? content : null
            },
            on: {
              // Prevent any clicks on the action column from triggering the
              // click-row event (unless in the bulk selection mode).
              click(event) {
                if (col.type === 'actions' && !hasSelection) {
                  event.stopPropagation()
                }
              }
            }
          },
          [content]
        )
      })
      return h(
        'tr',
        {
          class: [$style.Row, isSelected && $style.Row_isSelected],
          on: {
            click: () => {
              if (hasSelection) {
                this.toggleRowSelection(row.id)
              } else {
                /**
                 * Emitted when a row is clicked. When you define a listener for this
                 * event, rows will be styled to show the pointer cursor on hover.
                 *
                 * @event click-row
                 * @type {RowData}
                 */
                this.$emit('click-row', row)
              }
            }
          }
        },
        cells
      )
    },
    updateScrollImmediate() {
      const el = this.$refs.scroll
      if (el) {
        this.hasScrollWidth = el.clientWidth !== el.scrollWidth
        this.hasScrollLeft = el.scrollLeft > 0
        this.hasScrollRight = el.scrollWidth - el.scrollLeft > el.clientWidth
        this.width = this.$el.clientWidth
      }
    },
    toggleRowSelection(rowId) {
      const {selectedIds} = this
      if (selectedIds) {
        const isSelected = selectedIds.includes(rowId)
        const selection = isSelected
          ? selectedIds.filter((id) => id !== rowId)
          : [...selectedIds, rowId]
        this.updateSelection(selection)
      }
    },
    updateSelection(selection) {
      /**
       * Emitted when the user toggles a row for bulk actions.
       * @type {Array<any>}
       */
      this.$emit('update:selectedIds', selection)
    },
    updateOffset(offset) {
      if (this.pagination.updateOffset) {
        this.pagination.updateOffset(offset)
      }
      // Clear selection when page changes
      if (this.selectedIds && this.selectedIds.length) {
        this.updateSelection([])
      }
    },
    updateLimit(limit) {
      if (this.pagination.updateLimit) {
        this.pagination.updateLimit(limit)
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';
@import '../../styles/mixins';

$rowHoverColor: #f5f6f8;
$bulkActionWidth: 45px;
$padding: 16px;

.DataTable {
  position: relative;
  max-width: 100vw;
  --lh: 14px * 1.4;
  color: #212b36;

  th {
    font-weight: 500;
    border-bottom: 1px solid #c4cdd5;
  }

  tbody tr:not(:last-child) td {
    border-bottom: 1px solid #dfe3e8;
  }

  tbody tr:hover td {
    background: $rowHoverColor;
  }

  [data-card-section] > & {
    margin: -16px;
  }
  [data-card-section] > [data-card-header] + & {
    margin-top: 0;
  }
}

.hasClickableRows tbody tr:hover {
  cursor: pointer;
}

.isCompact {
  .RowCell {
    padding: 8px 16px;
  }
}

.Table {
  // width: 100%;
  min-width: 100%;
  border-spacing: 0px;
  table-layout: fixed;
  background: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-collapse: separate;
}

@include compatMode {
  .Table {
    th,
    td {
      line-height: normal;
    }
  }
}

.Row:last-child td {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.Cell {
  padding: $padding;
  text-align: left;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  .Row_isSelected & {
    background: lighten($focusColor, 45%);
  }
  .Row_isSelected:hover & {
    background: lighten($focusColor, 43%);
  }
}

.Cell_sortable {
  cursor: pointer;

  &:hover {
    color: $focusColor;
  }
}
.SortIcon {
  opacity: 0.3;
  margin-right: 5px;

  .Cell_isSorted &,
  .Cell_sortable:hover & {
    opacity: 1;
  }
}

.Cell_wrap {
  overflow: visible;
  white-space: normal;
  word-break: break-all;
}

.CellWrapContent {
  --max-lines: 3;
  position: relative;
  max-height: calc(var(--lh) * var(--max-lines));
  padding-right: 14px;
  // text-overflow: ellipsis;
  overflow: hidden;
  min-width: 150px;
  max-width: unset;

  &::before {
    content: '...';
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0; /* note: not using bottom */
    width: 1rem;
    height: 1rem;
    background: white;

    tr:hover & {
      background: $rowHoverColor;
    }
  }
}

.Cell_fixed {
  position: sticky;
  top: auto;
  left: 0;
  z-index: 1;
  // width: 145px;
  white-space: unset;
  background: #fff;
  overflow: visible;

  .hasBulkActions & {
    left: $bulkActionWidth;
  }
}

.Cell_bulkActions {
  composes: Cell_fixed;
  left: 0 !important;
  width: $bulkActionWidth;
  min-width: $bulkActionWidth;
  max-width: $bulkActionWidth;
}
th.Cell_bulkActions {
  z-index: 2;
  vertical-align: top;
}

.hasScrollLeft {
  .ScrollContainerOuter {
    .Cell_fixedLast {
      border-right: 2px solid #dfe3e8;
      box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.05);
    }
    &.NoFixedLeft {
      &::before {
        content: ' ';
        display: block;
        width: 5px;
        height: 100%;
        position: absolute;
        box-shadow: inset 2px 0px 2px 1px rgba(0, 0, 0, 0.05);
        top:0;
        left:0;
      }
    }
  }
}

.Cell_numeric {
  text-align: right;
}

.Cell_total {
  background: #f9fafb !important;
}

.Cell_actions {
  position: sticky;
  top: auto;
  right: 0;
  min-width: 40px;
  background: #fff;
  // vertical-align: middle;
  overflow: visible;
  text-align: right;
  cursor: default;
  // For tables with few columns, Cell_actions takes up more width than it needs.
  // When combined with the @click-row listener it prevents a usable area of the
  // row from being clicked. This forces Cell_actions to take as little width
  // as possible.
  width: 1%;
}
.hasScrollRight {
  .ScrollContainerOuter {
    .Cell_actions {
      border-left: 2px solid #dfe3e8;
      box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.05);
    }
    &.NoFixedRight {
      &::after {
        content: ' ';
        display: block;
        width: 5px;
        height: 100%;
        position: absolute;
        box-shadow: inset -2px 0px 2px 1px rgba(0, 0, 0, 0.05);
        top:0;
        right:0;
      }
    }
  }
}

.ActionButton {
  & + & {
    margin-left: 8px;
  }
}

.ScrollContainerInner {
  overflow-x: auto;
  overscroll-behavior: contain auto;
}
.ScrollContainerOuter {
  position: relative;
}

.RowCheckbox {
  position: relative;
  top: 2px;
}

.hasSelection {
  .HeaderContent {
    visibility: hidden;
  }
  .SortIcon {
    display: none;
  }
  th.Cell {
    cursor: default;
  }
  td.Cell {
    cursor: pointer;

    // Disable clicking any links or buttons that may be rendered as custom
    // column slots while in bulk selection mode.
    * {
      pointer-events: none;
    }
  }
}

.Loader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 3px;
}

.EmptyText {
  padding: $padding;
  border-radius: 0 0 3px 3px;
  color: #637381;
  font-style: italic;
}
</style>
