<script>
import {mergeDataClassStyle} from '../../utils'
import {Checkbox} from '../Checkbox'
import {Labelled} from '../Labelled'
import {RadioButton} from '../RadioButton'

export default {
  name: 'ChoiceList',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String
    },
    error: {
      type: String
    },
    helpText: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    /**
     * ```ts
     * interface Option {
     *   label: string
     *   value: any
     *   helpText?: string
     *   disabled?: boolean
     *   content: string | VNode
     * }
     * ```
     */
    options: {
      type: Array
    },
    /**
     * If `true`, renders using a list of checkboxes and expects `value` to
     * be an array.
     */
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     * Disable all the options.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Makes all options readonly.
     */
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * An array if using `multiple`, otherwise any value.
     */
    value: {
      type: null
    }
  },
  functional: true,
  render(h, {data, props}) {
    const {disabled, readonly, value} = props
    const choicesMarkup = props.options.map(choice => {
      if (props.multiple) {
        const isChecked = value && value.indexOf(choice.value) >= 0
        return h(Checkbox, {
          props: {
            label: choice.label,
            helpText: choice.helpText,
            helpLink: choice.helpLink,
            disabled: choice.disabled || disabled,
            readonly: choice.readonly || readonly,
            checked: isChecked
          },
          on: {
            change(val) {
              if (data.on && data.on.change) {
                const newValue = value.slice(0)
                const index = newValue.indexOf(choice.value)
                if (val) {
                  if (index === -1) {
                    newValue.push(choice.value)
                  }
                } else if (index >= 0) {
                  newValue.splice(index, 1)
                }
                data.on.change(newValue)
              }
            }
          }
        }, [choice.content])
      }
      return h(RadioButton, {
        props: {
          label: choice.label,
          helpText: choice.helpText,
          helpLink: choice.helpLink,
          disabled: choice.disabled,
          readonly: choice.readonly,
          trueValue: choice.value,
          value: props.value
        },
        on: {
          change(val) {
            if (data.on && data.on.change) {
              data.on.change(val)
            }
          }
        }
      }, [choice.content])
    })
    return h(Labelled, mergeDataClassStyle(data, {
      props: {
        label: props.label,
        error: props.error,
        helpText: props.helpText
      }
    }), choicesMarkup)
  }
}
</script>
