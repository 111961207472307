<script>
import {mergeData} from 'vue-functional-data-merge'

export default {
  name: 'TextContainer',
  functional: true,
  render(h, {children, data, $style}) {
    return h('div', mergeData(data, {
      class: [
        'UIElement',
        $style.TextContainer
      ]
    }), children)
  }
}
</script>

<style lang="scss" module>
.TextContainer {
  > :not(:first-child) {
    margin-top: 16px;
  }
}
</style>
