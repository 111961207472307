<script>
/* eslint no-unused-vars: off */
import {mergeData} from 'vue-functional-data-merge'
import {Icon} from '../Icon'
import {isEqualUnordered, isVNode} from '../../utils'
import {format} from '../../utils/format'
import {emptyValuePlaceholder} from '../../utils/lang'

const defaultValueSlot = ({value}) => value
const identifyFn = (val) => val

function renderItem(h, $style, column, rawValue, render, state) {
  let getItem = column.accessor
  let value = rawValue
  if (!getItem && column.options) {
    getItem = (value) => column.options.find(d => d.value === value)
  }
  if (!getItem) {
    getItem = identifyFn
  }
  if (value === undefined || value === null) {
    return emptyValuePlaceholder
  }
  if (column.format && column.type !== 'list') {
    value = format(value, column.format)
  }
  value = render({
    state,
    rawValue,
    value: String(value),
    item: getItem(value)
  })
  if (column.type === 'list' && column.multiple) {
    return h('div', {
      class: [
        $style.Item,
        $style['Item_' + state]
      ]
    }, [
      value
    ])
  } else if (column.noWrap && !isVNode(value)) {
    // We need to enclose the cell value in something if we want to
    // prevent wrapping of long values. This is because `.CellValue`
    // uses `display: flex` and flex containers cannot use `text-overflow`.
    value = h('span', [value])
  }
  return value
}

function diffArray(left, right) {
  return {
    bothEmpty: (!left || !left.length) && (!right || !right.length),
    unchanged: left && right ? left.filter(d => right.includes(d)) : (left || []),
    removed: left && right ? left.filter(d => !right.includes(d)) : [],
    added: left && right ? right.filter(d => !left.includes(d)) : (right || [])
  }
}

export default {
  props: {
    column: {
      type: null
    },
    source: {
      type: null
    },
    value: {
      type: null
    },
    selection: {
      type: Object
    },
    highlight: {
      type: Boolean
    },
    rowState: {
      type: null
    }
  },
  functional: true,
  render(h, {data, props, $style, listeners}) {
    const {column, source, value, selection} = props
    const hasValue = value !== undefined
    const hasChanged = hasValue && !isEqualUnordered(value, source)
    const isSelected = selection && selection.isSelected
    const isPrimarySelection = selection && selection.isPrimary
    const isMultiSelection = selection && selection.hasMultiple
    const isMultiValue = column.type === 'list' && column.multiple
    const renderFn = column.valueSlot || defaultValueSlot

    const actionsMarkup = isSelected && h('div', {
      class: $style.Actions,
      on: {
        mousedown(event) {
          event.stopPropagation()
        }
      }
    }, [
      h(Icon, {
        props: {
          icon: 'fa fa-pencil'
        },
        class: $style.Action,
        on: {
          click() {
            listeners.edit()
          }
        }
      }),
      hasChanged && h(Icon, {
        props: {
          icon: 'fa fa-history'
        },
        class: $style.Action,
        on: {
          click() {
            listeners.revert()
          }
        }
      })
    ])

    let children = []
    if (isMultiValue) {
      const diff = diffArray(source, value)
      if (diff.bothEmpty) {
        children = [
          h('div', {
            class: $style.Item
          }, [emptyValuePlaceholder]),
          actionsMarkup
        ]
      } else {
        children = [
          ...diff.unchanged.map(val => renderItem(h, $style, column, val, renderFn, 'unchanged')),
          ...diff.removed.map(val => renderItem(h, $style, column, val, renderFn, 'removed')),
          ...diff.added.map(val => renderItem(h, $style, column, val, renderFn, 'added')),
          actionsMarkup
        ]
      }
    } else {
      children = [
        h('div', {
          class: [
            $style.CellValue,
            hasChanged && $style.CellValue_sourceChanged
          ]
        }, [
          renderItem(h, $style, column, source, renderFn, hasChanged ? 'removed' : null)
        ]),
        hasChanged && h('div', {
          class: [
            $style.CellValue,
            $style.CellValue_changed
          ]
        }, [
          renderItem(h, $style, column, value, renderFn, 'added')
        ]),
        actionsMarkup
      ]
    }

    return h('div', mergeData(data, {
      class: [
        $style.Cell,
        isSelected && $style.selected,
        isPrimarySelection && $style.primarySelection,
        isMultiSelection && $style.multiSelection,
        hasChanged && $style.hasChanged,
        column.type === 'number' && $style.isNumeric,
        column.noWrap && $style.Cell_noWrap,
        props.rowState === 'delete' && $style.isDeleted,
        props.rowState === 'create' && $style.isCreated,
        props.highlight && $style.isHighlighted
      ]
    }), children)
  }
}
</script>

<style lang="scss" module>
$borderColor: #e2e3e3;
$changedColor: #edf6ff;
$selectedBorderColor: #09f;
$highlightBorderColor: purple;

.Cell {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 40px;
  background: #fff;
  border: 1px solid $borderColor;
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 1px;
  overflow: hidden;
  cursor: default;

  [data-row-id]:hover & {
    background: #fafafa;
  }
  &.selected {
    z-index: 1;
    border-color: $selectedBorderColor;
  }
  &.isHighlighted {
    z-index: 1;
    padding: 0;
    border: 2px solid $highlightBorderColor;
  }
  &.multiSelection {
    background: #ebf1fd !important;
  }
  [data-row-id]:hover &.multiSelection {
    background: darken(#ebf1fd, 2%) !important;
  }
  &.primarySelection {
    padding: 0;
    border-width: 2px;
  }
  &.isDeleted {
    background: #fef0ef  !important;
  }
  &.isCreated {
    background: #e9f3e9  !important;
  }
}

.isNumeric {
  text-align: right;
}

.CellValue {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;

  .Cell_noWrap & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.CellValue_sourceChanged {
  text-decoration: line-through;
  color: red;
}
.CellValue_changed {
  // background: $changedColor;
  color: green;
  border-top: 1px solid $borderColor;
}

.Item {
  margin: 5px;
  & + & {
    margin-top: 0;
  }
}
.Item_added {
  color: green;
}
.Item_removed {
  color: #f01800;
  text-decoration: line-through;
}

.Actions {
  position: absolute;
  right: 12px;
  z-index: 2;
  display: none;
  .Cell:hover & {
    display: block;
  }
}

.Action {
  display: inline-block;
  padding: 8px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
  color: #666;
  & + & {
    margin-left: 5px;
  }
  &:hover {
    color: #000;
    cursor: pointer;
  }
}
</style>