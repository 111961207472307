<template>
  <div
    :class="[
      'UIElement',
      $style.Page,
      size && $style['Page__' + size]
    ]"
  >
    <div v-if="loading" :class="$style.Page_loader">
      <Spinner color="blue" size="large" />
    </div>
    <div v-if="breadcrumbs.length" :class="$style.Page_breadcrumbs">
      <span
        v-for="(item, index) in breadcrumbs"
        :key="index"
        :class="$style.Breadcrumb"
      >
        <Button
          :class="$style.Breadcrumb_button"
          :url="item.url"
          :disabled="item.disabled || (!item.url && !item.onAction)"
          type="plain"
          @click="item.onAction"
        >{{item.label}}</Button>
      </span>
    </div>
    <div :class="$style.Page_header">
      <div :class="$style.Page_headerMain">
        <div :class="$style.Page_titleWrapper">
          <div :class="$style.Page_title">
            {{title}}
            <Icon
              v-if="includeHelp"
              :class="$style.Page_helpIcon"
              icon="fas fa-info-circle"
              @click="$emit('clickHelp')"
            />
            <Badge
              v-if="badge"
              :class="$style.Page_badge"
              :progress="badge.progress"
              :size="badge.size"
              :status="badge.status"
            >{{badge.label}}</Badge>
          </div>
          <div v-if="subtitle" :class="$style.Page_subtitle">
            {{subtitle}}
          </div>
        </div>
        <div :class="$style.PageActions">
          <div
            v-for="(action, index) in secondaryActions"
            :key="'secondary' + index"
            :class="$style.PageAction"
          >
            <Button
              :class="$style.PageAction_button"
              :icon="action.icon"
              :disabled="action.disabled"
              type="plain"
              :url="action.url"
              :external="action.external"
              :tooltip="action.tooltip"
              :tooltipPosition="action.tooltipPosition"
              @click="action.onAction"
            >{{action.label}}</Button>
          </div>
          <div
            v-for="(group, index) in actionGroups"
            :key="'group' + index"
            :class="$style.PageAction"
          >
            <ActionList :items="group.actions">
              <Button
                :icon="group.icon"
                :class="$style.PageAction_button"
                type="plain"
                dropdown
              >{{group.title}}</Button>
            </ActionList>
          </div>
        </div>
        <!-- .PageActions -->
      </div>
      <div
        v-if="collapsedActionSections.length"
        :class="$style.PageActions_collapsed"
      >
        <ActionList placement="bottom-end" :sections="collapsedActionSections">
          <Button
            :class="$style.PageAction_button"
            type="plain"
            icon="fa fa-ellipsis-h"
          ></Button>
        </ActionList>
      </div>
      <div
        v-if="primaryAction || $slots['primaryAction']"
        :class="$style.Page_headerPrimaryActionWrapper"
      >
        <slot name="primaryAction">
          <Button
            v-if="primaryAction.label"
            :type="primaryAction.type || 'primary'"
            :icon="primaryAction.icon"
            :disabled="primaryAction.disabled"
            :url="primaryAction.url"
            :external="primaryAction.external"
            @click="primaryAction.onAction"
          >{{primaryAction.label}}</Button>
          <Button
            v-else
            :type="primaryAction.type || 'primary'"
            :icon="primaryAction.icon"
            :disabled="primaryAction.disabled"
            :url="primaryAction.url"
            :external="primaryAction.external"
            @click="primaryAction.onAction"
          />
        </slot>
      </div>
    </div>
    <div :class="$style.Page_content">
      <slot />
    </div>
    <SaveBarController
      :state="saveBarState"
      @save="$emit('save')"
      @discard="$emit('discard')"
    />
  </div>
</template>

<script>
import {Badge} from '../Badge'
import {Button} from '../Button'
import {SaveBarController} from '../SaveBarController'
import {Spinner} from '../Spinner'

const pageSizes = ['narrow', 'fullWidth']

export default {
  components: {
    Badge,
    Button,
    SaveBarController,
    Spinner
  },
  props: {
    /**
     * The main heading of the page.
     */
    title: {
      type: String
    },
    /**
     * The secondary heading of the page.
     */
    subtitle: {
      type: String
    },
    /**
     * Optional show help icon next to page title
     * Emits 'clickHelp' event
     */
    includeHelp: {
      type: Boolean
    },
    /**
     * Optional badge to display at the end of the title. See `Badge` component.
     *
     * ```ts
     * interface Badge {
     *   label: string
     *   progress?: string
     *   size?: string
     *   status?: string
     * }
     * ```
     */
    badge: {
      type: Object
    },
    /**
     * An array of breadcrumbs.
     *
     * ```ts
     * interface Breadcrumb {
     *   label: string
     *   url?: string
     *   onAction?(): void
     * }
     * ```
     */
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    /**
     * The main page action.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // Defaults to "primary". See `Button` for other types.
     *   type: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url?: string
     *   external: boolean
     *   onAction?(): void
     * }
     * ```
     */
    primaryAction: {
      type: Object
    },
    /**
     * Optional secondary actions. These actions and the action groups are
     * displayed below the page header and collapse into a dropdown on small
     * screens.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url?: string
     *   external: boolean
     *   onAction?(): void
     * }
     * ```
     */
    secondaryActions: {
      type: Array,
      default: () => []
    },
    /**
     * A group of secondary actions. These are listed after the main secondary
     * actions. These appear as sections when the secondary actions are collapsed.
     */
    actionGroups: {
      type: Array,
      default: () => []
    },
    /**
     * The width of the page. By default it will center content with modest space.
     *
     * Options:
     *
     *   - `fullWidth`
     */
    size: {
      type: String,
      default: null,
      validator(value) {
        return pageSizes.indexOf(value) >= 0
      }
    },
    /**
     * Shows a page-level loader.
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the Global Nav's Save Bar state to "changed".
     */
    changed: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the Global Nav's Save Bar state to "saving".
     */
    saving: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    collapsedActionSections() {
      const sections = []
      if (this.secondaryActions.length) {
        sections.push({
          title: '',
          items: this.secondaryActions
        })
      }
      for (const group of this.actionGroups) {
        sections.push({
          title: group.title,
          items: group.actions
        })
      }
      return sections
    },
    saveBarState() {
      if (this.saving && this.changed) return 'saving'
      if (this.changed) return 'changed'
      return 'unchanged'
    }
  },
  created() {
    this.$watch('size', size => {
      const currentMaxWidth = size === 'fullWidth'
        ? 'none'
        : 'var(--ui-page-maxWidth)'
      document.documentElement.style.setProperty(
        '--ui-page-currentMaxWidth',
        currentMaxWidth
      )
    }, {immediate: true})
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';
@import '../../styles/mixins';

.Page {
  max-width: var(--ui-page-maxWidth);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: var(--ui-page-yPadding) var(--ui-page-xPadding);
}

.Page__fullWidth {
  max-width: none;
}

.Page_loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding-top: 100px;
  background: rgba(255, 255, 255, 0.8);
}

@include inStyleguide {
  .Page_loader {
    position: absolute;
    // border: 2px solid red;
  }
}

.Page_header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.Page_headerMain {
  flex: 1;
}

.Page_titleWrapper {
  margin-bottom: 8px;
}
.Page_title {
  display: flex;
  align-items: center;
  font-size: 30px;
}
.Page_subtitle {
  color: #212b36;
  font-size: 14px;
}

.Page_helpIcon {
  margin-left: 5px;
  font-size: 14px;
  height: 30px;
  color: #86929d;
  &:hover {
    color: #333;
    cursor: pointer;
  }
}

.Page_badge {
  margin-left: 16px;
}

.PageActions {
  display: flex;
}
.PageAction + .PageAction {
  margin-left: 16px;
}

.PageAction_button {
  color: #454f5b;

  &:hover:not(:disabled) {
    color: #212b36;
  }
}

.Breadcrumb {
  & + &::before {
    content: '\00a0\00a0/\00a0\00a0';
    color: #888;
  }
}
.Breadcrumb_button {
  color: #454f5b;
  &:hover:not(:disabled) {
    color: #212b36;
    cursor: pointer;
  }
}

.PageActions_collapsed {
  display: none;
}

.Page_content {
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .Page_headerPrimaryActionWrapper {
    width: 100%;
  }

  .PageActions {
    display: none;
  }

  .PageActions_collapsed {
    display: block;
  }
}
</style>