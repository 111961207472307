<template>
  <div :class="$style.ScrollPane">
    <div
      :class="$style.indicatorTop"
      v-show="showTop"
    ></div>
    <div
      :class="[
        $style.container,
        containerClass
      ]"
      ref="container"
      @scroll.passive="update"
      :style="{
        padding: padding,
        overflowY: disabled ? 'hidden' : undefined,
        ...(containerStyle || {})
      }"
    >
      <!-- @slot The content to render in the scrollable container -->
      <slot></slot>
    </div>
    <div
      :class="$style.indicatorBottom"
      v-show="showBottom"
    ></div>
  </div>
</template>

<script>
import {debounce} from '../../utils'

// TODO: Use MutationObserver to detect changes to slot content
// and update accordingly.

/**
 * The ScrollPane component vertically scrolls the content within it as
 * necessary and adds visual indicators to the top and bottom of the container
 * to show where content overflows.
 */
export default {
  name: 'ScrollPane',
  props: {
    /**
     * Applies CSS padding to the scrollable container.
     */
    padding: {
      type: String
    },
    /**
     * Set `watch` to a reactive value to cause the overflow indicators to
     * refresh whenever the value changes.
     */
    watch: {
      type: null
    },
    /**
     * Disables scrolling by setting the CSS `overflow` of the content to
     * "hidden".
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Applies a class name to the scrollable container.
     */
    containerClass: {
      type: String
    },
    /**
     * Applies styles to the scrollable container.
     */
    containerStyle: {
      type: Object
    }
  },
  data() {
    return {
      showTop: false,
      showBottom: false
    }
  },
  created() {
    this.update = debounce(this.updateImmediate, 100, {leading: true})
    this.$watch('watch', () => {
      this.$nextTick(this.update)
    })
    window.addEventListener('resize', this.update)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.update)
  },
  mounted() {
    this.update({})
  },
  methods: {
    updateImmediate() {
      const el = this.$refs.container
      // Ensure `el` exists as scroll event may be triggered while component is
      // being destroyed.
      if (el) {
        const {clientHeight, scrollHeight, scrollTop} = el
        this.showTop = scrollTop > 0
        this.showBottom =
          scrollHeight > clientHeight
          && scrollTop + clientHeight < scrollHeight
      }
    },
    scrollTo(x, y) {
      this.$refs.container.scrollTo(x, y)
    }
  }
}
</script>

<style lang="scss" module>
.ScrollPane {
  position: relative;
  display: flex;
}
.container {
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  // will-change: scroll-position;
}
.indicatorTop,
.indicatorBottom {
  position: absolute;
  left: 0;
  width: 100%;
  height: 20px;
  z-index: 10;
  pointer-events: none;
}
.indicatorTop {
  top: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, var(--ScrollPane-indicatorAlpha, 0.1)) 100%
  );
}
.indicatorBottom {
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, var(--ScrollPane-indicatorAlpha, 0.1)) 100%
  );
}
</style>
