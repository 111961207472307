<script>
export default {
  props: {
    code: {
      type: String
    },
    language: {
      type: String
    }
  },
  functional: true,
  render(h, {props}) {
    const {code, language} = props
    if (!code) {
      return h('div', 'No code')
    }
    const Prism = window.Prism
    const grammar = Prism.languages[language]
    const className = `language-${language}`
    if (!grammar) {
      throw new Error(`Unsupported Prism grammar "${language}"`)
    }
    return h('pre', {
      class: ['Prism', className]
    }, [
      h('code', {
        class: className,
        domProps: {
          innerHTML: Prism.highlight(code, Prism.languages[language], language)
        }
      })
    ])
  }
}
</script>
