var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    _vm.$style.SaveBar,
    _vm.state === 'saving' && _vm.$style.saving,
    _vm.layoutMode === 'alternate' && _vm.$style.SaveBar__alternate
  ]},[_c('div',{class:_vm.$style.Content},[_c('div',{class:_vm.$style.Title},[_vm._v(" "+_vm._s(_vm.i18n.unsavedChanges)+" ")]),_c('div',{class:_vm.$style.Actions},[_c('button',{class:[
          _vm.$style.Action,
          _vm.$style.Action__discard
        ],attrs:{"disabled":_vm.state === 'saving'},on:{"click":_vm.discardChanges}},[_vm._v(_vm._s(_vm.i18n.discard))]),_c('button',{class:[
          _vm.$style.Action,
          _vm.$style.Action__save
        ],attrs:{"disabled":_vm.state === 'saving'},on:{"click":_vm.saveChanges}},[_c('span',{class:_vm.$style.Action_content},[_vm._v(_vm._s(_vm.i18n.save))]),(_vm.state === 'saving')?_c('Icon',{class:_vm.$style.Action_spinner,attrs:{"icon":"fa fa-spinner-third"}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }