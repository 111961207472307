<template>
  <Labelled
    :label="label"
    :error="error"
    :help-text="helpText"
    :help-text-html="helpTextHtml"
    :help-link="helpLink"
  >
    <PreventAutoComplete :enabled="autocomplete === 'off'">
      <div
        :class="[
          'UIElement',
          $style.TextField,
          error && $style.TextField__hasError,
          align && $style['TextField__align_' + align],
          multiline && $style.TextField__multiline,
          disabled && $style.isDisabled,
          (prefix || $slots.prefix) && $style.TextField__hasPrefix,
          (suffix || $slots.suffix) && $style.TextField__hasSuffix,
          $slots.left && $style.TextField__hasLeft,
          $slots.right && $style.TextField__hasRight
        ]"
      >
        <div
          v-if="$slots.left"
          :class="$style.TextField_left"
        >
          <!-- @slot Injects content outside the input to the left. -->
          <slot name="left"></slot>
        </div>
        <div :class="[$style.TextField_control]">
          <div v-if="prefix || $slots.prefix" :class="$style.TextField_prefix">
            <!-- @slot Injects content within the input to the left. -->
            <slot name="prefix">{{prefix}}</slot>
          </div>
          <!--
            Bound directly to the underlying input element.
            @event click
            @property {MouseEvent} event
          -->
          <!--
            Bound directly to the underlying input element.
            @event focus
            @property {FocusEvent} event
          -->
          <!--
            Bound directly to the underlying input element.
            @event blur
            @property {FocusEvent} event
          -->

          <component
            :is="multiline ? 'textarea' : 'input'"
            :class="$style.TextField_input"
            :type="multiline ? null : type"
            :value.prop="value"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            :min="min"
            :max="max"
            :step="step"
            :maxlength="maxLength"
            :style="{
              height: height ? height + 'px' : null,
              fontFamily: monospace ? 'monospace' : 'inherit'
            }"
            :autocomplete="autocomplete"
            ref="input"
            @input="onInput"
            @keydown="onKeydown"
            @click="$emit('click')"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
          />
          <div
            v-if="suffix || $slots.suffix"
            :class="$style.TextField_suffix"
          >
            <!-- @slot Injects content within the input to the right. -->
            <slot name="suffix">{{suffix}}</slot>
          </div>
          <div
            v-if="multiline"
            :class="[
              $style.TextField_input,
              $style.TextField_ghost
            ]"
            ref="ghost"
            aria-hidden="true"
          >{{value + '\n'}}</div>
        </div>
        <div
          v-if="$slots.right"
          :class="$style.TextField_right"
        >
          <!-- @slot Injects content outside the input to the right. -->
          <slot name="right"></slot>
        </div>
      </div>
    </PreventAutoComplete>
  </Labelled>
</template>

<script>
import {Icon} from '../Icon'
import {Labelled} from '../Labelled'
import {inputToNumber} from '../../utils'
import {PreventAutoComplete} from '../PreventAutoComplete'

const lineHeight = 24

const inputTypes = ['email', 'number', 'password', 'text']

export default {
  name: 'TextField',
  components: {
    Icon,
    Labelled,
    PreventAutoComplete
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    /**
     * Label to display above the input.
     */
    label: {
      type: String
    },
    /**
     * Error text to show below the control.
     */
    error: {
      type: String
    },
    /**
     * Help text to show below the control.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    /**
     * The control value.
     */
    value: {
      type: [String, Number]
    },
    /**
     * Hint to display in the input.
     */
    placeholder: {
      type: String
    },
    /**
     * Allow multiple lines of input.
     */
    multiline: {
      type: [Boolean, String, Number]
    },
    /**
     * Maximum character length for the input.
     */
    maxLength: {
      type: Number
    },
    /**
     * Text to display before the input value.
     */
    prefix: {
      type: String
    },
    /**
     * Text to display after the input value.
     */
    suffix: {
      type: String
    },
    /**
     * Determines the alignment of the text in the input: `"left" | "center" | "right"`.
     */
    align: {
      type: String
    },
    /**
     * By default the text field will display any text. Can use:
     *
     * - email
     * - number
     * - password
     * - text
     */
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return inputTypes.indexOf(value) >= 0
      }
    },
    /**
     * For number inputs this is the minimum value allowed.
     */
    min: {
      type: Number
    },
    /**
     * For number inputs this is the maximum value allowed.
     */
    max: {
      type: Number
    },
    /**
     * For number inputs this amount incremented using the stepper.
     */
    step: {
      type: Number
    },
    /**
     * Disable the input.
     */
    disabled: {
      type: Boolean
    },
    /**
     * Set input `autocomplete` value
     */
    autocomplete: {
      type: String
    },
    /**
     * Disable editing of the input.
     */
    readonly: {
      type: Boolean
    },
    /**
     * Makes font monospace
     */
    monospace: {
      type: Boolean
    },
    /**
     * The model value to emit when the text input is cleared by the user.
     */
    emptyValue: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      height: 0
    }
  },
  created() {
    this.$watch('multiline', (multiline) => {
      this.height = multiline ? lineHeight + 1 : 0
    }, {immediate: true})

    this.$watch('value', () => {
      this.updateHeight()
    }, {immediate: true})
  },
  methods: {
    onInput(event) {
      let value = event.target.value
      if (this.type === 'number') {
        value = inputToNumber(value)
      }
      /**
       * Triggered when the value of the underlying input element changes.
       *
       * @event input
       * @property {string | number} value If `type` is "number" the value will
       * be coerced to a number.
       * @property {KeyboardEvent} event
       */
      if (value === '') {
        this.$emit('input', this.emptyValue, event)
      } else {
        this.$emit('input', value, event)
      }
      if (this.multiline) {
        this.updateHeight()
      }
    },
    onKeydown(event) {
      /**
       * Bound directly to the underlying input element.
       *
       * @event keydown
       * @property {KeyboardEvent} event
       */
      this.$emit('keydown', event)
    },
    updateHeight() {
      // `$nextTick` didn't work in all situations. I think this might occur when
      // the component is nested in more complex layouts which contain conditional
      // rendering. `setTimeout` seems to work consistently.
      setTimeout(() => {
        const el = this.$refs.ghost
        if (el) {
          if (this.multiline) {
            // Checks the multiline prop value, and always defaults to 2 rows unless specified
            const multilineVal = typeof this.multiline !== 'boolean'
              ? parseInt(this.multiline)
              : 2
            this.height = Math.max(
              lineHeight * multilineVal + 10,
              el.clientHeight
            )
          } else {
            this.height = Math.max(lineHeight + 1, el.clientHeight)
          }
        }
      }, 0)
    },
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

$shadowInner: inset 0 1px 0 #f6f6f6;
$shadowBorderFocus: 0 0 0 1px $focusColor;
$shadowBorderError: 0 0 0 1px $errorColor;
$fontSize: 14px;
$inputHeight: 34px;
$addonOuterMargin: 12px;
$addonInnerMargin: 8px;

.TextField {
  display: flex;
}

.TextField_control {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  min-height: $inputHeight;

  background: #fff;
  border-radius: 3px;
  border: 1px solid $inputBorderColor;
  box-shadow: $shadowInner;
  font-size: $fontSize;

  &:focus-within {
    z-index: 1;
    border-color: $focusColor;
    box-shadow: $shadowInner, $shadowBorderFocus;
  }

  .TextField__hasLeft & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .TextField__hasRight & {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .TextField__hasError & {
    background: $errorBgColor;
    border-color: $errorColor;

    .TextField_input {
      background: $errorBgColor;
    }

    &:focus-within {
      box-shadow: $shadowInner, $shadowBorderError;
    }
  }

  .isDisabled & {
    background: $inputDisabledBgColor;
  }
}

.TextField_input {
  display: block;
  width: 100%;
  min-height: $inputHeight;
  padding: 5px 12px;
  border: 0;
  box-shadow: $shadowInner;
  border-radius: 3px;
  font-size: $fontSize;

  .TextField__multiline & {
    max-height: 200px;
    overflow: auto;
    resize: none;
    line-height: 24px;
  }

  .TextField__align_right & {
    text-align: right;
  }

  .TextField__hasPrefix & {
    padding-left: 0;
  }

  .TextField__hasSuffix & {
    padding-right: 0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: $inputDisabledBgColor;
    cursor: $inputDisabledCursor;
  }
  &:read-only {
    background: $inputReadonlyBgColor;
    box-shadow: none;
    cursor: $inputReadonlyCursor;
  }

  @media (max-width: 640px) {
    // Use 16px as minimum font size for smaller mobile devices to prevent iOS
    // Safari from zooming the viewport.
    font-size: 16px;
  }
}

.TextField_ghost {
  position: absolute;
  z-index: -10;
  visibility: hidden;
  user-select: none;
  white-space: pre-line;
  word-break: break-word;
  outline: 1px solid red;
}

.TextField_prefix,
.TextField_suffix {
  color: #637381;
}
.TextField_prefix {
  margin-left: $addonOuterMargin;
  margin-right: $addonInnerMargin;
}
.TextField_suffix {
  margin-left: $addonInnerMargin;
  margin-right: $addonOuterMargin;
}

.TextField_left {
  margin-right: -1px;
  * {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.TextField_right {
  margin-left: -1px;
  * {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
</style>
