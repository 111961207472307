<script>
import {mergeData} from 'vue-functional-data-merge'
import {format} from '../../utils/format'
import {getContextInjections} from '../../utils/vm'
import {$t} from '../../utils/lang'

export default {
  name: 'Formatter',
  inject: {
    ...getContextInjections()
  },
  props: {
    /**
     * The formatter type to use to when rendering the value.
     */
    type: {
      type: String
    },
    /**
     * The value to pass to formatter as a property. Alternatively the value can be
     * passed as the default slot content.
     */
    value: {
      type: null
    },
    /**
     * The formatter locale (e.g. to be used with Intl.NumberFormat)
     */
    locale: {
      type: String
    },
    /**
     * The formatter currency (e.g. to be used with Intl.NumberFormat)
     */
    currency: {
      type: String
    },
    /**
     * The formatter timezone
     * @deprecated
     */
    timezone: {
      type: String
    },
    /**
     * The formatter input timezone
     * @deprecated
     */
    inputTimezone: {
      type: String
    }
  },
  functional: true,
  render(h, {data, injections, props, $style}) {
    const {type, value} = props
    const context = type && {
      type,
      locale: props.locale || injections.defaultLocale,
      currency: props.currency || injections.defaultCurrency,
      timezone: props.timezone || injections.defaultTimezone,
      inputTimezone: props.inputTimezone || injections.defaultInputTimezone
    }
    try {
      let title
      if (type && type.indexOf('date') >= 0) {
        title = format(value, 'dateIso', context)
      }
      const result = format(value, type, context)
      return h('span', mergeData(data, {
        class: [
          'UIElement',
          $style.Formatter
        ],
        attrs: {
          title
        }
      }), [result])
    } catch {
      return h('Tooltip', {
        attrs: {
          content: $t('ui.lang.invalidFormatting', {value})
        }
      }, [
        h('Icon', {
          class: [
            $style.WarningIcon
          ],
          attrs: {
            icon: 'fas fa-exclamation-circle'
          }
        })
      ])
    }
  }
}
</script>

<style lang="scss" module>
.Formatter {
  font-size: inherit;
}
.WarningIcon {
  color: #de3618;
}
</style>
