<script>
import {mergeData} from 'vue-functional-data-merge'

/**
 * The `StatGroup` component uses a CSS Grid to layout `Stat` child components.
 *
 * @requires ./Stat.vue
 */
export default {
  name: 'StatGroup',
  props: {
    /**
     * The minimum width of columns in the group.
     */
    minWidth: {
      type: String,
      default: '200px'
    },
    /**
     * The maximum width of columns in the group.
     */
    maxWidth: {
      type: String,
      default: '1fr'
    },
    /**
     * Adds top margin to the component. This property is not standardized so
     * avoid using it.
     * @deprecated
     */
    marginTop: {
      type: String,
      default: '0'
    }
  },
  functional: true,
  render(h, {children, data, props, $style}) {
    const {minWidth, maxWidth, marginTop} = props
    let style
    if (minWidth || maxWidth) {
      style = {
        gridTemplateColumns: `repeat(
          auto-fill,
          minmax(
            ${minWidth || '200px'},
            ${maxWidth || '1fr'}
          )
        )`,
        marginTop: `${marginTop}px`
      }
    }
    return h('div', mergeData(data, {
      class: ['UIElement', $style.Stats],
      style
    }), children)
  }
}
</script>

<style lang="scss" module>
.Stats {
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}
</style>
