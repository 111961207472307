<template>
  <div
    :class="[
      'UIElement',
      $style.ResourceList
    ]"
  >
    <div
      :class="$style.ResourceList_header"
      :style="gridStyle"
    >
      <div
        v-for="col in columns"
        :key="col.key"
        :class="[
          $style.ResourceList_headerCell,
          getColumnClass(col)
        ]"
      >{{col.label}}</div>
    </div>
    <template v-if="$scopedSlots.item">
      <slot
        name="item"
        v-for="item in items"
        :item="item"
      >
        Item {{item.id}}
      </slot>
    </template>
    <template v-else>
      <ResourceItem
        v-for="row in items"
        :key="row.id"
        :class="$style.ResourceList_row"
        :style="gridStyle"
      >
        <div
          v-for="col in columns"
          :key="col.key"
          :class="[
            $style.ResourceList_cell,
            getColumnClass(col)
          ]"
        >{{formatCell(col, row)}}</div>
      </ResourceItem>
    </template>
  </div>
</template>

<script>
import ResourceItem from './ResourceItem.vue'

export default {
  name: 'ResourceList',
  components: {
    ResourceItem
  },
  props: {
    columns: {
      type: Array
    },
    items: {
      type: Array
    }
  },
  computed: {
    gridStyle() {
      return {
        display: 'grid',
        gridTemplateColumns: `repeat(${this.columns.length}, 1fr)`
      }
    }
  },
  methods: {
    getColumnClass(col) {
      if (col.format === 'currency') {
        return this.$style.ResourceList_cell__numeric
      }
    },
    formatCell(col, row) {
      return row[col.key]
    }
  }
}
</script>

<style lang="scss" module>
$cellPadding: 16px;

.ResourceList {
  // border: 1px solid red;
}

.ResourceList_header {
  display: flex;
  border-bottom: 1px solid #c4cdd5;
}

.ResourceList_headerCell {
  padding: $cellPadding;
}

.ResourceList_row {
  padding: 0;
}

.ResourceList_cell {
  padding: 0 $cellPadding;
}

.ResourceList_cell__numeric {
  text-align: right;
}
</style>
