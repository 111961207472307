var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"title":_vm.opts.title,"primaryAction":{
    label: _vm.opts.primaryActionLabel || _vm.$t('ui.modal.confirm'),
    type: _vm.opts.primaryActionType || 'primary',
    onAction: _vm.confirm
  },"secondaryActions":[{
    label: _vm.opts.secondaryActionLabel || _vm.$t('ui.modal.cancel'),
    onAction: _vm.cancel
  }],"visible":_vm.visible},on:{"close":_vm.cancel,"afterLeave":_vm.handleLeave}},[_c('render-node',{attrs:{"node":_vm.opts.content}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }