<template>
  <div :class="$style.BulkEditor">
    <layout>
      <layout-section size="primary">
        <value-editor
          ref="editor"
          @update="val => setValue(val)"
        />
        <popup ref="errorPopup">{{currentRowError}}</popup>
        <card
          :class="$style.TableCard"
          :title="title"
          :actions="editorActions"
        >
          <div
            :class="$style.Table"
            :style="`--grid-template: ${gridTemplate}`"
          >
            <div :class="[
              $style.Row,
              $style.HeaderRow
            ]">
              <div
                :class="[
                  $style.Cell,
                  $style.idColumn,
                  allowSort && $style.Cell_sortable,
                  allowSort && sort.column === 'id' && $style.Cell_isSorted
                ]"
                @click="() => updateSort({ key: 'id', sortable: !!allowSort })"
              >
                <Icon
                  v-if="allowSort"
                  :class="$style.SortIcon"
                  :icon="`fa fa-caret-${sort.direction === 'asc' ? 'up' : 'down'}`"
                />
                {{$t('ID')}}
              </div>
              <div
                v-for="col in _columns"
                :key="col.key"
                :class="[
                  $style.Cell,
                  allowSort && col.sortable && $style.Cell_sortable,
                  allowSort && sort.column === col.key && $style.Cell_isSorted,
                  ...col.classes
                ]"
                @click="() => updateSort(col)"
              >
                <Icon
                  v-if="allowSort && col.sortable"
                  :class="$style.SortIcon"
                  :icon="`fa fa-caret-${sort.direction === 'asc' ? 'up' : 'down'}`"
                />
                {{col.label}}
              </div>
            </div><!-- .HeaderRow -->
            <div
              v-for="row in records"
              :key="row.id"
              :class="[
                $style.Row,
                ...getRowClasses(row)
              ]"
              :data-row-id="row.id"
            >
              <div
                :class="[
                  $style.Cell,
                  $style.idColumn
                ]"
                data-id-col
              >
                <ActionList :items="getRowActions(row)">
                  <div
                    :class="$style.IdColumn_text"
                  >
                    {{row.state === 'create' ? 'CREATED' : row.id}}
                    <Icon icon="fa fa-caret-down" />
                  </div>
                </ActionList>
                <icon
                  v-if="row.error"
                  :class="$style.RowErrorIcon"
                  icon="fa fa-exclamation-circle"
                  @mouseenter="showRowErrors(row)"
                  @mouseleave="hideRowErrors"
                />
              </div><!-- .idColumn -->
              <cell
                v-for="col in _columns"
                :key="col.key"
                :class="[
                  ...col.classes
                ]"
                :column="col"
                :source="row.source[col.key]"
                :value="row.updates[col.key]"
                :selection="getCellSelection(col, row)"
                :highlight="col.key === highlightedColumnKey && highlightedRowIds.includes(row.id)"
                :row-state="row.state"
                @edit="editCell(col, row)"
                @dblclick="editCell(col, row)"
                @revert="revertCell(col, row)"
                @mousedown="toggleCell($event, col, row)"
              />
            </div>
          </div>
        </card>
      </layout-section>
      <layout-section
        size="secondary"
      >
        <div style="position: sticky; top: 0;">
          <card
            :title="$t('ui.lang.summary')"
          >
            <card-section>
              <List v-if="changeSummary.length">
                <ListItem
                  v-for="(item, index) in changeSummary"
                  :key="index"
                >
                  <TextStyle
                    type="link"
                    @click="highlightSummary(item)"
                  >{{item.content}}</TextStyle>
                </ListItem>
              </List>
              <TextStyle
                v-else
                type="subdued"
              >{{$t('ui.bulkEditor.noPendingChanges')}}</TextStyle>
            </card-section>
            <card-section
              :title="$t('ui.lang.problems') + ' (' + errors.length + ')'"
            >
              <List
                type="error"
                in-card-section
                :items="errors"
                @select-item="onClickError"
              />
            </card-section>
          </card>
        </div>
      </layout-section>
    </layout>
  </div>
</template>

<script>
import {ActionList} from '../ActionList'
import {Card, CardSection} from '../Card'
import {Icon} from '../Icon'
import {Layout, LayoutSection} from '../Layout'
import {List, ListItem} from '../List'
import {TextStyle} from '../TextStyle'
import {
  capitalize,
  isEqualUnordered,
  scrollIntoViewIfNeeded,
  orderBy
} from '../../utils'
// Local
import Cell from './Cell'
import Popup from './Popup'
import ValueEditor from './ValueEditor'

const STATE_CREATE = 'create'
const STATE_UPDATE = 'update'
const STATE_DELETE = 'delete'

/**
 * Merges current and new values together based on an update strategy. Merging
 * only occurs for "list" column types. All other types simply replace the
 * current value with the new value.
 *
 * @param colType The column type
 * @param strategy The update stragegy, either "replace", "add" or "remove".
 * @param currentValue The current cell value.
 * @param newValue The incoming value to merge in the the current.
 * @returns The resulting merged value.
 */
function mergeValues({
  colType,
  strategy,
  colIsMultiple,
  currentValue,
  newValue
}) {
  if (colType === 'list' && Array.isArray(newValue) && colIsMultiple) {
    if (strategy === 'replace') {
      return newValue
    } else if (strategy === 'add') {
      const result = (currentValue || []).slice()
      for (const val of newValue) {
        if (!result.includes(val)) {
          result.push(val)
        }
      }
      return result
    } else if (strategy === 'remove') {
      const result = (currentValue || []).slice()
      for (const val of newValue) {
        const index = result.indexOf(val)
        if (index >= 0) {
          result.splice(index, 1)
        }
      }
      return result
    }
  }
  return newValue
}

/**
 * Bulk editors allow users the edit multiple objects or values at once using an
 * interface similar to a spreadsheet.
 */
export default {
  name: 'BulkEditor',
  components: {
    ActionList,
    Card,
    CardSection,
    Icon,
    Layout,
    LayoutSection,
    List,
    ListItem,
    TextStyle,
    // Local components
    Cell,
    Popup,
    ValueEditor
  },
  props: {
    /**
     * ```ts
     * interface Column {
     *   // The data type of column's values.
     *   type: 'text' | 'number' | 'boolean' | 'list' | 'datetime' | 'date' | 'month' | 'year' | 'timezone'
     *   // The property to access on each row.
     *   key: string
     *   // The column display name.
     *   label: string
     *   // Default value (or value generator) to use for created rows
     *   defaultValue?: any | () => any
     *   // Truncates long cell values and prevents them from wrapping.
     *   noWrap?: boolean
     *   // Manual width for column. Supports CSS Grid size/unit.
     *   // Defaults to `minmax(100px, 1fr)`.
     *   width?: string
     *   // For non 'list' types, an optional formatter name.
     *   format?: string
     *   // For 'list' column types, this is the array of options available
     *   // to the user.
     *   options?: ColumnOption[]
     *   // For 'list' column type, this allows multiple values to be seleted.
     *   multiple?: boolean.
     *   // For 'list' column type, this allows values to be clearable.
     *   clearable?: boolean.
     *   // For 'number'
     *   min?: number
     *   // For 'number'
     *   max?: number
     *   // For 'number'
     *   step?: number
     *   // For 'text' type, allows multiple lines.
     *   multiline?: boolean
     *   // Indicates that a value must be set in editing modal before updating
     *   // By default, all single "list" and "boolean" types are required. Multiple "list" columns
     *   // and others are non-required by default.
     *   required?: boolean
     * }
     *
     * interface ColumnOption {
     *   value: any
     *   label: string
     *   description?: string
     * }
     * ```
     */
    columns: {
      type: Array,
      required: true
    },
    /**
     * An array of objects which represents the initial rows being edited.
     * Each object must at least have a unique `id` property.
     */
    source: {
      type: Array,
      required: true
    },
    /**
     * A function to validate a row when it is updated.
     *
     * The function should return an error message (string) for the first error found.
     * If there are no errors the function should return a falsy value.
     */
    validateRow: {
      type: Function
    },
    /**
     * A function to validate all rows.
     */
    validateRows: {
      type: Function
    },
    /**
     * Whether to allow the user to delete source rows. When false, the user can only
     * delete rows that are yet to be created (rows that have been created or copied
     * in the editor).
     */
    allowDelete: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to show the 'Add record' editor action.
     */
    allowCreate: {
      type: Boolean,
      default: true
    },
    /**
     * Whether to show the 'Copy' row action.
     */
    allowCopy: {
      type: Boolean,
      default: true
    },
    /**
     * The name of the resource being edited. Should contain lowercase singular and plural forms if used.
     */
    resourceName: {
      type: Object,
      default() {
        return {
          singular: 'record',
          plural: 'records'
        }
      }
    },
    /**
     * ```ts
     * type SortingFn = (rows: Row[], column:string, direction:string) => Row[]
     * ```
     */
    sortingFunc: {
      type: Function,
      required: false
    },
    allowSort: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      records: [],
      selectedColumn: null,
      selectedRecord: null,
      selectedRowIds: [],
      highlightedColumnKey: null,
      highlightedRowIds: [],
      currentRowError: null,
      sort: {
        column: 'id',
        direction: 'asc'
      },
      nextRowId: -1
    }
  },
  computed: {
    gridTemplate() {
      const widths = this._columns.map(d => d.width).join(' ')
      return 'minmax(100px, 1fr) ' + widths
    },
    _columns() {
      return this.columns.map(column => {
        const col = {
          ...column,
          classes: [],
          width: column.width || 'minmax(100px, 1fr)'
        }
        if (col.type === 'number') {
          col.classes.push(this.$style.Cell_isNumeric)
        }
        if (!col.format) {
          if (col.type === 'boolean') {
            col.format = 'boolean'
          }
        }
        col.valueSlot = (
          this.$scopedSlots['col:' + col.key] ||
          this.$scopedSlots['col:' + col.key.toLowerCase()]
        )
        return col
      })
    },
    selectedColKey() {
      return this.selectedColumn && this.selectedColumn.key
    },
    errors() {
      return orderBy(this.records, ['id'], ['ASC'])
        .filter(row => row.error)
        .map(row => {
          const colKey = row.error.colKey
          const col = colKey && this._columns.find(d => d.key === colKey)
          return {
            label: col
              ? this.$t('ui.bulkEditor.rowIndexColumn', {
                index: row.index + 1,
                column: col.label
              })
              : this.$t('ui.bulkEditor.rowIndex', {index: row.index + 1}),
            content: row.error.message,
            idLabel: row.state !== STATE_CREATE
              ? this.$t('ui.bulkEditor.recordId', {id: row.id})
              : null,
            rowIndex: row.index,
            colKey: row.error.colKey
          }
        })
    },
    hasChanged() {
      return this.records.some(row => {
        return row.state !== null
      })
    },
    changeSummary() {
      const columns = this._columns
      const columnRowIdUpdates = columns.reduce((map, col) => {
        map[col.key] = []
        return map
      }, {})
      let createdRowIds = []
      let deletedRowIds = []
      for (const row of this.records) {
        if (row.state === STATE_CREATE) {
          createdRowIds.push(row.id)
        }
        else if (row.state === STATE_UPDATE) {
          for (const key of Object.keys(row.updates)) {
            columnRowIdUpdates[key].push(row.id)
          }
        } else if (row.state === STATE_DELETE) {
          deletedRowIds.push(row.id)
        }
      }
      const summary = []
      if (createdRowIds.length) {
        summary.push({
          content: this.$t('ui.bulkEditor.rowCreateCount', {count: createdRowIds.length}),
          rowIds: createdRowIds,
          colKey: columns[0].key
        })
      }
      if (deletedRowIds.length) {
        summary.push({
          content: this.$t('ui.bulkEditor.rowDeleteCount', {count: deletedRowIds.length}),
          rowIds: deletedRowIds,
          colKey: columns[0].key
        })
      }
      for (const col of columns) {
        const rowIds = columnRowIdUpdates[col.key]
        if (rowIds.length) {
          summary.push({
            content: this.$t('ui.bulkEditor.columnRowChangeCount', {
              column: col.label,
              count: rowIds.length
            }),
            rowIds,
            colKey: col.key
          })
        }
      }
      return summary
    },
    title() {
      return capitalize(this.resourceName.plural)
    },
    editorActions() {
      if (this.allowCreate) {
        return [
          {
            label: this.$t('ui.bulkEditor.addRecord', {resource: this.resourceName.singular}),
            onAction: this.createRow
          }
        ]
      }
      return []
    }
  },
  created() {
    this.$watch('source', source => {
      if (source) {
        this.initalizeRecords(source)
      }
    }, {immediate: true})

    this.$watch(() => {
      return {
        changes: this.getChanges(),
        errors: this.errors
      }
    }, state => {
      /**
       * Emitted as the user makes changes in the editor. Includes an array of
       * record changes and errors.
       */
      this.$emit('update:state', state)
    })
  },
  methods: {
    initalizeRecords(rows) {
      const records = rows.map((row, index) => {
        return {
          index,
          id: row.id,
          source: row,
          updates: {},
          error: null,
          state: null
        }
      })
      // this.initDemoChanges(records)
      this.records = records
      this.nextRowId = -1
      this.sortRecords()
      this.validateAllRows()
    },
    indexRows() {
      this.records.forEach((row, index) => row.index = index)
    },
    updateSort(col) {
      if (!this.allowSort) return
      const newSort = {
        column: col.key,
        direction: this.sort ? this.sort.direction : 'desc'
      }
      if (this.sort && this.sort.column === col.key) {
        newSort.direction =
          this.sort.direction === 'desc' ? 'asc' : 'desc'
      }
      this.sort = newSort

      this.sortRecords()
    },
    sortRecords() {
      if (!this.allowSort) return
      /**
       * Uses resulting IDs to sort internal records array.
       */
      const recordMap = this.records.reduce((acc, curr) => {
        acc[curr.id] = curr
        return acc
      }, {})
      const sortingFunc = this.sortingFunc || orderBy
      this.records = sortingFunc(
        this.records.map(r => ({
          ...r.source,
          ...r.updates,
          id: r.id
        })),
        this.sort.column,
        this.sort.direction
      ).map(r => recordMap[r.id])
      this.indexRows()
    },
    toggleCell(event, col, row) {
      const {selectedRecord} = this
      const hasRangeKey = event.shiftKey
      const hasToggleKey = event.ctrlKey || event.metaKey
      const isSameColumn = this.selectedColKey === col.key
      event.preventDefault()
      this.clearHighlight()
      if (selectedRecord && hasRangeKey) {
        // User is selecting a range of cells relative to the primary cell
        if (!isSameColumn) {
          this.showWarning()
          return
        }
        this.selectedRowIds = this.getRecordRangeFromRowIndex(row.index).map(d => d.id)
      } else if (selectedRecord && hasToggleKey) {
        // User is toggling individual cells
        if (!isSameColumn) {
          this.showWarning()
          return
        }
        const index = this.selectedRowIds.indexOf(row.id)
        if (index >= 0) {
          // Use is removing a cell from the current selection
          // Only allow deselecting the cell if it is not the only selection
          if (this.selectedRowIds.length > 1) {
            this.selectedRowIds.splice(index, 1)
            if (this.selectedRecord === row) {
              // We've deselected the primary cell to make another cell primary
              const other = this.records.find(d => d.id === this.selectedRowIds[0])
              this.selectedRecord = other
            }
          }
        } else {
          // User is adding a cell to the current selection
          this.selectedRowIds.push(row.id)
          this.selectedRecord = row
          this.selectedColumn = col
        }
      } else {
        // User is resetting the selection to a new cell
        this.selectedRowIds = [row.id]
        this.selectedRecord = row
        this.selectedColumn = col
      }
    },
    showWarning() {
      this.$ui.alert({
        title: this.$t('ui.bulkEditor.title'),
        content: this.$t('ui.bulkEditor.multipleColumnError')
      })
    },
    editCell(col, row) {
      this.$refs.editor.beginEdit({
        col,
        row,
        noValue: !this.getSelectedValuesAreEqual()
      })
    },
    getSelectedValuesAreEqual() {
      const selectedRecords = this.getSelectedRecords()
      if (selectedRecords.length <= 1) return true
      const key = this.selectedColKey
      const getValue = (row) => key in row.updates ? row.updates[key] : row.source[key]
      return selectedRecords.every(row => {
        return isEqualUnordered(getValue(row), getValue(selectedRecords[0]))
      })
    },
    revertCell(col) {
      this.getSelectedRecords().forEach(row => {
        this.removeCellUpdate(row, col.key)
      })
    },
    getCellSelection(col, row) {
      if (this.selectedColKey === col.key) {
        const isPrimary = this.selectedRecord.id === row.id
        const isSelected = isPrimary || this.selectedRowIds.includes(row.id)
        return {
          isPrimary,
          isSelected,
          hasMultiple: isSelected && this.selectedRowIds.length > 1
        }
      }
      return null
    },
    removeCellUpdate(row, colKey) {
      const updates = {
        ...row.updates
      }
      delete updates[colKey]
      row.updates = updates
      if (row.state === STATE_UPDATE && !Object.keys(updates).length) {
        row.state = null
      }
      this.validateAllRows()
    },
    setValue({value, strategy}) {
      const colKey = this.selectedColKey
      this.getSelectedRecords().forEach(row => {
        const currentValue = row.state === STATE_CREATE
          ? row.source[colKey]
          : (row.updates[colKey] || row.source[colKey])

        const newValue = mergeValues({
          strategy,
          colType: this.selectedColumn.type,
          colIsMultiple: this.selectedColumn.multiple,
          currentValue: currentValue,
          newValue: value
        })

        if (row.state === STATE_CREATE) {
          row.source[colKey] = newValue
        } else {
          const sourceValue = row.source[colKey]
          if (isEqualUnordered(newValue, sourceValue)) {
            this.removeCellUpdate(row, colKey)
          } else {
            const currentValue = row.updates[colKey]
            if (!isEqualUnordered(currentValue, newValue)) {
              row.updates = {
                ...row.updates,
                [colKey]: newValue
              }
              row.state = STATE_UPDATE
            }
          }
        }
        this.validateAllRows()
      })
    },
    validateSingleRow(row) {
      if (this.validateRow) {
        const rowData = {
          $state: row.state,
          ...row.source,
          ...row.updates
        }
        const error = this.validateRow(rowData)
        if (error) {
          row.error = typeof error === 'string'
            ? {message: error, colKey: null}
            : error
        } else {
          row.error = null
        }
      }
    },
    validateAllRows() {
      for (const row of this.records) {
        row.error = null
        this.validateSingleRow(row)
      }
      if (this.validateRows) {
        const newRecords = this.records.map(row => {
          return {
            $state: row.state || 'unchanged',
            ...row.source,
            ...row.updates
          }
        })
        const errors = this.validateRows(newRecords)
        if (Array.isArray(errors) && errors.length) {
          for (const error of errors) {
            const row = this.records.find(d => d.id === error.rowId)
            if (row) {
              if (!row.error) {
                row.error = {
                  message: error.message,
                  colKey: error.colKey
                }
              }
            } else {
              throw new Error(`BulkEditor: Row error refers to unknown row ID "${error.rowId}"`)
            }
          }
        }
      }
    },
    getRecordRangeFromRowIndex(rowIndex) {
      const selection = []
      if (this.selectedRecord) {
        const primaryIndex = this.selectedRecord.index
        const minIndex = Math.min(primaryIndex, rowIndex)
        const maxIndex = Math.max(primaryIndex, rowIndex)
        for (let i = minIndex; i <= maxIndex; i++) {
          const rec = this.records[i]
          selection.push(rec)
        }
      }
      return selection
    },
    getSelectedRecords() {
      return this.selectedRowIds.map(id => this.records.find(d => d.id === id))
    },
    getRowClasses(row) {
      return [
        this.selectedRowIds.includes(row.id) && this.$style.Row_selected,
        Object.keys(row.updates).length && this.$style.Row_changed,
        row.state === STATE_DELETE && this.$style.Row_deleted,
        row.state === STATE_CREATE && this.$style.Row_created,
        row.error && this.$style.Row_hasError
      ]
    },
    showRowErrors(row) {
      const el = this.$el.querySelector(`[data-row-id="${row.id}"] [data-id-col]`)
      this.currentRowError = row.error.message
      this.$refs.errorPopup.showAt(el)
    },
    hideRowErrors() {
      this.$refs.errorPopup.hide()
    },
    onClickError(item) {
      const col = this._columns.find(d => d.key === item.colKey) || this._columns[0]
      this.selectCell(col, this.records[item.rowIndex])
    },
    selectCell(col, row) {
      this.clearHighlight()
      this.selectedColumn = col
      this.selectedRecord = row
      this.selectedRowIds = [row.id]
      const el = this.$el.querySelector(`[data-row-id="${row.id}"]`)
      scrollIntoViewIfNeeded(el)
    },
    selectRow(row) {
      this.selectCell(this._columns[0], row)
    },
    clearCursor() {
      this.clearHighlight()
      this.clearSelection()
    },
    clearSelection() {
      this.selectedColumn = null
      this.selectedRecord = null
      this.selectedRowIds = []
    },
    clearHighlight() {
      this.highlightedColumnKey = null
      this.highlightedRowIds = []
    },
    highlightSummary(item) {
      this.clearSelection()
      this.highlightedColumnKey = item.colKey
      this.highlightedRowIds = item.rowIds
      const el = this.$el.querySelector(`[data-row-id="${item.rowIds[0]}"]`)
      scrollIntoViewIfNeeded(el)
    },
    revertRow(row) {
      row.updates = {}
      row.state = null
      this.validateAllRows()
    },
    copyRow(row) {
      const copy = {
        index: 0,
        id: this.nextRowId--,
        source: {
          ...row.source,
          ...row.updates
        },
        updates: {},
        state: STATE_CREATE,
        error: null
      }
      this.records.splice(row.index + 1, 0, copy)
      this.indexRows()
      this.validateAllRows()
    },
    createRow() {
      const row = {
        index: 0,
        id: this.nextRowId--,
        source: {},
        updates: {},
        state: STATE_CREATE,
        error: null
      }
      for (const col of this.columns) {
        let value = null
        const {defaultValue} = col
        if (defaultValue !== undefined) {
          value = typeof defaultValue === 'function'
            ? defaultValue()
            : defaultValue
        } else if (col.type === 'text') {
          value = ''
        } else if (col.type === 'list') {
          value = col.options[0].value
        }
        row.source[col.key] = value
      }
      this.records.push(row)
      this.indexRows()
      this.validateAllRows()
      this.$nextTick(() => this.selectRow(row))
    },
    deleteRow(row) {
      if (row.state === STATE_CREATE) {
        this.records.splice(row.index, 1)
        this.indexRows()
      } else {
        row.state = STATE_DELETE
        row.updates = {}
        this.validateAllRows()
      }
    },
    viewRow(row) {
      /**
       * When a listener is provided for this event, a 'View record' row action
       * will be made available to the user. This event is emitted with the row ID
       * when the user clicks the action.
       *
       * When emitted, you should open a new tab to display the original record in
       * the portal.
       */
      this.$emit('view-row', row.id)
    },
    getRowActions(row) {
      const actions = [
        {
          label: this.$t('ui.lang.revert'),
          disabled: (!row.state || row.state === STATE_CREATE),
          onAction: () => this.revertRow(row)
        }
      ]
      if (this.allowCopy) {
        actions.push({
          label: this.$t('ui.lang.copy'),
          onAction: () => this.copyRow(row)
        })
      }
      if (this.allowDelete || row.state === STATE_CREATE) {
        actions.push({
          label: this.$t('ui.lang.delete'),
          disabled: row.state === STATE_DELETE,
          onAction: () => this.deleteRow(row)
        })
      }
      if (this.$listeners['view-row']) {
        actions.push({
          label: this.$t('ui.lang.view'),
          disabled: row.state === STATE_CREATE,
          onAction: () => this.viewRow(row)
        })
      }
      return actions
    },
    getChanges() {
      return orderBy(this.records, ['id'], ['ASC'])
        .filter(row => row.state !== null)
        .map(row => {
          const entry = {
            id: row.id,
            state: row.state,
            oldRecord: null,
            newRecord: null,
            changedKeys: []
          }
          if (row.state === STATE_CREATE) {
            entry.oldRecord = null
            entry.newRecord = row.source
            entry.changedKeys = Object.keys(row.source)
          } else if (row.state === STATE_UPDATE) {
            entry.oldRecord = row.source
            entry.newRecord = {
              ...row.source,
              ...row.updates
            }
            entry.changedKeys = Object.keys(row.updates)
          } else if (row.state === STATE_DELETE) {
            entry.oldRecord = row.source
            entry.newRecord = null
            entry.changedKeys = []
          }
          return entry
        })
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

$borderColor: #ddd;
$textColor: #798c9c;
$headerBgColor: #f5f6f7;
$headerHoverBgColor: darken($headerBgColor, 2%);
$headerSelectedBgColor: darken($headerBgColor, 5%);
$rowHoverBgColor: #e6f4ff;

$accentChanged: #056ebb;
$accentDeleted: #f01800;
$accentCreated: #008000;

.TableCard {
  padding-bottom: 10px;
}

.Table {
  background: #fff;
  overflow: auto;
  max-height: 80vh;
  margin-top: 16px;
  // Stretch spreadsheet one pixel to the right to compensate for Cell component
  margin-right: -1px;
  //
  border-bottom: 1px solid $borderColor;
}

.Row {
  display: grid;
  grid-template-columns: var(--grid-template);
  // Undo effect of `margin-right: -1px` on Cell component
  margin-right: 1px;
}

.HeaderRow {
  position: sticky;
  top: 0;
  z-index: 3;

  .Cell {
    background: $headerBgColor;
    font-weight: bold;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .idColumn {
    justify-content: flex-start;
  }
}

.Cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -1px;
  margin-right: -1px;
  padding: 6px;
  border: 1px solid $borderColor;
  overflow: hidden;
}
.Cell_isNumeric {
  text-align: right;
}

.idColumn {
  position: sticky;
  left: 0;
  z-index: 2;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  border-left: none;
  background: $headerBgColor;
  white-space: nowrap;

  .Row:not(.Row_selected):hover & {
    background: $headerHoverBgColor;
  }
  .Row_selected & {
    background: $headerSelectedBgColor;
  }
  .Row_changed & {
    padding-left: 11px;
    border-left: 5px solid $accentChanged;
    // background: #deefff;
  }
  .Row_deleted & {
    padding-left: 11px;
    border-left: 5px solid $accentDeleted;
    // background: #ffdcda;
  }
  .Row_created & {
    padding-left: 11px;
    border-left: 5px solid $accentCreated;
    // background: #d9e8d9;
  }
}

.IdColumn_text {
  display: inline-block;
  color: $linkColor;
  font-weight: 500;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.RowErrorIcon {
  float: right;
  margin: 0 5px;
  color: #f01800;
  font-size: 20px;
}

.Cell_sortable {
  cursor: pointer;

  &:hover {
    color: $focusColor;
  }
}
.SortIcon {
  opacity: 0.3;
  margin-right: 5px;

  .Cell_isSorted &,
  .Cell_sortable:hover & {
    opacity: 1;
  }
}
</style>
