var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'UIElement',
    _vm.$style.Tree
  ],style:({
    '--gutter': _vm.gutter,
    '--lineColor': _vm.lineColor
  })},[_c('div',{class:_vm.$style.Tree_wrapper},[_c('TreeNode',{attrs:{"node":_vm.root,"children-prop":_vm.childrenProp,"layout":_vm.layout},scopedSlots:_vm._u([{key:"node",fn:function(ref){
  var node = ref.node;
  var parent = ref.parent;
  var index = ref.index;
return [_vm._t("node",null,{"node":node,"parent":parent,"index":index})]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }