<template>
  <MaybeRouterLink
    :class="[
      $style.Item,
      item.description && $style.hasDesc
    ]"
    :data-type="item.type"
    :to="item.url"
    @click.native="onClick(item)"
    plain
  >
    <div
      :class="$style.Icon"
      :style="{background: item.color}"
    >
      <Icon
        :class="$style.Icon_icon"
        :icon="'fas ' + item.icon"
      />
    </div>
    <div :class="$style.Item_label">{{item.label}}</div>
    <div
      v-if="item.description"
      :class="$style.Item_desc"
    >{{item.description}}</div>
  </MaybeRouterLink>
</template>

<script>
import {Icon} from '@/components/Icon'
import {MaybeRouterLink} from '@/components/MaybeRouterLink'

export default {
  components: {
    Icon,
    MaybeRouterLink
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick(item) {
      if (item.onAction) {
        item.onAction()
      }
      this.$emit('click-item', item)
    }
  }
}
</script>

<style lang="scss" module>
.Item {
  --bgColor_hover: #eee;

  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;

  &.hasDesc {
    align-items: flex-start;
  }

  &:hover {
    background: var(--bgColor_hover);
    overflow: visible;
    .Item_desc {
      // position: absolute;
      white-space: normal;
    }
  }
}
.Item_icon {
  flex: 0 0 30px;
  padding-top: 3px;
  color: #1a73e8;
  font-size: 20px;
}
.Item_label {
  margin-left: 10px;
  font-weight: 500;
}
.Item_desc {
  // Absolutely position the description so that we can resize it without
  // affecting the layout of other grid items.
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  padding-left: 50px;
  border-radius: 3px;
  margin-top: 5px;
  color: #6b778c;
  font-size: 12px;
  // Only show one line of description text by default.
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  // When hovered show full description text and extend the background of the
  // item as necessary.
  .Item:hover & {
    padding-bottom: 8px;
    background: var(--bgColor_hover);
    // box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  }
}

.Icon {
  position: relative;
  z-index: 1;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: #1a73e8;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  font-size: 16px;
}
.Icon_icon {
  color: #fff;
}

.Item[data-type="link"] .Icon {
  background: #3c485d;
}
</style>
