<template>
  <Modal
    :title="opts.title"
    :primaryAction="{
      label: opts.primaryActionLabel || $t('ui.modal.submit'),
      type: 'primary',
      disabled: !this.canSubmit,
      onAction: submit
    }"
    :secondaryActions="[{
      label: opts.secondaryActionLabel || $t('ui.modal.cancel'),
      onAction: cancel
    }]"
    :visible="visible"
    @close="cancel"
    @afterEnter="handleEnter"
    @afterLeave="handleLeave"
  >
    <render-node
      :node="opts.content"
      style="margin-bottom: 8px"
    />
    <TextField
      ref="input"
      v-model="inputValue"
      :helpText="opts.inputHelpText"
      :error="error"
      :placeholder="opts.inputPlaceholder"
      @keydown.enter="submit($event, true)"
      @keydown.escape="cancel"
      :multiline="multiline"
    />
  </Modal>
</template>

<script>
import {TextField} from '../../TextField'
import mixin from './mixin'
export default {
  mixins: [mixin],
  components: {
    TextField
  },
  data() {
    return {
      inputValue: this.opts.inputValue || '',
      invalidValueText: this.opts.invalidValueText || this.$t('ui.modal.invalidValue'),
      multiline: !!this.opts.multiline
    }
  },
  computed: {
    validatorResult() {
      return this.opts.inputValidator
        ? this.opts.inputValidator(this.inputValue)
        : true
    },
    error() {
      if (!this.inputValue) {
        // Don't error when input is empty
        return ''
      }
      const result = this.validatorResult
      if (result === false) {
        return this.invalidValueText
      }
      if (typeof result === 'string') {
        return result
      }
      return ''
    },
    canSubmit() {
      return this.inputValue && !this.error
    }
  },
  methods: {
    submit(e, enterPressed) {
      // multiline needs to support submit
      // can press command or control + enter to submit
      if (this.multiline && enterPressed && !(e.metaKey || e.ctrlKey)) {
        return
      }
      if (!this.error) {
        if (this.opts.onSubmit) {
          this.opts.onSubmit(this.inputValue)
        }
        this.handleAction(this.inputValue)
      }
    },
    cancel() {
      if (this.opts.onCancel) {
        this.opts.onCancel()
      }
      this.handleAction()
    },
    handleEnter() {
      this.$refs.input.focus()
    }
  }
}
</script>
