<script>
import styles from './RadioButtonControl.module.scss'
import {mergeData} from 'vue-functional-data-merge'

export default {
  name: 'RadioButtonControl',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    name: {
      type: String
    },
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  functional: true,
  render(h, {data, props}) {
    return h('input', mergeData(data, {
      class: [
        styles.RadioButtonControl,
        props.error && styles.error,
        props.readonly && styles.readonly
      ],
      attrs: {
        name: props.name
      },
      domProps: {
        type: 'radio',
        checked: props.checked,
        disabled: props.disabled
      },
      on: props.readonly && {
        click(event) {
          event.preventDefault()
        }
      }
    }))
  }
}
</script>
