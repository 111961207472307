<script>
import {mergeData} from 'vue-functional-data-merge'
import {elementChildren, cloneVNode} from '../../utils'

/**
 * Button group displays multiple related actions stacked or in a horizontal row
 * to help with arrangement and spacing.
 */
export default {
  name: 'ButtonGroup',
  props: {
    /**
     * Join buttons as segmented group.
     */
    segmented: {
      type: Boolean
    },
    /**
     * Renders the background area of the button group to visually connect the
     * gaps between buttons. To be used in conjunction with the `segmented`
     * option.
     */
    fill: {
      type: Boolean,
      default: false
    }
  },
  functional: true,
  render(h, {children, data, props, $style}) {
    return h('div', mergeData(data, {
      class: [
        'UIElement',
        $style.ButtonGroup,
        props.segmented && $style.segmented,
        props.fill && $style.fill
      ],
      attrs: {
        'data-buttongroup-segmented': props.segmented ? true : undefined
      }
    }), elementChildren(children).map(node => {
      const attrs = node.data && node.data.attrs
      if (attrs && attrs['data-button-group-item']) {
        return cloneVNode(node, {
          class: $style.item
        })
      }
      return h('div', {
        class: $style.item
      }, [node])
    }))
  }
}
</script>

<style lang="scss" module>
.ButtonGroup {
  display: flex;
  flex-wrap: nowrap;
}
.item + .item {
  margin-left: 8px;
}
.segmented {
  .item {
    margin: 0;
    // * {
    //   border-radius: 0;
    // }
    // &:first-child > * {
    //   border-top-left-radius: 3px;
    //   border-bottom-left-radius: 3px;
    // }
    // &:last-child > * {
    //   border-top-right-radius: 3px;
    //   border-bottom-right-radius: 3px;
    // }
    &:not(:first-child) {
      margin-left: -1px;
    }
  }
}
.fill {
  background: linear-gradient(to bottom, white, #fafafa);
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #c4cdd5;
}
</style>
