import debounce from 'lodash.debounce'
import isEqual from 'lodash.isequal'
import orderBy from 'lodash.orderby'
import memoize from 'lodash.memoize'
import xor from 'lodash.xor'
import {load} from './loader'
import {
  mergeData,
  mergeDataClassStyle,
  mergeDataFixListeners
} from './mergeData'

export {
  ReactiveMap,
  ReactiveSet
} from './reactive'

export {
  debounce,
  isEqual,
  orderBy,
  memoize,
  mergeData,
  mergeDataClassStyle,
  mergeDataFixListeners,
  xor,
  load
}

export function isObject(object) {
  return object !== null && typeof object === 'object'
}

export function cloneDeep(value) {
  return JSON.parse(JSON.stringify(value))
}
export function isEqualUnordered(array1, array2) {
  if (Array.isArray(array1) && Array.isArray(array2)) {
    return xor(array1, array2).length === 0
  }
  return isEqual(array1, array2)
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function camelCase(str) {
  return str
    .replace(/[_-]/g, ' ')
    .trim()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) {
        return ''
      }
      return index == 0
        ? match.toLowerCase()
        : match.toUpperCase()
    })
}

export function getDataClasses(data) {
  const out = {
    ...data.class
  }
  if (data.staticClass) {
    data.staticClass.split(' ').forEach(d => out[d] = true)
  }
  return out
}

export function nonEmptyChildren(vnodes) {
  return vnodes
    ? vnodes.filter(node => node.text || node.tag)
    : []
}

export function elementChildren(vnodes) {
  return vnodes
    ? vnodes.filter(node => node.tag)
    : []
}

export function destroyManualVm(vm) {
  vm.$destroy()
  const parent = vm.$el && vm.$el.parentNode
  if (parent) {
    parent.removeChild(vm.$el)
  }
}

export function isEmptyValue(value) {
  return value === undefined ||
    value === null ||
    value === ''
}

const uniqueIds = {}
export function createUniqueId(namespace) {
  const count = uniqueIds[namespace] || 0
  uniqueIds[namespace] = count + 1
  return namespace + '_' + count
}

export function get(object, path, defaultValue) {
  const keys = String(path)
    .replace(/\[/g, '.')
    .replace(/\]/g, '')
    .split('.')
  while (keys.length) {
    const type = typeof object
    if (!object || (type !== 'object' && type !== 'function')) {
      object = defaultValue
      break
    }
    object = object[keys.shift()]
  }
  return (typeof object === 'undefined')
    ? defaultValue
    : object
}

export function clamp(value, min, max) {
  if (value < min) return min
  if (value > max) return max
  return value
}

export function inputToNumber(value) {
  const num = parseFloat(value)
  return isNaN(num) ? value : num
}

export function isVNode(value) {
  return (
    value &&
    typeof value === 'object' &&
    'isRootInsert' in value &&
    'isComment' in value
  )
}

// See https://jsfiddle.net/Linusborg/px3he5vc/
export function cloneVNode(vnode, newData = {}) {
  // use the context that the original vnode was created in.
  const h = vnode.context && vnode.context.$createElement
  const isComp = !!vnode.componentOptions
  const isText = !vnode.tag // this will also match comments but those will be dropped, essentially
  const children = isComp
    ? vnode.componentOptions.children
    : vnode.children

  if (isText) return vnode.text

  const tag = isComp
    ? vnode.componentOptions.Ctor
    : vnode.tag

  const childNodes = children ? children.map(c => cloneVNode(c)) : undefined
  return h(tag, mergeData(vnode.data, newData), childNodes)
}

export function scrollIntoViewIfNeeded(el) {
  if (el.scrollIntoViewIfNeeded) {
    el.scrollIntoViewIfNeeded()
  } else if (el.scrollIntoView) {
    el.scrollIntoView({
      block: 'center',
      inline: 'nearest'
    })
  }
}

export function escapeRegExp(string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function createWildcardRegExp(string) {
  const pattern = '^' + escapeRegExp(string).replace(/\\\*/g, '.*') + '$'
  return new RegExp(pattern)
}

export function pad(value) {
  return value < 10 ? '0' + value : value
}
