import ToastManager from './ToastManager.vue'

const install = (Vue) => {
  const UI = Vue.prototype.$ui = Vue.prototype.$ui || {}
  let vm = null

  function createToast(opts) {
    if (!vm) {
      vm = new Vue({
        render(h) {
          return h(ToastManager, {
            ref: 'manager'
          })
        }
      }).$mount()
      document.body.appendChild(vm.$el)
    }
    // Add toast
    return vm.$refs.manager.addItem(opts)
  }

  createToast.clearAll = function() {
    if (vm) {
      vm.$refs.manager.clearAll()
    }
  }

  UI.toast = createToast

  return {
    toast: createToast
  }
}

export {
  install,
  ToastManager
}
