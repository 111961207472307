<template>
  <div>
    <SimplePopper
      align="right"
      backdrop
      animate
      content-class="ServicePicker_content"
    >
      <template #trigger>
        <div :class="$style.Button">
          <Icon
            :class="$style.Button_icon"
            icon="fa fa-th"
          />
          <span :class="$style.Button_icon_label">
            {{i18n.services}}
          </span>
        </div>
      </template>
      <template #default="{close}">
        <div :class="$style.Menu">
          <div
            v-for="section in filteredSections"
            :key="section.title"
            :class="$style.Section"
          >
            <div :class="$style.Section_title">{{section.title}}</div>
            <div :class="$style.Section_content">
              <ServiceItem
                v-for="item in section.items"
                :key="item.id"
                :item="item"
                @click-item="close"
              />
            </div>
          </div>
        </div>
      </template>
    </SimplePopper>
  </div>
</template>

<script>
import {Icon} from '@/components/Icon'
import ServiceItem from './ServiceItem'
import SimplePopper from './SimplePopper'

export default {
  components: {
    Icon,
    ServiceItem,
    SimplePopper
  },
  props: {
    i18n: {
      type: Object,
      required: true
    },
    sections: {
      type: Array,
      required: true
    }
  },
  computed: {
    filteredSections() {
      const sections = []
      for (const section of this.sections) {
        const items = []
        for (const item of section.items) {
          if (!item.hidden) {
            items.push(item)
          }
        }
        if (items.length) {
          sections.push({
            ...section,
            items
          })
        }
      }
      return sections
    }
  }
}
</script>

<style lang="scss" module>
@import "../common.module.scss";
// @import '../../../styles/mixins.scss';

$menuWidth: 800px;
$menuMarginX: 16px;
$menuRightOffset: 16px;

// The service picker is 800px wide. When the screen is too small, it will
// be cut off by the left-edge of the viewport. This fixes the service picker
// menu into the top-right corner of the screen.
:global(.ServicePicker_content) {
  @media (max-width: 1250px) {
    position: fixed !important;
    top: calc(var(--ui-globalNav-height) - 4px);
    right: $menuRightOffset;
  }
}

// Ensure the the service picker menu is never fixed while in the styleguide.
// @include inStyleguide {
//   :global(.ServicePicker_content) {
//     position: absolute !important;
//   }
// }

.Button {
  @include navButtonBase;
}
.Button_icon {
  font-size: 16px;
  &_label {
    margin-left: 6px;
  }
  @media (max-width: 600px) {
    &_label {
      display: none;
    }
  }
}

.Menu {
  @include menuBase;
  width: $menuWidth;
  @media (max-width: ($menuWidth + $menuMarginX * 2)) {
    max-width: calc(100vw - #{$menuMarginX * 2});
  }

  @media (max-width: 640px) {
    height: calc(100vh - var(--ui-globalNav-height) - #{$menuRightOffset});
    overflow-y: scroll;
  }
}

.Section + .Section {
  margin-top: 20px;
}
.Section_title {
  margin-left: 8px;
  margin-bottom: 5px;
  color: #6b778c;
  font-weight: 500;
}
.Section_content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>