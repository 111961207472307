<template>
  <div :class="['UIElement', $style.ActionListMenu]">
    <div
      v-for="(section, index) in finalSections"
      :key="index"
      :class="$style.ActionListMenuSection"
    >
      <div
        v-if="section.title"
        :class="$style.ActionListMenuSection_title"
      >
        {{section.title}}
      </div>

      <template v-for="(item, index) in section.items">
        <div
          v-if="item.items || item.sections"
          :key="index"
        >
          <ActionList
            :items="item.items"
            :sections="item.sections"
            placement="right-start"
            @hide="handleHide"
          >
            <ActionListButton
              :key="index"
              :icon="item.icon"
              :label="item.label"
              :disabled="item.disabled"
              :tooltip="item.tooltip"
              :tooltip-position="item.tooltipPosition"
              :helpText="item.helpText"
              dropdown
            />
          </ActionList>
        </div>

        <ActionListButton
          v-else
          :key="index"
          :icon="item.icon"
          :label="item.label"
          :disabled="item.disabled"
          :tooltip="item.tooltip"
          :tooltip-position="item.tooltipPosition"
          :helpText="item.helpText"
          :url="item.url"
          :external="item.external"
          @click="handleClick(item)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import ActionListButton from './ActionListButton.vue'

let ActionList = null
export function setActionList(value) {
  ActionList = value
}

export default {
  name: 'ActionListMenu',
  components: {
    ActionListButton
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    sections: {
      type: Array,
      default: null
    }
  },
  computed: {
    finalSections() {
      const {items, sections} = this
      if (sections) {
        return sections
      }
      return [{items}]
    }
  },
  beforeCreate() {
    // Workaround circular reference
    this.$options.components.ActionList = ActionList
  },
  methods: {
    handleClick(item) {
      if (item.onAction) {
        item.onAction()
      }
      // An action was clicked so emit 'hide'
      this.$emit('hide')
    },
    handleHide() {
      // A sub list emitted a 'hide' event so pass it upwards
      this.$emit('hide')
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

.ActionListMenu {
  display: inline-block;
  margin: 4px 0;
  padding: 8px 0;
  background: #fff;
  border: 1px solid #dbe0e4;
  border-radius: 3px;
  box-shadow: 0 2px 16px rgba(33, 43, 54, 0.08);
}

.ActionListMenuSection + .ActionListMenuSection {
  margin-top: 12px;
}

.ActionListMenuSection_title {
  padding: 2px 16px 12px;
  border-bottom: 1px solid #dbe0e4;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
