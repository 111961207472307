/*global VERSION*/

// Important to import before components so that component styles
// can override global ones.
import './styles/global.scss'

import {capitalize} from './utils'
import {install as installModal} from './components/Modal'
import {install as installToastManager} from './components/ToastManager'
import * as components from './components'
import * as utils from './utils/_all'
import {setLocale} from './utils/date'
import saveBarApi from './api/saveBar'
import loaderApi from './api/loader'
import progressApi from './api/progress'

const UI = {
  // Replaced by build
  version: VERSION,
  components,
  install,
  utils,
  setLocale,
  format: utils.format,
  loader: {
    show(message) {
      loaderApi.show(message)
    },
    hide() {
      loaderApi.hide()
    }
  },
  progress: {
    start(opts) {
      if (opts && opts.loader) {
        const message = typeof opts.loader === 'string'
          ? opts.loader
          : undefined
        loaderApi.show(message)
      }
      progressApi.start()
    },
    done() {
      progressApi.done()
      loaderApi.hide()
    }
  },
  saveBar: {
    save: () => saveBarApi.save(),
    discard: () => saveBarApi.discard(),
    setState: (state) => saveBarApi.setState(state)
  }
}

function install(Vue, options) {
  const opts = options || {
    compatMode: false
  }
  const prefix = opts.prefix
    ? capitalize(opts.prefix)
    : ''

  // Exposes modal APIs under UI global
  Object.assign(this, installModal(Vue))
  Object.assign(this, installToastManager(Vue))

  Object.keys(components).forEach(name => {
    Vue.component(prefix + name, components[name])
  })

  if (opts.compatMode) {
    document.documentElement.classList.add('ui-compat')
  }

  Vue.prototype.$ui = UI
}

export default UI
