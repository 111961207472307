<template>
  <Modal
    :title="opts.title"
    :primaryAction="{
      label: opts.primaryActionLabel || $t('ui.modal.close'),
      type: opts.primaryActionType || 'primary',
      onAction: () => handleAction()
    }"
    :visible="visible"
    @close="handleAction"
    @afterLeave="handleLeave"
  >
    <render-node :node="opts.content" />
  </Modal>
</template>

<script>
import mixin from './mixin'
export default {
  mixins: [mixin]
}
</script>
