<script>
import {mergeData} from 'vue-functional-data-merge'
import styles from './TextStyle.module.scss'

const types = [
  'positive',
  'negative',
  'strong',
  'subdued',
  'code',
  'link'
]

export default {
  name: 'TextStyle',
  props: {
    type: {
      type: String,
      validator(value) {
        return types.indexOf(value) !== -1
      }
    }
  },
  functional: true,
  render(h, {children, data, props}) {
    const tag = props.type === 'code'
      ? 'code'
      : 'span'
    return h(tag, mergeData(data, {
      class: [
        'UIElement',
        styles[props.type]
      ]
    }), children)
  }
}
</script>