var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Labelled',{attrs:{"label":_vm.label,"error":_vm.error,"help-text":_vm.helpText,"help-text-html":_vm.helpTextHtml,"help-link":_vm.helpLink}},[_c('div',{staticClass:"UIElement",class:[
      _vm.$style.Select,
      _vm.error && _vm.$style.hasError,
      _vm.isEmpty && _vm.$style.isEmpty,
      _vm.isOpen && _vm.$style.isOpen,
      _vm.multiple && _vm.$style.isMultiple,
      _vm.disabled && _vm.$style.isDisabled,
      _vm.readonly && _vm.$style.isReadonly,
      _vm.showDescription && _vm.$style.showDescription,
      _vm.$style['popperPlacement_' + _vm.popperPlacement]
    ]},[_c('div',{ref:"control",class:_vm.$style.control,attrs:{"tabindex":_vm.disabled ? -1 : 0},on:{"click":_vm.open,"blur":_vm.onBlur,"keydown":_vm.onKeydown}},[_c('div',{class:_vm.$style.DisplayText},[_vm._t("display",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.displayText)}})]},{"displayText":_vm.displayText,"selectedOptions":_vm.selectedOptions})],2),(_vm.loading)?_c('Spinner',{attrs:{"size":"small"}}):_vm._e(),(_vm.canClear)?_c('Icon',{class:_vm.$style.ClearButton,attrs:{"icon":"fas fa-times-circle"},on:{"click":function($event){$event.stopPropagation();return _vm.clearSelected.apply(null, arguments)}}}):_vm._e(),(!_vm.loading)?_c('Icon',{class:_vm.$style.caret,attrs:{"icon":"fas fa-caret-down"}}):_vm._e()],1),(_vm.multiple && _vm.tags && _vm.selectedOptions.length > 0)?_c('div',{class:_vm.$style.Tags},[_vm._t("tags",function(){return _vm._l((_vm.selectedOptions),function(option){return _c('Tag',{key:option.value,class:_vm.$style.Tag,on:{"remove":function($event){return _vm.toggleOption(option)}}},[_vm._v(_vm._s(option.label))])})},{"selectedOptions":_vm.selectedOptions,"removeFn":_vm.toggleOption})],2):_vm._e(),(_vm.isOpen)?_c('MaybeMountingPortal',{attrs:{"mount-to":"body","append":"","teleport":_vm.teleport}},[_c('div',{ref:"dropdown",class:[
          'UIElement',
          _vm.$style.Dropdown,
          _vm.dropdownMinWidth && _vm.$style.Dropdown__minWidthSet
        ],staticStyle:{"z-index":"10000"},style:(_vm.dropdownStyle),on:{"click":function($event){$event.stopPropagation();}}},[(_vm.showSearch)?_c('div',{class:_vm.$style.Dropdown_search},[_c('Icon',{class:_vm.$style.Dropdown_searchIcon,attrs:{"icon":"far fa-search"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],ref:"searchInput",class:_vm.$style.Dropdown_searchInput,attrs:{"placeholder":_vm.$t('ui.select.search'),"tabindex":"-1"},domProps:{"value":(_vm.searchText)},on:{"focus":_vm.searchFocus,"blur":_vm.searchBlur,"keydown":_vm.onKeydown,"input":function($event){if($event.target.composing){ return; }_vm.searchText=$event.target.value}}}),_c('Icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchText),expression:"searchText"}],class:_vm.$style.Dropdown_searchCloseIcon,attrs:{"icon":"far fa-times"},on:{"click":function($event){_vm.searchText = ''}}})],1):_vm._e(),(_vm.multiple && _vm.toggleAll && _vm.numCurrentOptions)?_c('div',{class:_vm.$style.ToggleAll,on:{"mousedown":function($event){$event.preventDefault();return _vm.toggleAllOptions.apply(null, arguments)},"mouseover":function($event){_vm.activeIndex = -1}}},[_c('CheckboxControl',{class:_vm.$style.Option_check,attrs:{"checked":_vm.toggleAllState.checked,"indeterminate":_vm.toggleAllState.indeterminate,"tabindex":"-1"}}),_vm._v(" "+_vm._s(_vm.$t('ui.lang.toggleAll'))+" ("+_vm._s(_vm.numCurrentOptions)+") ")],1):_vm._e(),_c('ScrollPane',{ref:"list",class:_vm.$style.Dropdown_content},_vm._l((_vm.filteredGroups),function(group,groupIndex){return _c('div',{key:groupIndex,class:_vm.$style.OptionGroup},[(group.title)?_c('div',{class:_vm.$style.OptionGroup_title},[_vm._v(_vm._s(group.title))]):_vm._e(),_vm._l((group.options),function(option){return _c('div',{key:option.value,class:[
                _vm.$style.Option,
                _vm.selectedMap[option.value] && _vm.$style.Option__selected,
                option.disabled && _vm.$style.Option__disabled,
                _vm.activeIndex === option.index && _vm.$style.Option__active
              ],on:{"mousedown":function($event){$event.preventDefault();return _vm.toggleOption(option)},"mouseover":function($event){_vm.activeIndex = option.index}}},[_vm._t("option",function(){return [(_vm.multiple)?_c('CheckboxControl',{class:_vm.$style.Option_check,attrs:{"checked":_vm.selectedMap[option.value],"disabled":option.disabled,"tabindex":"-1"}}):_vm._e(),_c('div',{class:_vm.$style.Option_content},[_c('div',{class:_vm.$style.Option_label},[_vm._v(_vm._s(option.label))]),(option.description)?_c('div',{class:_vm.$style.Option_desc},[_vm._v(_vm._s(option.description))]):_vm._e()])]},{"option":option,"group":group,"selected":_vm.selectedMap[option.value]})],2)})],2)}),0),(!_vm.filteredGroups.length && _vm.searchText)?_c('div',{class:_vm.$style.NoResults},[_vm._v(_vm._s(_vm.noResults || _vm.$t('ui.select.noResults')))]):(!_vm.filteredGroups.length && !_vm.searchText)?_c('div',{class:_vm.$style.NoResults},[_vm._v(_vm._s(_vm.noOptions || _vm.$t('ui.select.noOptions')))]):_vm._e()],1)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }