<template>
  <div :class="$style.Pagination">
    <button
      :class="$style.Button"
      :disabled="!hasPrev"
      @click="setPageIndex(pageIndex - 1)"
    >
      <Icon icon="fa fa-chevron-left" />
    </button>
    <template v-for="(page, index) in pages">
      <button
        v-if="typeof page === 'number'"
        :key="index"
        :class="[
          $style.Button,
          page - 1 === pageIndex && $style.selected
        ]"
        @click="setPageIndex(page - 1)"
      >
        <Formatter :value="page" type="integer" />
      </button>
      <div
        v-else
        :key="index"
        :class="$style.Ellipsis"
      >...</div>
    </template>
    <button
      :class="$style.Button"
      :disabled="!hasNext"
      @click="setPageIndex(pageIndex + 1)"
    >
      <Icon icon="fa fa-chevron-right" />
    </button>
    <div
      v-if="pageSizes"
      :class="$style.PageSize"
    >
      <select
        :value="limit"
        :class="$style.PageSize_select"
        @change="changePageSize"
      >
        <option
          v-for="size in pageSizes"
          :key="size"
          :value="size"
        >{{size}}</option>
      </select>
      <Icon
        :class="$style.PageSize_icon"
        icon="fa fa-caret-down"
      />
    </div>
    <!-- <UnstyledSelect>
      <option>Matt</option>
      <option>This is long</option>
    </UnstyledSelect> -->
  </div>
</template>

<script>
import {Formatter} from '../Formatter'
import {Icon} from '../Icon'

// https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-3003402
function getRange(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((v,i) => i + start)
}

function pagination(current, length, delta = 4) {
  const range = {
    start: Math.round(current - delta / 2),
    end: Math.round(current + delta / 2)
  }
  if (range.start - 1 === 1 || range.end + 1 === length) {
    range.start += 1
    range.end += 1
  }
  let pages = current > delta
    ? getRange(
        Math.min(range.start, length - delta),
        Math.min(range.end, length)
      )
    : getRange(
        1,
        Math.min(length, delta + 1)
      )
  const withDots = (value, pair) => {
    return pages.length + 1 !== length
      ? pair
      : [value]
  }
  if (pages[0] !== 1) {
    pages = withDots(1, [1, '...']).concat(pages)
  }
  if (pages[pages.length - 1] < length) {
    pages = pages.concat(withDots(length, ['...', length]))
  }
  return pages
}

export default {
  name: 'Pagination',
  components: {
    Formatter,
    Icon
  },
  props: {
    /**
     * The page index to start at.
     */
    offset: {
      type: Number,
      default: 0
    },
    /**
     * The maximum number of items to show on the page.
     */
    limit: {
      type: Number,
      default: 25
    },
    /**
     * The total number of items. Used to calculate the number of pages to show.
     */
    total: {
      type: Number,
      default: 0
    },
    /**
     * The maximum number of page shortcuts to show.
     */
    maxPages: {
      type: Number,
      default: 10
    },
    /**
     * An array of page sizes (limits) the user can choose from.
     *
     * Must be used in combination with the `update:limit` event to be reactive.
     *
     * If set to `false` it will remove the option to change page sizes.
     */
    pageSizes: {
      type: null,
      default: () => [10, 25, 50, 100]
    }
  },
  computed: {
    numPages() {
      return Math.ceil(this.total / this.limit)
    },
    pageIndex() {
      return this.offset / this.limit
    },
    hasPrev() {
      return this.pageIndex > 0
    },
    hasNext() {
      return this.pageIndex < this.numPages - 1
    },
    pages() {
      if (this.maxPages > 0 && this.total > 0) {
        return pagination(this.pageIndex + 1, this.numPages, this.maxPages - 2)
      }
      return []
    }
  },
  methods: {
    setPageIndex(index) {
      /**
       * The new offset.
       * @type {number}
       */
      this.$emit('change', index * this.limit)
    },
    changePageSize(event) {
      const size = parseInt(event.target.value, 10)
      /**
       * Emitted when the user changes the page size.
       */
      this.$emit('update:limit', size)
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

.Pagination {
  display: flex;
  align-items: center;
}

.Button,
.Ellipsis {
  min-width: 30px;
  min-height: 30px;
  padding: 0 5px;
  background: transparent;
  border: 0;
  border-radius: 3px;
  font-size: 14px;

  &:disabled {
    color: $buttonDisabledTextColor;
  }
}

.Button {
  &:hover:not([disabled]) {
    background: #ecedf0;
    cursor: pointer;
  }

  &:focus {
    outline: 0;
    box-shadow: inset 0 0 0 2px #aaa;
  }

  &.selected, &.selected:hover {
    background: $focusColor;
    color: #fff;
  }
  &.selected:focus {
    box-shadow: inset 0 0 0 2px darken($focusColor, 10%);
  }
}

.Ellipsis {
  display: flex;
  align-items: center;
  justify-content: center;
}

.PageSize {
  min-height: 30px;
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid #ddd;
}
.PageSize_select {
  appearance: none;
  width: 50px;
  min-height: 30px;
  margin-right: -15px;
  padding: 0 5px;
  border: none;
  background: transparent;
  font-size: 14px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #ecedf0;
  }
  &:focus {
    outline: 0;
    box-shadow: inset 0 0 0 2px #aaa;
  }
}
.PageSize_icon {
  pointer-events: none;
}
</style>
