import {
  isEmptyValue,
  memoize
} from './'
import {$t, emptyValuePlaceholder, getCurrentLocale} from './lang'
import {dayjs} from './date'

const getIntegerFormatter = memoize(({locale}) => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    maximumFractionDigits: 0
  })
})

const getDecimalFormatter = memoize(({locale}) => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal'
  })
})

const getCurrencyFormatter = memoize(({locale, currency}) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol'
  })
}, ({locale, currency}) => {
  return `${locale}-${currency}`
})

const getPercentFormatter = memoize(({locale}) => {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })
})

function createDateFormatter(fn) {
  return function(value) {
    return fn(dayjs(value))
  }
}

/**
 * relative time: https://day.js.org/docs/en/customization/relative-time
 * durations: https://day.js.org/docs/en/durations/durations
 */
function formatDuration(duration, short = false) {
  let {years, months, days, hours, minutes, seconds} = duration.$d
  const parts = []
  if (years > 0 || months > 0) {
    // We don't want to show durations higher than the day unit,
    // so recalculate days if necessary.
    days = Math.floor(duration.$ms / 86400000)

    /**
     * This library doesn't work reliably for values > 30 days
     * So in that case, just show value as days and hope to god
     * they fix this library.
     */
    years = months = hours = minutes = seconds = 0
  }
  // slight hack to grab the translations for days, hours, minutes, etc. from dayjs
  const localeMap = dayjs.Ls[dayjs.locale() || 'en'].relativeTime
  if (days) {
    if (short) {
      parts.push(`${days}d`)
    } else {
      const k = days > 1 ? 'dd' : 'd'
      parts.push(localeMap[k].replace('%d', days))
    }
  }
  if (hours) {
    if (short) {
      parts.push(`${hours}h`)
    } else {
      const k = hours > 1 ? 'hh' : 'h'
      parts.push(localeMap[k].replace('%d', hours))
    }
  }
  if (minutes) {
    if (short) {
      parts.push(`${minutes}m`)
    } else {
      const k = minutes > 1 ? 'mm' : 'm'
      parts.push(localeMap[k].replace('%d', minutes))
    }
  }
  if (seconds) {
    if (short) {
      parts.push(`${seconds}s`)
    } else {
      const k = seconds > 1 ? 'ss' : 's'
      parts.push(localeMap[k].replace('%d', seconds))
    }
  }
  return short
    ? (parts.join(' ') || '0s')
    : parts.join(' ')
}

export const formatters = {
  dateAgo: createDateFormatter(m => m.fromNow()),
  dateCalendar: createDateFormatter(m => m.calendar()),
  dateIso: createDateFormatter(m => m.toISOString()),
  dateNumeral: createDateFormatter(m => m.format('L')),
  dateShort: createDateFormatter(m => m.format('ll')),
  dateTimeShort: createDateFormatter(m => m.format('lll')),
  dateLong: createDateFormatter(m => m.format('LL')),
  dateTimeLong: createDateFormatter(m => m.format('LLL')),
  dateMonthShort: createDateFormatter(m => m.format('MMM')),
  dateMonthLong: createDateFormatter(m => m.format('MMMM')),
  dateMonthYearShort: createDateFormatter(m => m.format('MMM, YYYY')),
  dateMonthYearLong: createDateFormatter(m => m.format('MMMM, YYYY')),
  duration(value) {
    if (!Array.isArray(value) && value.length !== 2) {
      throw new Error('Duration formatter value must be an array of two dates')
    }
    let start = value[0]
    let end = value[1]
    if (start > end) {
      start = value[1]
      end = value[0]
    }
    return formatDuration(dayjs.duration(dayjs(end).diff(start)))
  },
  durationShort(value) {
    if (!Array.isArray(value) && value.length !== 2) {
      throw new Error('Duration formatter value must be an array of two dates')
    }
    let start = value[0]
    let end = value[1]
    if (start > end) {
      start = value[1]
      end = value[0]
    }
    return formatDuration(dayjs.duration(dayjs(end).diff(start)), true)
  },
  durationMs(value) {
    return formatDuration(dayjs.duration(value))
  },
  durationShortMs(value) {
    return formatDuration(dayjs.duration(value), true)
  },
  integer(value, context) {
    return getIntegerFormatter(context)
      .format(value)
  },
  decimal(value, context) {
    return getDecimalFormatter(context)
      .format(value)
  },
  currency(value, context) {
    return getCurrencyFormatter(context)
      .format(value)
  },
  percent(value, context) {
    return getPercentFormatter(context)
      .format(value)
  },
  boolean(value) {
    if (value === true) {
      return $t('TRUE')
    }
    if (value === false) {
      return $t('FALSE')
    }
    return emptyValuePlaceholder
  },
  filesize(value, context) {
    const info = parseAsBytes(value)
    return formatters.decimal(info.value, context) + ' ' + info.unit
  }
}

export function format(value, format, context) {
  if (isEmptyValue(value)) {
    return emptyValuePlaceholder
  }
  if (!format) {
    return value
  }
  if (!context) {
    context = {}
  }
  if (!context.locale) {
    context.locale = getCurrentLocale()
  }
  if (!context.currency) {
    context.currency = 'USD'
  }
  const formatFn = formatters[format]
  if (!formatFn) {
    throw new Error(`Invalid formatter "${format}"`)
  }
  return formatFn(value, context)
}

export function isNumericType(type) {
  return (
    type === 'integer' ||
    type === 'decimal' ||
    type === 'currency' ||
    type === 'percent'
  )
}

export function isSummableNumericType(type) {
  return (
    type === 'integer' ||
    type === 'decimal' ||
    type === 'currency'
  )
}

// https://github.com/sindresorhus/pretty-bytes/blob/master/index.js
const BYTE_UNITS = [
  'B',
  'kB',
  'MB',
  'GB',
  'TB',
  'PB',
  'EB',
  'ZB',
  'YB'
]
function parseAsBytes(value) {
  let exponent = Math.min(
    Math.floor(
      Math.log10(value) / 3
    ),
    BYTE_UNITS.length - 1
  )
  if (exponent < 0) {
    exponent = 0
  }
  value = Number((value / Math.pow(1000, exponent)).toFixed(2))
  const unit = BYTE_UNITS[exponent]
  return {
    value,
    unit
  }
}
