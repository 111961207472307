<script>
import {mergeData} from 'vue-functional-data-merge'
import StackItem from './StackItem.vue'
import styles from './Stack.module.scss'
import {capitalize, elementChildren} from '../../utils'

const alignmentProps = [
  'leading',
  'trailing',
  'center',
  'fill',
  'baseline'
]

const distributionProp = [
  'equalSpacing',
  'leading',
  'trailing',
  'center',
  'fill',
  'fillEvenly'
]

const spacingProp = [
  'extraTight',
  'tight',
  'loose',
  'extraLoose',
  'none'
]

export default {
  name: 'Stack',
  props: {
    /**
     * Aligns items along the stack's cross axis.
     *
     * - leading
     * - trailing
     * - center
     * - fill
     * - baseline
     */
    alignment: {
      type: String,
      validator(value) {
        return alignmentProps.indexOf(value) >= 0
      }
    },
    /**
     * Aligns items along the stack's main axis.
     *
     * - equalSpacing
     * - leading
     * - trailing
     * - center
     * - fill
     * - fillEvenly
     */
    distribution: {
      type: String,
      validator(value) {
        return distributionProp.indexOf(value) >= 0
      }
    },
    /**
     * The margin to add between items.
     *
     * - extraTight
     * - tight
     * - loose
     * - extraLoose
     * - none
     */
    spacing: {
      type: String,
      validator(value) {
        return spacingProp.indexOf(value) >= 0
      }
    },
    /**
     * Flip the main axis so that items are rendered vertically.
     */
    vertical: {
      type: Boolean,
      default: false
    },
    /**
     * Prevent items from wrapping when there is limited space.
     */
    wrap: {
      type: Boolean,
      default: true
    }
  },
  functional: true,
  render(h, {children, data, props}) {
    const childrenMarkup = children && elementChildren(children).map(child => {
      if (child.fnOptions && child.fnOptions.name === 'StackItem') {
        return child
      }
      return h(StackItem, [child])
    })
    return h('div', mergeData(data, {
      class: [
        'UIElement',
        styles.Stack,
        props.alignment && styles['alignment' + capitalize(props.alignment)],
        props.distribution && styles['distribution' + capitalize(props.distribution)],
        props.spacing && styles['spacing' + capitalize(props.spacing)],
        props.vertical && styles.vertical,
        !props.wrap && styles.noWrap
      ],
      props
    }), childrenMarkup)
  }
}
</script>
