<template>
  <div :class="[
    $style.Filters,
    disabled && $style.Filters__disabled
  ]">
    <div
      v-if="disabled"
      :class="$style.Filters_disabled"
    ></div>
    <TextField
      v-if="localQuery !== false"
      :value="localQuery"
      @input="updateQuery"
      :placeholder="$t('ui.filters.queryPlaceholder')"
    >
      <template #prefix>
        <Icon icon="fa fa-search" />
      </template>
      <template #right>
        <FilterActions
          :filters="filters"
          :shortcuts="shortcuts"
          :mode="mode"
          @open="open"
        />
      </template>
      <template #suffix>
        <div
          :class="$style.clearQuery"
          v-show="localQuery"
          @click="updateQuery('')"
        >
          <Icon icon="fa fa-times-circle" />
        </div>
      </template>
    </TextField>
    <FilterActions
      v-else
      :filters="filters"
      :shortcuts="shortcuts"
      :mode="mode"
      @open="open"
    />
    <div v-if="appliedTags.length" :class="$style.Filter_tags">
      <Tag
        v-for="tag in appliedTags"
        :key="tag.key"
        :required="tag.required"
        :tooltip="tag.required ? $t('ui.filters.requiredError') : null"
        @remove="mode === 'single' ? removeValue(tag.parentKey, tag.key) : removeFilter(tag.key)"
      >{{tag.label}}</Tag>
    </div>
    <Sheet
      :title="$t('ui.filters.filters')"
      :container-class="$style.Filters_sheet"
      :visible="showSheet"
      :width="400"
      @close="cancel"
    >
      <template #actions>
        <Button
          type="primary"
          @click="commit"
          :disabled="!hasChanged || commitErrors.length > 0"
        >{{$t('ui.filters.apply')}}</Button>
      </template>
      <FilterItem
        v-for="filter in filters"
        :key="filter.key"
        :filter="filter"
        v-model="editFilters[filter.key]"
      />
    </Sheet>
  </div>
</template>

<script>
import {Button} from '../Button'
import FilterActions from './FilterActions.vue'
import FilterItem from './FilterItem.vue'
import {Icon} from '../Icon'
import {Tag} from '../Tag'
import {TextField} from '../TextField'
import {Sheet} from '../Sheet'
import {cloneDeep, isEqual} from '../../utils'
import {debounce} from '../../utils'
import {format} from '../../utils/format'
import {isEmptyFilterValue} from './utils'

export default {
  name: 'Filters',
  components: {
    Button,
    FilterActions,
    FilterItem,
    Icon,
    Tag,
    TextField,
    Sheet
  },
  model: {
    prop: 'appliedFilters',
    event: 'update'
  },
  props: {
    /**
     * An array of filter objects. Each must have a unique `key` which corresponds
     * to those used in `appliedFilters`.
     *
     * ```js
     * interface Filter {
     *   key: string // A unique key
     *   label: string
     *   type: 'datetime' | 'date' | 'month' | 'year' | 'text' | 'select' | 'boolean'
     *   placeholder?: string
     *   options?: object[] // Options for `select`
     *   optionGroups?: object[] // Option groups for `select`
     *   multiple?: boolean // For `select`
     * }
     * ```
     */
    filters: {
      type: Array,
      default: () => []
    },
    /**
     * The data representing applied filters. Each filter value is assigned
     * to the `key` provided in the filter array definition.
     *
     * This is what is also bound to `v-model`.
     *
     * For example, if you defined two filters with keys "startDate" and "endDate" you
     * would represent their current values using the following object:
     *
     * ```js
     * {
     *   startDate: null,
     *   endDate: null
     * }
     * ```
     */
    appliedFilters: {
      type: Object,
      default: () => ({})
    },
    /**
     * An optional array of shortcut filters which are added to the left
     * of the query input.
     *
     * Each shortcut is represented as an ActionList.
     *
     * ```js
     * interface Shortcut {
     *   label: string
     *   // See `ActionList` for more items options
     *   items: Array<{
     *     label: string
     *     onAction(): void
     *   }>
     * }
     * ```
     */
    shortcuts: {
      type: Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * The value to use in the query text field.
     *
     * Set this to `false` to disable querying.
     */
    query: {
      type: null
    },
    /**
     * The delay between emitting updated query values in milliseconds.
     */
    queryDelay: {
      type: Number,
      default: 0
    },
    mode: {
      type: String,
      default: 'multi'
    }
  },
  data() {
    return {
      showSheet: false,
      isOpen: false,
      editFilters: {},
      localQuery: null
    }
  },
  watch: {
    query: {
      handler(query) {
        this.localQuery = query
      },
      immediate: true
    },
    queryDelay: {
      handler(delay) {
        this.updateQueryDebounced = debounce(function(value) {
          /**
           * Emitted after the user stops editing the query input after
           * `queryDelay` milliseconds.
           *
           * @event update:query
           * @type {string}
           */
          this.$emit('update:query', value)
        }, delay)
      },
      immediate: true
    }
  },
  computed: {
    mergedFilters() {
      const {filters, appliedFilters} = this
      return filters.map(d => {
        return {
          ...d,
          value: appliedFilters[d.key]
        }
      })
    },
    appliedTags() {
      if (this.mode === 'single' && this.filters.length === 1) {
        const f = this.filters[0]
        const val = this.appliedFilters[f.key]
        return (f.multiple ? val : [val])
          .filter(v => !isEmptyFilterValue(v))
          .map(v => {
            const opt = (f.options || []).find(o => o.value === v)
            return {
              key: v,
              parentKey: f.key,
              label: opt && opt.label || v
            }
          })
      }
      return this.mergedFilters
        .filter(f => !isEmptyFilterValue(f.value))
        .map(f => {
          return {
            key: f.key,
            label: getFilterLabel(f, f.value, this.$t),
            required: !!f.required
          }
        })
    },
    hasChanged() {
      return !isEqual(this.editFilters, this.appliedFilters)
    },
    commitErrors() {
      const errors = []
      for (const filter of this.filters) {
        const value = this.editFilters[filter.key]
        if (filter.required && isEmptyFilterValue(value)) {
          errors.push(`Filter ${filter.label} is required.`)
        }
      }
      return errors
    }
  },
  created() {
    this.$watch('appliedFilters', (appliedFilters) => {
      this.editFilters = cloneDeep(appliedFilters)
    }, {immediate: true})
  },
  methods: {
    open() {
      this.showSheet = true
      this.editFilters = cloneDeep(this.appliedFilters)
    },
    commit() {
      this.emitAppliedFilters(this.editFilters)
      this.showSheet = false
    },
    cancel() {
      if (this.hasChanged) {
        this.$ui.confirm({
          title: this.$t('ui.filters.unsavedTitle'),
          content: this.$createElement('div', [
            this.$t('ui.filters.unsavedMessage')
          ]),
          primaryActionLabel: this.$t('ui.filters.unsavedDiscard'),
          primaryActionType: 'destructive',
          secondaryActionLabel: this.$t('ui.filters.unsavedKeep'),
          onConfirm: () => {
            this.showSheet = false
          }
        })
      } else {
        this.showSheet = false
      }
    },
    removeValue(parentKey, val) {
      const filter = this.filters.find(f => f.key === parentKey)
      const map = cloneDeep(this.appliedFilters)
      map[parentKey] = filter.multiple ? map[parentKey].filter(v => v !== val) : null
      this.emitAppliedFilters(map)
    },
    removeFilter(key) {
      const filter = this.filters.find(f => f.key === key)
      if (filter.required) {
        this.showRequiredMessage(filter)
        return
      }
      const map = cloneDeep(this.appliedFilters)
      map[key] = null
      this.editFilters[key] = null
      this.emitAppliedFilters(map)
    },
    emitAppliedFilters(filters) {
      /**
       * Emitted when the user applies new filters (DEPRECATED).
       *
       * @type {object} The filter data.
       * @deprecated
       */

      this.$emit('update', filters)
      /**
       * Emitted when the user applies new filters.
       *
       * @type {object} The filter data.
       */
      this.$emit('update:applied-filters', filters)
    },
    getFilterByKey(key) {
      return this.filters.find(f => f.key === key)
    },
    showRequiredMessage(filter) {
      this.$ui.alert({
        title: 'Filter required',
        content: `Filter "${filter.label}" is required.`
      })
    },
    updateQuery(value) {
      this.localQuery = value
      this.updateQueryDebounced(value)
    }
  }
}

const translations = {
  is: 'ui.filters.is',
  isOneOf: 'ui.filters.isOneOf'
}

function getFilterLabel(filter, value, $t) {
  const parts = getFilterTagParts(filter, value)
  return parts.map(part => {
    if (part.type === 'operator') {
      return $t(translations[part.value])
    }
    return part.value
  }).join(' ')
}

function getFilterTagParts(filter, value) {
  if (isEmptyFilterValue(value)) {
    return null
  }
  const {label, type} = filter
  if (type === 'select') {
    if (filter.multiple) {
      return [
        {type: 'label', value: label},
        {type: 'operator', value: 'isOneOf'},
        {
          type: 'value',
          value: value.map(val => {
            const option = filter.options
              .find(d => d.value === val)
            return option && option.label || '—'
          }).join(', ')
        }
      ]
    }
    const option = filter.options
      .find(d => d.value === value)
    return [
      {type: 'label', value: label},
      {type: 'operator', value: 'is'},
      {
        type: 'value',
        value: option && option.label || '—'
      }
    ]
  }
  if (type === 'date') {
    return [
      {type: 'label', value: label},
      {type: 'operator', value: 'is'},
      {
        type: 'value',
        value: format(value, 'dateNumeral')
      }
    ]
  }
  return [
    {type: 'label', value: label},
    {type: 'operator', value: 'is'},
    {type: 'value', value: value}
  ]
}
</script>

<style lang="scss" module>
.Filters {
  position: relative;
  padding: 16px;
  border-bottom: 1px solid #dfe3e7;
}

.Filters_disabled {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: rgba(255, 255, 255, 0.5);
}

.Filter_tags {
  // Needed by the old portal to fix 1 pixel off when a tag with a tooltip
  // is rendered next to one without a tooltip.
  display: flex;
  flex-wrap: wrap;

  margin-top: 8px;
  gap: 8px;

  // > * {
  // }
}

.Filters_sheet {
  background: #fff;
}

.Filters_filter {
  padding: 16px;
  padding-bottom: 20px;
}

.Filters_filter + .Filters_filter {
  border-top: 1px solid #dadce0;
}

.clearQuery {
  margin-right: -6px;
  padding: 0 3px;
  opacity: 0.8;
  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}
</style>
