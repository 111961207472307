<template>
  <Labelled
    :label="label"
    :error="errorMessage"
    :help-text="helpText"
    :help-text-html="helpTextHtml"
    :help-link="helpLink"
  >
    <Popper
      placement="bottom-start"
      ref="popper"
      auto-hide
      z-index="2005"
    >
      <template #trigger="{show, hide}">
        <div :class="$style.DatePicker_inputWrapper">
          <div
            v-if="clearable"
            :class="[
              $style.DatePicker_clearAction,
              (readonly || disabled) && $style.DatePicker_clearActionDisabled
            ]"
            @click="clearValue"
          >
            <Icon icon="fa fa-times-circle" />
          </div>
          <div
            v-if="!autoOpen"
            :class="[
              $style.DatePicker_openAction
            ]"
            @click="attemptShow(show, true)"
          >
            <Icon icon="far fa-calendar-day" />
          </div>
          <input
            :class="[
              $style.DatePicker_input,
              errorMessage && $style.DatePicker_input__hasError
            ]"
            :value="inputValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            @input="inputValue = $event.target.value"
            @change="setFromInput($event.target.value)"
            @click="attemptShow(show)"
            @focus="attemptShow(show)"
            @keydown.enter="setFromInput($event.target.value)"
            @keydown.tab="hide"
            type="text"
          />
        </div>
      </template>
      <div :class="$style.DatePicker_popper">
        <Calendar
          :type="type"
          :model-format="modelFormat"
          :value="value"
          :disable-date="disableDate"
          :min-time="minTime"
          :max-time="maxTime"
          @change="val => setFromCalendar(val)"
          @close="onClose"
        />
      </div>
    </Popper>
  </Labelled>
</template>

<script>
import {dayjs} from '../../utils/date'
import {Calendar} from '../Calendar'
import {Icon} from '../Icon'
import {Labelled} from '../Labelled'
import {Popper} from '../Popper'
import {getContextInjections} from '../../utils/vm'

export default {
  name: 'DatePicker',
  components: {
    Calendar,
    Icon,
    Labelled,
    Popper
  },
  inject: {
    ...getContextInjections()
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String
    },
    error: {
      type: String
    },
    /**
     * Help text to show below the control.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * The type of picker:
     *
     *  - datetime
     *  - date
     *  - month
     *  - year
     */
    type: {
      type: String,
      default: 'date'
    },
    /**
     * The model date value formatted according to `modelFormat`. Parts of the
     * date may be truncated depending on the `type` being used.
     */
    value: {
      type: String
    },
    /**
     * Format of the underling value.
     *
     * @see https://day.js.org/docs/en/display/format
     */
    modelFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss'
    },
    /**
     * Provide a filter function to disable specific dates. The function is
     * passed a Dayjs instance.
     */
    disableDate: {
      type: Function,
      default: null
    },
    /**
     * Restricts the minimum time that can be selected.
     */
    minTime: {
      type: String,
      default: '00:00'
    },
    /**
     * Restricts the maximum time that can be selected.
     */
    maxTime: {
      type: String,
      default: '23:59'
    },
    /**
     * Shows a button at the end of the date input which allows the value to be
     * cleared.
     */
    clearable: {
      type: Boolean,
      default: false
    },
    /**
    * If set to `false` will not automatically display the picker.
    */
    autoOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputValue: null
    }
  },
  created() {
    this.$watch('value', function(value) {
      if (value) {
        const formatted = dayjs(value, this.modelFormat, true)
          .format(this.displayFormat)
        this.inputValue = formatted
      } else {
        this.inputValue = value
      }
    }, {immediate: true})
  },
  computed: {
    displayFormat() {
      const type = this.type
      if (type === 'datetime') {
        return 'L LT'
      } else if (type === 'date') {
        return 'L'
      } else if (type === 'month') {
        return 'YYYY-MM'
      }
      return 'YYYY'
    },
    usageFormat() {
      return this.displayFormat
        .split(' ')
        .map(token => {
          try {
            // seems like this can error in dayjs
            return dayjs
              .localeData()
              .longDateFormat(token)
          } catch {
            return token
          }
        })
        .join(' ')
    },
    errorMessage() {
      const {inputValue, displayFormat} = this
      if (inputValue) {
        const mDate = dayjs(inputValue, displayFormat, true)
        if (!mDate.isValid()) {
          return `Invalid date format, please use ${this.usageFormat}`
        }
      }
      return this.error
    }
  },
  methods: {
    setFromInput(text) {
      const mDate = dayjs(text, this.displayFormat, true)
      if (mDate.isValid()) {
        const value = mDate.format(this.modelFormat)
        /**
         * Emitted when a value is selected.
         *
         * @event change
         * @property {string} value
         */
        this.$emit('change', value)
      } else {
        this.$emit('change', null)
      }
      this.$refs.popper.hide()
    },
    setFromCalendar(date) {
      this.$emit('change', date)
      if (this.type !== 'datetime') {
        this.$refs.popper.hide()
      }
    },
    clearValue() {
      if (!this.readonly && !this.disabled) {
        this.$emit('change', null)
      }
    },
    attemptShow(showFn, force = false) {
      if (!this.disabled && !this.readonly && (force || this.autoOpen)) {
        showFn()
      }
    },
    onClose() {
      this.$refs.popper.hide()
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";
@import "../../styles/mixins";

.DatePicker_inputWrapper {
  position: relative;
}

.DatePicker_clearAction {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $inputMinHeight;
  height: $inputMinHeight;
  color: #666;

  &:hover {
    cursor: pointer;
    color: #000;
  }
  &.DatePicker_clearActionDisabled {
    pointer-events: none !important;
  }
}

.DatePicker_openAction {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $inputMinHeight;
  height: $inputMinHeight;
  color: #666;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  .DatePicker_clearAction + & {
    right: 25px;
  }
}

.DatePicker_input {
  &:read-only {
    @include inputBaseReadonly;
  }
  &:disabled {
    @include inputBaseDisabled;
  }
  @include input;
}

.DatePicker_input__hasError {
  @include inputBaseError;

  &:focus {
    @include inputBaseErrorFocus;
  }
}

.DatePicker_popper {
  margin: 8px 0;
  padding: 16px;
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>
