<template>
  <Labelled
    :label="label"
    :error="errorMessage"
    :help-text="helpText"
    :help-text-html="helpTextHtml"
    :help-link="helpLink"
  >
    <Popper
      placement="bottom-start"
      ref="popper"
      auto-hide
      z-index="2005"
    >
      <template #trigger="{show, hide}">
        <div :class="$style.TimePicker_inputWrapper">
          <div
            v-if="clearable"
            :class="$style.TimePicker_clearAction"
            @click="clearValue"
          >
            <Icon icon="fa fa-times-circle" />
          </div>
          <div
            v-if="!autoOpen"
            :class="[
              $style.TimePicker_openAction
            ]"
            @click="attemptShow(show, true)"
          >
            <Icon icon="far fa-clock" />
          </div>
          <input
            :class="[
              $style.TimePicker_input,
              errorMessage && $style.TimePicker_input__hasError
            ]"
            :value="inputValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :readonly="readonly"
            @input="inputValue = $event.target.value"
            @change="setFromInput($event.target.value)"
            @click="attemptShow(show)"
            @focus="attemptShow(show)"
            @keydown.enter="setFromInput($event.target.value)"
            @keydown.tab="hide"
            type="text"
          />
        </div>
      </template>
      <div :class="$style.TimePicker_popper">
        <select
          :class="$style.TimeSelect"
          :value="selectedHour"
          @change="selectTime($event, 'hour')"
        >
          <option
            v-for="hour in hours"
            :key="hour.value"
            :value="hour.value"
            :disabled="hour.disabled"
          >{{hour.label}}</option>
        </select>
        <select
          :class="$style.TimeSelect"
          :value="selectedMinute"
          @change="selectTime($event, 'minute')"
        >
          <option
            v-for="minute in minutes"
            :key="minute.value"
            :value="minute.value"
            :disabled="minute.disabled"
          >{{minute.label}}</option>
        </select>
        <button
          :class="$style.ConfirmButton"
          @click="close"
        >{{$t('ui.modal.close')}}</button>
      </div>
    </Popper>
  </Labelled>
</template>

<script>
import {dayjs} from '../../utils/date'
import {Icon} from '../Icon'
import {Labelled} from '../Labelled'
import {Popper} from '../Popper'
import {getContextInjections} from '../../utils/vm'
import {pad} from '../../utils'

/**
 * **TimePicker** is similar to [DatePicker](/#/Components/DatePicker), except
 * that it is more lightweight and only allows selecting time values. If you
 * need to work with both dates and times, use DatePicker.
 */
export default {
  name: 'TimePicker',
  components: {
    Icon,
    Labelled,
    Popper
  },
  inject: {
    ...getContextInjections()
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String
    },
    error: {
      type: String
    },
    /**
     * Help text to show below the control.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * The model date value formatted according to `modelFormat`. Parts of the
     * date may be truncated depending on the `type` being used.
     */
    value: {
      type: String
    },
    /**
     * Format of the underling value.
     *
     * @see https://day.js.org/docs/en/display/format
     */
    modelFormat: {
      type: String,
      default: 'HH:mm:ss'
    },
    /**
     * Restricts the minimum time that can be selected.
     */
    minTime: {
      type: String,
      default: '00:00'
    },
    /**
     * Restricts the maximum time that can be selected.
     */
    maxTime: {
      type: String,
      default: '23:59'
    },
    /**
     * Shows a button at the end of the date input which allows the value to be
     * cleared.
     */
    clearable: {
      type: Boolean,
      default: false
    },
    /**
    * If set to `false` will not automatically display the picker.
    */
    autoOpen: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      inputValue: null,
      selectedHour: 0,
      selectedMinute: 0
    }
  },
  created() {
    this.$watch('value', function(value) {
      if (value) {
        const mDate = dayjs(value, this.modelFormat, true)
        const formatted = mDate.format(this.displayFormat)
        this.inputValue = formatted
        this.selectedHour = mDate.hour()
        this.selectedMinute = mDate.minute()
      } else {
        this.inputValue = value
      }
    }, {immediate: true})
  },
  computed: {
    displayFormat() {
      return 'LT'
    },
    usageFormat() {
      return this.displayFormat
        .split(' ')
        .map(token => {
          try {
            // seems like this can error in dayjs
            return dayjs
              .localeData()
              .longDateFormat(token)
          } catch {
            return token
          }
        })
        .join(' ')
    },
    errorMessage() {
      const {inputValue, displayFormat} = this
      if (inputValue) {
        const mDate = dayjs(inputValue, displayFormat, true)
        if (!mDate.isValid()) {
          return `Invalid time format, please use ${this.usageFormat}`
        }
        const {minTime, maxTime} = this
        const modelValue = mDate.format('HH:mm:ss')
        if (minTime && modelValue < minTime) {
          return `Must be on or after ${minTime}`
        }
        if (maxTime && modelValue > maxTime) {
          return `Must be on or before ${maxTime}`
        }
      }
      return this.error
    },
    hours() {
      const {minTime, maxTime} = this
      const minParts = minTime.split(':')
      const maxParts = maxTime.split(':')
      const hours = []
      for (let i = 0; i < 24; i++) {
        const label = pad(i)
        hours.push({
          label,
          value: i,
          disabled: label < minParts[0] || label > maxParts[0]
        })
      }
      return hours
    },
    minutes() {
      const {minTime, maxTime, selectedHour} = this
      const minutes = []
      for (let i = 0; i < 60; i++) {
        const label = pad(i)
        const compare = pad(selectedHour) + ':' + label
        minutes.push({
          label,
          value: i,
          disabled: compare < minTime || compare > maxTime
        })
      }
      return minutes
    }
  },
  methods: {
    setFromInput(text) {
      const mDate = dayjs(text, this.displayFormat, true)
      if (mDate.isValid()) {
        const value = mDate.format(this.modelFormat)
        /**
         * Emitted when a value is selected.
         *
         * @event change
         * @property {string} value
         */
        this.$emit('change', value)
      } else {
        this.$emit('change', null)
      }
      this.$refs.popper.hide()
    },
    setFromPicker() {
      const time = pad(this.selectedHour) + ':' + pad(this.selectedMinute)
      const mDate = dayjs(time, 'HH:mm')
      const value = mDate.format(this.modelFormat)
      this.$emit('change', value)
    },
    setFromCalendar(date) {
      this.$emit('change', date)
      this.$refs.popper.hide()
    },
    clearValue() {
      this.$emit('change', null)
    },
    attemptShow(showFn, force = false) {
      if (!this.disabled && !this.readonly && (force || this.autoOpen)) {
        showFn()
      }
    },
    onClose() {
      this.$refs.popper.hide()
    },
    selectTime(event, unit) {
      const number = parseInt(event.target.value, 10)
      if (unit === 'hour') {
        this.selectedHour = number
      } else {
        this.selectedMinute = number
      }
      this.setFromPicker()
    },
    close() {
      this.$refs.popper.hide()
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";
@import "../../styles/mixins";

$hoverBgColor: #ebecf0;

.TimePicker_inputWrapper {
  position: relative;
}

.TimePicker_clearAction {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $inputMinHeight;
  height: $inputMinHeight;
  color: #666;

  &:hover {
    cursor: pointer;
    color: #000;
  }
}

.TimePicker_openAction {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $inputMinHeight;
  height: $inputMinHeight;
  color: #666;

  &:hover {
    cursor: pointer;
    color: #000;
  }

  .TimePicker_clearAction + & {
    right: 25px;
  }
}

.TimePicker_input {
  &:read-only {
    @include inputBaseReadonly;
  }
  &:disabled {
    @include inputBaseDisabled;
  }
  @include input;
}

.TimePicker_input__hasError {
  @include inputBaseError;

  &:focus {
    @include inputBaseErrorFocus;
  }
}

.TimePicker_popper {
  display: flex;
  margin: 8px 0;
  padding: 16px;
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.TimeSelect {
  min-width: 50px;
  margin-right: 8px;
  padding: 3px 10px;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  background: $hoverBgColor;
}

.ConfirmButton {
  margin-left: auto;
  background: #eee;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 16px;

  &:hover {
    background: #ddd;
    cursor: pointer;
  }
}
</style>
