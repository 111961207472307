var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.AccountNav},[_c('SimpleMenu',{attrs:{"align":"right","sections":_vm.sections,"menu-props":{
      style: {
        minWidth: '240px',
        paddingTop: 0
      }
    }},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{class:_vm.$style.MenuHeader},[_c('Icon',{class:_vm.$style.User_icon,attrs:{"icon":"fa fa-user-circle"}}),_c('div',{class:_vm.$style.User},[_c('div',{class:_vm.$style.User_name},[_vm._v(_vm._s(_vm.userName))]),_c('div',{class:_vm.$style.User_email},[_vm._v(_vm._s(_vm.userEmail))])])],1)]},proxy:true}])},[_c('div',{class:_vm.$style.Button,attrs:{"disabled":!_vm.sections.length}},[(_vm.avatarUrl)?_c('div',{class:_vm.$style.Avatar,style:({
          backgroundImage: _vm.avatarUrl ? 'url(' + _vm.avatarUrl + ')' : undefined
        })}):_vm._e(),_c('div',{class:_vm.$style.Title},[_c('div',{class:_vm.$style.Title_account},[_vm._v(_vm._s(_vm.accountName))])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }