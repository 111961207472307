<template>
  <div
    :class="[
      $style.SaveBar,
      state === 'saving' && $style.saving,
      layoutMode === 'alternate' && $style.SaveBar__alternate
    ]"
  >
    <div :class="$style.Content">
      <div :class="$style.Title">
        {{i18n.unsavedChanges}}
      </div>
      <div :class="$style.Actions">
        <button
          :class="[
            $style.Action,
            $style.Action__discard
          ]"
          :disabled="state === 'saving'"
          @click="discardChanges"
        >{{i18n.discard}}</button>
        <button
          :class="[
            $style.Action,
            $style.Action__save
          ]"
          :disabled="state === 'saving'"
          @click="saveChanges"
        >
          <span :class="$style.Action_content">{{i18n.save}}</span>
          <Icon
            v-if="state === 'saving'"
            :class="$style.Action_spinner"
            icon="fa fa-spinner-third"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {Icon} from '../../Icon'
import saveBarStore, {validateState} from '../../../api/saveBar'

const defaultTranslations = {
  save: 'Save',
  discard: 'Discard',
  unsavedChanges: 'Unsaved Changes',
  discardModal: {
    title: 'Discard all unsaved changes?',
    content: 'If you discard changes, you\'ll delete any edits you made since you last saved.',
    primaryActionLabel: 'Discard changes',
    secondaryActionLabel: 'Continue editing'
  }
}

export default {
  components: {
    Icon
  },
  props: {
    state: {
      type: String,
      default: 'unchanged',
      validator: validateState
    },
    translations: {
      type: Object,
      required: true
    },
    layoutMode: {
      type:String,
      default:'default'
    }
  },
  computed: {
    i18n() {
      return {
        ...defaultTranslations,
        ...this.translations
      }
    }
  },
  methods: {
    saveChanges() {
      this.$emit('save')
      saveBarStore.save()
    },
    discardChanges() {
      if (this.$ui && this.$ui.confirm) {
        this.$ui.confirm({
          ...this.i18n.discardModal,
          primaryActionType: 'destructive',
          onConfirm: () => {
            this.$emit('discard')
            saveBarStore.discard()
          }
        })
      } else {
        if (window.confirm(this.i18n.discardModal.title)) {
          this.$emit('discard')
          saveBarStore.discard()
        }
      }
    }
  }
}
</script>

<style lang="scss" module>
.SaveBar {
  --textColor: #fff;
  --bgColor: #1b73e8;
  --hoverBgColor: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--ui-globalNav-height);
  background: var(--bgColor);
  color: var(--textColor);
}
.SaveBar__alternate {
  .Content {
    margin-left: 0px;
  }
}
.Content {
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--ui-sideNav-width);
  max-width: var(--ui-page-currentMaxWidth);
  padding: 0 var(--ui-page-xPadding);
}

.Title {
  font-weight: 500;
  font-size: 16px;
}
.Actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.Action {
  position: relative;
  appearance: none;
  padding: 6px 16px;
  border: none;
  border-radius: 3px;
  & + & {
    margin-left: 8px;
  }
  &:hover:not(:disabled) {
    cursor: pointer;
  }
  &:disabled {
    opacity: 0.5;
  }
}
.Action_content {
  .saving & {
    opacity: 0;
  }
}

.Action_spinner {
  position: absolute;
  left: 50%;
  animation: spinner 0.5s infinite linear;
}
@keyframes spinner {
  from {
    transform: translate(-50%, 0) rotate(0deg);
  }
  to {
    transform: translate(-50%, 0) rotate(360deg);
  }
}

.Action__save {
  background: var(--textColor);
  color: var(--bgColor);
  &:hover:not(:disabled) {
    opacity: 0.85;
  }
}
.Action__discard {
  background: transparent;
  color: var(--textColor);
  &:hover:not(:disabled) {
    background: var(--hoverBgColor);
  }
}

.SaveBarFade_enterActive,
.SaveBarFade_leaveActive {
  transition: opacity 0.3s ease;
}
.SaveBarFade_enter,
.SaveBarFade_leaveTo {
  opacity: 0;
}

</style>