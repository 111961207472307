<script>
import {mergeData} from 'vue-functional-data-merge'
import {capitalize} from '../../utils'
import {Tooltip} from '../Tooltip'

/**
 * Badges are used to inform users of the status of an object or an action
 * that's been taken.
 */
export default {
  props: {
    /**
     * - `info`
     * - `success`
     * - `attention`
     * - `warning`
     * - `new`
     */
    status: {
      type: String
    },
    /**
     * - `incomplete`
     * - `partiallyComplete`
     * - `complete`
     */
    progress: {
      type: String
    },
    /**
     * - `small`
     * - `medium`
     */
    size: {
      type: String,
      default: 'medium'
    },
    /**
     * An optional tooltip to display when the user hovers over the badge.
     */
    tooltip: {
      type: String
    },
    /**
     * An optional radius value for the badge edges
     */
    radius: {
      type: String,
      default: '20px'
    }
  },
  functional: true,
  render(h, {props, data, slots, $style}) {
    const {status, progress, size, radius} = props
    const classes = ['UIElement', $style.Badge]
    const children = [slots().default]
    if (status) {
      classes.push($style['status' + capitalize(status)])
    }
    if (progress) {
      classes.push($style['progress' + capitalize(progress)])
      children.unshift(h('span', {
        class: $style.pip
      }))
    }
    if (size) {
      classes.push($style['size' + capitalize(size)])
    }
    const content = h('span', mergeData(data, {
      class: classes,
      style: [
        `border-radius: ${radius};`
      ]
    }), children)
    if (props.tooltip) {
      return h(Tooltip, {
        props: {
          content: props.tooltip
        }
      }, [content])
    }
    return content
  }
}
</script>

<style lang="scss" module>
.Badge {
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  background-color: #dfe3e8;
  border: 2px solid #fff;
  // border-radius: 20px;
  font-size: 13px;
  line-height: 20px;
  color: #454f5b;
  cursor: default;
  .pip {
    color: #919eab;
  }
}

.pip {
  height: 10px;
  width: 10px;
  margin: 0 4px 0 -3px;
  border: 2px solid currentColor;
  border-radius: 50%;
}

.statusInfo {
  background-color: #b4e1fa;
  color: #3e4e57;
  .pip {
    color: #084e8a;
  }
}
.statusSuccess {
  background-color: #bbe5b3;
  color: #414f3e;
  .pip {
    color: #108043;
  }
}
.statusAttention {
  background-color: #ffea8a;
  color: #595130;
  .pip {
    color: #8a6116;
  }
}
.statusWarning {
  background-color: #ffc58b;
  color: #594430;
  .pip {
    color: #c05717;
  }
}
.statusNew {
  background-color: #dfe3e8;
  color: #212b36;
  font-weight: 500;
  border: none;
  .pip {
    color: #c05717;
  }
}

.progressIncomplete {
  .pip {
    background: transparent;
  }
}
.progressPartiallyComplete {
  .pip {
    background: linear-gradient(0deg, currentColor, currentColor 50%, transparent 0, transparent);
  }
}
.progressComplete {
  .pip {
    background: currentColor;
  }
}

.sizeSmall {
  font-size: 12px;
  line-height: 18px;
}
</style>
