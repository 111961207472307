<template>
  <SimpleMenu
    :items="items"
    @click-item="onClickItem"
  >
    <div :class="$style.Button">
      <Icon icon="fa fa-globe-americas" />
    </div>
    <template #item-label="{item}">
      <div :class="$style.Item_label">
        <img
          v-if="flagUrl(item.value)"
          :src="flagUrl(item.value)"
          height="18"
        >
        {{item.label}}
      </div>
    </template>
  </SimpleMenu>
</template>

<script>
import {Icon} from '@/components/Icon'
import SimpleMenu from './SimpleMenu'

export default {
  components: {
    Icon,
    SimpleMenu
  },
  props: {
    value: {
      type: String
    },
    languages: {
      type: Array
    }
  },
  computed: {
    items() {
      return this.languages.map(item => {
        return {
          label: item.label,
          value: item.value,
          icon: this.value === item.value ? 'fa fa-check' : ''
        }
      })
    }
  },
  methods: {
    onClickItem(item) {
      this.$emit('change', item.value)
    },
    flagUrl(code) {
      if (code) {
        return `https://static.fanplayr.com/portal/assets/img/flags/${code.toUpperCase()}.svg`
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '../common.module.scss';
.Button {
  @include navButtonBase;
  padding: 4px 8px;
  font-size: 16px;
  // filter: saturate(0);

  // &:hover, &.isOpen {
  //   filter: saturate(1);
  // }
}

.Item_label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  img {
    margin-right: 8px;
  }
}
</style>
