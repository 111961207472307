import {destroyManualVm} from '../../utils'
import Modal from './Modal'
import GenericModal from './api/GenericModal'
import AlertModal from './api/AlertModal'
import ConfirmModal from './api/ConfirmModal'
import PromptModal from './api/PromptModal'

const install = (Vue) => {
  function createApi(component) {
    return function(opts) {
      return new Promise(resolve => {
        const vm = new Vue({
          data: {
            visible: false
          },
          render(h) {
            return h(component, {
              props: {
                opts,
                visible: this.visible
              },
              on: {
                action: (result) => {
                  if (opts.onAction) {
                    opts.onAction(result)
                  }
                  resolve(result)
                  this.visible = false
                },
                afterLeave: () => {
                  destroyManualVm(this)
                }
              }
            })
          }
        }).$mount()
        document.body.appendChild(vm.$el)
        setTimeout(() => vm.visible = true)
      })
    }
  }

  return {
    modal: createApi(GenericModal),
    alert: createApi(AlertModal),
    confirm: createApi(ConfirmModal),
    prompt: createApi(PromptModal)
  }
}

export {
  install,
  Modal
}
