<script>
import {mergeData} from 'vue-functional-data-merge'
import {Icon} from '../Icon'
import {TextStyle} from '../TextStyle'
import {UnstyledLink} from '../UnstyledLink'

export default {
  props: {
    label: {
      type: String
    },
    helpText: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Tooltip to show when hovering the button.
     */
    tooltip: {
      type: String
    },
    /**
     * Tooltip position, "top", "bottom", "right" or "left".
     */
     tooltipPosition: {
      type: String,
      default: 'bottom'
    },
    dropdown: {
      type: Boolean,
      default: false
    },
    url: {
      type: String
    },
    external: {
      type: Boolean
    },
    icon: {
      type: String
    }
  },
  functional: true,
  render(h, {data, props, $style}) {
    const contentMarkup = props.helpText
      ? (
        h('div', [
          h('div', props.label),
          h(
            TextStyle,
            {
              props: {
                type: 'subdued'
              }
            },
            props.helpText
          )
        ])
      )
      : props.label

    const icon =
      props.icon &&
      h(Icon, {
        props: {
          icon: props.icon
        },
        style: 'margin-right: 10px'
      })

    const iconMarkup =
      props.dropdown &&
      h(Icon, {
        props: {
          icon: 'fa fa-caret-right'
        },
        style: 'margin-left: 10px'
      })

    const element = props.url && !props.disabled ? UnstyledLink : 'button'
    return h(
      element,
      mergeData(data, {
        class: $style.ActionListButton,
        domProps: {
          href: props.url,
          target: props.external ? '_blank' : undefined,
          disabled: props.disabled
        },
        attrs: {
          'data-ui-tooltip': props.tooltip,
          'data-ui-tooltip-position': props.tooltipPosition
        }
      }),
      [icon, contentMarkup, iconMarkup]
    )
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

.ActionListButton {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  padding: 10px 16px;
  background: #fff;
  border: 0;
  font-size: 14px;
  text-align: left;
  text-decoration: none;

  &:not(:disabled) {
    &:hover {
      background: #f5f6f8;
      cursor: pointer;
    }
    &:active {
      background: #e5f0ff;
    }
    &:focus {
      outline: 0;
    }
  }

  &:disabled {
    color: $buttonDisabledTextColor;
  }
}
</style>
