import {mergeData} from 'vue-functional-data-merge'

export {mergeData}

export function mergeDataFixListeners() {
  const data = mergeData(...arguments)
  removeEmptyListeners(data.on)
  removeEmptyListeners(data.nativeOn)
  return data
}

function removeEmptyListeners(listeners) {
  if (listeners) {
    const events = Object.keys(listeners)
    for (const event of events) {
      const fnOrArray = listeners[event]
      if (!fnOrArray) {
        delete listeners[event]
      } else if (Array.isArray(fnOrArray)) {
        for (let i = fnOrArray.length - 1; i > 0; i--) {
          if (!fnOrArray[i]) {
            fnOrArray.splice(i, 1)
          }
        }
      }
    }
  }
}

/**
 * Merges VNodeData together for functional components, only using the class and
 * style data from the render function.
 * @param {VNodeData} data Render function data. Only class and style data is
 * used.
 * @param  {...any} vNodeData Other data to merge.
 * @returns {VNodeData}
 */
export function mergeDataClassStyle(data, ...vNodeData) {
  return mergeData({
    style: data.style,
    staticStyle: data.staticStyle,
    staticClass: data.staticClass,
    class: data.class
  }, ...vNodeData)
}
