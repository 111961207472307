import {load} from '../../utils/loader'

async function loadComponent() {
  await load(['https://cdn.fanplayr.com/apps/ruleset-editor/loader.js'])
  const loader = window.RulesetEditorLoader
  const module = await loader.load()
  return module.Editor
}

function RulesetEditor() {
  return {
    component: loadComponent(),
    loading: {
      render(h) {
        return h('div', `${this.$t('Loading')} ...`)
      }
    }
  }
}

export {RulesetEditor}
