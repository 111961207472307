<template>
  <div
    :class="$style.FilterItem"
  >
    <TextField
      v-if="filter.type === 'text'"
      :label="filter.label"
      :placeholder="filter.placeholder"
      :error="error"
      :value="value"
      @input="val => $emit('update', val)"
    />
    <DatePicker
      v-if="isDatePickerType(filter.type)"
      :type="filter.type"
      :label="filter.label"
      :placeholder="filter.placeholder"
      :error="error"
      :model-format="filter.modelFormat || 'YYYY-MM-DD'"
      :value="value"
      :end-of-day="filter.endOfDay"
      @change="val => $emit('update', val)"
    />
    <Select
      v-if="filter.type === 'select'"
      :label="filter.label"
      :placeholder="filter.placeholder"
      :error="error"
      :options="filter.options"
      :option-groups="filter.optionGroups"
      :multiple="filter.multiple"
      :tags="filter.multiple"
      :value="value"
      @change="val => $emit('update', val)"
    />
    <Select
      v-if="filter.type === 'boolean'"
      :label="filter.label"
      :placeholder="filter.placeholder"
      :error="error"
      :options="[
        { label: 'True', value: true },
        { label: 'False', value: false },
      ]"
      :value="value"
      @change="val => $emit('update', val)"
    />
    <div
      v-if="filter.required"
      :class="$style.FilterItem_required"
    >{{$t('ui.filters.required')}}</div>
    <Button
      v-else
      type="plain"
      @click="$emit('update', null)"
      :disabled="hasEmptyValue"
    >{{$t('ui.filters.clear')}}</Button>
  </div>
</template>

<script>
import {Button} from '../Button'
import {DatePicker, isDatePickerType} from '../DatePicker'
import {TextField} from '../TextField'
import {Select} from '../Select'
import {isEmptyFilterValue} from './utils'

export default {
  name: 'FilterItem',
  components: {
    Button,
    DatePicker,
    TextField,
    Select
  },
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    filter: {
      type: Object
    },
    value: {
      type: null
    }
  },
  computed: {
    hasEmptyValue() {
      return isEmptyFilterValue(this.value)
    },
    error() {
      if (this.filter.required && this.hasEmptyValue) {
        return this.$t('ui.filters.requiredError')
      }
      return null
    }
  },
  methods: {
    isDatePickerType
  }
}
</script>

<style lang="scss" module>
.FilterItem {
  padding: 16px;
  padding-bottom: 20px;
}

.FilterItem + .FilterItem {
  border-top: 1px solid #dadce0;
}

.FilterItem_required {
  margin-top: 4px;
  color: #666;
}
</style>
