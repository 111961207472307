<script>
import {mergeData} from 'vue-functional-data-merge'

export default {
  props: {
    element: {
      type: String,
      default: 'h3'
    }
  },
  functional: true,
  render(h, {data, props, children, $style}) {
    return h(props.element, mergeData(data, {
      class: [
        'UIElement',
        $style.Heading
      ]
    }), children)
  }
}
</script>

<style lang="scss" module>
.Heading {
  margin: 0;
  color: #212b36;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;

  @media (min-width: 40em) {
    font-size: 16px;
  }
}
</style>
