<template>
  <div>
    <div :class="$style.Button">
      <Icon
        icon="fas fa-list"
      />
      <select
        :class="$style.Select"
        :value="value"
        @change="onChange"
      >
        <option
          v-for="account in accounts"
          :key="account.id"
          :value="account.id"
        >{{account.name}}</option>
      </select>
    </div>
  </div>
</template>

<script>
import {Icon} from '@/components/Icon'

export default {
  components: {
    Icon
  },
  props: {
    accounts: {
      type: Array,
      required: true
    },
    value: {
      type: Number
    },
    i18n: {
      type: Object
    }
  },
  methods: {
    onChange(event) {
      const id = parseInt(event.target.value, 10)
      const account = this.accounts.find(d => d.id === id)
      this.$emit('change', account)
    }
  }
}
</script>

<style lang="scss" module>
@import '../common.module.scss';
.Button {
  @include navButtonBase;
}

.Select {
  position: absolute;
  width: calc(var(--ui-globalNav-height) - 4px);
  height: calc(var(--ui-globalNav-height) - 4px);
  opacity: 0;
  cursor: pointer;
  appearance: none;
}
</style>
