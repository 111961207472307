<script>
import saveBarStore from "../../api/saveBar"

export default {
  model: {
    prop: 'state',
    event: 'update:state'
  },
  props: {
    state: {
      type: String,
      default: 'unchanged',
      validator: function(value) {
        return ['unchanged', 'changed', 'saving'].indexOf(value) !== -1
      }
    }
  },
  render() {
    return undefined
  },
  created() {
    saveBarStore.addEventListener(this.onSaveBar)
    this.$watch('state', value => {
      saveBarStore.setState(value)
    }, {immediate: true})
  },
  beforeDestroy() {
    saveBarStore.removeEventListener(this.onSaveBar)
  },
  methods: {
    onSaveBar(event) {
      if (event.detail.type === 'save') {
        this.$emit('save')
        this.$emit('update', true)
      } else if (event.detail.type === 'discard') {
        this.$emit('discard')
        this.$emit('update', false)
      } else if (event.detail.type === 'stateChange') {
        this.$emit('update:state', event.detail.state)
      }
    }
  }
}
</script>
