<template>
  <div :class="$style.ProgressBar">
    <div :class="$style.Track">
      <div :class="$style.InnerTrack"></div>
      <div
        :class="$style.Bar"
        :style="'width: ' + percent + '%'"
      ></div>
    </div>
  </div>
</template>

<script>
import {clamp} from '../../utils'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    percent() {
      return clamp(this.value, 0, 100)
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

$trackColor: #aaa;
$barColor: $focusColor;

.Track {
  height: 8px;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
}

.InnerTrack {
  position: absolute;
  top: 1px;
  width: 100%;
  height: 6px;
  background: $trackColor;
  border-radius: 3px;
}

.Bar {
  position: relative;
  z-index: 1;
  height: 8px;
  background: $barColor;
  transition: width .2s ease;
}
</style>
