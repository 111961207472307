import {
  popperGenerator,
  defaultModifiers
} from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'
import offset from '@popperjs/core/lib/modifiers/offset'

const createPopper = popperGenerator({
  defaultModifiers: [
    ...defaultModifiers,
    flip,
    preventOverflow,
    offset
  ]
})

export default createPopper
