<template>
  <div
    :class="[
      'UIElement',
      $style.AppFrame,
      sidebarMin && $style.Min,
      $slots.sidebar && $style.HasSidebar,
      layoutMode === 'alternate' && $style.Dark,
    ]"
    data-app-frame
  >
    <ScrollPane
      v-if="$slots.sidebar"
      :class="[
        $style.SideBar,
        layoutMode === 'alternate' && $style.Dark,
        layoutMode === 'alternate' && $style.Alt,
        sidebarMin && $style.Min,
      ]"
      style="--ScrollPane-indicatorAlpha: 0.2"
    >
      <slot name="sidebar"></slot>
    </ScrollPane>

    <div
      :class="$style.GlobalNav"
      data-app-frame-global-nav
    >
      <slot name="topbar"></slot>
    </div>
    <div :class="$style.Content">
      <div
        v-if="loading || showLoader"
        :class="$style.Loader"
      >
        <div :class="$style.Loader_content">
          <Spinner
            :class="$style.Loader_spinner"
            color="blue"
            size="small"
          />
          <div :class="$style.Loader_text">{{loaderMessage}}</div>
        </div>
      </div>
      <ScrollPane
        :class="$style.Main"
        style="--ScrollPane-indicatorAlpha: 0.2"
        :container-style="{
          display: 'flex'
        }"
      >
        <div :class="$style.Main_content">
          <slot></slot>
        </div>
        <div
          v-if="$slots.footer"
          :class="$style.Footer"
        >
          <slot name="footer"></slot>
        </div>
      </ScrollPane>
    </div>
  </div>
</template>

<script>
import {ScrollPane} from '@/components/ScrollPane'
import {Spinner} from '@/components/Spinner'
import loaderApi from '../../api/loader'

export default {
  components: {
    ScrollPane,
    Spinner
  },
  props: {
    /**
     * Shows a loader over the entire app frame.
     */
    loading: {
      type: Boolean
    },
    /**
     * 'default': SideBar below GlobalNav
     * 'alternate': Sidebar next to GlobalNav
     */
    layoutMode: {
      type:String,
      default:'default'
    },
    sidebarMin: {
      type: Boolean,
      default: false
    },
    /**
     * If set to `pink` all primary action buttons will be pink. Used for CDP.
     */
    theme: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      showLoader: false,
      loaderMessage: ''
    }
  },
  created() {
    loaderApi.addEventListener(this.onUiLoader)
  },
  mounted() {
    this.$watch('theme', (theme) => {
      if (theme === 'pink') {
        document.body.classList.add('UIThemePink')
      } else {
        document.body.classList.remove('UIThemePink')
      }
    }, {immediate: true})
  },
  beforeDestroy() {
    loaderApi.removeEventListener(this.onUiLoader)
  },
  methods: {
    onUiLoader(event) {
      this.showLoader = event.subType === 'show'
      this.loaderMessage = event.message || 'Loading..'
    }
  }
}
</script>

<style lang="scss" module>
@import '@/styles/variables';
@import '@/styles/mixins';

$navHeight: 55px;

.AppFrame {
  --ui-page-xPadding: var(--ui-appFrame-pageXPadding);

  display: grid;
  grid-template-columns: 0fr 0fr 1fr;
  grid-template-rows: 40px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  overflow: hidden;
  background: #f4f6f8;

  &.HasSidebar {
    --ui-sideNav-width: 240px;
  }

  &.Min {
    --ui-sideNav-width: 64px;
  }
  &.Dark {
    background: #EDEDED;
  }
}

.AppFrame__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.GlobalNav {
  grid-area: 1 / 2 / 2 / 4;
  position: relative;
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
}

.Content {
  grid-area: 2 / 3 / 3 / 4;
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.Loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  background: rgba(255, 255, 255, 0.8);
  // background: rgba(0, 0, 0, 0.2);
}
.Loader_content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  padding: 20px 40px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  background: #232e3e;
  color: #fff;
}
.Loader_text {
  margin-left: 10px;
  font-size: 20px;
}

.SideBar {
  grid-area: 2 / 2 / 3 / 3;

  border-right: 1px solid #dfe3e8;
  overflow-x: hidden;
  overflow-y: auto;
  width: var(--ui-sideNav-width);
  transition: width 0.2s ease-in-out;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  &.Alt {
    grid-area: 1 / 1 / 3 / 2;
  }

  &.Dark {
    background: #28235F;
    grid-area: 1 / 1 / 3 / 2;
    ::-webkit-scrollbar-track {
      background: #28235F;
    }
    ::-webkit-scrollbar-thumb {
      background: #514D7D;
      border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #514D7D;
    }
  }
  &.Min {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-thumb:hover {
      border-right: none;
    }
  }
}

.Main {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
.Main_content {
  flex: 1;
  max-width: calc(100vw - var(--ui-sideNav-width));
}

.Footer {
  // overflow: hidden;
}
</style>
