<template>
  <MaybeRouterLink
    :class="$style.ItemContainer"
    :to="item.url"
    :match-routes="!location"
    custom
  >
    <template #default="{href, isActive, isExactActive}">
      <div
        :class="[
          'UIElement',
          $style.ItemContainer,
          isSubItem && $style.isSubItem,
          hasChildren && $style.hasChildren,
          (isActive || simpleMatch.isActive) && $style.isActive,
          (isExactActive || simpleMatch.isExactActive) && $style.isExactActive,
          min && $style.min
        ]"
      >
        <Tooltip
          v-if="min && (!item.items || !item.items.length)"
          :content="item.label"
          placement="right"
        >
          <a
            :class="[
              $style.Item,
              theme === 'dark' && $style.Dark
            ]"
            :href="href"
            @click="event => $emit('click', item, event)"
          >
            <Icon
              v-if="hasPrimaryIcons && item.icon"
              :icon="'fa-fw ' + item.icon"
              :class="[
                $style.Item_icon,
                $style.Item_icon_min
              ]"
            />
          </a>
        </Tooltip>

        <a
          v-else-if="!min"
          :class="[
            $style.Item,
            theme === 'dark' && $style.Dark
          ]"
          :href="href"
          @click="event => $emit('click', item, event)"
        >
          <Icon
            v-if="hasPrimaryIcons && item.icon"
            :icon="'fa-fw ' + item.icon"
            :class="[
              $style.Item_icon,
              $style.Item_icon_max
            ]"
          />
          <span :class="$style.Item_label">{{item.label}}</span>
          <span
            v-if="item.badge"
            :class="[
              $style.Item_badge,
              'dark' && $style.Item_badge_dark,
            ]"
          >{{item.badge}}</span>
        </a>

        <Popper
          v-else-if="min && item.items && item.items.length"
          :zIndex="10000"
          placement="right"
        >
          <template #trigger="{show, hide}">
            <a
              @click="event => $emit('click', item, event)"
              :href="href"
              @mouseenter="mouseEnterIcon(show)"
              @mouseleave="mouseLeaveIcon(hide)"
              :class="[
                $style.Item,
                theme === 'dark' && $style.Dark
              ]"
            >
              <Icon
                v-if="hasPrimaryIcons && item.icon"
                :icon="'fa-fw ' + item.icon"
                :class="[
                  $style.Item_icon,
                  $style.Item_icon_min,
                ]"
              />
            </a>
          </template>
          <template #default="{hide}">
            <div
              @mouseenter="mouseEnterSub"
              @mouseleave="mouseLeaveSub(hide)"
              :class="[
                $style.Content,
                $style.SubItemContent,
                theme === 'dark' && $style.SubItemContent_Dark,
                min && $style.SubItemContent_min
              ]"
            >
              <Item
                v-for="child in item.items"
                :key="child.id"
                :item="child"
                :theme="theme"
                :location="location"
                @click="(child, event) => $emit('click', child, event)"
              />
            </div>
          </template>
        </Popper>

        <div
          v-if="!isSubItem && (isActive || simpleMatch.isActive) && !min"
          :class="$style.Content"
        >
          <Item
            v-for="child in item.items"
            :key="child.id"
            :item="child"
            :theme="theme"
            :location="location"
            is-sub-item
            @click="(child, event) => $emit('click', child, event)"
          />
        </div>
      </div>
    </template>
  </MaybeRouterLink>
</template>

<script>
import {Icon} from '@/components/Icon'
import {MaybeRouterLink} from '@/components/MaybeRouterLink'
import Tooltip from '../../Tooltip/Tooltip.vue'

export default {
  name: 'Item',
  components: {
    Icon,
    MaybeRouterLink,
    Tooltip
},
  props: {
    theme: {
      type:String,
      default:'light'
    },
    item: {
      type: Object
    },
    isSubItem: {
      type: Boolean
    },
    location: {
      type: String
    },
    hasPrimaryIcons: {
      type: Boolean
    },
    min: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconSubMenuState: {
        onSub: false,
        onIcon: false
      }
    }
  },
  computed: {
    hasChildren() {
      return !this.isSubItem && Array.isArray(this.item.items)
    },
    simpleMatch() {
      const {location, item} = this
      const match = {
        isActive: false,
        isExactActive: false
      }
      if (item.active) {
        match.isActive = true
        match.isExactActive = true
      } else if (location) {
        if (this.item.id === location) {
          match.isActive = true
          match.isExactActive = true
        } else if (!this.isSubItem && item.items) {
          for (const child of item.items) {
            if (child.id === location) {
              match.isActive = true
              break
            }
          }
        }
      }
      return match
    }
  },
  methods: {
    mouseEnterIcon(show) {
      show()
      this.iconSubMenuState.onIcon = true
    },
    mouseLeaveIcon(hide) {
      setTimeout(() => {
        if (!this.iconSubMenuState.onSub) {
          hide()
        }
      }, 100)
      this.iconSubMenuState.onIcon = false
    },
    mouseEnterSub() {
      this.iconSubMenuState.onSub = true
    },
    mouseLeaveSub(hide) {
      setTimeout(() => {
        if (!this.iconSubMenuState.onIcon) {
          hide()
        }
      }, 100)
      this.iconSubMenuState.onSub = false
    }
  }
}
</script>

<style lang="scss" module>
@import '@/styles/variables';

$itemXPadding: 13px;

.ItemContainer {
  margin-top: 3px;
}

.Item {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px $itemXPadding;
  padding: 5px;
  background: transparent;
  border-radius: 3px;
  font-size: 14px;
  color: #202223;
  text-decoration: none;

  .isSubItem & {
    padding-left: 40px;
    color: #6d7175;
  }

  .isExactActive:not(.hasChildren) > & {
    color: var(--nav-focusColor);
    background: #edeeef;
  }

  &:hover {
    background: #f1f2f3;
    cursor: pointer;
  }

  &.Dark {
    color: var(--nav-textColor);
    .Item_icon {
      color: var(--nav-textColor);
    }
    .isExactActive, .isActive.min & {
      background: var(--nav-focusColor);
      color: var(--nav-focusTextColor);
      .Item_icon {
        color: var(--nav-focusTextColor);
      }
    }
    .isSubItem & {
      padding-left: 20px;
      background: none;
      color: var(--nav-textColor);
    }
    .isExactActive:not(.hasChildren) > & {
      background-color: var(--nav-focusColor);
      color: var(--nav-focusTextColor);
      .Item_icon {
        color: var(--nav-focusTextColor);
      }
    }
    &:hover {
      background-color: var(--nav-focusColor);
      color: var(--nav-focusTextColor);
      .Item_icon {
        color: var(--nav-focusTextColor);
      }
    }
    .isExactActive:not(.hasChildren).isSubItem & {
      background-color: var(--nav-focusColor);
      color: var(--nav-focusTextColor);
    }
  }
}

.Item_icon {
  flex: 0 0 27px;
  padding: 4px;
  border-radius: 3px;
  color: var(--nav-textColor);
  &.Item_icon_max {
    margin-right: 10px;
  }
}

.Item_label {
  flex: 1;
  font-weight: 500;
}

.Item_badge {
  padding: 2px 8px;
  background: #e4e5e6;
  border-radius: 20px;
  font-size: 12px;
  &.Item_badge_dark {
    color: #000;
  }
}

.SubItemContent {
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 3px;
  margin-left: 5px;

  &.SubItemContent_Dark {
    background: #28235F;
    .Item {
      color: #D5D3E7;
    }
  }

  &.SubItemContent_Dark.SubItemContent_min {
    .Item {
      // padding-left: 30px;
      &:hover {
        background-color: #514D7D;
        color: #f4f6f8;
      }

      &::before {
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
        left: 10px;
        border-radius: 50%;
        background: transparent;
      }
    }
    .isExactActive .Item {
      background-color: #514D7D;
      color: #f4f6f8;
    }
  }
}
</style>