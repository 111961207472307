import Modal from '../Modal'

const RenderNode = {
  props: ['node'],
  functional: true,
  render(h, {data, props}) {
    return h('div', data, [props.node])
  }
}

export default {
  components: {
    Modal,
    RenderNode
  },
  props: {
    opts: {
      type: Object
    },
    visible: {
      type: Boolean
    }
  },
  methods: {
    handleAction(result) {
      this.$emit('action', result)
    },
    handleLeave() {
      this.$emit('afterLeave')
    }
  }
}
