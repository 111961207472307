<script>
import {mergeData} from 'vue-functional-data-merge'
import {elementChildren} from '../../utils'

export default {
  name: 'FormLayoutGroup',
  props: {
    condensed: {
      type: Boolean,
      default: false
    }
  },
  functional: true,
  render(h, {children, data, props, $style}) {
    return h('div', mergeData(data, {
      class: [
        $style.FormLayoutGroup,
        props.condensed && $style.FormLayoutGroup__condensed
      ]
    }), elementChildren(children).map(node => {
      return h('div', {
        class: $style.FormLayoutItem
      }, [node])
    }))
  }
}
</script>

<style lang="scss" module>
$gutter: 16px;

.FormLayoutGroup {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-top: -$gutter;
  margin-left: -$gutter;
}

.FormLayoutItem {
  flex: 1 1 220px;
  margin-top: $gutter;
  margin-left: $gutter;
}

.FormLayoutGroup__condensed .FormLayoutItem {
  flex-basis: 110px;
}
</style>
