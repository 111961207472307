<template>
  <div>
    <Modal
      :title="$t('ui.timezonePicker.title')"
      :visible="visible"
      :primaryAction="{
        label: $t('ui.timezonePicker.update'),
        disabled: !timezone,
        onAction: commit
      }"
      :secondaryActions="[
        {
          label: $t('ui.timezonePicker.cancel'),
          onAction: () => visible = false
        }
      ]"
      overflow-visible
      @close="visible = false"
    >
      <FormLayout v-if="data">
        <Select
          :label="$t('ui.timezonePicker.location')"
          :placeholder="$t('ui.timezonePicker.locationPlaceholder')"
          :options="countryOptions"
          :value="countryCode"
          @change="onSelectCountry"
        />
        <Select
          :label="$t('ui.timezonePicker.timezone')"
          :placeholder="$t('ui.timezonePicker.timezonePlaceholder')"
          :options="timezoneOptions"
          v-model="timezone"
        />
      </FormLayout>
    </Modal>
    <NavField
      :loading="!data"
      :error="error"
      :help-text="helpText"
      :help-text-html="helpTextHtml"
      :help-link="helpLink"
      :label="label"
      :title="selected && selected.title"
      :description="selected && selected.description"
      :placeholder="$t('ui.timezonePicker.title')"
      :disabled="disabled"
      :readonly="readonly"
      @click="open"
    />
  </div>
</template>

<script>
import {FormLayout} from '../FormLayout'
import {Modal} from '../Modal'
import {NavField} from '../NavField'
import {Select} from '../Select'
import {load} from '../../utils'
import {dayjs} from '../../utils/date'

const TZ_DATA_URL = `${process.env.BASE_URL}tzData.js`

function getSuffix(name) {
  const m = dayjs().tz(name)
  const offset = m.format('Z')
  const abbr = m.format('z')
  return /\d/.test(abbr)
    ? offset
    : offset + ' ' + abbr
}

function formatName(name) {
  return name
    .replace(/_/g, ' ')
    .split('/')
    .reverse()
    .join(', ')
}

export default {
  name: 'TimezonePicker',
  components: {
    FormLayout,
    Modal,
    NavField,
    Select
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String
    },
    error: {
      type: String
    },
    /**
     * Help text to show below the control.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    /**
     * When `true` and the initial `value` is empty, the picker will guess the
     * current timezone for the user and update the model.
     */
    guess: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: null,
      visible: false,
      countryCode: null,
      timezone: null
    }
  },
  computed: {
    countryOptions() {
      const countries = this.data && this.data.countries
      if (countries) {
        return Object.keys(countries).map(key => {
          const d = countries[key]
          return {
            label: d.name,
            value: d.abbr
          }
        }).sort((a, b) => {
          if (a.label > b.label) {
            return 1
          }
          if (a.label < b.label) {
            return -1
          }
          return 0
        })
      }
      return []
    },
    timezoneOptions() {
      const country = this.data && this.data.countries[this.countryCode]
      if (country) {
        return country.zones.map(d => {
          return {
            label: `${formatName(d)} (${getSuffix(d)})`,
            description: dayjs().tz(d).format('lll'),
            value: d
          }
        })
      }
      return []
    },
    selected() {
      const {data, value} = this
      if (data && value) {
        return {
          title: formatName(value),
          description: getSuffix(value)
        }
      }
      return null
    }
  },
  created() {
    load([
      TZ_DATA_URL
    ]).then(() => {
      this.data = Object.freeze(window.UI_TZ_DATA)
      if (!this.value) {
        this.doGuess()
      } else {
        this.setTimezone(this.value)
      }
    })
  },
  methods: {
    doGuess() {
      const tz = dayjs.tz.guess()
      if (tz) {
        this.setTimezone(tz)
        if (this.guess) {
          this.$emit('change', tz)
        }
      }
    },
    open() {
      if (!this.disabled && !this.readonly && this.data) {
        if (this.value) {
          this.setTimezone(this.value)
        }
        this.visible = true
      }
    },
    setTimezone(value) {
      if (value && this.data) {
        const zone = this.data.zones[value]
        this.countryCode = zone.countries[0]
        this.timezone = value
      }
    },
    commit() {
      this.$emit('change', this.timezone)
      this.visible = false
    },
    onSelectCountry(code) {
      this.countryCode = code
      const country = this.data && this.data.countries[code]
      this.timezone = country.zones[0]
    }
  }
}
</script>
