var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Labelled',{attrs:{"label":_vm.label,"error":_vm.errorMessage,"help-text":_vm.helpText,"help-text-html":_vm.helpTextHtml,"help-link":_vm.helpLink}},[_c('Popper',{ref:"popper",attrs:{"placement":"bottom-start","auto-hide":"","z-index":"2005"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var show = ref.show;
var hide = ref.hide;
return [_c('div',{class:_vm.$style.DatePicker_inputWrapper},[(_vm.clearable)?_c('div',{class:[
            _vm.$style.DatePicker_clearAction,
            (_vm.readonly || _vm.disabled) && _vm.$style.DatePicker_clearActionDisabled
          ],on:{"click":_vm.clearValue}},[_c('Icon',{attrs:{"icon":"fa fa-times-circle"}})],1):_vm._e(),(!_vm.autoOpen)?_c('div',{class:[
            _vm.$style.DatePicker_openAction
          ],on:{"click":function($event){return _vm.attemptShow(show, true)}}},[_c('Icon',{attrs:{"icon":"far fa-calendar-day"}})],1):_vm._e(),_c('input',{class:[
            _vm.$style.DatePicker_input,
            _vm.errorMessage && _vm.$style.DatePicker_input__hasError
          ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"text"},domProps:{"value":_vm.inputValue},on:{"input":function($event){_vm.inputValue = $event.target.value},"change":function($event){return _vm.setFromInput($event.target.value)},"click":function($event){return _vm.attemptShow(show)},"focus":function($event){return _vm.attemptShow(show)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setFromInput($event.target.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return hide.apply(null, arguments)}]}})])]}}])},[_c('div',{class:_vm.$style.DatePicker_popper},[_c('Calendar',{attrs:{"type":_vm.type,"model-format":_vm.modelFormat,"value":_vm.value,"disable-date":_vm.disableDate,"min-time":_vm.minTime,"max-time":_vm.maxTime},on:{"change":function (val) { return _vm.setFromCalendar(val); },"close":_vm.onClose}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }