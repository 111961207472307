<script>
import {mergeData} from 'vue-functional-data-merge'
import styles from './Stack.module.scss'

export default {
  name: 'StackItem',
  props: {
    /**
     * Fill available horizontal space in the stack with this item.
     */
    fill: {
      type: Boolean,
      default: false
    }
  },
  functional: true,
  render(h, {children, data, props}) {
    return h('div', mergeData(data, {
      class: [
        styles.Item,
        props.fill && styles.Item__fill
      ]
    }), children)
  }
}
</script>
