<template>
  <Labelled
    :label="label"
    :error="error"
    :help-text="helpText"
    :help-text-html="helpTextHtml"
    :help-link="helpLink"
  >
    <div
      class="UIElement"
      :class="[
        $style.AutoComplete,
        error && $style.hasError,
        isEmpty && $style.isEmpty,
        isOpen && filteredOptions.length && $style.isOpen,
        disabled && $style.isDisabled,
        readonly && $style.isReadonly,
        $style['popperPlacement_' + popperPlacement]
      ]"
    >
      <div
        :class="$style.control"
        @click="open"
        @keydown="onKeydown"
      >
        <input
          ref="control"
          :tabindex="disabled ? -1 : 0"
          :class="[
            $style.AutoComplete_input,
            error && $style.AutoComplete_input__hasError
          ]"
          :value="inputValue"
          :placeholder="placeholder"
          :disabled="disabled"
          :readonly="readonly"
          @input="onInput"
          type="text"
        />
        <!-- <pre>inputValue:{{inputValue}}</pre> -->
      </div>
      <div
        ref="dropdown"
        :class="$style.Dropdown"
        v-show="isOpen"
      >
        <ScrollPane
          :class="$style.Dropdown_content"
          ref="list"
        >
          <div
            v-for="option in filteredOptions"
            :key="option.index"
            :class="[
              $style.Option,
              activeIndex === option.index && $style.Option__active
            ]"
            @mousedown.prevent="select(option.value)"
            @mouseover="activeIndex = option.index"
          >
            <div :class="$style.Option_content">
              <div :class="$style.Option_label">{{option.value}}</div>
            </div>
          </div>
        </ScrollPane>
      </div>
    </div>
  </Labelled>
</template>

<script>
import {Labelled} from '../Labelled'
import {ScrollPane} from '../ScrollPane'
import createPopper from '../../utils/createPopper'
import {scrollIntoViewIfNeeded} from '../../utils'

export default {
  name: 'AutoCompleteText',
  components: {
    Labelled,
    ScrollPane
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    /**
     * Label for the control.
     */
    label: {
      type: String
    },
    /**
     * Error text to show below the control.
     */
    error: {
      type: String
    },
    /**
     * Help text to show below the control.
     */
    helpText: {
      type: String
    },
    /**
     * Renders help text as raw HTML. Use with caution.
     */
    helpTextHtml: {
      type: String
    },
    /**
     * Renders a help icon next to the label which links to an external page.
     */
    helpLink: {
      type: String
    },
    /**
     * A array of values when using the `multiple` mode, otherwise a single
     * value of any type.
     */
    value: {
      type: null
    },
    /**
     * The value to consider as "empty". When `value` is equal to this the
     * placeholder option will be shown. If an array is provided then any of its
     * values will be considered as empty.
     */
    emptyValue: {
      type: null,
      default: ''
    },
    /**
     * The options to show for autocompletion. This should just be an array of strings. They do not have to be unique.
     */
    options: {
      type: Array,
      default: () => []
    },
    /**
     * Placeholder text to display when the input has no value.
     */
    placeholder: {
      type: String
    },
    /**
     * Disables the control and prevents all interaction.
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Prevents the user from editing the control value.
     */
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      inputValue: null,
      popperPlacement: 'bottom',
      activeIndex: -1
    }
  },
  watch: {
    value: {
      handler(value) {
        this.inputValue = value
      },
      immediate: true
    },
    numCurrentOptions: {
      handler() {
        this.activeIndex = -1
      }
    }
  },
  computed: {
    isEmpty() {
      const {emptyValue, inputValue} = this
      return emptyValue === inputValue
    },
    filteredOptions() {
      const {filterFn, options, inputValue} = this
      const trimmedSearchText = inputValue && inputValue.trim()
      // All the options in the final output need to have an `index` property
      // to indicate their order in the dropdown to allow keyboard navigation.
      let opts = options
      if (filterFn && trimmedSearchText) {
        // Filter options for search text
        opts = filterFn(trimmedSearchText, opts)
      }
      // Cache the indicies of visible options for keyboard navigation
      opts = opts.map((opt, i) => {
        // Create new objects to avoid mutating the original options
        return {
          value: opt,
          index: i
        }
      })
      return opts
    },
    numCurrentOptions() {
      return this.filteredOptions.length
    }
  },
  beforeDestroy() {
    if (this._popper) {
      this._popper.destroy()
      this._popper = null
    }
    this.setDocClickHandler(false)
  },
  methods: {
    onInput(event) {
      this.inputValue = event.target.value
      this.emitChange(this.inputValue, event)
    },
    onKeydown(event) {
      const code = event.code
      if (!this.isOpen) this.open()
      // Dropdown is open.
      if (code === 'Enter') {
        // Toggle active option.
        event.preventDefault()
        const option = this.findOption(
          (opt) => opt.index === this.activeIndex
        )
        this.select(option ? option.value : this.inputValue)
      } else if (code === 'ArrowDown') {
        // Navigate down/forwards in the list.
        event.preventDefault()
        this.activeIndex += 1
        if (this.activeIndex > this.numCurrentOptions - 1) {
          this.activeIndex = this.numCurrentOptions - 1
        }
        this.scrollActiveOptionIntoView()
      } else if (code === 'ArrowUp') {
        // Navigate up/backwards in the list.
        event.preventDefault()
        this.activeIndex -= 1
        if (this.activeIndex < -1) this.activeIndex = -1
        this.scrollActiveOptionIntoView()
      } else if (code === 'Escape') {
        // Close the dropdown.
        this.close()
        // Ensure the main control is focused in case the search input had
        // focus. This is so that the focus outline appears.
        this.$refs.control.focus()
      } else if (code === 'Tab') {
        this.close()
      }
    },
    filterFn(filterText, options) {
      const text = filterText.toLowerCase()
      return options.filter((d) => {
        return (
          d.toLowerCase().indexOf(text) >= 0
        )
      })
    },
    findOption(predicate) {
      for (const option of this.filteredOptions) {
        if (predicate(option)) {
          return option
        }
      }
    },
    findOptionWithLabelStart(label) {
      return this.findOption(
        (opt) =>
          opt.value
            .toLowerCase()
            .trim()
            .indexOf(label) === 0
      )
    },
    scrollActiveOptionIntoView() {
      this.$nextTick(() => {
        const activeOptionEl = this.$refs.list.$el.querySelector(
          'div[class*="Option__active"]'
        )
        if (activeOptionEl) {
          scrollIntoViewIfNeeded(activeOptionEl)
        }
      })
    },
    open() {
      if (this.disabled || this.readonly) {
        return
      }

      this.isOpen = true
      this.setDocClickHandler(true)

      this.activeIndex = -1

      // Position the dropdown popper.
      this.$nextTick(() => {
        if (this._popper) {
          this._popper.destroy()
        }
        let lastPopperPlacement = 'bottom'
        this._popper = createPopper(this.$refs.control, this.$refs.dropdown, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 0]
              }
            },
            {
              name: 'detectFlip',
              phase: 'afterWrite',
              enabled: true,
              fn: ({state}) => {
                if (state.placement !== lastPopperPlacement) {
                  lastPopperPlacement = state.placement
                  this.popperPlacement = state.placement
                }
              }
            }
          ],
          onFirstUpdate: () => {
            // Scroll list to active item after showing the popper.
            this.scrollActiveOptionIntoView()
          }
        })
      })
    },
    close() {
      this.setDocClickHandler(false)
      this.isOpen = false
    },
    updatePopper() {
      if (this._popper) {
        this._popper.update()
      }
    },
    setDocClickHandler(doAdd) {
      if (doAdd && !this._onDocClick) {
        setTimeout(() => {
          this._onDocClick = () => {
            this.close()
          }
          document.addEventListener('click', this._onDocClick)
        })
      } else if (!doAdd && this._onDocClick) {
        document.removeEventListener('click', this._onDocClick)
        this._onDocClick = null
      }
    },
    emitChange(value, event) {
      if (value === '') {
        this.$emit('input', this.emptyValue, event)
      } else {
        this.$emit('input', value, event)
      }
    },
    select(value) {
      this.close()
      this.inputValue = value
      this.emitChange(value, null)
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';
@import '../../styles/mixins';

$borderColor: #c4cdd5;
$shadowInner: inset 0 1px 0 #f6f6f6;
$shadowBorderFocus: 0 0 0 1px $focusColor;
$shadowBorderError: 0 0 0 1px $errorColor;
$fontSize: 14px;
$inputHeight: 34px;

.AutoComplete {
  position: relative;
}

.AutoComplete_input {
  display: block;
  width: 100%;
  min-height: $inputHeight;
  padding: 5px 12px;
  border: 0;
  box-shadow: $shadowInner;
  border-radius: 3px;
  font-size: $fontSize;

  &:focus {
    outline: none;
  }

  &:disabled {
    background: $inputDisabledBgColor;
    cursor: $inputDisabledCursor;
  }
  &:read-only {
    background: $inputReadonlyBgColor;
    box-shadow: none;
    cursor: $inputReadonlyCursor;
  }

  @media (max-width: 640px) {
    // Use 16px as minimum font size for smaller mobile devices to prevent iOS
    // Safari from zooming the viewport.
    font-size: 16px;
  }
}

.control {

  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  min-height: $inputHeight;

  background: #fff;
  border-radius: 3px;
  border: 1px solid $inputBorderColor;
  box-shadow: $shadowInner;
  font-size: $fontSize;
  &:focus-within {
    z-index: 1;
    border-color: $focusColor;
    box-shadow: $shadowInner, $shadowBorderFocus;
  }

  .hasError & {
    background: $errorBgColor;
    border-color: $errorColor !important;

    .AutoComplete_input {
      background: $errorBgColor;
    }

    &:focus-within {
      box-shadow: $shadowInner, $shadowBorderError;
    }
  }

  .isDisabled &, .isReadonly & {
    background: #f9fafb;
    box-shadow: none !important;
    border-color: $borderColor;
    color: #919eab;
  }

  .isOpen & {
    border: 1px solid $focusColor;
    box-shadow: none;
  }
  .isOpen.popperPlacement_top & {
    border-top: 1px solid $borderColor !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 1px 5px 0 rgba(22, 29, 37, 0.2);
  }
  .isOpen.popperPlacement_bottom & {
    border-bottom: 1px solid $borderColor !important;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -1px 5px 0 rgba(22, 29, 37, 0.2);
  }
  .isOpen.hasError & {
    border: 1px solid $errorColor;
  }
}


.Dropdown {
  position: absolute;
  top: 36px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-top: 3px;
  background: #fff;
  border: 1px solid $focusColor;
  border-radius: 3px;

  .hasError & {
    border-color: $errorColor;
  }

  &[data-popper-placement='top'] {
    border-bottom-width: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -3px 5px 0 rgba(22, 29, 37, 0.2);
  }
  &[data-popper-placement='bottom'] {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 3px 5px 0 rgba(22, 29, 37, 0.2);
  }
}

.Dropdown_content {
  flex: 1 1 auto;
  max-height: 200px;
}


.Option {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }
}
.Option__active {
  background: #f5f5f5;
}
</style>
