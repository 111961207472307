<template>
  <div
    :class="$style.Toast"
  >
    <div :class="$style.Content">
      <div :class="$style.Header">
        <Icon
          v-if="item.icon"
          :class="$style.Header_icon"
          :icon="item.icon"
        />
        <div :class="$style.Header_title">
          {{item.title}}
        </div>
        <div :class="$style.Header_right">
          <Spinner
            v-if="item.loading"
            size="small"
            color="white"
          />
          <Icon
            v-else
            :class="$style.Header_close"
            icon="fal fa-times"
            @click="$emit('remove', item)"
          />
        </div>
      </div>
      <div
        :class="$style.Body"
        v-if="item.content"
      >{{item.content}}</div>
      <ProgressBar
        v-if="showProgress"
        :class="$style.ProgressBar"
        :value="item.progress || 0"
      />
      <div
        v-if="item.actions && item.actions.length"
        :class="$style.Actions"
      >
        <button
          v-for="(action, index) in item.actions"
          :key="index"
          :class="$style.Action"
          @click="action.onAction"
        >{{action.label}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {Icon} from '../Icon'
import {Spinner} from '../Spinner'
import ProgressBar from './ProgressBar'

export default {
  components: {
    Icon,
    ProgressBar,
    Spinner
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    showProgress() {
      return typeof this.item.progress === 'number'
    }
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

.Toast {
  position: relative;
  display: flex;
  align-items: center;
  width: 400px;
  max-width: calc(100vw - 32px);
  margin: 16px;
  background: #333;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);

  @media (max-width: 500px) {
    margin: 10px;
    right: 26px;
    bottom: 26px;
  }
}

.Content {
  flex: 1;
  padding: 14px 18px;
}

.Header {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.Header_icon {
  margin-right: 8px;
}
.Header_title {
  flex: 1;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Header_right {
  margin-left: auto;
}
.Header_close {
  font-size: 20px;
  opacity: 0.7;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.Body {
  margin-top: 6px;
  line-height: 1.3;
}

.ProgressBar {
  margin-top: 10px;
}

.Actions {
  margin-top: 10px;
  text-align: right;
}

.Action {
  appearance: none;
  padding: 4px 8px;
  background: $focusColor;
  border: none;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background: lighten($focusColor, 10%);
  }

  &:focus {
    outline: none;
  }

  & + & {
    margin-left: 12px;
  }
}
</style>
