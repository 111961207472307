<template>
  <Popper
    v-if="$scopedSlots.default"
    auto-hide
    :boundary="boundary"
    :disabled="disabled"
    :placement="placement"
    :offset="popperOffset"
    :zIndex="1050"
  >
    <template v-slot:default="{hide}">
      <ActionListMenu
        :items="items"
        :sections="sections"
        @hide="handleHide(hide)"
      />
    </template>
    <template v-slot:trigger>
      <slot>
        <div>asdfdf</div>
      </slot>
    </template>
  </Popper>
  <ActionListMenu
    v-else
    :items="items"
    :sections="sections"
  />
</template>

<script>
import ActionListMenu from './ActionListMenu.vue'
import {Popper} from '../Popper'

/**
 * Action lists render a list of actions or selectable options. The component
 * should wrap a button or element that should trigger the dropdown list of
 * actions when clicked.
 */
export default {
  name: 'ActionList',
  components: {
    ActionListMenu,
    Popper
  },
  props: {
    /**
     * Use for a single list of items with no sections.
     *
     * ```ts
     * interface Item {
     *   label: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url: string
     *   external: boolean
     *   onAction(): void
     *   items?: Item[]
     *   sections?: Section[]
     * }
     * ```
     */
    items: {
      type: Array,
      default: () => []
    },
    /**
     * Or, provide an array of sections with titles and items.
     * (Do not use `items` and `sections` at the same time).
     *
     * ```ts
     * interface Section {
     *   items: Item[]
     *   title?: string
     * }
     * ```
     */
    sections: {
      type: Array,
      default: null
    },
    /**
     * @see Popper
     */
    boundary: {
      type: String,
      default: 'viewport'
    },
    /**
     * @see Popper
     */
    placement: {
      type: String,
      default: 'bottom-start'
    },
    /**
     * @see Popper
     */
     popperOffset: {
      type: Number
    },
    /**
     * Disables showing the action list.
     */
    disabled: {
      type: Boolean
    }
  },
  methods: {
    handleHide(hide) {
      // Hide the popper
      hide()
      // Emit hide to parent as this might be a sub list
      this.$emit('hide')
    }
  }
}
</script>
