var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'UIElement',
    _vm.$style.AppFrame,
    _vm.sidebarMin && _vm.$style.Min,
    _vm.$slots.sidebar && _vm.$style.HasSidebar,
    _vm.layoutMode === 'alternate' && _vm.$style.Dark ],attrs:{"data-app-frame":""}},[(_vm.$slots.sidebar)?_c('ScrollPane',{class:[
      _vm.$style.SideBar,
      _vm.layoutMode === 'alternate' && _vm.$style.Dark,
      _vm.layoutMode === 'alternate' && _vm.$style.Alt,
      _vm.sidebarMin && _vm.$style.Min ],staticStyle:{"--ScrollPane-indicatorAlpha":"0.2"}},[_vm._t("sidebar")],2):_vm._e(),_c('div',{class:_vm.$style.GlobalNav,attrs:{"data-app-frame-global-nav":""}},[_vm._t("topbar")],2),_c('div',{class:_vm.$style.Content},[(_vm.loading || _vm.showLoader)?_c('div',{class:_vm.$style.Loader},[_c('div',{class:_vm.$style.Loader_content},[_c('Spinner',{class:_vm.$style.Loader_spinner,attrs:{"color":"blue","size":"small"}}),_c('div',{class:_vm.$style.Loader_text},[_vm._v(_vm._s(_vm.loaderMessage))])],1)]):_vm._e(),_c('ScrollPane',{class:_vm.$style.Main,staticStyle:{"--ScrollPane-indicatorAlpha":"0.2"},attrs:{"container-style":{
        display: 'flex'
      }}},[_c('div',{class:_vm.$style.Main_content},[_vm._t("default")],2),(_vm.$slots.footer)?_c('div',{class:_vm.$style.Footer},[_vm._t("footer")],2):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }