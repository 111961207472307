<script>
import {mergeData} from 'vue-functional-data-merge'

export default {
  name: 'SkeletonDisplayText',
  props: {
    size: {
      type: String,
      default: 'medium'
    }
  },
  functional: true,
  render(h, {data, props, $style}) {
    return h('div', mergeData(data, {
      class: [
        $style.SkeletonDisplayText,
        $style[props.size]
      ]
    }))
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

.SkeletonDisplayText {
  max-width: 12rem;
  background: $skeletonColor;
  border-radius: 3px;
  animation: SkeletonShimmerAnimation .8s linear infinite alternate;
  backface-visibility: hidden;
  will-change: opacity;
}

.tiny {
  height: 20px;
}
.small {
  height: 28px;
}
.medium {
  height: 32px;
}
.large {
  height: 44px;
}

@keyframes SkeletonShimmerAnimation {
  0% {
    opacity: 0.45;
  }
  100% {
    opacity: 0.9;
  }
}
</style>

