import Vue from 'vue'

export const AbortNextTickUntil = Symbol('AbortNextTickUntil')

/**
 * Calls `Vue.nextTick()` until the test function returns `AbortNextTickUntil`
 * or a truthy result.
 *
 * If a callback function is provided, it is passed a boolean to indicate
 * whether test succeeded (`true`) or was aborted (`false`).
 *
 * Also returns a promise with the same result.
 * @param {() => boolean | undefined} testFn
 * @param {(success: boolean) => void} [callbackFn]
 * @returns {Promise<boolean | undefined>} Returns a promise with the result of
 * the
 */
export function nextTickUntil(testFn, callbackFn) {
  return new Promise((resolve) => {
    const next = () => {
      const result = testFn()
      if (result === AbortNextTickUntil) {
        if (callbackFn) {
          callbackFn(false)
        }
        resolve(false)
      } else if (result) {
        if (callbackFn) {
          callbackFn(true)
        }
        resolve(true)
      } else {
        Vue.nextTick(() => next())
      }
    }
    next()
  })
}
