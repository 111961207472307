<script>
import {mergeData} from 'vue-functional-data-merge'

export default {
  name: 'ButtonGroupItem',
  props: {
    align: {
      type: String
    }
  },
  functional: true,
  render(h, {children, data}) {
    return h('div', mergeData(data, {
      attrs: {
        'data-button-group-item': true
      }
    }), children)
  }
}
</script>
