<script>
let didInitHistoryHooks = false

function wrapHandler(handler, type) {
  return function() {
    const result = handler.apply(this, arguments)
    window.dispatchEvent(new Event(type))
    return result
  }
}

function initHistoryHooks() {
  if (!didInitHistoryHooks) {
    didInitHistoryHooks = true
    history.pushState = wrapHandler(history.pushState, 'ui-history-change')
    history.replaceState = wrapHandler(history.replaceState, 'ui-history-change')
  }
}

/**
 * @param url {string}
 */
function isExternal(url) {
  if (typeof url === 'string') {
    return new URL(url, location.href).origin !== location.origin
  }
  if (url && typeof url === 'object') {
    // Allow forcing as an external url
    return !!url.external
  }
  return false
}

export default {
  props: {
    /**
     * The target location of the route. See https://router.vuejs.org/api/#to
     * for usage.
     */
    to: {
      type: [String, Object, null]
    },
    /**
     * Render the link as another tag type, e.g. `<div>`.
     */
    tag: {
      type: String,
      default: 'a'
    },
    /**
     * Enables custom rendering of the link and prevents the default output
     * which wraps the slot content with `tag`.
     */
    custom: {
      type: Boolean
    },
    /**
     * Allows matching only using the path section of the url, effectively
     * ignoring the query and the hash sections.
     */
    exactPath: {
      type: Boolean,
      default: true
    },
    /**
     * Removes the defaylt link styling of `<a>` tags.
     */
    plain: {
      type: Boolean
    },
    /**
     * Set to `false` to disable route-based matching to determine active states.
     */
    matchRoutes: {
      type: Boolean,
      default: true
    }
  },
  created() {
    // If not using Vue Router, watch for regular history state changes
    if (!this.$router) {
      initHistoryHooks()
      this.onHistoryChange = () => this.$forceUpdate()
      window.addEventListener('ui-history-change', this.onHistoryChange)
    }
  },
  beforeDestroy() {
    if (this.onHistoryChange) {
      window.removeEventListener('ui-history-change', this.onHistoryChange)
      this.onHistoryChange = null
    }
  },
  render(h) {
    if (this.custom && (!this.$scopedSlots || !this.$scopedSlots.default)) {
      throw new Error(`<maybe-router-link with to="${
        this.to
      } requires a default scoped slot when using "custom".`)
    }
    const matchRoutes = this.matchRoutes
    const external = isExternal(this.to)
    if (matchRoutes && !external && this.$router && this.to) {
      if (this.custom) {
        return h('router-link', {
          on: this.$listeners,
          props: {
            to: this.to,
            custom: true,
            exactPath: this.exactPath
          },
          class: [
            'UIElement',
            this.$style.MaybeRouterLink,
            this.plain && this.$style.plain
          ],
          scopedSlots: {
            default: props => {
              return this.$scopedSlots.default(props)
            }
          }
        })
      }
      return h('router-link', {
        on: this.$listeners,
        props: {
          to: this.to,
          exactPath: this.exactPath
        },
        class: [
          'UIElement',
          this.$style.MaybeRouterLink,
          this.plain && this.$style.plain
        ]
      }, this.$slots.default)
    }
    const slotProps = {
      href: undefined,
      isActive: false,
      isExactActive: false
    }
    const path = typeof this.to === 'string'
      ? this.to
      : (this.to && this.to.path)
    if (this.to && !path) {
      throw new Error(`<maybe-router-link with to='${
        JSON.stringify(this.to)
      }' requires a string or {"path": string} value for "to" when "matchRoutes" is false OR when NOT using VueRouter.`)
    }
    if (path) {
      const url = new URL(path, location.href)
      slotProps.href = url.href
      if (matchRoutes) {
        slotProps.isActive = !external && location.pathname.startsWith(url.pathname)
        slotProps.isExactActive = !external && location.pathname === url.pathname
      }
    }
    if (this.custom) {
      const scopedSlot = this.$scopedSlots.default(slotProps)
      if (scopedSlot.length === 1) {
        // In order to render just the scoped slot content merged with the
        // classes and listeners, we need to use a compoennt which simply
        // returns the default slot. This avoids wrapping the scoped slot
        // content in another element.
        const DefaultSlotPassThrough = {
          // By using a stateful (non-functional) component, all properties and
          // listeners are inherited automatically.
          render() {
            return this.$slots.default
          }
        }
        return h(DefaultSlotPassThrough, {
          on: this.$listeners,
          class: [
            'UIElement',
            this.$style.MaybeRouterLink,
            this.plain && this.$style.plain
          ]
        }, scopedSlot)
      }
      throw new Error(`<maybe-router-link with to="${
        this.to
      }" is trying to use a scoped slot but didn't provide exactly one child.`)
    }
    return h(this.tag, {
      on: this.$listeners,
      class: [
        'UIElement',
        this.$style.MaybeRouterLink,
        this.plain && this.$style.plain
      ],
      domProps: {
        href: this.tag === 'a' ? slotProps.href : undefined
      }
    }, this.$slots.default)
  }
}
</script>

<style lang="scss" module>
.plain {
  color: inherit;
  text-decoration: none;
}
</style>
