<template>
  <div
    :class="[
      $style.LayoutSection,
      size && $style['LayoutSection__' + size]
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
const sizes = [
  'full',
  'half',
  'third',
  'primary',
  'secondary'
]
export default {
  props: {
    /**
     * The size of the section:
     *
     *  - full
     *  - half
     *  - third
     *  - primary
     *  - secondary
     */
    size: {
      type: String,
      default: null,
      validator(value) {
        return sizes.indexOf(value) >= 0
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

.LayoutSection {
  flex: 1 1 auto;
  max-width: calc(100% - #{$layoutGutter});
  margin-top: $layoutGutter;
  margin-left: $layoutGutter;
}

.LayoutSection__full {
  flex: 1 1 auto;
  min-width: 51%;
}

.LayoutSection__half {
  flex: 1 1 450px;
}

.LayoutSection__third {
  flex: 1 1 240px;
}


.LayoutSection__primary {
  flex: 100 100 680px;
}

.LayoutSection__secondary {
  // flex: 1 1 $layoutSecondaryMinWidth;
  flex: 1 1 300px;
}
</style>
