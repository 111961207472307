import {load} from '../../utils/loader'
import SyntaxHighlighter from './SyntaxHighlighter.vue'

const Loader = () => ({
  component: load([
    'https://unpkg.com/prismjs@1.17.1/prism.js',
    'https://unpkg.com/prismjs@1.17.1/themes/prism.css'
  ]).then(() => {
    return SyntaxHighlighter
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(SyntaxHighlighter)
    //   }, 1000)
    // })
  }),
  loading: {
    template: '<div>Loading</div>'
  }
})

export {
  Loader as SyntaxHighlighter
}
