var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Labelled',{attrs:{"label":_vm.label,"error":_vm.errorMessage,"help-text":_vm.helpText,"help-text-html":_vm.helpTextHtml,"help-link":_vm.helpLink}},[_c('Popper',{ref:"popper",attrs:{"placement":"bottom-start","auto-hide":"","z-index":"2005"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var show = ref.show;
var hide = ref.hide;
return [_c('div',{class:_vm.$style.TimePicker_inputWrapper},[(_vm.clearable)?_c('div',{class:_vm.$style.TimePicker_clearAction,on:{"click":_vm.clearValue}},[_c('Icon',{attrs:{"icon":"fa fa-times-circle"}})],1):_vm._e(),(!_vm.autoOpen)?_c('div',{class:[
            _vm.$style.TimePicker_openAction
          ],on:{"click":function($event){return _vm.attemptShow(show, true)}}},[_c('Icon',{attrs:{"icon":"far fa-clock"}})],1):_vm._e(),_c('input',{class:[
            _vm.$style.TimePicker_input,
            _vm.errorMessage && _vm.$style.TimePicker_input__hasError
          ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"text"},domProps:{"value":_vm.inputValue},on:{"input":function($event){_vm.inputValue = $event.target.value},"change":function($event){return _vm.setFromInput($event.target.value)},"click":function($event){return _vm.attemptShow(show)},"focus":function($event){return _vm.attemptShow(show)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setFromInput($event.target.value)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return hide.apply(null, arguments)}]}})])]}}])},[_c('div',{class:_vm.$style.TimePicker_popper},[_c('select',{class:_vm.$style.TimeSelect,domProps:{"value":_vm.selectedHour},on:{"change":function($event){return _vm.selectTime($event, 'hour')}}},_vm._l((_vm.hours),function(hour){return _c('option',{key:hour.value,attrs:{"disabled":hour.disabled},domProps:{"value":hour.value}},[_vm._v(_vm._s(hour.label))])}),0),_c('select',{class:_vm.$style.TimeSelect,domProps:{"value":_vm.selectedMinute},on:{"change":function($event){return _vm.selectTime($event, 'minute')}}},_vm._l((_vm.minutes),function(minute){return _c('option',{key:minute.value,attrs:{"disabled":minute.disabled},domProps:{"value":minute.value}},[_vm._v(_vm._s(minute.label))])}),0),_c('button',{class:_vm.$style.ConfirmButton,on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('ui.modal.close')))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }