<script>
import {mergeData} from 'vue-functional-data-merge'
import {Icon} from '../Icon'

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    /**
     * Opens the link in a new tab.
     */
    external: {
      type: Boolean
    }
  },
  functional: true,
  render(h, {data, props, slots, $style}) {
    const iconMarkup = props.external && h(Icon, {
      props: {
        icon: 'fa fa-external-link-alt'
      },
      style: 'margin-left: 6px'
    })
    return h('a', mergeData(data, {
      class: [
        'UIElement',
        $style.Link
      ],
      domProps: {
        href: props.url,
        target: props.external ? '_blank' : undefined
      }
    }), [
      slots().default,
      iconMarkup
    ])
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

.Link {
  color: $linkColor;
  text-decoration: none;

  &:hover {
    color: $linkHoverColor;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>