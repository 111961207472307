<template>
  <div
    :class="[
      'UIElement',
      $style.Navigation,
      theme === 'dark' && $style.Dark,
    ]"
  >
    <div
      v-if="title || allowMinimize || logo"
      :class="$style.TitleGroup"
    >
      <div
        v-if="title"
        :class="$style.Title"
      >{{title}}</div>
      <div
        v-else-if="logo && !childMin"
        @click.prevent="$emit('clickLogo')"
      >
        <img
          v-if="theme === 'dark'"
          :class="[
            $style.Logo,
            $style.Dark
          ]"
          src="../../assets/logo-negative-cdp.svg"
        >
        <img
          v-else
          :class="$style.Logo"
          src="../../assets/logo.svg"
        >
      </div>
      <div
        v-if="allowMinimize"
        :class="$style.Min_Button"
        @click.prevent="toggleMin"
      >
        <Icon v-if="childMin" icon="fa fa-angle-right"/>
        <Icon v-else icon="fa fa-angle-left"/>
      </div>
    </div>

    <div
      v-for="(section, ind) in sections"
      :key="section.id"
    >
      <div v-if="ind > 0" :class="$style.Section_Divider"/>
      <Section
        :theme="theme"
        :section="section"
        :location="location"
        :has-primary-icons="hasPrimaryIcons"
        @click-item="onClickItem"
        :min="childMin"
      />
    </div>
  </div>
</template>

<script>
import Section from './components/Section.vue'
import Icon from '../Icon/Icon.vue'

export default {
  components: {
    Section,
    Icon
  },
  props: {
    theme: {
      type: String,
      default: 'light'
    },
    allowMinimize: {
      type:Boolean
    },
    title: {
      type: String
    },
    location: {
      type: String
    },
    sections: {
      type: Array
    },
    /**
     * Use to stop navigation of links by automatically calling
     * `event.preventDefault` on clicks. Useful programmattic navigation while
     * without needing to remove URLs from item defintiions.
     */
    preventDefault: {
      type: Boolean
    },
    logo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      min: false,
      childMin: false
    }
  },
  computed: {
    hasPrimaryIcons() {
      for (const section of this.sections) {
        for (const item of section.items) {
          if (item.icon) {
            return true
          }
        }
      }
      return false
    }
  },
  methods: {
    toggleMin() {
      if (!this.allowMinimize) return
      this.min = !this.min
      this.$emit('toggle-min', this.min)
      setTimeout(() => {
        this.childMin = this.min
      }, !this.min ? 200 : 0)
    },
    onClickItem(item, event) {
      if (this.preventDefault) {
        event.preventDefault()
      }
      this.$emit('click-item', item, event)
      this.$emit('update:location', item.id)
      if (item.onClick) item.onClick(item)
    }
  }
}
</script>


<style lang="scss" module>
@import '@/styles/variables';

.Navigation {
  --nav-bgColor: #f4f6f8;
  --nav-textColor: #5d5f62;
  --nav-focusColor: #{$focusColor};

  position: relative;
  z-index: 0;
  width: 100%;
  // width: var(--ui-sideNav-width, 240px);
  // Bottom padding ensures that content at the bottom of long menus is not
  // obscured by the browser's link location preview in the bottom-left corner
  // of the browser. E.g. in Google Chrome.
  padding-bottom: 30px;

  &.Dark {
    --nav-bgColor: #28235f;
    --nav-textColor: #D5D3E7;
    --nav-focusColor: #514D7D;
    --nav-focusTextColor: #f4f6f8;
    .Section_Divider {
      // width: 100%;
      position: absolute;
      left: 15px;
      right: 15px;
      height: 2px;
      border-radius: 2px;
      background: var(--nav-focusColor);
    }
  }
}

.TitleGroup {
  display: flex;
  // margin: 15px;
  margin: 8px 13px 0px 13px;
  height: 50px;
  align-items: center;
  justify-content: space-between;

  .Title {
    padding: 5px 20px;
    color: var(--nav-textColor);
    font-weight: 500;
  }
  .Logo {
    width: 125px;
    margin-top: 0px;
    border-radius: 3px;
    &.Dark {
      padding: 10px;
      &:hover {
        cursor: pointer;
        background-color: var(--nav-focusColor);
      }
      width: 155px;
    }
  }
  .LogoIcon {
    width: 46px;
    // margin-left: 9px;
    margin-top: 0px;
    display: block;
    &.Dark {
      padding: 8px;
      width: 37px;
      border-radius: 3px;
      &:hover {
        cursor: pointer;
        background-color: var(--nav-focusColor);
      }
    }
  }
  .Min_Button {
    color: var(--nav-textColor);
    padding: 5px 10px;
    width: 37px;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    i {
      font-size: 18px;
    }
    &:hover {
      cursor: pointer;
      background-color: var(--nav-focusColor);
    }
  }
}
</style>
