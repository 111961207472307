<script>
export default {
  name: 'SkeletonBodyText',
  props: {
    lines: {
      type: Number,
      default: 3
    }
  },
  functional: true,
  render(h, {data, props, $style}) {
    const lines = parseInt(props.lines, 10)
    const children = []
    for (let i = 0; i < lines; i++) {
      children.push(h('div', {
        class: $style.SkeletonBodyTextLine
      }))
    }
    return h('div', data, children)
  }
}
</script>

<style lang="scss" module>
@import "../../styles/variables";

.SkeletonBodyTextLine {
  height: 8px;
  background: $skeletonColor;
  border-radius: 3px;
  animation: SkeletonShimmerAnimation .8s linear infinite alternate;
  backface-visibility: hidden;
  will-change: opacity;

  & + & {
    margin-top: 12px;
  }

  &:last-child {
    width: 80%;
  }
}

@keyframes SkeletonShimmerAnimation {
  0% {
    opacity: 0.45;
  }
  100% {
    opacity: 0.9;
  }
}
</style>

