<template>
  <Modal
    :title="opts.title"
    :primaryAction="primaryAction"
    :secondaryActions="secondaryActions"
    :visible="visible"
    @close="() => handleAction(undefined)"
    @afterLeave="handleLeave"
  >
    <render-node :node="opts.content" />
  </Modal>
</template>

<script>
import mixin from './mixin'

export default {
  mixins: [mixin],
  computed: {
    primaryAction() {
      return this.wrapAction(this.opts.primaryAction)
    },
    secondaryActions() {
      return (this.opts.secondaryActions || []).map(this.wrapAction)
    }
  },
  methods: {
    wrapAction(action) {
      return {
        ...action,
        onAction: () => {
          if (action.onAction) {
            action.onAction()
          }
          this.handleAction(action.id)
        }
      }
    }
  }
}
</script>
