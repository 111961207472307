<template>
  <div
    :class="[
      'UIElement',
      $style.Banner,
      $style[`status-${$props.status}`]
    ]"
  >
    <Icon
      v-if="icon !== false"
      :class="$style.Icon"
      :icon="icon"
    />

    <div :class="$style.content">
      <div
        v-if="title"
        :class="$style.title"
      >
        {{ title }}
      </div>
      <p
        v-if="$slots.default"
        :class="$style.info"
        :style="[title ? { 'padding-top': '5px' } : '']"
      >
        <slot></slot>
      </p>
    </div>

    <Button
      v-if="$listeners.close"
      :class="$style.close"
      type="plain"
      icon="fal fa-times"
      @click="close"
    />
  </div>
</template>

<script>
import Icon from '../Icon/Icon.vue'
import Button from '../Button/Button.vue'

/**
 * Informs users about important changes or persistent conditions. Use this
 * component if you need to communicate to users in a prominent way. Banners are
 * placed at the top of the page or section they apply to, and below the page or
 * section header.
 */
export default {
  components: {
    Icon,
    Button
  },
  props: {
    /**
     * Title of the banner.
     */
    title: {
      type: String
    },
    /**
     * Status which determines the color scheme:
     *
     *   - `default`
     *   - `info`
     *   - `success`
     *   - `attention`
     *   - `warning`
     *   - `new`
     */
    status: {
      type: String,
      default: 'default'
    },
    /**
     * Font Awesome icon.
     * @see [Icon](/#/Components/Icon) for more details.
     */
    icon: {
      type: [String, Boolean],
      default: 'fa fa-info-circle'
    }
  },
  methods: {
    close() {
      /**
       * Emitted when the banners's "X" button is clicked.
       *
       * @event close
       */
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" module>
@import '../../styles/variables';

.Banner {
  display: flex;
  border-radius: 3px;
  padding: 10px 35px 9px 10px;
  position: relative;
}

.Icon {
  color: inherit;
  margin-right: 16px;
}

.content {
  flex-grow: 1;
  margin-top: -1px;

  & p {
    margin: 0;
    font-family: $defaultFontFamily;
  }
}

.title {
  font-weight: bold;
  font-family: $defaultFontFamily;
}

.close {
  position: absolute;
  top: 5px;
  right: 12px;
  font-size: 20px;
  color: inherit;
}

// Work out default theme
// Colors etc

// Then functionality
//

@mixin bannerColor($fontColor, $color) {
  background-color: $color;
  border: 1px solid darken($color, 5%);
  color: $fontColor;
}

.status-default {
  @include bannerColor(#454f5b, #dfe3e8);
}

.status-info {
  @include bannerColor(#3e4e57, #b4e1fa);
}

.status-success {
  @include bannerColor(#414f3e, #bbe5b3);
}

.status-attention {
  @include bannerColor(#595130, #ffea8a);
}

.status-warning {
  @include bannerColor(#594430, #ffc58b);
}

.status-new {
  @include bannerColor(#212b36, #dfe3e8);
}
</style>
