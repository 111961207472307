<template>
  <SimplePopper
    :align="align"
    :disabled="disabled || !finalSections.length"
  >
    <template #trigger="{isOpen}">
      <slot :isOpen="isOpen"></slot>
    </template>
    <template #default="{close}">
      <div
        :class="[
          $style.Menu,
          hasIcons && $style.hasIcons,
          hasSelected && $style.hasSelected
        ]"
        v-bind="menuProps"
      >
        <slot name="header"></slot>
        <div
          v-for="(section, index) in finalSections"
          :key="index"
          :class="$style.Section"
        >
          <div
            v-if="section.title"
            :class="$style.Section_title"
          >{{section.title}}</div>
          <MaybeRouterLink
            v-for="(item, index) in section.items"
            :key="index"
            :class="$style.Item"
            :to="item.url"
            plain
            @click.native="onClickItem(item, close)"
          >
            <div
              v-if="hasIcons"
              :class="$style.IconWrapper"
            >
              <slot name="item-icon" :item="item">
                <Icon
                  v-if="item.icon"
                  :icon="'fa-fw ' + item.icon"
                />
              </slot>
            </div>
            <div :class="$style.Item_text">
              <div :class="$style.fItem_label">
                <slot name="item-label" :item="item">{{item.label}}</slot>
              </div>
              <div
                v-if="item.description"
                :class="$style.Item_desc"
              >{{item.description}}</div>
            </div>
          </MaybeRouterLink>
        </div>
      </div>
    </template>
  </SimplePopper>
</template>

<script>
import {Icon} from '@/components/Icon'
import {MaybeRouterLink} from '@/components/MaybeRouterLink'
import SimplePopper from './SimplePopper'

export default {
  components: {
    Icon,
    MaybeRouterLink,
    SimplePopper
  },
  props: {
    align: {
      type: String,
      default: 'right'
    },
    items: {
      type: Array,
      default: () => []
    },
    sections: {
      type: Array,
      default: () => []
    },
    menuProps: {
      type: null
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    hasIcons() {
      for (const section of this.finalSections) {
        for (const item of section.items) {
          if (item.icon) {
            return true
          }
        }
      }
      return false
    },
    hasSelected() {
      for (const section of this.finalSections) {
        for (const item of section.items) {
          if (item.selected) {
            return true
          }
        }
      }
      return false
    },
    finalSections() {
      if (this.sections && this.sections.length) {
        return this.sections
      }
      if (this.items && this.items.length) {
        return [{items: this.items}]
      }
      return []
    }
  },
  methods: {
    onClickItem(item, close) {
      if (item.onAction) {
        item.onAction(item)
      }
      this.$emit('click-item', item)
      close()
    }
  }
}
</script>

<style lang="scss" module>
@import "../common.module.scss";

.Menu {
  @include menuBase;
  padding: 8px 0;
}

.Section {
  padding: 5px 0;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
}
.Section + .Section {
  border-top: 1px solid #dbe0e4;
}
.Section_title {
  padding: 4px 0 0 16px;
  font-weight: 500;
}

.Item {
  @include menuItemBase;
}
.Item_label {
  white-space: nowrap;
}
.Item_desc {
  color: #637381;
  font-size: 13px;
}

.IconWrapper {
  min-width: 30px;
  color: #9b9b9b;
}
</style>
