<script>
import {mergeData} from 'vue-functional-data-merge'

export default {
  functional: true,
  render(h, {data, slots, $style}) {
    return h('a', mergeData(data, {
      class: [
        'UIElement',
        $style.UnstyledLink
      ]
    }), slots().default)
  }
}
</script>

<style lang="scss" module>
.UnstyledLink {
  color: currentColor;
}
</style>