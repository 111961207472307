<script>
import {mergeData} from 'vue-functional-data-merge'
import ListItem from './ListItem.vue'
import {elementChildren} from '../../utils'
import {$t} from '../../utils/lang'
import styles from './List.module.scss'

export default {
  props: {
    /**
     * The style to render the list. Options:
     *
     * - `bullet`
     * - `number`
     * - `error`
     */
    type: {
      type: String,
      default: 'bullet'
    },
    /**
     * Optionally bound the list to data.
     *
     * ```ts
     * interface ListItem {
     *   label: string
     *   content: string
     *   idLabel: string
     * }
     * ```
     */
    items: {
      type: Array
    },
    /**
     * The maximum number of items to display. If  the list is truncated
     * `moreItemsText` will be added to the bottom.
     */
    maxItems: {
      type: Number,
      default: Infinity
    },
    /**
     * The text to render when there are no items to display. Set to falsy
     * to disable.
     */
    emptyText: {
      type: String
    },
    /**
     * The text to render when items are truncated. The `{count}` symbol is replaced
     * by the number of items that were truncated.
     */
    moreItemsText: {
      type: String
    },
    /**
     * If enabled the list is rendered with condensed spacing.
     */
    tight: {
      type: Boolean
    },
    /**
     * Adds a separator between each item as well as padding so they can be
     * rendered in a card.
     */
    inCard: {
      type: Boolean
    },
    /**
     * Same as `inCard` but also negates the horizontal padding that the card
     * section provides.
     */
    inCardSection: {
      type: Boolean
    }
  },
  functional: true,
  render(h, {children, data, listeners, props}) {
    const onSelectItem = listeners['select-item']

    let items = []
    let usingDataItems = false
    if (props.items) {
      // Using data items
      items = props.items
      usingDataItems = true
    } else {
      // Using slot items
      items = elementChildren(children)
    }

    const leftover = items.length - props.maxItems
    let bottomText = ''
    if (!items.length) {
      const emptyText = props.emptyText === undefined
        ? $t('ui.lang.noItems')
        : (props.emptyText || '')
      bottomText = emptyText
    } else if (leftover > 0) {
      items = items.slice(0, props.maxItems)
      const moreItemsText = props.moreItemsText === undefined
        ? $t('ui.lang.countMoreItems')
        : (props.moreItemsText || '')
      bottomText = moreItemsText.replace('{count}', leftover)
    }

    // Wrap data items if necessary
    if (usingDataItems) {
      items = items.map(d => h(ListItem, {
        props: {
          item: d,
          listType: props.type
        },
        // Use `on` instead of `nativeOn` because ListItem is an `<li>`
        on: {
          click() {
            if (onSelectItem) {
              onSelectItem(d)
            }
          }
        }
      }))
    }

    // Append 'more items...' if necessary
    if (bottomText) {
      items.push(h(ListItem, {
        class: styles.ListItem__summary
      }, bottomText))
    }

    const tag = props.type === 'bullet' ? 'ul' : 'ol'
    return h(tag, mergeData(data, {
      class: [
        'UIElement',
        styles.List,
        props.type === 'number' && styles.number,
        props.type === 'error' && styles.List__errorList,
        props.tight && styles.List__tight,
        (props.inCard || props.inCardSection) && styles.List__inCard,
        props.inCardSection && styles.List__inCardSection,
        onSelectItem && styles.List__canSelect
      ]
    }), items)
  }
}
</script>
