<script>
import {mergeData} from 'vue-functional-data-merge'

export default {
  name: 'Icon',
  functional: true,
  props: {
    /**
     * The Font Awesome 5 icon prefix and name.
     *
     * See documentation below for more details.
     */
    icon: {
      type: String,
      required: true
    },
    fixedWidth: {
      type: Boolean
    }
  },
  render(h, {props, data}) {
    return h('i', mergeData(data, {
      class: [
        props.icon,
        props.fixedWidth ? 'fa-fw' : null
      ]
    }))
  }
}
</script>
