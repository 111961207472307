<template>
  <div
    :class="$style.Popup"
    :style="{
      display: display ? 'block' : 'none',
      visibility: visible ? 'visible' : 'hidden'
    }"
  >
    <div :class="$style.Header">{{$t('ui.lang.error')}}</div>
    <slot />
  </div>
</template>

<script>
import createPopper from '../../utils/createPopper'

export default {
  data() {
    return {
      display: false,
      visible: false
    }
  },
  beforeDestroy() {
    this.destroyPopper()
  },
  methods: {
    destroyPopper() {
      if (this.popper) {
        this.popper.destroy()
      }
      this.removeClickListener()
    },
    showAt(el) {
      this.destroyPopper()
      this.display = true
      this.$nextTick(function() {
        this.visible = true
        this.popper = createPopper(el, this.$el, {
          placement: 'right',
          onFirstUpdate: () => {
            this.addClickListener()
          }
        })
      })
    },
    hide() {
      this.display = false
      this.visible = false
      this.destroyPopper()
    },
    addClickListener() {
      if (!this.clickListener) {
        this.clickListener = () => {
          this.hide()
          this.removeClickListener()
        }
        document.addEventListener('mousedown', this.clickListener, {capture: true})
      }
    },
    removeClickListener() {
      if (this.clickListener) {
        document.removeEventListener('mousedown', this.clickListener, {capture: true})
      }
    }
  }
}
</script>

<style lang="scss" module>
.Popup {
  position: absolute;
  z-index: 10;
  padding: 16px;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.Header {
  color: #f01800;
  font-weight: bold;
}
</style>
