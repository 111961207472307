<template>
  <div :class="$style.DataTableFooter">
    <template v-if="loading">
      <SkeletonDisplayText
        size="tiny"
        style="min-width: 200px"
      />
      <SkeletonDisplayText
        size="tiny"
        style="min-width: 150px"
      />
    </template>
    <template v-else>
      <div>{{summaryText}}</div>
      <Pagination
        :offset="offset"
        :limit="limit"
        :total="total"
        :max-pages="maxPages"
        :page-sizes="pageSizes"
        @change="updateOffset"
        @update:limit="val => $emit('update:limit', val)"
      />
    </template>
  </div>
</template>

<script>
import {Pagination} from '../Pagination'
import {SkeletonDisplayText} from '../Skeleton'
import {format} from '../../utils/format'

export default {
  name: 'DataTableFooter',
  components: {
    Pagination,
    SkeletonDisplayText
  },
  props: {
    offset: {
      type: Number
    },
    limit: {
      type: Number
    },
    total: {
      type: Number
    },
    summary: {
      type: String
    },
    maxPages: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    summaryText() {
      const {offset, limit, total} = this
      const start = total
        ? Math.min(offset, total) + 1
        : 0
      const end = Math.min(start + limit - 1, total)
      const f = val => format(val, 'integer')
      const range = (start === end)
        ? f(start)
        : `${f(start)} — ${f(end)}`
      return this.$t(this.summary || 'ui.dataTable.paginationSummary', {
        range,
        total: format(total, 'integer')
      })
    }
  },
  methods: {
    updateOffset(offset) {
      /**
       * Emitted when the user selects a new offset
       * @event update-offset
       */
      this.$emit('update-offset', offset)
    }
  }
}
</script>

<style lang="scss" module>
.DataTableFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: #f4f6f8;
  border-radius: 0 0 3px 3px;
  color: #637381;
}
</style>
