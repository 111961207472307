<template>
  <div :class="['UIElement', $style.PageActions]">
    <Button
      v-for="(action, index) in secondaryActions"
      :key="index"
      :type="action.type"
      :class="$style.Action"
      :disabled="action.disabled"
      :icon="action.icon"
      :url="action.url"
      :external="action.external"
      @click="action.onAction"
    >{{action.label}}</Button>
    <Button
      type="primary"
      :class="$style.PrimaryAction"
      :disabled="primaryAction.disabled"
      :icon="primaryAction.icon"
      :url="primaryAction.url"
      :external="primaryAction.external"
      @click="primaryAction.onAction"
    >{{primaryAction.label}}</Button>
  </div>
</template>

<script>
import {Button} from '../Button'

export default {
  components: {
    Button
  },
  props: {
    /**
     * The main page action options.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // Defaults to "primary". See `Button` for other types.
     *   type: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url?: string
     *   external: boolean
     *   onAction?(): void
     * }
     * ```
     */
    primaryAction: {
      type: Object
    },
    /**
     * Optional secondary actions. These actions and the action groups are
     * displayed below the page header and collapse into a dropdown on small
     * screens.
     *
     * ```ts
     * interface Action {
     *   label: string
     *   // Defaults to "default". See `Button` for other types.
     *   type: string
     *   // See &lt;Icon&gt; component.
     *   icon: string
     *   disabled: boolean
     *   url?: string
     *   external: boolean
     *   onAction?(): void
     * }
     * ```
     */
    secondaryActions: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" module>
.PageActions {
  display: flex;
  border-top: 1px solid #e0e3e9;
  margin-top: 20px;
  padding: 20px 0;
}

.Action + .Action {
  margin-left: 10px;
}

.PrimaryAction {
  margin-left: auto;
}
</style>
